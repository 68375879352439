import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const DayCheckBox = ({
  item,
  setDaysChecked,
  preChecked,
}: {
  item: any;
  setDaysChecked: any;
  preChecked: any;
}) => {
  const {t} = useTranslation();
  const [isChecked, setIsChecked] = useState(
    preChecked?.length === 0 ? false : preChecked?.includes(item)
  );

  const shortDay = item.slice(0, 3);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setDaysChecked(
      isChecked
        ? preChecked.filter((d: any) => d !== item)
        : [...preChecked, item]
    );
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <p
        style={{
          color: "rgba(34, 53, 109, 0.70)",
          fontFamily: "var(--font-regular)",
          fontSize: "16px",
          lineHeight: "17px",
        }}
      >
        {t(`week.${shortDay}`)}
      </p>
      <label style={{ margin: "10px" }} className="container">
        <input
          value={item}
          checked={isChecked}
          onChange={handleCheckboxChange}
          type="checkbox"
        />
        <span className="mark"></span>
      </label>
    </div>
  );
};

export default DayCheckBox;
