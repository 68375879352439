import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import AddIcon from "@mui/icons-material/Add";
import { allTimeFormats } from "src/constants/variables";
import TimeRangePicker from "../CustomDropdownTimeRange";
import { RxCross2 } from "react-icons/rx";
import BreakTimeRangePicker from "../CustomDropdownBreakTimeRange";
import { useTranslation } from "react-i18next";

const CourtSettingsLeft2days = ({
  day,
  setDayTimeBreak,
  DayTimeBreak,
  index,
}: {
  DayTimeBreak: any;
  day: any;
  setDayTimeBreak: any;
  index: any;
}) => {
  const {t} = useTranslation();
  const [isChecked, setIsChecked] = useState(DayTimeBreak?.ischecked !== undefined ? DayTimeBreak?.ischecked : false);
  //time break
  const [timeBreak, setTimeBreak] = useState<any>(DayTimeBreak?.break_times?.length !== 0 ? DayTimeBreak?.break_times : []);
  //main time
  const [time, setTime] = useState<any>(DayTimeBreak?.start_time !== undefined && DayTimeBreak.end_time !== undefined ? {"start_time":DayTimeBreak?.start_time,"end_time":DayTimeBreak?.end_time} :{});
  const [showTimeBreak, setshowTimeBreak] = useState(false);
  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClose = () => {
    setshowTimeBreak(false);
  };

  useEffect(() => {
    const days = {
      id: index,
      days: day,
      is_checked: isChecked,
      start_time: time.start_time,
      end_time: time.end_time,
      break_times:
        timeBreak?.length !== 0
          ? timeBreak?.map((item: any) => ({
              start_break: item?.start_break,
              end_break: item?.end_break,
            }))
          : null,
    };
    setDayTimeBreak((previous: any) => {
      // Check if the state array contains an object with the same id as AlltimeRange
      const indexToUpdate = previous?.findIndex(
        (item: any) => item.id === days.id
      );

      // If an object with the same id is found, update its data
      if (indexToUpdate !== -1) {
        const updatedState = [...previous];
        updatedState[indexToUpdate] = days; // Assuming AlltimeRange contains updated data
        return updatedState;
      } else {
        // If no object with the same id is found, add AlltimeRange to the state array
        return [...previous, days];
      }
    });
  }, [isChecked, time, timeBreak]);

  const removeBreak = (i: number) => {
    const filteredBreaks = timeBreak.filter(
      (item: any, index: any) => index !== i
    );
    setTimeBreak(filteredBreaks);
  };



  const maincss = {
    color: "#22356D",
    fontFamily: "var(--font-regular)",
    fontSize: "16px",
    width: "75px",
  };
  const borderInput = {
    opacity: "0.7",
    padding: "5px 8px",
    fontSize: "14px",
    border: "1px solid #EEEFEE",
    borderRadius: "7px",
    color: "#22356D",
  };
  const righttext = {
    color: "rgba(34, 53, 109, 0.70)",
    fontFamily: "var(--font-regular)",
    fontSize: "14px",
  };

  return (
    <>
      <Box
        className="CourtSettings"
        sx={{
          position:'relative',
          display: "flex",
          flexDirection: {xs:"column", sm:"row"},
          justifyContent: "space-between",
          alignItems:{xs:"baseline", sm:"center"}
        }}
      >
        {isChecked ? (
          ""
        ) : (
          <div
            style={{
              zIndex: "1",
              position: "absolute",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          ></div>
        )}
        <Box sx={{ display: "flex", flexDirection:{xs:"column", sm:"row"}, alignItems: {xs:"baseline", sm:"center"}, gap: {xs:"2px", sm:"15px"} }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <label
              style={{ marginTop: "-2px", zIndex: "2" }}
              className="container"
            >
              <input
                checked={isChecked}
                onChange={handleCheckboxChange}
                type="checkbox"
              />
              <span className="mark"></span>
            </label>
            <p style={maincss}>{t(`week.${day}`)}</p>
          </div>
          <TimeRangePicker
            TimeRange={time}
            listData={allTimeFormats}
            getTime={setTime}
          />
         
        </Box>

        <div>
          <Button
            onClick={() => setshowTimeBreak(true)}
            style={{
              width: "140px",
              color: "rgba(34, 53, 109, 0.70)",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",
              gap: "10px",
              textTransform: "unset",
            }}
          >
            {t('courts.Add time break')}
            <AddIcon style={{ verticalAlign: "middle" }} fontSize="small" />
          </Button>
        </div>
        <Dialog
          sx={{
            ".MuiDialog-paper": {
              width: "auto",
              height: "200px",
            },
          }}
          onClose={handleClose}
          open={showTimeBreak}
        >
          <DialogContent>
          <Typography
            sx={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              margin: "10px",
            }}
          >
            {t('courts.Add break times')}
          </Typography>
          <BreakTimeRangePicker
            handleClose={handleClose}
            preTime={timeBreak}
            getTime={setTimeBreak}
          />
          </DialogContent>
        </Dialog>
      </Box>
      {timeBreak?.length == 0
        ? ""
        : timeBreak?.map((item: any, index: any) => (
            <div
              key={index}
              style={{ marginBottom: "1px" }}
              className="relative"
            >
              {isChecked ? (
                ""
              ) : (
                <div
                  style={{
                    zIndex: "1",
                    position: "absolute",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                ></div>
              )}
              <Box
                key={index}
                className="flex justify-between items-center"
                sx={{
                  display:"flex",
                  justifyContent:'space-between',
                  alignItems:"center",
                  height: "28px",
                  width: "110px",
                  padding: "5px 8px",
                  rowGap: "5px",
                  borderRadius: "7px",
                  marginLeft: {xs:"0px", sm:"120px"},
                  marginBottom:{xs:"7px", sm:"0px"},
                  backgroundColor: "rgba(136, 154, 182, 0.15)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                }}
              >
                <span>
                  {item?.start_break.slice(0,5)}-
                  {item?.end_break.slice(0,5)}
                </span>
                <Box
                  onClick={() => removeBreak(index)}
                  sx={{
                    marginTop: "-2px",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#22356DB2",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <RxCross2 />
                </Box>
              </Box>
            </div>
          ))}
    </>
  );
};

export default CourtSettingsLeft2days;
