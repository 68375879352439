import * as React from "react";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { AiOutlineClose } from "react-icons/ai";

export default function CustomModel({
  payPlayers,
  customerList,
  open,
  setOpen,
  children,
  size = "lg",
}: {
  payPlayers?: boolean,
  customerList?:boolean,
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  size?: string;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');

  const handleClose = () => {
    setOpen(false);
  };
 
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{".MuiDialog-paper":{
            maxWidth:{xs:"auto !important", sm:"527px !important",md:customerList ? '658px !important' :payPlayers ? "824px !important" :'630px !important'},
            paddingInline:{xs:"10px",sm:"0px", md:'1rem'},
            width:{xs:"auto !important"}
          },
          ".MuiDialogContent-root":{
            padding:{xs:"12px 10px",sm:"20px 24px"}
          }
        }}
      >
        <DialogContent>
          <Box position={'relative'} sx={{marginBottom:{xs:"30px", sm:"0"}}}><AiOutlineClose style={{position:'absolute', right:0, color:"#22356DB2", cursor:"pointer",zIndex:'10'}} onClick={handleClose} /></Box>
          {children}
        </DialogContent>
      </Dialog>
    
  );
}
