import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { AddCustomSetting, DeleteCustomSetting, UpdateCustomSetting } from "src/api/Customer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Box, Button } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { IoClose, IoMenu } from "react-icons/io5";


const style = {
    fontFamily: 'var(--font-semibold)',
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    padding: "7px 30px",
    borderRadius: '7px'
}

const inputStyle = {border:'1px solid #EEEEFE', width: "var(--custom-fields)", padding:"7px 0px 7px 12px", marginTop:"15px",fontFamily:"var(--font-regular)",color:'#22356D'};

const commonStyle = {display:'flex', alignItems:"center", gap:{xs:"5px", sm:"7px"},flexDirection:'row', flexWrap:"wrap"};

const fieldType = [
    {
        name:"Text field",
        type:"text"
    },
    {
        name:"Numeric field",
        type:"number"
    },
    {
        name:"Date field",
        type:"date"
    },
    {
        name:"Checkbox field",
        type:"checkbox"
    },
    {
        name:"Radio Button field",
        type:"radio"
    },
    {
        name:"Drop Down field",
        type:"dropdown"
    }
]

const AddFieldModel = ({show, setShow,custFieldLength,selected,setSelected,update}:{update:any,custFieldLength:any,setSelected:any,selected:any,show:boolean, setShow:(show: boolean) => void}) => {
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const {register, control, handleSubmit, reset,watch,setValue} = useForm();
    const [isVisible,setIsVisible] = useState(false);
    const [isEditable,setisEditable] = useState(false);
  const { fields, append,remove,} = useFieldArray(
    {
      control,
      name: "options",
    }
  );
  const Options = watch("options");
    const [addField, setAddField] = useState<any>({});
    const {label} = watch();

    useEffect(()=>{ 
        const update ={
            label:selected?.columnName,
            options:selected?.options
        }

        const fieldType ={
            name:selected?.columnType==="string"?"Text field":selected?.columnType==="selectbox"?"Checkbox field":selected?.columnType==="radiobutton"?"Radio Button field":selected?.columnType==="number"?"Numeric field":selected?.columnType==="dropdown"?"Drop Down field":selected?.columnType==="date"&&"Date field",
            type:selected?.columnType
        }
        setIsVisible(selected?.visibleForUsers);
        setisEditable(selected?.editableForUsers);
        setAddField(fieldType)
        reset(update)
    },[show])
    
    const handleClose = () => {
        setShow(false);
        setSelected(undefined);
        setAddField({});
    };

    const apidata:any={
        "columnType":addField?.type==="text"?"string":addField?.type==="checkbox"?"selectbox":addField?.type==="radio"?"radiobutton":addField?.type,
        "options":Options
    }

    const handleField = async() =>{
      if(!!selected){
        apidata["oldColumnName"]=selected?.columnName;
        apidata["newColumnName"]=label;
        apidata['visibleForUsers'] =isVisible;
        apidata['editableForUsers'] =isEditable;
        // apidata["sequence"] = custFieldLength+1
        const response = await UpdateCustomSetting(apidata);
        if(response?.data?.status==="success"){
            update();
            setSelected(undefined)
            setShow(false);
            setIsVisible(false);
            setisEditable(false);
        }
      }else{
        apidata["columnName"]=label;
        apidata["sequence"] = custFieldLength+1
        apidata['visibleForUsers'] =isVisible;
        apidata['editableForUsers'] =isEditable;

        const response = await AddCustomSetting(apidata);
        if(response?.data?.status==="success"){
            update();
            setSelected(undefined)
            setShow(false);
            setIsVisible(false);
            setisEditable(false);
        }
      }
    }

    const handleAdd = () => {
       append("")
    };

    const handleRemove = (i:number) => {
          remove(i)
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
        }
    
        const { source, destination } = result;
    
        if (source.index === destination.index) {
          return;
        }
    
        const updatedItems = Array.from(Options);
        const [removed] = updatedItems.splice(source.index, 1); // Remove the item from its original position
        updatedItems.splice(destination.index, 0, removed); // Insert the item into the new position
    
       updatedItems?.map((item:any,index:number)=> setValue(`options[${index}]`,item))
      };



      const handleDelete= async()=>{
        if(!!selected){
            const response = await DeleteCustomSetting(selected?.columnName);
            if(response?.status===200){
                update();
                setSelected(undefined)
                setShow(false);
            }
        }else{
          setShow(false);
        }
      }


      
    const renderInput = () => {
        if (addField?.name === "Text field" || addField?.name === "Numeric field" || addField?.name === "Date field") {
          return (
            <input type='text' {...register("label", {required: true})} placeholder='Enter field name' style={{...inputStyle}} />
          );
        } else if (addField?.name === "Checkbox field" || addField?.name === "Radio Button field" || addField?.name === "Drop Down field") {
          return (
            <Box>
                <input type='text' {...register("label", {required: true})} placeholder='Enter field name' style={{...inputStyle}} />
                <Button onClick={handleAdd} sx={{...commonStyle,color:'#22356D',textTransform:'capitalize',cursor:'pointer', fontFamily:"var(--font-regular)", padding:"10px 0px 10px 10px"}}>Add new label <IoMdAdd  style={{cursor:'pointer'}} /></Button>
                <Box sx={{paddingLeft:"20px"}}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        {Options?.map((item: any, index: any) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <Box
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                sx={{ display:'flex',flexDirection:'row',alignItems:'center', marginBottom: "10px" }}
                                key={index}
                              >
                                <IoMenu
                                  style={{ color: "#22356D", fontSize: "1.5rem" }}
                                />
                                <input
                                 {...register(`options[${index}]`, { required: true })} 
                                  type="text"
                                  placeholder="Enter a label"
                                  style={{...inputStyle, marginTop:"0px !important"}}
                                />
                                <IoClose
                                  style={{ cursor: "pointer", width: "20px" }}
                                  onClick={() => handleRemove(index)}
                                />
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
                </Box>
            </Box>
          )
        }else{
            return null
        }
      };

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{".MuiDialog-paper":{ maxWidth:{
                xs:"calc(100% - 64px)",
                sm:'100% !important'
            } }}}
        >
            <DialogContent>
                <Box>
                    <form onSubmit={handleSubmit(handleField)}>
                        <Typography sx={{fontFamily:"var(--font-medium)",}}>{!!selected?"Edit field":"Add new field"}</Typography>
                        <Box sx={{display:'flex',flexDirection:'row',gap:'30px',marginTop:'10px'}}>
                        <Box sx={{display:'flex',flexDirection:'row'}}>
                        <Typography sx={{fontFamily:"var(--font-regular)"}}>{"Visible for user"}</Typography>
                        <label className="container">
                                        <input
                                          type="checkbox"
                                          checked={isVisible}   
                                          onChange={()=>setIsVisible(!isVisible)}
                                        />
                                        <span
                                          style={{
                                            position: "relative",
                                            top: "9px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {isVisible}
                                        </span>
                                        <span className="mark" style={{ left: "9px",top:'12px' }}></span>
                                      </label>
                        </Box>
                        <Box sx={{display:'flex',flexDirection:'row'}}>
                        <Typography sx={{fontFamily:"var(--font-regular)"}}>{"Editable by user"}</Typography>
                        <label className="container">
                                        <input
                                          type="checkbox"
                                          checked={isEditable}
                                          onChange={()=>setisEditable(!isEditable)}
                                        />
                                        <span
                                          style={{
                                            position: "relative",
                                            top: "9px",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {isEditable}
                                        </span>
                                        <span className="mark" style={{ left: "9px",top:'12px' }}></span>
                                      </label>
                        </Box>
                        </Box>
                        <Box sx={{...commonStyle, marginTop:"15px"}}>
                            {fieldType.map((item,index:number)=>
                                <Box key={index} sx={{...commonStyle, background:addField?.name==item.name ? "#22356D" : "#889AB626", color:addField?.name==item.name ? "white" : "#22356DB2", borderRadius:'7px', padding:"3px 8px", fontSize:'14px', cursor:"pointer",width:'fit-content'}} onClick={()=>{setAddField(item);reset()}}>
                                    <IoMdAdd />
                                    <p>{item.name}</p>
                                </Box>
                            )}
                        </Box>
                        <Box>
                            {renderInput()}
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} marginTop={2} >
                            <button onClick={handleDelete} style={{ ...style, color: "#22356D" }} type="button">{!!selected ?"Delete":"Cancel"}</button>
                            <button style={{ ...style, color: 'white', background: "#22356D" }} type='submit' >Save</button>
                        </Box>
                    </form>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AddFieldModel;