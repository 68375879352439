import { Box, Dialog, DialogContent, Paper, useMediaQuery } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { activeColor } from 'src/constants/color';
import { addThousandSeparator } from 'src/constants/SportSEparator';
import SearchTeammates from './SearchTeammates';
import { IoMdAdd } from 'react-icons/io';
import StatusWarning from './StatusWarning';
import theme from 'src/theme';
import { IoEyeOutline } from 'react-icons/io5';
import PaymentPopup from 'src/features/Payments/PaymentPopup';

const TeamMatesPer = ({item,events,add,update,AllPlayers,customerList,updateCustList,setSelectedCheckBox,selectEvent,index,selectedCheckbox,setSelectedCustomerId,handleEditInfo,setTeammatesData,SelectedLocation}:{item:any,index:number,setTeammatesData:any,selectedCheckbox:any,handleEditInfo:any,selectEvent:any,events:any,customerList:any,updateCustList:any,setSelectedCustomerId:any,update:any,SelectedLocation:any,add:any,setSelectedCheckBox:any,AllPlayers:any}) => {
    const [showOption, setShowOption] = useState<boolean>(false);
    const [isPayOpen,setISPayOpen] =useState<boolean>(false);
    const [showOption2, setShowOption2] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [PlayerId,setPlayerId]= useState<number>(0);
    const [SelectedPlayer,setSelectedPlayer]= useState<any>();
    const [clickedPlayer,setClickedPlayer] =useState<any>();
    const [showWarning,setshowWarning] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));



    const handleShowAndCancel =(event:any)=>{
        if(name==="show"){
          handleEditInfo(event, clickedPlayer, 'show');
          setshowWarning(false);
          setTimeout(() => {
            update(); 
         }, 1000);
        }else if(name==="cancel"){
          handleEditInfo(event, clickedPlayer, 'cancel');
          setshowWarning(false);
         setTimeout(() => {
            update(); 
         }, 1000);
        }
      }
  
      
    const handleshowWarning = (event: any, name: any,item:any) => {
      setName(name);
      setClickedPlayer(item)
      setshowWarning(true);
    };

    const handleChildClick =(child:any)=>{
        setSelectedCheckBox((prev:any)=>{
          if(prev.some((item:any)=>item?.id===child?.id)){
            return prev?.filter((item:any)=>item?.id!==child?.id);
          }else{
              const player = AllPlayers?.find((item:any)=>item?.id ===child?.id);
              return [...prev,player]
          }
        })
      }

      const handlepopup= async(data:any)=>{
        setSelectedPlayer(data)
        setISPayOpen(true);
        setPlayerId(data?.id);
      }

  return (
    <Box>
    <Box sx={{ borderRadius: "7px", width: "26px", height: "26px", textAlign: "center", padding: '0px 10px', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)' }}>{index + 1}</Box>
    <Box sx={{display:'flex', flexDirection:"column", width:'100%', paddingInline:'10px'}}>
      {/* first teammate */}
    {!!item.customer &&  <Box sx={{height:'40px'}} position={'relative'} onMouseOver={()=>setShowOption(true)} onMouseOut={()=>setShowOption(false)} display={'flex'} justifyContent={"space-between"} width={"100%"} color={activeColor} marginBottom={'5px'}>
        <Box display={"flex"} alignItems={"center"} gap={1} sx={{">img":{display:{xs:"none", sm:"block"}}}} 
          >
          <img onClick={()=>{
            const passdata ={
              ...item?.customer,id:item?.customer?.id
            }
            setSelectedCustomerId(passdata)
            setTeammatesData(true)
          }} src={item?.customer?.profile_url?item?.customer?.profile_url:"/images/Reserved.svg"} alt="user" style={{width:"35px", height:"35px",borderRadius:'50%'}}/>
          <Box sx={{fontFamily: "var(--font-regular)"}} lineHeight={'20px'}>
            <Box fontSize={'16px'} sx={{">br":{display:{xs:"block", sm:"none"},position:'relative'}}}>{item?.customer?.first_name} {item?.customer?.last_name}  {item.reserved && <span 
              style={{
                background:"#22356DB2", 
                color:'white', 
                padding:"2px 8px", 
                borderRadius:'4px',
                fontSize:isMobile ? "0.8rem" :"1rem"
                }}>Reserved Slot</span>} &nbsp; 
            {showOption && <Box  display={'flex'} gap={1.5} marginRight={1} position={'absolute'} sx={{left:{xs:"0%", sm:item.is_show?"10%":"10%"}, bottom:{xs:"-1%", sm:"unset"}}}>
          <Paper sx={{padding:'0px 6px', color:'#22356DB2', cursor:'pointer',zIndex:'99'}} onClick={(event:any)=>handleshowWarning(event,'show',item)}>{item.is_show ? "Undo" : "No Show"}</Paper>
          <Paper sx={{padding:'0px 6px', color:'#FF1313', cursor:'pointer',zIndex:'99'}} onClick={(event:any)=> handleshowWarning(event,'cancel',item)} >{item.is_canceled ? "Undo" : "Cancel"}</Paper>
          </Box>}
            <br />
              {item.is_show && <span 
              style={{
                background:"#FFFDC5", 
                color:'#838383D9', 
                padding:"2px 8px", 
                borderRadius:'4px',
                fontSize:isMobile ? "0.8rem" :"1rem"
                }}>No Show</span>}
              {item.is_canceled && <span 
              style={{
                background:"#FFE8E8", 
                color:'#FF1313D9', 
                padding:"2px 8px", 
                borderRadius:'4px',
                fontSize:isMobile ? "0.8rem" :"1rem"
                }}>Cancelled</span>}
            </Box>
            <Box sx={{display:{xs:"block", sm:"none"}, width:"61px",height:"25px", borderRadius:"5px", border:"1px solid #DDDDDD", padding:"1px 8px", background:"white"}} onClick={(event:any)=>{event.stopPropagation();setShowOption(true)}}>Action</Box>
          </Box>
        </Box>
       
      
        <Box display={"flex"} alignItems={"center"} gap={1}>
        {
          item?.status==="Paid" && <IoEyeOutline cursor={"pointer"} onClick={() => handlepopup(item)} fontSize={12}/>
        }
        <p style={{color:item?.status!=="Pending payment" ? "#5B8777": "#FF1313"}}>
        <span style={{color:'#22356DB2'}}>
          {item?.refunded_status?`Refunded | `:''}
          {item?.status==="Paid" ?`${item?.payment_method} | `:''}
        </span>
        {SelectedLocation?.currency}{" "}
        {
          item?.pending_price===0 && !item?.refunded_status ? addThousandSeparator(item?.paid_price,SelectedLocation.currency): item?.pending_price>0 ? addThousandSeparator(item?.pending_price,SelectedLocation.currency) : item?.refunded_status ? (`- ${addThousandSeparator(item?.refund_price,SelectedLocation.currency)}`):''
        }
        </p>
          <label className="container">
            <input onClick={()=>!item.refunded_status && handleChildClick(item)} checked={selectedCheckbox?.some((plar:any)=>plar?.id ===item?.id)} type="checkbox" />
            <span className="mark"></span>
          </label>
        </Box>
      </Box>}
      {/* first player to add */}
      {item.customer === null && <Box display={'flex'} justifyContent={"space-between"} width={"100%"} color={activeColor} marginBottom={'5px'}>
        <Box display={"flex"} alignItems={"center"}  gap={1}>
          <SearchTeammates index={index} updateList={updateCustList} customerList={customerList} text={'first'} customerId={item.id} addingTeam={add} />
        </Box> 
      
        <Box display={"flex"} alignItems={"center"} gap={1}>
          {/* <p style={{color:"red"}}>{SelectedLocation?.currency} {addThousandSeparator(Object.keys(selectEvent||{}).length>0 ?selectEvent?.price:events?.service?.price,SelectedLocation?.currency)}</p> */}
          <label className="container">
            <input disabled={true} type="checkbox" />
            <span className="mark"></span>
          </label>
        </Box>
      </Box>}
      {/* second teammate if added */}
      {item.pairedData?.length!==0 && <Box position={'relative'} onMouseOver={()=>setShowOption2(true)} onMouseOut={()=>setShowOption2(false)} display={'flex'} justifyContent={"space-between"} width={"100%"} color={activeColor} marginBottom={'5px'}>
        <Box display={"flex"} alignItems={"center"} gap={1} sx={{">img":{display:{xs:"none", sm:"block"}},height:'40px',}} >
          <img  onClick={()=>{
            const passdata ={
              ...item.pairedData[0]?.customer,id:item.pairedData[0]?.customer?.id
            }
            setSelectedCustomerId(passdata)
            setTeammatesData(true)
          }} src={item.pairedData[0].customer?.profile_url?item.pairedData[0].customer?.profile_url:"/images/Reserved.svg"} alt="user" style={{width:"35px", height:"35px",borderRadius:'50%'}}/>
          <Box sx={{fontFamily: "var(--font-regular)"}} lineHeight={'20px'}>
            <Box fontSize={'16px'} sx={{">br":{display:{xs:"block", sm:"none"}},position:'relative'}}>{item?.pairedData[0]?.customer?.first_name} {item?.pairedData[0]?.customer?.last_name} {item.pairedData[0].reserved && <span 
              style={{
                background:"#22356DB2", 
                color:'white', 
                padding:"2px 8px", 
                borderRadius:'4px',
                fontSize:isMobile ? "0.8rem" :"1rem"
                }}>Reserved Slot</span>} &nbsp; 
            {showOption2 && <Box display={'flex'} gap={1.5} marginRight={1} position={'absolute'} sx={{left:{xs:"0%", sm:item.pairedData[0].cancel?"10%":"10%"}, bottom:{xs:"-1%", sm:"unset"},width:'180px'}}>
          <Paper sx={{padding:'0px 6px', color:'#22356DB2', cursor:'pointer'}} onClick={(event:any)=>handleshowWarning(event,'show',item?.pairedData[0])}>{item.pairedData[0].is_show ? "Undo" : "No Show"}</Paper>
          <Paper sx={{padding:'0px 6px', color:'#FF1313', cursor:'pointer'}} onClick={(event:any)=> handleshowWarning(event,'cancel',item?.pairedData[0])} >{item.pairedData[0].is_canceled ? "Undo" : "Cancel"}</Paper>
      </Box>}
              <br />
                {item?.pairedData[0]?.is_show && <span 
                style={{
                  background:"#FFFDC5", 
                  color:'#838383D9', 
                  padding:"2px 8px", 
                  borderRadius:'4px',
                  fontSize:isMobile ? "0.8rem" :"1rem"
                  }}>No Show</span>}
                {item?.pairedData[0]?.is_canceled && <span 
                style={{
                  background:"#FFE8E8", 
                  color:'#FF1313D9', 
                  padding:"2px 8px", 
                  borderRadius:'4px',
                  fontSize:isMobile ? "0.8rem" :"1rem"
                  }}>Cancelled</span>}
            </Box>
            <Box sx={{display:{xs:"block", sm:"none"}, width:"61px",height:"25px", borderRadius:"5px", border:"1px solid #DDDDDD", padding:"1px 8px", background:"white"}} onClick={(event:any)=>{event.stopPropagation();setShowOption(true)}}>Action</Box>
          </Box>
        </Box>
       
        <Box display={"flex"} alignItems={"center"} gap={1}>
        {
          item?.pairedData[0]?.status==="Paid" && <IoEyeOutline cursor={"pointer"} onClick={() => handlepopup(item?.pairedData[0])} fontSize={12}/>
        }
        <p style={{color:item.pairedData[0]?.status!=="Pending payment" ?"#5B8777": "#FF1313"}}>  <span style={{color:'#22356DB2'}}>
          {item.pairedData[0]?.refunded_status?`Refunded | `:''}
          {item?.pairedData[0]?.status==="Paid" ?`${item?.pairedData[0]?.payment_method} | `:''}
        </span>
        {SelectedLocation?.currency}{" "}
        {
           item?.pairedData[0]?.pending_price===0 && !item?.pairedData[0]?.refunded_status ? addThousandSeparator(item?.pairedData[0]?.paid_price,SelectedLocation.currency): item?.pairedData[0]?.pending_price>0 ? addThousandSeparator(item?.pairedData[0]?.pending_price,SelectedLocation.currency) : item?.pairedData[0]?.refunded_status ? (`- ${addThousandSeparator(item?.pairedData[0]?.refund_price,SelectedLocation.currency)}`):''
        }
        {/* {
          item?.pairedData[0]?.pending_price===0 && !item?.pairedData[0]?.refunded_status ? (item?.pairedData[0]?.paid_price): item?.pairedData[0]?.pending_price>0 ? (item?.pairedData[0]?.pending_price) : item?.pairedData[0]?.refunded_status ? (`- ${item?.pairedData[0]?.refund_price}`):''
        } */}
         </p>
        <label className="container">
          <input onClick={()=>!item.pairedData[0].refunded_status && handleChildClick(item?.pairedData[0])} checked={selectedCheckbox?.some((plar:any)=>plar?.id ===item?.pairedData[0]?.id)} type="checkbox" />
          <span className="mark"></span>
        </label>
        </Box>

      </Box>}

     

      {/* second teammate to add */}
      {item.pairedData.length==0 && <Box display={'flex'} justifyContent={"space-between"} width={"100%"} color={activeColor} marginBottom={'5px'}>
        <Box display={"flex"} alignItems={"center"}  gap={1}>
          <SearchTeammates index={index} updateList={updateCustList} customerList={customerList} text={'second'} customerId={item.id} addingTeam={add} />
        </Box> 
      
        <Box display={"flex"} alignItems={"center"} gap={1}>
          {/* <p style={{color:"red"}}>{SelectedLocation?.currency} {addThousandSeparator(Object.keys(selectEvent||{}).length>0 ?selectEvent?.price:events?.service?.price,SelectedLocation?.currency)}</p> */}
          <label className="container">
            <input disabled={true} type="checkbox" />
            <span className="mark"></span>
          </label>
        </Box>
      </Box>}
    </Box>

    <PaymentPopup selectedLocation={SelectedLocation} index={0} bookingData={''} open={isPayOpen} player={SelectedPlayer} setOpen={setISPayOpen} playerId={PlayerId} />

    <Dialog onClose={() => setshowWarning(false)} open={showWarning}>
        <DialogContent>
         <StatusWarning handleShowAndCancel={handleShowAndCancel} name={name} setshowWarning={setshowWarning} userData={clickedPlayer} />
        </DialogContent>
      </Dialog>
  </Box>
  )
}

export default TeamMatesPer