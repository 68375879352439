import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { RxCross2 } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import BreakTimeRangePicker from "../../components/CustomDropdownBreakTimeRange";
import formatResponse from "src/constants/FormatResponse";
import CustomCalenderTime from "../../components/CourtSetting/CustomCalenderTime";
import { BsBox2Heart } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const leftborderInput = {
  width: "200px",
  height: "28px",
  opacity: "0.9",
  padding: "5px 8px",
  fontSize: "14px",
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  color: "#22356D",
};

const ServicesSpecialRow = ({
  index,
  preSpecialDays,
  setSpecial,
  handleUpdateSpecial,
  remove,
  isBreak
}: {
  index: number;
  preSpecialDays: any;
  setSpecial: any;
  handleUpdateSpecial: any;
  remove: any;
  isBreak:boolean;
}) => {
  const {t} =useTranslation();
  const [showBreak, setShowBreak] = useState(false);
  const [showSpecialcalendar, setShowSpecialCalendar] = useState(false);

  const handleSpecialClose = () => {
    setShowSpecialCalendar(false);
  };

  const handleClose = () => [setShowBreak(false)];

  const removeBreak = (i: number) => {
    const updated = preSpecialDays[index].break_times?.filter(
      (item: any, index: number) => index !== i
    );
    setSpecial(`Special.${index}.break_times`, updated);
  };

  const handleUpdateBreak = (data: any) => {
    setSpecial(`Special.${index}.break_times`, data);
  };

  return (
    <Box sx={{ marginLeft: { xs: "0px", sm: "20px" } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "baseline", sm: "center" },
          gap: { xs: "10px", sm: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <p
            className="flex items-center justify-between"
            onClick={() => setShowSpecialCalendar(true)}
            style={leftborderInput}
          >
            <input
              style={{
                padding: "0px",
                width: "100%",
                fontSize: "14px",
                fontFamily: "var(--font-regular)",
              }}
              placeholder="Select day and time"
              value={formatResponse([preSpecialDays[index]])}
            />
            <IoIosArrowDown opacity={0.7} />
          </p>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button
            onClick={() => setShowBreak(true)}
            style={{
              color: "rgba(34, 53, 109, 0.70)",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",
              gap: "10px",
              textTransform: "none",
              display:isBreak?'flex':'none'
            }}
          >
            {t('courts.Add time break')}
            <AddIcon
              style={{ opacity: 0.7, color: "rgba(34, 53, 109, 1)" }}
              fontSize="small"
            />
          </Button>
          <span style={{ color: "rgba(34, 53, 109, 1)", cursor: "pointer" }}>
            {" "}
            <RxCross2 onClick={() => remove(index)} />
          </span>
        </Box>
      </Box>
      <div className="flex flex-row gap-1 items-center">
        {preSpecialDays[index]?.break_times?.length
          ? preSpecialDays[index]?.break_times?.map((item: any, index: any) => (
              <div
                key={index}
                style={{ marginRight: "5px" }}
                className="relative"
              >
                <span
                  key={index}
                  className="flex justify-between items-center"
                  style={{
                    height: "28px",
                    width: "110px",
                    padding: "5px 8px",
                    rowGap: "5px",
                    color: "#22356D",
                    borderRadius: "7px",
                    backgroundColor: "rgba(136, 154, 182, 0.15)",
                    fontFamily: "var(--font-regular)",
                    fontSize: "14px",
                  }}
                >
                  <span>
                    {item?.start_break.slice(0, 5)}-
                    {item?.end_break.slice(0, 5)}
                  </span>
                  <Box
                    onClick={() => removeBreak(index)}
                    sx={{
                      marginTop: "-2px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#22356DB2",
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <RxCross2 />
                  </Box>
                </span>
              </div>
            ))
          : ""}
      </div>
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            width: "auto",
            minHeight: "300px",
          },
        }}
        hideBackdrop={true}
        onClose={handleSpecialClose}
        open={showSpecialcalendar}
      >
        <DialogContent>
          <CustomCalenderTime
            isCoach={true}
            handleClose={handleSpecialClose}
            index={index}
            preTime={preSpecialDays[index]}
            setGetDateTime={(e: any) => handleUpdateSpecial(e, index)}
            setShowCalendar={showSpecialcalendar}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            width: "auto",
            height: "200px",
          },
        }}
        hideBackdrop={true}
        onClose={handleClose}
        open={showBreak}
      >
        <DialogContent>
          <Typography
            sx={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              margin: "10px",
            }}
          >
            {t('courts.Add break times')}
          </Typography>
          <BreakTimeRangePicker
            handleClose={handleClose}
            preTime={preSpecialDays[index]?.break_times}
            getTime={(e: any) => handleUpdateBreak(e)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ServicesSpecialRow;
