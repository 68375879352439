import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';

// Define the context type
interface SocketContextType {
  messages: string[];
  sendMessage: (message: string) => void;
  connectionStatus: string;
  setUrl: (url: string) => void; // Add a function to update the URL
  listenToEvent: (event: string, callback: (data: any) => void) => void;
}

// Define the provider props type
interface SocketProviderProps {
  children: ReactNode;
  initialUrl: string;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

export const SocketProvider: React.FC<SocketProviderProps> = ({ children, initialUrl }) => {
  const [messages, setMessages] = useState<string[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<string>('Disconnected');
  const [url, setUrl] = useState<string>(initialUrl);

  useEffect(() => {
    const connectSocket = () => {
      setConnectionStatus('Connecting...');
      try {
        const newSocket = io(url, {
          transports: ['websocket'], // Ensure it uses WebSocket if possible
        });

        newSocket.on('connect', () => {
          console.log('Socket.IO connected');
          setConnectionStatus('Connected');
        });

        newSocket.on('message', (message: string) => {
          console.log('Socket.IO message received:', message);
          setMessages((prevMessages) => [...prevMessages, message]);
        });

        newSocket.on('disconnect', () => {
          console.log('Socket.IO disconnected');
          setConnectionStatus('Disconnected');
        });

        newSocket.on('connect_error', (error) => {
          console.error('Socket.IO connection error:', error);
          setConnectionStatus('Error (Reconnecting...)');
        });

        setSocket(newSocket);
      } catch (error) {
        console.error('Socket.IO connection failed:', error);
        setConnectionStatus('Error (Invalid URL or Server Down)');
        setTimeout(connectSocket, 5000);
      }
    };

    connectSocket();

    return () => {
      console.log('Cleaning up Socket.IO connection');
      if (socket) {
        socket.disconnect();
      }
    };
  }, [url]); // Reconnect if the URL changes

  const sendMessage = (message: string) => {
    if (socket && socket.connected) {
      console.log('Sending Socket.IO message:', message);
      socket.emit('message', message);
    } else {
      console.error('Socket.IO is not connected.');
    }
  };

  const listenToEvent = (event: string, callback: (data: any) => void) => {
    if (socket) {
      socket.on(event, callback);
    } else {
      console.error('Socket.IO is not initialized.');
    }
  };


  return (
    <SocketContext.Provider value={{ messages, sendMessage, connectionStatus, setUrl,listenToEvent }}>
      {children}
    </SocketContext.Provider>
  );
};
