import { Box, Button, FormControl, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useGetClubWalletOfCustomer } from "src/api/Customer";
import { useGetAllPaymentMethodsByCust } from "src/api/Voucher";
import { addThousandSeparator } from "src/constants/SportSEparator";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import MainContext from "src/context/MainContext";
import Cleave from "cleave.js/react";
import { useTranslation } from "react-i18next";
import CheckboxTree from "src/features/POS/CustomCategorySelector";
import { useGetAllProducts } from "src/api/POS";
import { useGetMembershipOfCust } from "src/api/membership";
import moment from "moment";

const common = {
  display: "flex",
  alignItems: "center",
  gap: "10px",
};

const style = {
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  height: "37px",
  color:'#22356D'
};

interface walletTypes {
  currency: string;
  balance: number;
  id: number;
}

const PendingPlayersList = ({
  currentFields,
  AllCoupons,
  player_id,
  selectedLocationID,
  reset,
  splitIndex,
  serviceID,
  index,
  register,
  splidata,
  PendingPlayers,
  setValue,
  unregister,
  selectedLocation
}: {
  register: any;
  index: number;
  currentFields: any;
  reset: any;
  splitIndex: number;
  splidata: any;
  AllCoupons: any;
  serviceID: number;
  player_id: number;
  PendingPlayers: any;
  selectedLocationID: number;
  setValue: any;
  unregister:any;
  selectedLocation:any;
}) => {
  const {
    data,
    isSuccess,
    isError,
    setCustomer_id,
    refetch: RefetchClubWallet,
  }:any = useGetClubWalletOfCustomer();
  const {
    data: PaymentMethodsData,
    isSuccess: PaymentMethodSuccess,
    isError: PaymentMethodError,
    setCustomer_id: SetPCustomer_id,
    setLocation_id,
  } = useGetAllPaymentMethodsByCust();
  const {data:MembershipData,isSuccess:MembershipSuccess,isError:MembershipError,setUser_Id} = useGetMembershipOfCust();
  const {t} = useTranslation();
  const {event} = useContext(MainContext)
  const {data:allproduct,isSuccess:productsuccess,isError:productError,refetch} = useGetAllProducts();
  const [PaymentMethods, setPaymentMethods] = useState<any>([]);
  const [AllProducts,setAllProducts] = useState<any[]>([]);
  const [AllMembership,setAllMembership] = useState<any[]>([]);
  const [SelectedVoucher, setSelectedVoucher] = useState<any>();
  const [selectedCoupon, setselectedCoupon] = useState<any>();
  const { Notification, setNotification } = useContext(MainContext);
  // const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const event_id = event?.service?.event?.id;
  const booking_id = event?.service?.booking?.id;
  const lesson_id = event?.service?.lesson?.id;


  useEffect(() => {
    const filetered = PendingPlayers?.filter(
      (item: any) => item?.id === player_id
    );
    const userid = filetered[0]?.customer?.id
    setUser_Id(userid)
    setCustomer_id(userid);
    SetPCustomer_id(userid);
    setLocation_id(selectedLocationID);
  }, [player_id, selectedLocationID, PendingPlayers]);


  useEffect(() => {
    if(MembershipSuccess){
      const filtered =  MembershipData?.filter((item:any)=>item?.uses_left>0 ||item?.uses_left===-1 && item?.membership?.locations?.some((loc:any)=>loc?.id===selectedLocationID) && item?.membership?.events?.some((event:any)=>event?.id===event_id) ||item?.membership?.bookings?.some((event:any)=>event?.id===booking_id) || item?.membership?.lessons?.some((event:any)=>event?.id===lesson_id) && (item?.finish_date===null||moment(item?.finish_date).isBefore(moment(new Date()))))?.map((item:any)=>{
          const {uses_left,membership,id} = item
          const method_name = membership?.membership_name;
          return {uses_left,method_name,id}
        })

        setAllMembership(filtered)
    }else{
      setAllMembership([]);
    }
  }, [MembershipData,MembershipSuccess,MembershipError])
  


  useEffect(() => {
    const getUpdatedWallets = () => {
      return data?.map((item: walletTypes) => {
        const method_name = `Wallet ${addThousandSeparator(
          item.balance,
          item.currency
        )} ${item.currency}`;
        return { ...item, method_name };
      }) || [];
    };
  
    const getUpdatedMemberships = () => {
      return MembershipData?.filter((item: any) => {
        const hasValidUsesLeft = item?.uses_left > 0 || item?.uses_left === -1;
        const validLocation = item?.membership?.locations?.some(
          (loc: any) => loc?.id === selectedLocationID
        );
        const validEvent = item?.membership?.events?.some(
          (event: any) => event?.id === event_id
        );
        const validBooking = item?.membership?.bookings?.some(
          (event: any) => event?.id === booking_id
        );
        const validLesson = item?.membership?.lessons?.some(
          (event: any) => event?.id === lesson_id
        );
        const isNotExpired =
          item?.finish_date === null ||
          moment(item?.finish_date).isAfter(moment(new Date()));
  
        return (
          hasValidUsesLeft &&
          validLocation &&
          (validEvent || validBooking || validLesson) &&
          isNotExpired
        );
      })?.map((item: any) => {
        const { uses_left, membership, id } = item;
        const method_name = `${membership?.membership_name} [${uses_left===-1?'Unlimited':uses_left}]`;
        return { uses_left, method_name, id };
      }) || [];
    };
  
    if (PaymentMethodSuccess || MembershipSuccess || isSuccess) {
      const updatedWallets = isSuccess ? getUpdatedWallets() : [];
      const updatedMemberships = MembershipSuccess ? getUpdatedMemberships() : [];
      const paymentMethods = PaymentMethodSuccess
        ? PaymentMethodsData?.paymentMethods || []
        : [];
  
      setPaymentMethods([...updatedWallets, ...paymentMethods, ...updatedMemberships]);
    } else {
      setPaymentMethods([]);
    }
  }, [
    data,
    isSuccess,
    PaymentMethodsData,
    PaymentMethodSuccess,
    MembershipSuccess,
    MembershipData,
    selectedLocationID,
    event_id,
    booking_id,
    lesson_id,
  ]);
  

  const addVoucher = (index: number, splitIndex: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].splitPayments[splitIndex].showCoupon = true;
    // updatedPayments[index].splitPayments[splitIndex].coupon_id = ;
    reset({ payments: updatedPayments });
  };

  const removeVoucher = (index: number, splitIndex: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].splitPayments[splitIndex].showCoupon = false;
    updatedPayments[index].splitPayments[splitIndex].discount = 0;
    unregister(`payments.${index}.splitPayments.${splitIndex}.coupon_id`);
    setselectedCoupon('');
    reset({ payments: updatedPayments });
  };

  const addSplitPayment = (index: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].splitPayments.push({
      method_type: "Pending",
      discount: 0,
      showCoupon: false,
    });
    reset({ payments: updatedPayments });
  };

  const handleRemoveSplit = (index: number, splitIndex: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].splitPayments.splice(splitIndex, 1);
    reset({ payments: updatedPayments });
  };

  const handleSelectPayment = (data: any) => {
    if (data?.hasOwnProperty("balance")) {
      if(data?.currency !== selectedLocation?.currency){
        setSelectedVoucher('');
        setNotification({
          isShow: true,
          message: "Customer wallet currency is different then service currency",
          subMessage: "",
          type: "error",
        });
      }else{
        if (
          data?.balance < currentFields[index]?.splitPayments[splitIndex]?.price
        ) {
          setNotification({
            isShow: true,
            message: "Customer doesn’t have sufficient wallet credits",
            subMessage: "",
            type: "error",
          });
        }else{
          setValue(
            `payments.${index}.splitPayments.${splitIndex}.wallet_id`,
            data?.id
          );
          setValue(
            `payments.${index}.splitPayments.${splitIndex}.method_type`,
            "Wallet"
          );
          unregister(`payments.${index}.splitPayments.${splitIndex}.membership_id`)
          unregister(`payments.${index}.splitPayments.${splitIndex}.method_id`)
          setSelectedVoucher(data);
        }
      }
     
    } else if (data?.hasOwnProperty("method_name") && !data?.hasOwnProperty("uses_left")) {
      setSelectedVoucher(data);
      setValue(
        `payments.${index}.splitPayments.${splitIndex}.method_type`,
        "Custom"
      );
      unregister(`payments.${index}.splitPayments.${splitIndex}.wallet_id`);
      unregister(`payments.${index}.splitPayments.${splitIndex}.membership_id`)
      setValue(
        `payments.${index}.splitPayments.${splitIndex}.method_id`,
        data?.id
      );
    }else if (data?.hasOwnProperty("uses_left")) {
      setSelectedVoucher(data);
      setValue(
        `payments.${index}.splitPayments.${splitIndex}.method_type`,
        "Membership"
      );
      setValue(
        `payments.${index}.splitPayments.${splitIndex}.membership_id`,
        data.id
      );
      unregister(`payments.${index}.splitPayments.${splitIndex}.method_id`)
      unregister(`payments.${index}.splitPayments.${splitIndex}.wallet_id`)
    
    }
  };

  const handleSelectCoupon = (data: any) => {
    if (data?.type === "Amount") {
    if(Number(data?.discount)>Number(splidata.price)){
      setValue(
        `payments.${index}.splitPayments.${splitIndex}.discount`,
        splidata.price
      );
    }else{
      setValue(
        `payments.${index}.splitPayments.${splitIndex}.discount`,
        data?.discount
      );
    }
    } else {
      const price = currentFields[index].splitPayments[splitIndex].price;
      const discount = (price * data?.discount) / 100;
      setValue(
        `payments.${index}.splitPayments.${splitIndex}.discount`,
        discount
      );
    }
    setselectedCoupon(data);
    setValue(
      `payments.${index}.splitPayments.${splitIndex}.coupon_id`,
      data?.id
    );
  };

    

  useEffect(() => {
    if(productsuccess){
        setAllProducts(allproduct)
    }else{
        setAllProducts([]);
    }
  }, [allproduct,productsuccess,productError])


  // useEffect(() => {
  //   if(selectedItems?.length>0){
  //    const updatedPayments = [...currentFields];
  //    updatedPayments[index].products = selectedItems?.map((item:any)=>{
  //      const {price,product_name,id:product_id} = item;
  //      return {price,product_name,product_id,discount:0,showCoupon:false}
  //    })
  //    reset({ payments: updatedPayments });
    
  //   }
  //  }, [selectedItems])
   

  
  const result = AllProducts.reduce((acc:any, product:any) => {
    const { category } = product;
    const categoryObj = acc.find((obj:any) => obj.title === category.category_name);

    const productDetails = {
        id: product.id,
        product_name: product.product_name,
        category_id: product.category_id,
        club_id: product.club_id,
        price: product.price,
        status: product.status,
    };

    if (categoryObj) {
        categoryObj.arr.push(productDetails);
    } else {
        acc.push({
            title: category?.category_name,
            sequence: category?.sequence,
            arr: [productDetails],
        });
    }

    return acc;
}, []);

const handleAddProduct =() =>{
  const updatedPayments = [...currentFields];
  updatedPayments[index].products = [
      ...updatedPayments[index].products,
      {
          price: 0,
          product_name: '',
          product_id: '',
          discount: 0,
          showCoupon: false
      }
  ];
  reset({ payments: updatedPayments });
}


result.sort((a:any, b:any) => a.sequence - b.sequence);

const formattedResult = result.map(({ title, arr }:{ title:any, arr:any }) => ({ title, arr }));

const handleDiscountChange = (value:number)=>{
 if(Number(value)>Number(splidata?.price)){
  setNotification({
    isShow: true,
    message: "Discount must be less than or equal to booking",
    subMessage: "",
    type: "error",
  });
  setValue(
    `payments.${index}.splitPayments.${splitIndex}.discount`,
    0
  )
 }else{
  setValue(
    `payments.${index}.splitPayments.${splitIndex}.discount`,
    value
  )
 }
}
  

const isShowCoupon =  currentFields?.some((item:any)=>{
  return item?.splitPayments?.some((spli:any)=>spli?.showCoupon)
});
const finalINdex = currentFields[index]?.splitPayments?.length-1

  return (
    <Box sx={{marginTop:'10px',marginLeft:{xs:'0px',sm:isShowCoupon && splidata?.showCoupon ?"0px":'195px'}}}>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Box
            sx={{
              position: "relative",
              ">p": {
                fontFamily: "var(--font-regular)",
                fontSize: "0.8rem",
                color: "#22356DB2",
              },
            }}
          >
              <Button
              sx={{ display:splitIndex===finalINdex?'auto':'none',position: "absolute", left: {xs:"0.2rem", md:isShowCoupon && splidata?.showCoupon?"0px":isShowCoupon?"195px":"0.2rem"}, top: {xs:"2rem", md:`2rem`},marginTop:'5px', fontFamily:"var(--font-regular) !important", color:"#22356DB2",
              textTransform:'capitalize' }}
              onClick={() => handleAddProduct()}
            >
               {t("POS.Add products")} +
            </Button>
            <Button
              sx={{
                position: "absolute", left: {xs:"0.2rem", md:"0.2rem"}, top: {xs:"-2rem", md:"-2rem"},
                pointerEvents: splitIndex === 0 ? "auto" : "none",
                opacity: splitIndex === 0 ? "1" : "0",
               fontFamily:"var(--font-regular) !important", color:"#22356DB2",
                textTransform:'capitalize'
              }}
              onClick={() => addSplitPayment(index)}
            >
              {t('common.Split Payment')} <span>+</span>
            </Button>
            <Button
              sx={{ position: "absolute", left: {xs:"0.2rem", md:"10.2rem"}, top: {xs:splidata?.showCoupon?"6rem":"5.5rem", md:"-2rem"}, fontFamily:"var(--font-regular) !important", color:"#22356DB2",
              textTransform:'capitalize' }}
              onClick={() => {splidata?.showCoupon ? removeVoucher(index,splitIndex):addVoucher(index, splitIndex)}}
            >
              {splidata?.showCoupon ? t("common.Cancel Coupon"):t("common.Add Coupon +")}
            </Button>
          </Box>
          {/* this below row to repeat when click on split payment */}
          {/* {fields.map((field:any, index:number) => ( */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              gap: "15px",
              marginBottom: "10px",
              ">div>p": { fontFamily: "var(--font-regular) !important" },
            }}
          >
            <Box sx={{display:'flex',flexDirection:'column',width:{xs:"100%", md:"unset"}}}>
            <FormControl sx={{width:{xs:"100%", md:"unset"}}}>
              <Select
                IconComponent={KeyboardArrowDownIcon}
                placeholder="Customer type"
                value={!!SelectedVoucher ? SelectedVoucher : t("customers.Payment Method")}
                id="customdropdown"
                renderValue={(value) => {
                  if (value?.method_name) {
                    return value?.method_name;
                  }
                  return <em>{splitIndex>0?"Pending":t("customers.Payment Method")}</em>;
                }}
                sx={{
                  width: {xs:"100%", md:"180px"},
                  height: "35px",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontSize: "14px",
                  opacity: "1",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                }}
                MenuProps={{ sx: dopdowncustomcss }}
              >
                {PaymentMethods?.map((location: any) => (
                  <MenuItem
                    onClick={(e: any) => handleSelectPayment(location)}
                    key={location.id}
                    value={location.id}
                  >
                    {location?.method_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>
            {/* <input type="text" placeholder='payment' {...register(`payments.${index}.splitPayments.${splitIndex}.paymentMethod`)} style={{...style, width:"155px"}} /> */}
            {/* will show when any one click on add coupon */}
            {splidata.showCoupon && (
              <Box sx={{ ...common, position: "relative", justifyContent:{xs:"space-between", md:"unset"}, width:{xs:"100%", md:"unset"}, marginTop:{xs:"20px", md:"0px"}, ">div>div":{width:{xs:"100%", md:"180px !important"}} }}>
                <Typography>{t('title.Coupon')}</Typography>
                <FormControl sx={{width:{xs:"100%", md:"unset"}}}>
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    placeholder="Customer type"
                    value={!!selectedCoupon ? selectedCoupon : "Select Coupon"}
                    id="customdropdown"
                    renderValue={(value) => {
                      if (value?.name) {
                        return value?.name;
                      }
                      return <em>{"Select Coupon"}</em>;
                    }}
                    sx={{
                      width: {xs:"100%", md:"180px"},
                      height: "35px",
                      border: "1px solid #EEEEFE",
                      borderRadius: "7px",
                      fontSize: "14px",
                      opacity: "1",
                      fontFamily: "var(--font-regular)",
                      color: "#22356D",
                    }}
                    MenuProps={{ sx: dopdowncustomcss }}
                  >
                    {AllCoupons?.map((location: any) => (
                      <MenuItem
                        onClick={(e: any) => handleSelectCoupon(location)}
                        key={location.id}
                        value={location.id}
                      >
                        {location?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <input type="text" placeholder='coupon' {...register(`payments.${index}.splitPayments.${splitIndex}.coupon_id`)} style={{...style, width:"155px"}} /> */}
              </Box>
            )}
            <Box sx={{...common, justifyContent:{xs:"space-between", sm:"unset"}, width:{xs:"100%", md:"unset"}}}>
              <Typography>{t("common.Discount")}</Typography>
              <Cleave
                readOnly={currentFields[index].splitPayments[splitIndex].showCoupon}
                value={splidata?.discount}
                placeholder={t("common.Discount")}
                onChange={(e:any)=>handleDiscountChange(e.target.rawValue)}
                style={{ ...style, width: "110px" }}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter: "\u2024",
                }}
              />
              
            </Box>
            <Box sx={{...common, width:{xs:"100%", md:"unset"}, justifyContent:{xs:"space-between", sm:"unset"}}}>
              <Typography>{t('common.Price')}</Typography>
              <Cleave
               value={splidata?.price}
                placeholder="price"
                onChange={(e:any)=>
                  setValue(
                  `payments.${index}.splitPayments.${splitIndex}.price`,
                  e.target.rawValue
                )}
                style={{ ...style, width: "110px" }}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter: "\u2024",
                }}
              />
            </Box>
              {splitIndex!==0 &&  <Box sx={{position:'absolute',right:'10px'}} onClick={() => handleRemoveSplit(index, splitIndex)}>
              <RxCross2 />
            </Box>}
          </Box>
          {/* ))} */}
        </Box>       
      </Box>
    </Box>
  );
};

export default PendingPlayersList;
