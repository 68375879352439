export const dopdowncustomcss =   {
  '& .MuiMenu-paper': {
    maxHeight:"232px",
    '&::-webkit-scrollbar': {
      width: '14px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: "0px 7px 7px 0px",
      border: "0.5px solid #EEEFEE",
      background: "linear-gradient(0deg, rgba(238, 239, 238, 0.05) 0%, rgba(238, 239, 238, 0.05) 100%), #FFF",
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset, -4px 0px 4px 0px rgba(0, 0, 0, 0.05) inset'
    },
    '&::-webkit-scrollbar-thumb': {
      border: "4px solid #EEEFEE",
      background: "rgba(147, 147, 147, 0.40)",
      borderRadius: "7px",
      marginTop: "10px",
    }
  },
  "& .MuiButtonBase-root": {
    background:"#f3f5f8 !important",
    "&:hover":{
      background:"#22356D !important",
      color:"white !important",
      "& .MuiTypography-root":{
        color:"white !important"
      }
    }
  },
  "& .Mui-selected":{
    background:"#22356D !important",
    color:"white !important",
    "& .MuiTypography-root":{
      color:"white !important"
    }
  },
  '& .Mui-selected::after': {
    content: '"\\292C"', 
    fontSize: 16, 
    color: 'white !important',
    position: 'absolute', 
    top: '50%', 
    right: 10,
    transform: 'translateY(-50%)',
  },
}