import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import MobileDrawer from "../features/sidebar/MobileSidebar";
import Sidebar from "../features/sidebar/Sidebar";
import NotificationToast from "src/Notification/NotificationToast";
import MainContext from "src/context/MainContext";


const MainPage: React.FC<any> = () => {
  const {Notification,setNotification} = useContext(MainContext);

  useEffect(() => {
    // Apply styles to body
    document.body.style.background =
      "#889AB626"; 

    // Clean up the styles when the component unmounts
    return () => {
      document.body.style.background = "";
    };
  }, []);

  useEffect(() => {
    const handleOffline = () => {
      setNotification({isShow:true,message:"No Internet Connection",subMessage:'',type:'error'})
    };

    window.addEventListener('offline', handleOffline);

    // Check initial connection status
    if (!navigator.onLine) {
      handleOffline();
    }

    return () => {
      window.removeEventListener('offline', handleOffline);
    };
  }, []);



  return (
    <Box
    sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}>
      <Box sx={{display:{xs:'none', md:"block"}}}>
        <Sidebar />
      </Box>
      <Box sx={{display:{xs:'block', md:"none"}}}>
        <MobileDrawer/>
      </Box>
      <Box component='main' sx={{ flexGrow: 1, p: 1, width:"93%" }}>
        <Outlet />
      
      </Box>
     <NotificationToast/>
    </Box>
  );
};

export default MainPage;
