import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box,Button, Grid, Dialog, DialogContent, DialogProps, Typography, FormControl, Stack } from '@mui/material'
import MyDropdown from "../../components/Payment/LocationSelectCheckBox";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { RxCross1, RxCross2 } from "react-icons/rx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useForm } from 'react-hook-form';
import Cleave from "cleave.js/react";
import DurationSelector, { Duration } from "./DurationSelector";
import { useTranslation } from "react-i18next";
import { CreateMembership, deleteMembership, updateMembership } from "src/api/membership";
import CustomServiceSelector from "./CustomServiceSelector";
import CurrencySelector from "../../components/Admin/CurrencySelector";
import moment from "moment";
import ServiceSelection from "../../components/Payment/ServiceSeletion";
import MainContext, { locationTypes } from "src/context/MainContext";
import { activeColor } from "src/constants/color";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

const common = {display:"flex", alignItems:'center', justifyContent:'space-between'};
const selectStyle = {
    "& fieldset": { border: "none" },
    "& .MuiSelect-select": { paddingRight: "6px !important" },
    minHeight: "3px !important",
    height: "33px !important",
    padding: "7px 14px 7px 0px !important",
    borderRadius: "7px",
    border: "1px solid #EEEFEE !important",
    fontFamily: "var(--font-regular) !important",
    width:"184px",
    color: "#22356D !important",
};
const fontD = {
    fontFamily: "var(--font-regular) !important",
    color: "#22356DB2",
};
const countingSequence = Array.from({ length: 1000 }, (_, i) => i + 1);
const Uses=[
    "Unlimited",...countingSequence
]


const transformSelectedData = (data:any) => {
    const transformed = [];

    if (data.bookings && data.bookings.length > 0) {
        transformed.push({
            id: 1, // Static or dynamic ID based on your requirement
            label: "Bookings",
            children: data.bookings.map((booking:any) => ({
                id: booking.id,
                location_id : booking.location_id,
                label: `Booking - ${booking.sport.sport_name} - ${booking.duration} min`,
                sport_name:booking.sport.sport_name
            })),
        });
    }

    if (data.lessons && data.lessons.length > 0) {
        transformed.push({
            id: 2, // Static or dynamic ID based on your requirement
            label: "Lessons",
            children: data.lessons.map((lesson:any) => ({
                id: lesson.id,
                location_id : lesson.location_id,
                label: `Lesson - ${lesson?.sport?.sport_name} - ${lesson.lesson_name}`,
                sport_name:lesson.sport.sport_name
            })),
        });
    }

    if (data.events && data.events.length > 0) {
        transformed.push({
            id: 3, // Static or dynamic ID based on your requirement
            label: "Events",
            children: data.events.map((event:any) => ({
                id: event.id,
                location_id : event.location_id,
                label: `Events - ${event?.sport?.sport_name} - ${event.event_name}`,
                sport_name:event.sport.sport_name
            })),
        });
    }

    return transformed;
};

const style = {
    fontFamily: "var(--font-semibold)",
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    padding: "7px 20px",
    borderRadius: "7px",
  };

const MembershipModel = ({data, open, handleClose, locationdata, update,allServices}:{data:any, open:boolean, handleClose:any, locationdata:any, update: any,allServices:any[]}) => {
    const {t} = useTranslation();
    const {Notification,setNotification,setIsPermissionOn} = useContext(MainContext);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const { register,watch, control, setValue, handleSubmit, formState: { errors },clearErrors, reset} = useForm();
     const StaffPermission = useGetPermissions();
    const {Use,price,duration,duration_date,name,bookings,lessons,events,currency}=watch();
    const [isOpen,setIsopen] = useState<boolean>(false);
    const [deletePl, setDeletePl] = useState<boolean>(false);
    const [selectedevents, setselectedevents] = useState<any>([]);
    const [selectedlocations, setSelectedLocations] = useState<any>([]);
    const [servicesopen, setservicesopen] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const locations = locationdata.map((item:any) => {
        const {location_name,id,currency} = item;
        const name = location_name
        return { name, id ,currency}
    });

    const transformData = (data:any) => {
        return data?.map((category:any) => ({
          id: category.id,
          label: category.label,
          children: category.children.map((child:any) => {
            let label = '';
            switch (category.label) {
              case 'Bookings':
                label = `Booking - ${child.sport.sport_name} - ${child.duration} min`;
                break;
              case 'Lessons':
                label = `Lesson - ${child.sport.sport_name} - ${child.lesson_name}`;
                break;
              case 'Events':
                label = `Events - ${child.sport.sport_name} - ${child.event_name}`;
                break;
              default:
                label = child.label || '';
            }
      
            return { id: child.id, label,location_id:child.location_id };
          }),
        }));
      };

      useEffect(() => {

       const updatesss =  transformSelectedData(data||[]);
       setselectedevents(updatesss);
      
      }, [data])
      
      const AllServices = transformData(allServices);

    const handleDelete =  async () => {
        if(Object.keys(data).length>0){
            try {
                let res = await deleteMembership(data.id);
                setNotification({
                    isShow: true,
                    message: 'Membership Deleted Successfully',
                    subMessage: "",
                    type: "success",
                  });
                handleClose();
                update();
            } catch (error:any) {
                setNotification({
                    isShow: true,
                    message: error,
                    subMessage: "",
                    type: "error",
                  });
                console.log(error);
            }
        }else{
            console.log("Deleted");
        }
    }


    const onSubmit = async (value:any) => {

      if(!StaffPermission.edit_permissions){
        setIsPermissionOn(true);
        handleClose()
        return;
      }
  

        if (!duration && !duration_date) {
            setError("duration");
            setTimeout(() => setError(""), 2000);
            return;
          } else  if (selectedlocations?.length===0) {
            setError("location");
            setTimeout(() => setError(""), 2000);
            return;
          } else  if (!Uses) {
            setError("uses");
            setTimeout(() => setError(""), 2000);
            return;
          }else  if (!price) {
            setError("price");
            setTimeout(() => setError(""), 2000);
            return;
          }
        const result :any= {};

        // Iterate through each category
        selectedevents.forEach((category:any) => {
        const categoryName = category.label.toLowerCase(); // Convert label to lowercase
        const itemIds = category.children.map((item:any) => item.id); // Extract IDs

        // Store the IDs in the result object
        result[categoryName] = itemIds;
        });

        let total_data = {
            membership_name:value.name,
            currency:selectedlocations[0]?.currency||currency,
            price:value.price,
            uses_limit:value.Use!=="Unlimited"?value.Use:-1,
            duration:value.duration||moment(value.duration_date,'DD/MM/YYYY').toDate(),
            bookings: result.bookings?.length>0 ?result.bookings:undefined,
            lessons: result.lessons?.length>0 ?result.lessons:undefined,
            events: result.events?.length>0 ?result.events:undefined,
            locations: selectedlocations.map((item:{name:string, id:number}) =>item.id)||[]
        }
        
        try{
            if(Object.keys(data).length>0){
                let res = await updateMembership(data.id, total_data);
                setNotification({
                    isShow: true,
                    message: 'Membership Updated Successfully',
                    subMessage: "",
                    type: "success",
                  });
            }else{;
                let res = await CreateMembership(total_data);
                setNotification({
                    isShow: true,
                    message: 'Membership created Successfully',
                    subMessage: "",
                    type: "success",
                  });
            }
            update();
            handleClose();
        }catch(error){
            console.log(error);
        }
    }

    


    useEffect(()=>{
        if(Object.keys(data).length>0){
            let varduration;
            let varduration_date;
       
            if(Duration.some((item:any)=>item===data.duration) ){
                varduration=data.duration
                varduration_date = undefined
            }else{
                varduration_date=data.duration
                varduration=undefined
            }
            const selectedloc = locationdata?.filter((loc:locationTypes)=>data?.locations?.some((item:any)=>item?.id===loc?.id))?.map((loc:any)=>{
                const {location_name,id,currency} = loc;
                const name=location_name
                return {name,id,currency}
                  })
            
            reset({
                currency:data.currency,
                name: data.membership_name,
                price: data.price,
                Use:data.uses_limit!==-1?data.uses_limit:"Unlimited",
                duration_date:varduration_date ? moment(varduration_date).format('DD/MM/YYYY'):undefined,
                duration:varduration,
                bookings:data.bookings.map((booking:any) => ({ "id": booking.id, "label": `Booking ${booking.duration} min - ${booking.sport.sport_name}` })),
                lessons:data.lessons.map((lesson:any) => ({ "id": lesson.id, "label": `Lesson ${lesson.lesson_name} - ${lesson.sport.sport_name}` })),
                events:data.events.map((event:any) => ({ "id": event.id, "label": `${event.event_name} - ${event.sport.sport_name}` }))
            })
            setSelectedLocations(selectedloc);
        }else{
            reset({
                name: '',
                price: '',
                Use:'',
                duration:'',
                duration_date:'',
                bookings:[],
                lessons:[],
                events:[],
                currency:''
            });
            setSelectedLocations([]);
        }
    },[data]);

    const removeBreak = (parentLabel: string, remove: any) => {

        // to find index to update
        const indexToUpdate = selectedevents?.findIndex(
          (selectedData: any) => selectedData?.label === parentLabel
        );
    
        //object to update
        const parent: any = selectedevents.find(
          (item: any) => item.label === parentLabel
        );
        const filterData = parent?.children.filter(
          (item: any) => item.label !== remove.label && item.id !== remove.id
        );
        const FINALEDATA = {
          id: parent.id,
          label: parent.label,
          children: filterData,
        };
    
        let asdfjkajsd;
        if (indexToUpdate !== -1) {
          const updatedState = [...selectedevents];
          updatedState[indexToUpdate] = FINALEDATA;
          asdfjkajsd = updatedState;
        }
    
        const filtered = asdfjkajsd?.filter((item) => item.children.length > 0);
        setselectedevents(filtered);
      };

      useEffect(() => {
        if (Object.keys(errors).length > 0) {
          const timer = setTimeout(() => {
            clearErrors(); // Clear all errors after 2 seconds
          }, 2000);
          return clearTimeout(timer); // Clear the timer if the component unmounts
        }
      }, [errors, clearErrors]);


     
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{".MuiDialog-paper":{
            paddingInline:{xs:"10px",sm:"0px", md:'1rem'},
            width:{xs:"auto !important", sm:'795px !important'}
          },
          ".MuiDialogContent-root":{
            padding:{xs:"12px 10px",sm:"20px 24px"}
          }
        }}
      >
        <DialogContent>
            <Box position={'relative'} sx={{marginBottom:{xs:"30px", sm:"0"}}}>
                <AiOutlineClose style={{position:'absolute', right:0, color:"#22356DB2", cursor:"pointer",zIndex:'10'}} onClick={handleClose} />
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{fontFamily:"var(--font-regular) !important", color:"#22356D"}}>
                    <Typography sx={{width:"fit-content",fontFamily:"var(--font-semibold)", borderBottom:'1px solid #22356D'}}>{t('payments.Membership Settings')}</Typography>
                    <Box sx={{display:"flex", flexDirection:"column", gap:"15px", marginTop:"20px"}}>
                        <Box sx={common}>
                            <Box>{t('payments.Membership Name')}<span style={{color:'red'}}> *</span></Box>
                            <Box>  
                            <input 
                                id={errors.name &&'customerror'} 
                                type="text" 
                                placeholder={t('common.Enter Name')} 
                                style = {{border:'1px solid #EEEFEE', borderRadius:"7px", width:"184px", height:"33px", color:"#22356D"}} 
                                {...register("name", {required: true})}
                            />
                    </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('services.bookings.Duration')}<span style={{color:'red'}}> *</span></Box>
                            <Box width={'184px'}>
                            <input value={duration||duration_date} onClick={()=>setIsopen(true)} placeholder={t('common.Select Duration')} id={error==="duration"?'customerror':''}  style={{ 
                                    width: "184px",
                                    height:"33px",
                                    color: "rgba(34, 53, 109)",
                                    fontFamily: "var(--font-regular)",
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    borderRadius: "7px",
                                    padding: "5px 8px",
                                    border: "1px solid #EEEFEE",}} className="calendar-ui" readOnly />
                               <Dialog open={isOpen} onClose={()=>setIsopen(false)}>
                               <DialogContent>
                               <DurationSelector duration_date={duration_date} setValue={setValue} duration={duration} setIsopen={setIsopen} 
                                />
                                </DialogContent>
                               </Dialog>
                            </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('payments.Locations')}<span style={{color:'red'}}> *</span></Box>
                            <Box width={'184px'}>
                                <MyDropdown 
                                    isValidation={true}
                                    getOpeon={setservicesopen} 
                                    border={true}
                                    error={error==='location' && error}
                                    text="locations"
                                    arr={locations}
                                    selected={selectedlocations}
                                    setSelected={setSelectedLocations}
                                />
                            </Box>
                        </Box>
                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10px'}}>
                            <Box>{t('title.Services')}</Box>
                            <ServiceSelection
                            selectedlocations={selectedlocations}
                                allServices={AllServices}
                                getOpen={setservicesopen}
                                error={error == "events" && error}
                                selectedServices={selectedevents}
                                setSelecteServices={setselectedevents}
                                text="services"
                            />
                        </Box>
                        <Box id={'table-person'} sx={{display:'flex',flexDirection:'column',maxHeight:'300px',marginTop:'0px !important',paddingRight:'5px'}}>
                        {selectedevents[0]?.children?.map((prser: any, index: any) => (
                            <Box key={index} sx={{height:"33px",
                                color: "rgba(34, 53, 109)",
                                fontSize: "14px",
                                display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'
                            }}><Typography sx={{ width:'95%',  lineHeight: "17px", borderRadius: "7px",fontFamily: "var(--font-regular)",
                                padding: "5px 8px",
                                border: "1px solid #EEEFEE",}}>{prser.label}</Typography><RxCross1   onClick={() => removeBreak(selectedevents[0].label, prser)}/></Box>
                        ))}
                        {selectedevents[1]?.children?.map((prser: any, index: any) => (
                            <Box key={index} sx={{height:"33px",
                                color: "rgba(34, 53, 109)",
                                fontSize: "14px",
                                display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'
                            }}><Typography sx={{ width:'95%',  lineHeight: "17px", borderRadius: "7px",fontFamily: "var(--font-regular)",
                                padding: "5px 8px",
                                border: "1px solid #EEEFEE",}}>{prser.label}</Typography><RxCross1   onClick={() => removeBreak(selectedevents[1].label, prser)}/></Box>
                        ))}
                        {selectedevents[2]?.children?.map((prser: any, index: any) => (
                            <Box key={index} sx={{height:"33px",
                                color: "rgba(34, 53, 109)",
                                fontSize: "14px",
                                display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'
                            }}><Typography sx={{ width:'95%',  lineHeight: "17px", borderRadius: "7px",fontFamily: "var(--font-regular)",
                                padding: "5px 8px",
                                border: "1px solid #EEEFEE",}}>{prser.label}</Typography><RxCross1   onClick={() => removeBreak(selectedevents[2].label, prser)}/></Box>
                        ))}
                      </Box>
                        <Box sx={common}>
                            <Box>{t('payments.Uses')}<span style={{color:'red'}}> *</span></Box> 
                            <Box sx={{...common, width:"184px"}}>
                                <Box>
                            <FormControl>
                            <Select
                                IconComponent={KeyboardArrowDownIcon}
                                placeholder="Customer type"
                                {...register("Use", {required: true})}
                                id={error==="uses"?'customerror customdropdown':'customdropdown'}
                                value={Use?Use:'select Uses'}
                                renderValue={(value) => {
                                  if (value) {
                                    return value;
                                  }
                                  return <em>{"Select Uses"}</em>;
                                }}
                                style={{
                                width: "180px",
                                height: "35px",
                                border: "1px solid #EEEEFE",
                                borderRadius: "7px",
                                fontSize: "14px",
                                opacity: "0.7",
                                fontFamily: "var(--font-regular)",
                                color: "#22356D",
                                }}
                                MenuProps={{ sx: dopdowncustomcss }}
                            >
                                {Uses?.map((location: any) => (
                                <MenuItem
                                    key={location}
                                    value={location}
                                >
                                    {location}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                            </Box>
                        </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('common.Price')}<span style={{color:'red'}}> *</span></Box>
                            <Box sx={{...common, width:"184px"}}>
                            <Cleave
                             id={error==="price"?'customerror':''}
                                style={{
                                    width: "110px",
                                    color: "rgba(34, 53, 109)",
                                    fontFamily: "var(--font-regular)",
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    borderRadius: "7px",
                                    padding: "5px 8px",
                                    border: "1px solid #EEEFEE",
                                }}
                               onChange={(e)=>setValue('price',e.target.rawValue)}
                                value={price}
                                placeholder={t("common.Enter Price")}
                                options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: "thousand",
                                    delimiter:'\u2024'
                                }}
                                />
                                {  selectedlocations?.length>0 ? selectedlocations[0]?.currency:<CurrencySelector inHeight={'30px'} maxHeight={'150px'} setIsopen={setservicesopen} width='60px' selected={currency} setCurrency={(curr:any)=>setValue('currency',curr)}/>}
                            </Box>
                        </Box>
                      
                    </Box>
                    <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginTop={servicesopen ?"150px":"20px"}
                    >
                        <Button
                            type='reset'
                            onClick={()=>setDeletePl(true)}
                            style={{
                                textTransform: "unset",
                                fontFamily: "var(--font-semibold)",
                                width: "150px",
                                height: "37px",
                                color: "#22356D",
                                backgroundColor: "white",
                                fontSize: "16px",
                                padding: "10px 30px",
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                            }}
                        >
                        {t('common.Delete')}
                        </Button>
                        <Button
                            type="submit"
                            style={{
                                zIndex: 1,
                                textTransform: "unset",
                                fontFamily: "var(--font-semibold)",
                                color: "white",
                                backgroundColor: "#22356D",
                                width: "92px",
                                height: "37px",
                                fontSize: "16px",
                            }}
                        >
                            {t('common.Save')}
                        </Button>
                    </Grid>
                </Box>
            </form>
              {/*deleting customer popup */}
          <Dialog onClose={() => setDeletePl(false)} open={deletePl}>
            <DialogContent>
              {/* ---- for delete user ---- */}
              {deletePl && (
                <Box padding={2}>
                 {StaffPermission?.role!=="OTHER" ? <Box>
                <Typography sx={{fontFamily:"var(--font-medium)",color:"#22356D",marginBottom:'5px'}}>{t('common.You are not allowed to')} {t('common.delete membership')}</Typography>
                <Stack direction={'row'} spacing={1} marginBottom={4}>
                    <img src="/images/info.svg" alt="info" />
                    <Typography sx={{color:"#22356DB2",fontFamily:"var(--font-regular)",}}>{t('common.Only staff managers are allowed to')} {t('common.delete membership')}</Typography>
                </Stack>
                <button style={{ ...style, color: activeColor }}   onClick={() => setDeletePl(false)}>{t('common.Close')}</button>
            </Box>:
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-medium)",
                        color: "#22356D",
                        marginRight: "5rem",
                      }}
                    >
                     {t('Are you sure you want to delete this membership ?')}
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      marginBottom={4}
                      marginRight={"5rem"}
                    >
                      <img src="/images/info.svg" alt="info" />
                      <Typography
                        sx={{
                          color: "#22356DB2",
                          fontFamily: "var(--font-regular)",
                        }}
                      >
                        {t('customers.This will erase all the player information.')}{" "}
                      </Typography>
                    </Stack>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      marginTop={2}
                    >
                      <button
                        style={{ ...style, color: activeColor }}
                        onClick={() => setDeletePl(false)}
                      >
                        {t('customers.No_undo')}
                      </button>
                      <button
                        onClick={handleDelete}
                        style={{
                          ...style,
                          color: "white",
                          background: activeColor,
                        }}
                      >
                         {t('customers.Yes, Cancel')}
                      </button>
                    </Box>
                  </Box>}
                </Box>
              )}
            </DialogContent>
          </Dialog>

        </DialogContent>
      </Dialog>
  )
}

export default MembershipModel