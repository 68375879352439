import Box from "@mui/material/Box";
import React from 'react'
import { useTranslation } from "react-i18next";
import { IoCloseOutline } from "react-icons/io5";

const EventServicesRow = ({data,index,id,setSelectedCoaches}:{data:any,id?:number,index:number, setSelectedCoaches?:any}) => {

  const {t} =  useTranslation();
  
  const handleREmove = () => {
    setSelectedCoaches((prev:any)=>{
      const filteredBreaks = prev.filter(
        (item: any) => item?.id !== id
      );
        return filteredBreaks
      }
    )
  };

   
  const maincss = {color:'#22356D',fontFamily:"var(--font-regular)",fontSize:'16px'}

  return (
    <div style={index%2==0 ? {backgroundColor:'rgba(136, 154, 182, 0.10)',padding:'8px 10px'}:{padding:'8px 10px'}} className=''>
     <Box display={'flex'} flexDirection={"row"} justifyContent={'space-between'} alignItems={'center'} margin={'5px'} gap={5}>
          <Box display={'flex'} flexDirection={"row"} gap={'5px'} alignItems={'center'}>
            <img style={{width:'40px',height:'40px',borderRadius:'50%'}} src={data.profile_url?data.profile_url:"/images/Reserved.svg"}/>
            <p style={maincss}>{t('common.Coach')} {data.full_name}</p>
          </Box>
          <Box display={'flex'} flexDirection={"row"} gap={'5px'} alignItems={'center'}>
           <div onClick={()=>handleREmove()} style={{marginLeft:'15px'}}><IoCloseOutline /></div>
          </Box>
          
    </Box>
    </div> 
  )
}

export default EventServicesRow;
