// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from './translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-http-backend';

i18n.use(LanguageDetector).use(initReactI18next).init({
  // debug:true,
  lng: 'en', // default language
  fallbackLng: 'en',
  resources:translation.languages,
  supportedLngs: ['en', 'es','ru'],
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['queryString', 'cookie'],
    caches: ['cookie'],
  },
});

export default i18n;
