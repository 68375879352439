import { apiCaller } from "./ApiCaller";
import { useMutation, useQuery } from "react-query";
import { getClubId } from "./CalendarBooking";


// get bookable time slots
const GetBookableTimeSlotsByID = async (id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(
      `${club_id}/courts/rule/${id}`
    );
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


// get courts by location and sport
const GetCourtsByLocationAndSport = async (
  sport_id: number,
) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(
      `${club_id}/courts/all/${sport_id}`
    );
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


// update bookable timeslots by court id
const submitBookableSlotsByID = async (id: number, data: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/courts/rule/${id}`,
      data
    );
    return response.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


//create bookable slots
const CreateBookableSlot = async (
  sport_id: number,
  TimeSlots: any
) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/courts/rule/update-all/${sport_id}`,
      TimeSlots
    );
    let data = response.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


const getCourtsByCourtID = async (court_id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(`${club_id}/courts/${court_id}`);
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


// update court by court id
const updateCourtByCourtID = async (court_id: number, Courtdata: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.put(`${club_id}/courts/${court_id}`, Courtdata);
    let data = response.data.message;
    return data;
  } catch (error: any) {
    throw error;
    let err = error.response?.data?.message;
    throw err;
  }
};


// add court by sport and location
const AddCourtBySportAndLocation = async (
  sportID: number,
  locationname: string,
  courtData: any
) => {
    let club_id = getClubId();
  try {

    const response: any = await apiCaller.post(
      `${club_id}/courts/update-all/${sportID}`,
      courtData
    );
    let data = response.data.message;
    return data;
  } catch (error: any) {
    throw error;
  }
};


// add court by sport id
const AddCourtBySportID = async (sportID: number, courtData: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/courts/${sportID}/add`,
      courtData
    );
    let data = response.data.message;
    return data;
  } catch (error: any) {
    throw error;
    let err = error.response?.data?.message;
    throw err;
  }
};


//delete court  by id
const DeleteCourtByID = async (courtID: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.delete(`${club_id}/courts/${courtID}`);
    return response?.data
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


//get lcoation
const GetLocations = async () => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(`${club_id}/services/locations`);
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


// get all sport of location
const GetSportByID = async (location_id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(`${club_id}/services/sports/${location_id}`);
    const sortedData = response.data.data.length >0 ? response.data.data?.sort((a:any, b:any) => a.sequence - b.sequence) : response.data.data
      return sortedData
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


// add sport by location id
const AddSportByLocationID = async (location_id:number,ApiData: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(`${club_id}/services/sports/${location_id}`, ApiData);
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

export const useGetCourtsByCourtID = (court_id: number) => {
    let club_id = getClubId();
  return useQuery(
    ["CourtByid", court_id],
    async () => {
      const response: any = await apiCaller.get(`${club_id}/courts/${court_id}`);
      return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: false }
  );
};


//add sport in a particular location
interface AddSportData {
  location_id: number;
  sport_name: string;
}

interface ApiResponse {
  success: boolean;
  message: string;
}
export const useAddSportByLocationID = (location_id:number) => {
    let club_id = getClubId();
  return useMutation<ApiResponse, Error, AddSportData>(
    ["AddSportbylocationid"],
    async (ApiData: any) => {
      const response: any = await apiCaller.post(`${club_id}/services/sports/${location_id}`, ApiData);
      return response.data.data;
    }
  );
};


// get bookable timeslots
export const useGetBookableTimeSlotByID = (id: number) => {
    let club_id = getClubId();
  return useQuery(
    ["BookableTimeSlotByID", id],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/courts/bookable-timeslots/${id}`
      );
      return response.data.data;
    },
    {
      select: (data: any) => {
        const showData = data?.map((item: any, index: number) => {
          let { id, ...newitem } = item;
          id = index;
          return { id, ...newitem };
        });
        return showData;
      },
      staleTime: 1000 * 60 * 5,
      retry: false,
      enabled: false,
    }
  );
};



//get locations
export const useGetLocation = () => {
    let club_id = getClubId();
  return useQuery(
    ["location"],
    async () => {
      const response: any = await apiCaller.get(`${club_id}/services/locations`);
      return response.data.data;
    },
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
};



export {
  submitBookableSlotsByID,
  AddSportByLocationID,
  DeleteCourtByID,
  AddCourtBySportID,
  GetLocations,
  GetCourtsByLocationAndSport,
  GetSportByID,
  GetBookableTimeSlotsByID,
  CreateBookableSlot,
  getCourtsByCourtID,
  updateCourtByCourtID,
  AddCourtBySportAndLocation,
};
