import Box from "@mui/material/Box";
import EventsRows from './EventsRows';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { GoPlus } from "react-icons/go";
import EventServices from './EventServices';
import React, { useEffect, useState } from 'react';
import DialogContent from "@mui/material/DialogContent";
import filterBySport from 'src/constants/SportSEparator';
import { useGetAllEventsServices } from "src/hooks/useGetAllEventsServices";
import { useTranslation } from "react-i18next";



const Events = ({selectedSport,selectedLocation,sports}:{sports:any,selectedSport:any,selectedLocation:any}) => {
    // for showing model
    const { t } = useTranslation();
    const [showBookingSettings,setshowBookingSettings] = useState(false);
    const [bookingData, setBookingData] = useState<any>([]);
    const [EventID, setEventID] = useState<number>();
    const [sport_id, setsport_id] = useState<number>();
    const {data,isSuccess,isError,refetch,setSportId,setlocation_id} = useGetAllEventsServices();

    useEffect(() => {
      if(!!selectedLocation){
       setlocation_id(selectedLocation?.id);
       setSportId(0)
      }
     }, [selectedLocation]);
   
     useEffect(() => {
       if(isSuccess){
         getBookingDATA(data);
       }else{
         setBookingData([])
       }
     }, [data,isSuccess,isError])
     
   
   
     const getBookingDATA = async (data:any) => {
         const final = filterBySport(data);
         setBookingData(final);
   
     };  
  
  
    const handleClicked = (data: any) => {
      setsport_id(data?.sport?.id);
      setEventID(data.id);
      setshowBookingSettings(true);
    };
  
    const handleAdd = (sport_id: number) => {
      setEventID(undefined);
      setsport_id(sport_id);
      setshowBookingSettings(true);
    };
  
    const handleClose = () => {
      setshowBookingSettings(false);
    };



    return (
        <Box sx={{ padding:{xs:"0px",sm:"0px", md:'33px 25px'},borderRadius:'7px' }} className='bg-white'>
          <Box sx={{display:{xs:"none", sm:"none", md:"block"}}}>
            <span style={{ color: '#22356D', fontFamily: "var(--font-semibold)", fontSize: '16px', borderBottom: '1px solid #22356D' }}>{t('common.Events')}</span>
          </Box>
            
          {sports?.map((item: any) => {
      return selectedSport?.sport_name == item?.sport_name ||
        selectedSport?.sport_name== "All Sports" ? (
        <Box key={item?.id}>
          <h3
            style={{
              marginTop: "20px",
              color: "#22356D",
              fontFamily: "var(--font-semibold)",
              fontSize: "16px",
            }}
          >
            {item?.sport_name}
          </h3>
          <Button
            onClick={() => handleAdd(item.id)}
            className="items-center flex flex-row justify-normal"
            style={{
              padding: "0",
              width: "190px",
              color: "rgba(34, 53, 109, 0.70)",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",
              gap: "10px",
              textTransform: "unset",
            }}
          >
            {t('services.events.Add new event')}{" "}
            <GoPlus style={{ verticalAlign: "middle", fontSize: "14px" }} />
          </Button>
          <Box>
            <Box
              style={{
                marginTop: "10px",
                borderRadius: "7px",
                boxShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.1)",
                background:
                  "linear-gradient(0deg, rgba(238, 239, 238, 0.05) 0%, rgba(238, 239, 238, 0.05) 100%), #FFF",
                border: "0.5px solid #EEEFEE",
              }}
            >
              <Box
                id={ selectedSport?.sport_name == "All Sports"?"table-person":''}
                className="overflow-hidden"
                style={{
                  marginTop: "0px",
                  width: "-webkit-fill-available",
                  maxHeight: selectedSport?.sport_name == "All Sports"? "210px":'',
                  overflowY: "auto",
                }}
              >
                {bookingData[`${item.sport_name}`]?.map(
                  (item: any, index: number) => (
                    <div key={index} onClick={() => handleClicked(item)}>
                      <EventsRows
                        index={index}
                        key={index}
                        Data={item}
                        selectedLocation={selectedLocation}
                      />
                    </div>
                  )
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      );
          })}
          <Dialog
       sx={{".MuiDialog-paper":{
        minWidth:{xs:"325px",sm:"525px", md:"850px"},
        width:'100%',
        overflowX:'hidden'
      }}}
      onClose={handleClose}
      open={showBookingSettings}>
        <DialogContent id="table-person" style={{marginTop:0,padding:'0px'}}>
          <EventServices update={refetch} handleClose={handleClose} selectedlocation={selectedLocation} EventID={EventID} sport_id={sport_id}/></DialogContent>
      </Dialog>    
        </Box>
    )
}

export default Events;
