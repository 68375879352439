import React from 'react'
import { FaCircle } from "react-icons/fa";
import { FaSquareFull } from "react-icons/fa";

const CustomSVG = () => {
  return (
    <div style={{position:'relative',height:'100%',minHeight:'50px'}}>
<FaCircle style={{color:'#22356D',fontSize:'7px',position:'absolute',left:'-2.2px',top:'-1px'}}/>
    
    <div className='customSVG' style={{height:'100%',minHeight:'50px',width:'10px',borderLeft:'2px dotted #22356D'}}>
    </div>
    <FaSquareFull style={{color:'#22356D',fontSize:'7px',position:'absolute',left:'-2.2px',bottom:'0',rotate:'45deg'}}/>
    </div>
  )
}

export default CustomSVG