import * as React from "react";
import { Box } from "@mui/material";
import { BsChevronUp } from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";
import MainContext from "src/context/MainContext";
import moment from "moment";
import { useGetCoachesTimely } from "src/api/CoachCalendar";

export default function CustomSelectMultiple({
  locationId,
  startTime,
  selectedSport,
  endTime,
  selectType,
  selectedValueIndex,
  widthSize,
  finalCourt,
  courtList,
  view,
  selectedService,
  customEvent,
}: {
  locationId: number;
  startTime: any;
  endTime: any;
  selectType?: string;
  selectedValueIndex?: any;
  widthSize?: string;
  finalCourt?: any;
  courtList?: any;
  view: string;
  selectedSport: any;
  selectedService: any;
  customEvent:boolean
}) {
  // const { selectedCourt,setselectedCourt } = React.useContext(MainContext);
  const context = React.useContext(MainContext);
  const selectedDate = context?.selectedDate;
  // const [selectedCourts, setSelectedCourts] = React.useState<any[]>([]);
  const [showDrop, setShowDrop] = React.useState(false);
  const [availableCourt, setAvailCourt] = React.useState<any>([]);
  const {data,isSuccess,isError,refetch,setLocation_id,setSelectedDT}= useGetCoachesTimely();

  const getCommonItemsById = (arr1: any, arr2: any) => {
    return (
      arr1?.filter((item1: any) =>
        arr2?.some((item2: any) => item2 === item1?.id)
      ) || []
    );
  };

  // console.log(getCommonItemsById(availableCourt, selectedValueIndex),"helllllll")


  const courtsString =
    getCommonItemsById(availableCourt, selectedValueIndex)?.length == 1
      ? `${
          getCommonItemsById(availableCourt, selectedValueIndex)[0]?.court_name ||
          getCommonItemsById(availableCourt, selectedValueIndex)[0]?.name
        }`
      : getCommonItemsById(availableCourt, selectedValueIndex)?.length !== 0
      ? `${
          getCommonItemsById(availableCourt, selectedValueIndex)[0]?.court_name ||
          getCommonItemsById(availableCourt, selectedValueIndex)[0]?.name
        } + ${
          getCommonItemsById(availableCourt, selectedValueIndex)?.length - 1
        } more`
      : "Select Court";

  const handleShow = (event: any) => {
    event.stopPropagation();
    setShowDrop((prev) => !prev);
  };

  const handleClick = (event: any, court: any) => {
    console.log('running')
    event.stopPropagation();

    if (selectedValueIndex.includes(court)) {
      finalCourt(
        selectedValueIndex.filter((selectCourt:any) => selectCourt !== court)
      );
    } else {
      finalCourt((prev:any) => [...prev, court]);
    }
  };


  // React.useEffect(() => {
  //   if (!!selectedValueIndex?.length) {
  //     finalCourt(selectedValueIndex);
  //     // setselectedCourt(getCommonItemsById(availableCourt, selectedValueIndex));
  //   }
  // }, [selectedValueIndex,availableCourt]);

  React.useEffect(() => {
    document.addEventListener("click", () => {
      setShowDrop(false);
    });
  }, []);


  React.useEffect(() => {
    if (view === "Coaches" && startTime && endTime) {
      setLocation_id(locationId);
      let sDate = moment(selectedDate, "MM/DD/YYYY").format("YYYY-MM-DD");
      setSelectedDT({startTime,endTime,sDate});
    }
  }, [
    startTime,
    endTime,
    selectedDate,
    view,
  ]);
  

  React.useEffect(() => {
    if(isSuccess && view==="Coaches"){
      if(!customEvent){
        const selectedCourtIds = selectedService?.courts?.map((item:any)=>item.id);
      const filtered = data?.filter(
         (item: any) => selectedCourtIds?.includes(item?.id)
       );
       setAvailCourt(filtered);
      }else{
        setAvailCourt(data);
      }
    }else if(view==="Coaches"){
      setAvailCourt([]);
    }
   
  }, [data,isSuccess,isError,selectedService,customEvent,view]);

  React.useEffect(() => {
    if(view==="Courts" && selectedSport){
      const filtered = courtList?.filter(
        (item: any) => item?.sport?.id === selectedSport
      );
      if (!!Object?.keys(selectedService||{})?.length) {
        const ServFilter: any = selectedService?.courts?.map((element: any) => {
          return filtered?.filter((item: any) => item?.id === element?.id)[0];
        });
        setAvailCourt(ServFilter);
      } else {
        setAvailCourt(filtered);
      }
    }
  }, [selectedService,view,selectedSport]);

  return (
    <Box position={"relative"}>
      <input
        type="text"
        placeholder="Select court"
        value={selectedValueIndex?.length > 0 ? `${courtsString}` : "Select Court"}
        style={{
          height: "41.5px",
          border: "1px solid #EEEEFE",
          marginTop: "2px",
          width: "100%",
          fontFamily: "var(--font-regular)",
          color: "#22356DB2",
        }}
        onClick={handleShow}
        readOnly
      />
      {!showDrop ? (
        <BsChevronDown
          onClick={handleShow}
          style={{
            position: "absolute",
            right: "3px",
            bottom: "11px",
            fontSize: "16px",
            paddingRight: "4px",
          }}
        />
      ) : (
        <BsChevronUp
          onClick={handleShow}
          style={{
            position: "absolute",
            right: "3px",
            bottom: "11px",
            fontSize: "16px",
            paddingRight: "4px",
          }}
        />
      )}
      {showDrop && (
        <Box
          position={"absolute"}
          top={41}
          zIndex={99}
          sx={{
            background: "#f3f5f8",
            width: "100%",
            marginTop: "0 !important",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px !important",
            borderTopLeftRadius: "0px !important",
            borderTopRightRadius: "0px !important",
            maxHeight: "206px",
          }}
          id="table-person"
        >
          {availableCourt?.map((item: any) => (
            <Box
              sx={{
                background: `${
                  selectedValueIndex.includes(item?.id) ? "#22356D" : ""
                }`,
                color: `${
                  selectedValueIndex.includes(item?.id) ? "white" : "#22356DB2"
                }`,
                padding: "10px",
                fontFamily: "var(--font-regular)",
                cursor: "pointer",
                "&:hover": { background: "#22356D", color: "white" },
              }}
              key={item?.id}
              onClick={(event) => handleClick(event, item?.id)}
            >
              {item?.court_name || item?.name}
            </Box>
          ))}
          {availableCourt?.length === 0 && (
            <Box
              sx={{
                color: "#22356DB2",
                padding: "10px",
                fontFamily: "var(--font-regular)",
                cursor: "pointer",
              }}
            >
              No Available Court
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
