import { Box, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SearchTeammates from './SearchTeammates';
import { addWaitingTeam, getClubId, useGetAllCustomerList, useGetAllWaitingList } from 'src/api/CalendarBooking';
import { useForm } from 'react-hook-form';
import { apiCaller } from 'src/api/ApiCaller';
import MainContext from 'src/context/MainContext';
import { RiSettings4Fill } from 'react-icons/ri';

const common = {display:"flex", alignItems:"center", gap:"10px",
    "& p":{
        fontFamily:"var(--font-regular) !important",
        color:"#22356D"
    }
};

const WaitingTeam = ({ events, serviceLists, selectEvent, PlayNotRefetch, teams, setWaitTeam }:{ events: any, serviceLists: any, selectEvent:any, PlayNotRefetch:any, teams:any, setWaitTeam:any }) => {
    const context = useContext(MainContext);
    const {Notification,setNotification} = useContext(MainContext);
    const SelectedLocation = context?.SelectedLocation;
    const { register, handleSubmit } = useForm();
    const [customerList, setCustomerList] = useState<any>([]);
    const [waitingTeam, setWaitingTeam] = useState<any>([]);
    const [arr, setArr] = useState<number[]>([]);
    const {data:CustomerData,isSuccess,isError,refetch} = useGetAllCustomerList();
    const {data, isSuccess:succ, isError: err, refetch: upd, setService_id} = useGetAllWaitingList();
    
   
    const movePlayers = async (id:number) =>{

        try {
            let club_id = getClubId();
          let res: any = await apiCaller.patch(`${club_id}/calendars/services/players/${id}?type=waiting`);
          upd(events?.service_id);
          PlayNotRefetch()
          serviceLists();
        } catch (error:any) {
          const err = error.response?.data?.message;
          console.log(err);
        }
      }

    const checkTeammate = async (data:any) => {
        let sendData = {};
        if(data.text==="first"){
            sendData = { "customer1Id" : data.newValue }
        }else{
            sendData = {
                "player1Id":data.index,
                "customer2Id":data.newValue
            }
        }
        try{
            let res = await addWaitingTeam(events?.service_id, sendData);
            console.log(res);
            upd(events?.service_id);
        }catch(error:any){
            console.log(error);
            setNotification({isShow:true, message:error.response.data?.message,subMessage:'',type:'error'})
        }
    }

    const pairData = (data: any) => {
        const paired: any = [];
      
        data?.forEach((item:any) => {
          const pairedItem = paired?.find((p:any) => p.id === item.other_player);
      
          if (pairedItem) {
            pairedItem.pairedData.push(item);
          } else {
            paired.push({
              ...item,
              pairedData: [],
            });
          }
        });
      
        return paired;
    };

    useEffect(() => {
        if(succ){
            setWaitTeam(data);
            let length = pairData(data).length;
            setArr(new Array(7-length)?.fill(0))
            setWaitingTeam(pairData(data));
            let arr:number[] = [];

            teams?.forEach((item:any)=>{
                arr.push(item.customer?.id)
            })

            data?.forEach((item:any)=>{
                arr.push (item.customer?.id);
                if(item?.pairedData?.length>0){
                  arr.push(item?.pairedData[0]?.customer?.id)
                }
            })
            let filteredData = CustomerData.filter((item:any)=> !arr.includes(item.id));
            setCustomerList(filteredData)
        }else{
            setWaitingTeam([]);
            setArr(new Array(7)?.fill(0))
        }
    }, [data, isSuccess, err])

    useEffect(()=>{
        if(Object.keys(events).length>0){
         events?.service_id && setService_id(events?.service_id)
        }else{
            setWaitingTeam([]);
        }
      }, [events]);

    useEffect(() => {
        if(isSuccess){
            
            setCustomerList(CustomerData);
        }
    }, [CustomerData,isSuccess,isError]);


    return (
       <Box>
            <Box id="table-person" sx={{marginTop:"0px !important", maxHeight:"313px !important",">div:nth-child(odd)":{
                    background:"#889AB626 !important"
                },">div:nth-child(even)":{
                    background:"white"
                },">div:hover":{background:"#889AB626 !important"}}}>
                {/* if added in waiting list */}
                {waitingTeam.map((item:any, index:number) => <Box sx={{...common, justifyContent:"space-between", padding:"10px"}}>
                    <Box sx={{...common}}>
                        <Box sx={{ borderRadius: "7px", width: "26px", height: "26px", textAlign: "center", padding: '0px 10px', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)' }}>1</Box>
                        <Box>
                            <Box sx={{...common, marginBottom:"5px"}}>
                            <img src={item.customer?.profile_url ? item.customer?.profile_url : "/images/Reserved.svg" } style={{width:"33px", height:"33px", borderRadius:"50%"}} />
                                <Typography>{item.customer?.first_name} {item.customer?.last_name}</Typography>
                                <Typography>{item.customer?.phone_number}</Typography>
                            </Box>
                            {item.pairedData.length!==0 && <Box sx={{...common}}>
                                <img src={item.pairedData[0].customer?.profile_url ? item.pairedData[0].customer?.profile_url : "/images/Reserved.svg" } style={{width:"33px", height:"33px", borderRadius:"50%"}} />
                                <Typography>{item.pairedData[0].customer?.first_name} {item.pairedData[0].customer?.last_name}</Typography>
                                <Typography>{item.pairedData[0].customer?.phone_number}</Typography>
                            </Box>}
                            {item.pairedData.length==0 && <SearchTeammates updateList={refetch} customer={'customer2Id'} customerList={customerList} text={'Second'} index={item.id} addingTeam={checkTeammate} />}
                        </Box>
                    </Box>
                    {item.pairedData.length!==0 ? <button style={{background:'white', padding:"0px 15px", border:"1px solid #EEEFEE", borderRadius:"5px",boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px", color:"#22356D"}} onClick={()=>movePlayers(item.id)}>Move to players</button> :
                    <Box>
                        <Box display={"flex"} alignItems={"center"} gap={1} marginBottom={'12px'}>
                            <p style={{color:"red"}}>{SelectedLocation?.currency} {item.price}</p>
                            <label className="container">
                                <input disabled={true} type="checkbox" />
                                <span className="mark"></span>
                            </label>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                            <p style={{color:"red"}}>{SelectedLocation?.currency} {item.price}</p>
                            <label className="container">
                                <input disabled={true} type="checkbox" />
                                <span className="mark"></span>
                            </label>
                        </Box>
                    </Box>
                    }
                </Box>)}

                {/* for adding team in waiting list */}
                {arr.map((item,index) => <Box sx={{...common, padding:"10px"}}>
                    <Box sx={{ borderRadius: "7px", width: "26px", height: "26px", textAlign: "center", padding: '0px 10px', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)' }}>{index + 1}</Box>
                    <Box sx={{flex:'1'}}>
                        <Box sx={{...common, justifyContent:"space-between", marginBottom:"5px"}}>
                            <Box sx={{...common}}>
                                <SearchTeammates updateList={refetch} customer={'customer1Id'} customerList={customerList} text={'first'} index={index+1 + waitingTeam?.length} addingTeam={checkTeammate} />
                            </Box>
                            <Box sx={{color:"#22356D !important"}}> {SelectedLocation?.currency} {selectEvent?.price}</Box>
                        </Box>
                        <Box sx={{...common, justifyContent:"space-between"}}>
                            <Box sx={{...common}}>
                                <SearchTeammates updateList={refetch} customer={'customer1Id'} customerList={customerList} text={'first'} index={index+1} addingTeam={checkTeammate} />
                            </Box>
                            <Box sx={{color:"#22356D !important"}}> {SelectedLocation?.currency} {selectEvent?.price}</Box>
                        </Box>
                    </Box>
                </Box>)}
            </Box>
       </Box>
  )
}

export default WaitingTeam