import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Tab,
  Dialog,
  DialogContent,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import "intl-tel-input/build/css/intlTelInput.css";
import AddIcon from "@mui/icons-material/Add";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { activeColor, pending, events } from "src/constants/color";
import { PiCaretLeft } from "react-icons/pi";
import "./tableStyling.css";
import UserSetting from "src/features/Customers/UserSetting";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AddManualCredits,
  DeleteCustomerByID,
  EditCustomerWallet,
  GetBookingOfCustomer,
  GetCustomerDetailsByID,
  MakePaymentOfCustomer,
  UpdateISCustomerBlocked,
  updateCustomerById,
  useGetClubWalletOfCustomer,
  useGetStatsOfCustomer,
} from "src/api/Customer";
import moment from "moment";
import { set, useFieldArray, useForm } from "react-hook-form";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import Cleave from "cleave.js/react";
import {
  useGetAllPaymentMethodsByCust,
  useGetAllVouchersByClub,
} from "src/api/Voucher";
import {
  AddVoucherToCustomer,
} from "src/api/ClubAdmin";
import { Link } from "react-router-dom";
import { useGetAllvouchersByCustId } from "src/hooks/useGetAllvouchersByCustId";
import { useGetAllPaymentMethodsByLoc } from "src/hooks/useGetAllPaymentMethods";
import MainContext from "src/context/MainContext";
import NotificationToast from "src/Notification/NotificationToast";
import { useGetLocation } from "src/api/CourtSettings";
import { addThousandSeparator } from "src/constants/SportSEparator";
import SingleCustomerPaymentRow from "./SingleCustomerPaymentRow";
import MembershipTab from "src/features/Customers/MembershipTab";
import { useTranslation } from "react-i18next";
import { useGetMembershipOfCust } from "src/api/membership";
import { useSocket } from "src/context/SocketProvider";
import { getClubId, Permission, useGetPermissions } from "src/api/CalendarBooking";
import StaffModal from "src/Notification/StaffModal";

const style = {
  fontFamily: "var(--font-semibold)",
  background: "white",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};

const divider = {
  display: "block",
  width: "0.8px",
  height: "20px",
  background: "#22356D",
};

const dynamicColor: any = {
  Lesson: {
    bgColor: "#FFFEE4",
    color: "#A3A434",
  },
  Booking: {
    bgColor: "#F3F4FE",
    color: "#7E93FF",
  },
  Event: {
    bgColor: "#FEF6F0",
    color: "#F07727",
  },
};

const ListDesign = ({
  item,
  checkbox,
  setSelectedPrices,
  selectedPrices,
}: {
  item: any;
  checkbox: boolean;
  setSelectedPrices?: any;
  selectedPrices?: any;
}) => {
  const {t} = useTranslation();
  const [ischecked, setIschecked] = useState(false);
  const { Notification, setNotification } = useContext(MainContext);


  const handleClicked = () => {
    setSelectedPrices((prev: any) => {
      if (prev?.length === 0) {
        return [item];
      } else if (prev.some((avail: any) => avail.id === item.id)) {
        return prev?.filter((old: any) => old.id !== item.id);
      } else if (
        prev[0].location.location_name ===
        item.location.location_name
      ) {
        setIschecked((prev) => !prev);
        return [...prev, item];
      } else {
        setNotification({isShow:true,message:'You can only select bookings from the same location',subMessage:'',type:'error'})
        return [...prev];
      }
    });
  };

  return (
    <Box
      key={item}
      display={"flex"}
      gap={1}
      sx={{ "&:hover": { background: "#889AB64D !important" } }}
    >
      <Box sx={{ width: "-webkit-fill-available" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          key={item}
          width={"100%"}
          sx={{ padding: { xs: "10px", sm: "16px" } }}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box
              sx={{
                width: "62px",
                height: "21px",
                borderRadius: "4px",
                fontSize: "0.9rem",
                fontFamily: "var(--font-regular)",
                textAlign: "center",
                background:
                  dynamicColor[item?.service_type]
                    .bgColor,
                color:
                  dynamicColor[item?.service_type].color,
              }}
            >
              {t(`common.${item?.service_type}`)}
            </Box>
            <Stack
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "baseline", sm: "center" },
                gap: { xs: "0", sm: "10px" },
              }}
              spacing={1}
              fontFamily={"var(--font-regular)"}
              color={"#22356D"}
            >
              <Box sx={{ marginTop: "4px !important" }}>
                {moment(item?.serv.date).format("ddd D/M/YYYY")}
              </Box>
              <span style={{ ...divider }} className="show-row"></span>
              <Box sx={{ marginTop: "4px !important", textTransform:"uppercase" }}>{`${moment(
                item?.start_time,
                "HH:mm:ss"
              ).format("HH:mm")} - ${moment(
                item?.end_time,
                "HH:mm:ss"
              ).format("HH:mm")}`}</Box>
              <span style={{ ...divider }} className="show-row"></span>
              <Box
                sx={{ marginTop: "4px !important",display:'flex',flexDirection:'row',alignItems:'center' }}
              ><Typography sx={{display:'flex',flexDirection:'row',alignItems:'center',fontFamily:'var(--font-regular)'}} >{item?.courts?.map((court:any)=>court?.court_name)} </Typography>{` - ${item.location.location_name}`}</Box>
            </Stack>
          </Stack>
          {item?.serv?.players[0]?.is_canceled && !checkbox && <span 
              style={{
                background:"#FFE8E8", 
                color:'#FF1313D9', 
                padding:"1px 8px", 
                borderRadius:'7px',
                fontSize:"0.8rem",
                height:'25px'
                }}>Cancelled</span>}
          {checkbox && (
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={1}
              fontFamily={"var(--font-regular)"}
            >
              <p style={{ color: "red" }}>
                {" "}
                {item?.serv?.players[0]?.pending_price > 1000 ? (
                  <span>{(item?.serv?.players[0]?.pending_price / 1000).toFixed(1)} k</span>
                ) : (
                  item?.serv?.players[0]?.pending_price
                )}
              </p>
              <label className="container">
                <input
                  checked={selectedPrices.some(
                    (avail: any) => avail.id === item.id
                  )}
                  onChange={handleClicked}
                  type="checkbox"
                />
                <span className="mark"></span>
              </label>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

const commonStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

interface Data {
  court: string;
  id: number;
  service_booking: {
    date: string;
    start_time: string;
    end_time: string;
    service: {
      service_type: string;
      location: {
        location_name: string;
      };
    };
    court: {
      id: number;
      created_at: string;
    };
  };
}

interface Location {
  id: number;
  location_name: string;
}

interface voucher {
  item: any;
  open: boolean;
}

interface walletTypes {
  currency: string;
  balance: number;
  id: number;
}
 
const CustomerInfo = ({
  show,
  GetAllCustomer,
  setShow,
  customfield,
  customerdata,
  fromCustomer,
  hidebackdrop,
  updateCustomSettings,
  columnRefetch
}: {
  show?: any;
  hidebackdrop: any;
  setShow?: any;
  fromCustomer: boolean;
  customfield?: any;
  customerdata?: any;
  GetAllCustomer?: any;
  updateCustomSettings?: any;
  columnRefetch?:any;
}) => {
  const {t} = useTranslation();
  const [value, setValue] = React.useState("User Settings");
  const { Notification, setNotification,isPermissionOn,setIsPermissionOn } = useContext(MainContext);
  const {control,
    register,
    handleSubmit,
    setValue: setFormvalue,
    watch,  
    reset,
  } = useForm();
  const { fields, append, remove,update } = useFieldArray({
    control,
    name: "walletdata",
  });
  const walletdata = watch("walletdata");
  const { first_name,last_name, email, phone_number, profile_url,customerTypes,password } = watch();
  
  const [ClubWallet, setClubWallet] = useState<any[]>([]);
  const [payments, setPayments] = useState<any[]>([]);
  const [addPlayer, setAddPlayer] = useState<string>("");
  const [showList, setShowList] = useState<boolean>(false);
  const [deletePl, setDeletePl] = useState<boolean>(false);
  const [ManualPrice, setManualPrice] = useState<number>(0);
  const [block, setBlock] = useState<boolean>(false);
  const [image, setImage] = useState("");
  const [isBlocked, setIsBlocked] = useState<boolean>();
  const [customerSetting, setCustomerSetting] = useState<any>("");
  const [pastBookings, setPastBookings] = useState<any[]>([]);
  const [credits, setCredits] = useState<number>();
  const [upcoming, setupcoming] = useState<any[]>([]);
  const [pendingPayments, setpendingPayments] = useState<any[]>([]);
  const [FilteredPendingPaymnets, setFilteredPendingPaymnets] = useState<any[]>([]);
  const [selectedLocation, setSelectedLcoation] = useState<Location>();
  const [stats, setStats] = useState<any>();
  const [error, setError] = useState("");
  const [voucherAdd, setVoucherADD] = useState(false);
  const [voucherList, setVoucherList] = useState<any[]>([]);
  const [SelectedVoucher, setSelectedVoucher] = useState<any>();
  const [PaymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [selectedMethods, setSelectedMethod] = useState<any>();
  const [selectedPrices, setSelectedPrices] = useState<any[]>([]);
  const [showeditWallet,setShowEditWallet] = useState<boolean>(false);
  const [AllVouchers, setAllVouchers] = useState<any>([]);
  const [AllPaymentMethodsOfCustomer, setALLPaymentMethodsOFCustomer] = useState<any[]>([]);
  const { isSuccess, isError, data: AllVoucher } = useGetAllVouchersByClub();
  const [showPayment, setShowPayment] = useState(false);
  const [AllMemberShip,setAllMemberShips] =useState<any[]>([]);
  const [Locations, setLocations] = useState([]);
  const {messages, sendMessage, connectionStatus,setUrl,listenToEvent  } = useSocket();
  const {data:MemberData,isSuccess:MemberSuccess,isError:MemberError,setUser_Id,refetch:MemberRefetch} = useGetMembershipOfCust();
  const {
    data: AllLocations,
    isSuccess: LocationSuccess,
    isError: LocationError,
    refetch: LocationRefetch,
  } = useGetLocation();
  const {
    data: ClubWallData,
    isSuccess: ClubWallSuccess,
    isError: ClubWallError,
    setCustomer_id: setClubWalletCusId,
    refetch,
  } = useGetClubWalletOfCustomer();
  const {
    data: StatsData,
    isSuccess: StatsSuccess,
    isError: StatsError,
    setCustomer_id: setStatsCustomer_id,
  } = useGetStatsOfCustomer();
  const {
    data: VouchersByCust,
    isSuccess: CustvoucherSuccess,
    isError: CustvoucherError,
    setCustomer_id,
    refetch: getVouchersByCustomer,
  } = useGetAllvouchersByCustId();
  const {
    data: PaymentMethodsByLoc,
    isError: PaymentMethodsByLocError,
    isSuccess: PaymentMethodsByLocSuccess,
    refetch: PaymentMethodsByLocRefetch,
    setlocation_id,
  } = useGetAllPaymentMethodsByLoc();
  const {
    data: PaymentMethodsData,
    isSuccess: PaymentMethodSuccess,
    isError: PaymentMethodError,
    setCustomer_id: SetPCustomer_id,
    setLocation_id,
  } = useGetAllPaymentMethodsByCust();

  useEffect(() => {
    if (isSuccess) {
      const BackendVouchers = AllVoucher;
      const custom = { id: 0, voucher_name: "manual credits" };
      setVoucherList([custom, ...BackendVouchers]);
    } else if (isError) {
      setVoucherList([]);
    }
  }, [isSuccess, isError, AllVoucher]);

  const customerUSER = {
    profile_url:profile_url,
    customerdata:customerdata,
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setShowList(false);
    });
  }, []);

  useEffect(() => {
    const handleEventTwo = (data: any) => {
      refetch();
     };

    listenToEvent('walletBalanceUpdated', handleEventTwo);

    // Cleanup
    return () => {
      // Unregister event listeners if necessary
    };
  }, [listenToEvent]);

  

  const handleSelectTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setlocation_id(undefined);
    setAddPlayer('');
    setClubWallet([]);
    setSelectedVoucher("");
    setShow(false);
    reset({
      first_name:'',last_name:'', email:'', phone_number:''
    });
  };

  // is  player blocked handler
  const handleBlock = async () => {
    if (customerdata !== "" && !!customerdata) {
      const response = await UpdateISCustomerBlocked(customerdata.id, {
        is_blocked: !isBlocked,
      });
      if (response.status) {
        setBlock(false);
        getCustomerbyid();
      }
    }
  };

  useEffect(() => {
    if (CustvoucherSuccess) {
      const finaldata = [...VouchersByCust?.vouchers,...VouchersByCust?.manualCredits]?.sort((a:any,b:any)=>new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      setAllVouchers(finaldata);
    } else {
      setAllVouchers([]);
    }
  }, [CustvoucherSuccess, CustvoucherError, VouchersByCust]);

  const handleClosePayment = () => {
    setShowPayment(false);
  };



  const Club_Id = getClubId();

  // getting customersetting data by api
  useEffect(() => {
    setCustomerSetting("");
    // setValue("User Settings");
    setIsBlocked(false);
    setSelectedPrices([]);
    reset();
  
    if (customerdata !== "" && !!customerdata) {
      getCustomerbyid();
      setFormvalue("first_name", customerdata.first_name);
      setFormvalue("last_name", customerdata.last_name);
      setFormvalue("email", customerdata.email ? customerdata?.email : "");
      setFormvalue(
        "phone_number",
        customerdata.phone_number !== null ? customerdata.phone_number : ""
      );
    }
  }, [customerdata, show]); 
 
  const getCustomerbyid = async () => {
    if(!!customerdata){
      const response = await GetCustomerDetailsByID(customerdata?.id);
    setIsBlocked(false);
    if (!!response) {
      setCustomerSetting(response);
      setIsBlocked(response.is_blocked);
      response?.profile_url &&
        setFormvalue("profile_url", response?.profile_url);
        setFormvalue("customerTypes", response?.customerTypes);
      setFormvalue(
        "phone_number",
        response?.phone_number ? response.phone_number : ""
      );
    }
    }
  };

  const catogroizePast = (bookings:any) => {
    const yesterday = moment().subtract(1, 'day').startOf('day');
  
    const groupedBookings = bookings.reduce((acc:any, booking:any) => {
      const bookingDate = moment(booking.serv.date);
      let category;
  
      if (bookingDate.isSame(yesterday, 'day')) {
        category = 'yesterday';
      } else {
        category = bookingDate.format('YYYY-MM-DD');
      }
  
      if (!acc[category]) {
        acc[category] = [];
      }
  
      acc[category].push(booking);
      return acc;
    }, {});
  
    const sortedCategories = Object.keys(groupedBookings).sort((a, b) => {
      if (a === 'yesterday') return -1;
      if (b === 'yesterday') return 1;
      return moment(b).diff(moment(a));
    });

    const sortedData = sortedCategories.map(category => ({
      category,
      data: groupedBookings[category]
    }));

    sortedData.sort((a, b) => {
      return moment(a.category).isAfter(moment(b.category)) ? 1 : -1;
    });
   
    return sortedData;
};

  useEffect(() => {
    if (PaymentMethodsByLocSuccess) {
      setPaymentMethods(PaymentMethodsByLoc);
    } else {
      setPaymentMethods([]);
    }
  }, [
    SelectedVoucher,
    PaymentMethodsByLoc,
    PaymentMethodsByLocError,
    PaymentMethodsByLocSuccess,
  ]);

  useEffect(() => {
    if (StatsSuccess) {
      setStats(StatsData);
    } else {
      setStats("");
    }
  }, [StatsData, StatsSuccess, StatsError]);

  useEffect(() => {
    if (ClubWallSuccess) {
    ClubWallData?.map((item:any)=>{
      append(item);
    })
      setClubWallet(ClubWallData);
    } else {
      setClubWallet([]);
    }
  }, [ClubWallData, ClubWallSuccess, ClubWallError]);

  // for getting past and upcoming bookings
  useEffect(() => {
    setPastBookings([]);
    setupcoming([]);
    setpendingPayments([]);
    setImage("");
    setStats("");
    setAllVouchers([]);
    setUrl(`${process.env.REACT_APP_BACKEND_URL}/calendars/${Club_Id}/customers/${customerdata?.id}`)
    if (customerdata !== "" && !!customerdata) {
      setClubWalletCusId(customerdata?.id);
      setStatsCustomer_id(customerdata?.id);
      setCustomer_id(customerdata?.id);
      GetAllBookings();
      setUser_Id(customerdata?.id)
    }
  }, [customerdata]);

  useEffect(() => {
    if(MemberSuccess){
      setAllMemberShips(MemberData||[])
    }else{
      setAllMemberShips([])
    }
  }, [MemberData,MemberSuccess,MemberError]);  

  //delete customer
  const handleDelete = async () => {
    try {
      const response = await DeleteCustomerByID(customerdata.id);
      if (response) {
        setNotification({
          isShow: true,
          message: response,
          subMessage: "",
          type: "success",
        });
        GetAllCustomer();
        setShow(false);
      }
    } catch (error) {}
  };

  //function for api call
  const GetAllBookings = async () => {
    const response = await GetBookingOfCustomer(customerdata.id);
    if (!!response?.services?.length) {


   const data = response?.services?.flatMap((item:any)=>{
      const filterData =  item?.serviceBookings?.map((serv:any)=>{
        const {courts,end_time,location,start_time,service_type} =item;
        const {id,date} = serv;
          return {
            serv, 
            courts,end_time,location,start_time,id,service_type
          }
        })

        return filterData
      }) 


      const currentDate:any = moment();
      let futureDateData:any = [];
      let pastAndCurrentDateData:any = [];
      data.forEach((item:any) => {
        const itemDate = moment(item.serv.date).startOf('day');
        const currentDateStart = currentDate.clone().startOf('day');
        if (itemDate.isBefore(currentDateStart)) {
          pastAndCurrentDateData.push(item);
        } else {
          futureDateData.push(item);
        }
      });
      futureDateData.sort((a:any, b:any) => {
        const timeA = moment(a.start_time, "HH:mm:ss");
        const timeB = moment(b.start_time, "HH:mm:ss");
      
        return timeA.isBefore(timeB) ? -1 : 1;
      });
      pastAndCurrentDateData.sort((a:any, b:any) => {
        const timeA = moment(a.start_time, "HH:mm:ss");
        const timeB = moment(b.start_time, "HH:mm:ss");
      
        return timeA.isBefore(timeB) ? -1 : 1;
      });
      futureDateData.sort((a:any, b:any) => {
        return moment(a.serv?.date).isAfter(moment(b.serv?.date)) ? -1 : 1;
      });
    
      const PendingPayments = data?.filter((item:any)=> item?.serv?.players[0]?.status==="Pending payment" && !item?.serv?.players[0]?.is_canceled)
   
      setPastBookings(catogroizePast(pastAndCurrentDateData));
      setupcoming(catogroizePast(futureDateData));
      setpendingPayments(PendingPayments);
   
    }
  };

  const totalLength = (bookings:any)=>{
    let len = 0;
    for(let i=0; i<bookings.length; i++){
      len = len + bookings[i]?.data.length;
    }
    return len;
  }

  const handleAddVoucher = async () => {
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      setVoucherADD(false);
      return;
    }

    if (SelectedVoucher?.voucher_name === "manual credits") {
      const apidata = {
        price: ManualPrice,
        value: credits,
        method_id: selectedMethods?.id,
      };
      const response = await AddManualCredits(customerdata?.id, apidata);
      if (response) {
        getVouchersByCustomer();
        refetch();
        setVoucherADD(false);
        setSelectedVoucher("");
        setSelectedMethod("");
      }
    } else {
      const apidata = {
        voucher_id: SelectedVoucher?.id,
        method_id: selectedMethods?.id,
      };
      const response = await AddVoucherToCustomer(customerdata?.id, apidata);
      if (response) {
        getVouchersByCustomer();
        refetch();
        setVoucherADD(false);
        setSelectedVoucher("");
        setSelectedMethod("");
      }
    }
  };

  const UpdateUSerDetails = async (e: any) => {
    e.preventDefault();
    if (!first_name) {
      setError("first_name");
      setTimeout(() => setError(""), 2000);
    } else {
      setError("");
      const formData = new FormData();
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("phone_number", phone_number);
      formData.append("email", email);
      image && formData.append("image", image);
      console.log(phone_number)
      // const updateresponse = await updateCustomerById(
      //   customerSetting?.id,
      //   formData
      // );
      // if (updateresponse) {
      //   fromCustomer && GetAllCustomer();
      //   handleClose();
      // }
    }
  };

  const handleClick = (location: any) => {
    if (location?.voucher_name === "manual credits") {
      setSelectedMethod(undefined);
      setSelectedVoucher(location);
    } else {
      setSelectedMethod(undefined);
      // setlocation_id(location?.id);
      setSelectedVoucher(location);
    }
  };

  useEffect(() => {
    if (!!selectedPrices?.length) {
      const Location_id =
        selectedPrices[0]?.location?.id;
      setLocation_id(Location_id);
      SetPCustomer_id(customerdata?.id);
    }
  }, [selectedPrices]);

  useEffect(() => {
    if (PaymentMethodSuccess && ClubWallSuccess) {
      const updated = ClubWallData?.map((item: walletTypes) => {
        const method_name = `Wallet ${addThousandSeparator(
          item.balance,
          item.currency
        )} ${item.currency}`;
        return { ...item, method_name };
      });
      setALLPaymentMethodsOFCustomer([
        ...updated,
        ...PaymentMethodsData?.paymentMethods,
      ]);
    } else if (ClubWallSuccess) {
      const updated = ClubWallData?.map((item: walletTypes) => {
        const method_name = `Wallet ${addThousandSeparator(
          item.balance,
          item.currency
        )} ${item.currency}`;
        return { ...item, method_name };
      });
      setALLPaymentMethodsOFCustomer(updated);
    } else if (PaymentMethodSuccess) {
      setALLPaymentMethodsOFCustomer(PaymentMethodsData?.paymentMethods);
    }
  }, [
    PaymentMethodsData,
    PaymentMethodSuccess,
    PaymentMethodError,
    ClubWallSuccess,
    ClubWallData,
    ClubWallError,
  ]);


  const uploadHandler = (event: any) => {
    const imageSize: number = Math.round(
      Number(event.target.files[0].size / 1024)
    );
    if (imageSize > 10 * 1024) {
      setNotification({
        isShow: true,
        message: "Image not uploaded",
        subMessage: "Please upload a file smaller than 10 MB.",
        type: "success",
      });
    } else {
      setImage(event.target.files[0]);
      setFormvalue("profile_url", URL.createObjectURL(event.target.files[0]));
    }
  };

  //submit payment
  const SubmitPayment = async(e: any) => {
    e.preventDefault();
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      setShowPayment(false);
      return;
    }
    const apidata = {
      "payments":payments
    }

   const errorDEf = payments?.length===0 ?"Please Select Payment Methods":''
    
    try {
     if(!errorDEf){
      const response :any= await MakePaymentOfCustomer(apidata)
      setNotification({
        isShow: true,
        message: response.data.message,
        subMessage: "",
        type: "success",
      });
     }
      GetAllBookings();
      handleCloseMakePayment();
    } catch (error:any) {
      setNotification({
        isShow: true,
        message: errorDEf,
        subMessage: "",
        type: "error",
      });
    }
  };


  useEffect(() => {
    let data=pendingPayments;
    if(addPlayer){
      data =filterData(data,addPlayer)  
    }
    setFilteredPendingPaymnets(data);
  }, [addPlayer,pendingPayments])
  


  useEffect(() => {
    if (LocationSuccess) {
      if(!!SelectedVoucher && SelectedVoucher.voucher_name!=="manual credits"){
        const selVoucher = AllLocations?.filter((item:any)=>{
          return SelectedVoucher?.locations?.some((loc:any)=>item.id===loc.id)
        })
        setLocations(selVoucher);
      }else{
        setLocations(AllLocations);
      }
    } else {
      setLocations([]);
    }
  }, [AllLocations, LocationError, LocationSuccess,SelectedVoucher]);

  let totalDiscount: any =
    payments?.length === 0
      ? 0
      : payments?.reduce((item, acc: any) => item + acc?.discount, 0);
  let TotalPrice = payments?.length === 0
  ? 0: payments?.reduce((acc, item: any) => acc + item?.price, 0);
  let finalPrice = TotalPrice - totalDiscount;

  const handleCloseMakePayment=()=>{
    setShowPayment(false);
    totalDiscount=0;
    TotalPrice=0;
    finalPrice=0;
    setPayments([]);
    setSelectedPrices([]);
  }

  const handleWalletEdit= async(data:any)=>{
    const apiData = {wallet:data.walletdata?.map((item:any)=>{
      const {currency,balance,id} =item;
      const Bala = Number(balance)
      return {wallet_id:id,balance:Bala}
    })}
    try {
      const response = await EditCustomerWallet(customerdata?.id,apiData);
      setNotification({
        isShow: true,
        message: 'Wallet Edited Successfully',
        subMessage: "",
        type: "success",
      });
      refetch();
      remove()
      getVouchersByCustomer()
      setShowEditWallet(false)
    } catch (error:any) {
      setNotification({
        isShow: true,
        message: error,
        subMessage: "",
        type: "error",
      });
    }
}

  const handleparentChange =()=>{
    const firstLocation  = FilteredPendingPaymnets[0]?.location?.id;
    const isSame = FilteredPendingPaymnets.every(
      (item) => item?.location?.id === firstLocation
    );
    if(isSame){
      setSelectedPrices((prev:any)=>{
        if(prev.length>0){
          return [];
        }else{
          return pendingPayments
        }
      })
    }else{
      setNotification({
        isShow: true,
        message: "You cannot select all bookings at once, as there are bookings with different location.",
        subMessage: "",
        type: "error",
      });
    }
    
  }
   const StaffPermission = useGetPermissions();
  const WhatsappURL = `https://api.whatsapp.com/send?phone=${phone_number}`;

  return (
    <Dialog
      hideBackdrop={hidebackdrop}
      open={show}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ ".MuiDialog-paper": { maxWidth: "680px !important" } }}
    >
      <DialogContent id="table-person" style={{ marginTop: "0px" }}>
        <Box>
          <Box>
            <Typography
              sx={{
                color: "#22356D",
                fontFamily: "var(--font-medium)",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                gap: "10px"
              }}
              marginBottom={1}
            >
              {!fromCustomer && (
                <PiCaretLeft
                  style={{ color: "#22356D", cursor: "pointer" }}
                  onClick={() => setShow(false)}
                />
              )}
              {t('customers.Player Info')} 
            </Typography>

            {isBlocked && (
              <Box
                sx={{
                  width: "95%",
                  height: "92%",
                  zIndex: "8",
                  backgroundColor: "rgba(255, 255, 255, 0.4)",
                }}
                className="absolute"
              >
                {" "}
              </Box>
            )}
            {/* user info */}
            <Box
              sx={{
                background: "#889AB61A",
                padding: "10px 20px",
                borderRadius: "7px",
                border: "0.5px solid #EEEFEE",
                boxShadow: "inset 0 0 10px #0000000D",
                marginBottom: "10px",
              }}
            >
              <Stack
                spacing={2}
                direction={"row"}
                sx={{ alignItems: "center" }}
              >
                <Box
                  sx={{ position: "relative", width: "100px", height: "100%" }}
                >
                  <img
                    style={{
                      borderRadius: "50%",
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                    }}
                    src={profile_url ? profile_url : "/images/Reserved.svg"}
                  />
                  <input
                    onChange={(e) => uploadHandler(e)}
                    style={{
                      zIndex: 4,
                      position: "absolute",
                      opacity: "0",
                      width: "30px",
                      height: "30px",
                      bottom: "0",
                      right: "3px",
                    }}
                    type="file"
                    name="file"
                  />

                  <img
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      right: "6px",
                    }}
                    src="/images/Group 106.svg"
                  />
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "auto", sm: "2fr 1fr" },
                    gap: { xs: "6px", sm: "15px" },
                  }}
                >
                  <Box sx={{display:'flex',flexDirection:'column'}}>                
                  <Box sx={{">input:nth-child(2)":{position:"relative",top:{xs:"0px", sm:"0px", md:"0px"}},display:'flex',flexDirection:{sm:'row',xs:'column'},alignItems:'center',gap:{sm:'10px',xs:'5px'}}}> 
                    <input
                      id={error === "first_name" ? "customerror" : ""}
                      {...register("first_name", { required: true })}
                      type="text"
                      placeholder={t('customers.First Name')}
                      className="h-7 outline-none rounded-md mb-2"
                      style={{
                        width: "100%",
                        paddingLeft: "10px",
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109)",
                      }}
                    />
                    <input  id={error === "last_name" ? "customerror" : ""}
                      {...register("last_name", { required: true })}
                      type="text"
                      placeholder={t("customers.Last Name")}
                      className="h-7 outline-none rounded-md mb-2"
                      style={{
                        width: "100%",
                        paddingLeft: "10px",
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109)",
                       
                      }}/>
                  </Box>
                  <Box sx={{">input:nth-child(1)":{position:"relative", top:{xs:"0px", sm:"0px", md:"0px"}},display:'flex',flexDirection:{sm:'row',xs:'column'},alignItems:'center',gap:'10px'}}>
                  <input
                      type="text"
                      {...register("phone_number")}
                      placeholder={t("customers.Phone")}
                      className="h-7 outline-none rounded-md"
                      style={{
                        width: "100%",
                        paddingLeft: "10px",
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109)",
                      }}
                    />
                  
                    <input
                      {...register("email")}
                      type="email"
                      placeholder={t("common.Email")}
                      className="h-7 outline-none rounded-md"
                      style={{
                        width: "100%",
                        paddingLeft: "10px",
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109)",
                      }}
                    />
                  </Box>
                  </Box>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      marginLeft={2}
                    >
                      <button
                        style={{
                          ...style,
                          color: activeColor,
                          paddingBlock: "0px",
                        }}
                      >
                        <Link target={"_blank"} to={WhatsappURL}>
                          Whatsapp
                        </Link>
                      </button>
                    </Box>
                  </Box>
                  
                </Box>
              </Stack>
              {/* mobile view - whatsapp / badge */}
              <Box
                sx={{ display: { xs: "flex", sm: "none" }, marginTop: "10px", position:'relative',flexWrap:'wrap'}}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                > */}
                  <button
                    style={{
                      ...style,
                      color: activeColor,
                      paddingBlock: "0px",
                      width:'110px'
                    }}
                  >
                    <Link target={"_blank"} to={WhatsappURL}>
                      Whatsapp
                    </Link>
                  </button>
                  <Box
                    justifyContent={'flex-end'}
                    display={"flex"}
                    flexWrap={'wrap'}
                    flexDirection={'row'}
                    gap={0.5}
                    sx={{
                      color: "#22356D",
                      fontFamily: "var(--font-regular)",
                      marginTop:'10px'
                    }}
                    marginBottom={"0.55rem"}
                    alignItems={"center"}
                  >
                    {AllMemberShip?.length > 0
                      ? AllMemberShip?.map((item: any) => 
                        <Box
                        key={item}
                        sx={{
                          background: "#889AB626",
                          padding: "0px 2px",
                          borderRadius: "4px",
                          height: "17px",
                          fontSize: "14px",
                        }}
                      >
                        {item?.membership?.membership_name} [{item?.uses_left===-1?'Unlimited':item?.uses_left}]
                      </Box>
                      )
                      : ""}
                  </Box>
                {/* </Box> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  margin: {xs:'0',sm:"10px 0 5px"},
                }}
              >
                <Grid
                  sx={{
                    width: "120px",
                    color: "#22356DB2",
                    display: "inline-block",
                    fontSize: "14px",
                    fontFamily: "var(--font-regular)",
                  }}
                >
                 {t('customers.Club Wallet')} :
                </Grid>
                <Grid container width={"80%"} gap={2} flexWrap={"wrap"}>
                  {ClubWallet?.map((item: any) => (
                    <Typography
                      sx={{
                        background: "#889AB626",
                        padding: "0px 8px",
                        borderRadius: "4px",
                        height: "17px",
                        fontSize: "14px",
                        fontFamily: "var(--font-regular)",
                      }}
                    >
                      {addThousandSeparator(item.balance, item.currency)}{" "}
                      {item?.currency}
                    </Typography>
                  ))}
                </Grid>
              </Box>
              <Grid sx={{marginTop:{xs:'0px',sm:'10px'}}} container>
                <Grid
                xs={8}
                  sx={{
                    color: "#22356DB2",
                    fontFamily: "var(--font-regular)",
                    fontSize: "0.9rem",
                    textAlign: "left",
                  }}
                >
                  <p>{t('customers.No-Show')}: {stats?.totalNoShowBookings}</p>
                  <p>
                    {t('customers.Pending Payment')}:<span style={{background: "#889AB626",
                        padding: "0px 8px",
                        borderRadius: "4px",
                        height: "17px",
                        fontSize: "14px",
                        marginLeft:'5px',
                        fontFamily: "var(--font-regular)"}}>{addThousandSeparator(pendingPayments?.reduce((acc:any,item:any)=>acc+item.serv?.players[0]?.pending_price,0)||0,"IDR")} IDR</span>
                  </p>
                </Grid>
                <Grid  xs={4}>
                <Box
                  gap={0.7}
                  sx={{
                    color: "#22356D",
                    fontFamily: "var(--font-regular)",
                    display:{sm:'flex',xs:'none'},
                    flexDirection:{sm:'row'},
                    flexWrap:'wrap',
                    justifyContent:'flex-end'
                  }}
                  marginBottom={"0.55rem"}
                  alignItems={"center"}
                >
                  {AllMemberShip?.length > 0
                    ? AllMemberShip?.map((item: any) =>  <Typography
                      key={item}
                      sx={{
                        background: "#889AB626",
                        padding: "0px 4px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        textTransform:'capitalize',
                        fontFamily:'var(--font-regular)'
                      }}
                    >
                      {item?.membership?.membership_name} [{item?.uses_left===-1?'Unlimited':item?.uses_left}]
                    </Typography>
                    )
                    : ""}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* end */}

            <Box display={"flex"} justifyContent={"flex-end"}>
              {isBlocked ? (
                <Box
                  onClick={handleBlock}
                  sx={{
                    background: "#FF1313",
                    padding: "4px 10px",
                    borderRadius: "4px",
                    color: "white",
                    width: "115px",
                    fontFamily: "var(--font-regular)",
                    marginBottom: "10px",
                    cursor: "pointer",
                    zIndex: "10",
                  }}
                >
                  {t('customers.Blocked Player')}
                </Box>
              ) : (
                <button
                  style={{ ...style, color: activeColor }}
                  onClick={() => setBlock(true)}
                >
                  {t('customers.Block Player')}
                </button>
              )}
            </Box>

            <Box>
              <TabContext value={value}>
                <Box>
                  <TabList
                    className="tbsssfasddasd"
                    onChange={handleSelectTab}
                    aria-label="tabs example"
                    TabIndicatorProps={{
                      sx: { backgroundColor: 'transparent' },
                    }}
                    sx={{
                      ".MuiTab-root": {
                        paddingBottom: "0px",
                        paddingInline: "10px",
                        textTransform: "none",
                      },
                      ".Mui-selected": {
                        color: "#22356D !important",
                        fontFamily: "var(--font-semibold)",
                      },
                    }}
                  >
                    <Tab
                      label={t('customers.User Settings')}
                      value="User Settings"
                      sx={{
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109, 0.60)",
                        borderBottom:value==="User Settings" ? '2px solid #22356D':'',
                      }}
                    />
                    <Tab
                      label={`${t('customers.Upcoming Bookings')} [${totalLength(upcoming)}]`}
                      value="Upcoming"
                      sx={{
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109, 0.60)",
                        borderBottom:value==="Upcoming" ? '2px solid #22356D':'',
                      }}
                    />
                    <Tab
                      label={`${t('customers.Past Bookings')} [${totalLength(pastBookings)}]`}
                      value="Past"
                      sx={{
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109, 0.60)",
                        borderBottom:value==="Past" ? '2px solid #22356D':'',
                      }}
                    />
                    <Tab
                      label={`${t('customers.Pending Payments')} [${pendingPayments.length}]`}
                      value="Pending payments"
                      sx={{
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109, 0.60)  ",
                        borderBottom:value==="Pending payments" ? '2px solid #22356D':'',
                      }}
                    />
                    <Tab
                      label={t('title.Stats')}
                      value="Stats"
                      sx={{
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109, 0.60)",
                        borderBottom:value==="Stats" ? '2px solid #22356D':'',
                      }}
                    />
                    <Tab
                      label={t('customers.Customer Wallets')}
                      value="Customer Wallets"
                      sx={{
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109, 0.60)",
                        borderBottom:value==="Customer Wallets" ? '2px solid #22356D':'',
                      }}
                    />
                     <Tab
                      label={t('customers.Membership')}
                      value="Membership"
                      sx={{
                        fontFamily: "var(--font-regular)",
                        color: "rgba(34, 53, 109, 0.60)",
                        borderBottom:value==="Membership" ? '2px solid #22356D':'',
                      }}
                    />
                  </TabList>
                </Box>
                <TabPanel value="User Settings" sx={{ padding: "10px 0", }}>
                  <UserSetting
                    columnRefetch={columnRefetch}
                    setFormvalue={setFormvalue}
                    UserProfile={image}
                    updateCustomSettings={updateCustomSettings}
                    fromCustomer={fromCustomer}
                    setError={setError}
                    GetAllCustomer={GetAllCustomer}
                    watch={watch}
                    setShow={setShow}
                    getCustomerbyid={getCustomerbyid}
                    handleClose={handleClose}
                    userdata={customerSetting}
                    custField={customfield}
                  />
                </TabPanel>
                <TabPanel value="Upcoming" sx={{ padding: "10px 0" }}>
                  <Box maxHeight={"330px"} height={"auto"} id="table-person">
                    <Box className="customerList">
                      {upcoming.length > 0 ? (
                        upcoming?.map((item) => (item?.data?.map((booking:any)=><ListDesign item={booking} checkbox={false} />))
                        )
                      ) : (
                        <Typography
                          sx={{
                            background: "#889AB64D !important",
                            textAlign: "center",
                            fontFamily: "var(--font-regular)",
                            color: "rgba(34, 53, 109, 0.80)",
                          }}
                        >
                          {t('customers.No Upcoming Bookings Found')}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <form onSubmit={(e: any) => UpdateUSerDetails(e)}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      marginTop={2}
                    >
                      <button
                        style={{ ...style, zIndex: "12", color: activeColor }}
                        onClick={() =>
                          customerdata !== ""
                            ? setDeletePl(true)
                            : setShow(false)
                        }
                      >
                        {customerdata !== "" ? t("customers.Delete Customer") : t("common.Cancel")}
                      </button>
                      <button
                        type="submit"
                        style={{
                          ...style,
                          color: "white",
                          zIndex: "12",
                          background: activeColor,
                        }}
                      >
                        {customerdata !== "" ?t("common.Save"):t("customers.Add new customer")}
                      </button>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value="Past" sx={{ padding: "10px 0" }}>
                  <Box maxHeight={"330px"} height={"auto"} id="table-person">
                    <Box className="customerList">
                      {pastBookings.length > 0 ? (
                        pastBookings?.map((item) => (
                          item.data.map((booking:any)=><ListDesign item={booking} checkbox={false} />)
                        ))
                      ) : (
                        <Typography
                          sx={{
                            background: "#889AB64D !important",
                            textAlign: "center",
                            fontFamily: "var(--font-regular)",
                            color: "rgba(34, 53, 109, 0.80)",
                          }}
                        >
                          {t('customers.No Past Bookings Found')}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <form onSubmit={(e: any) => UpdateUSerDetails(e)}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      marginTop={2}
                    >
                      <button
                        style={{ ...style, zIndex: "12", color: activeColor }}
                        onClick={() =>
                          customerdata !== ""
                            ? setDeletePl(true)
                            : setShow(false)
                        }
                      >
                       {customerdata !== "" ? t("customers.Delete Customer") : t("common.Cancel")}
                      </button>
                      <button
                        type="submit"
                        style={{
                          ...style,
                          color: "white",
                          zIndex: "12",
                          background: activeColor,
                        }}
                      >
                        {customerdata !== "" ?t("common.Save"):t("customers.Add new customer")}
                      </button>
                    </Box>
                  </form>
                </TabPanel>

                <TabPanel value="Pending payments" sx={{ padding: "10px 0" }}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <input
                      type="text"
                      value={addPlayer}
                      placeholder={"Search Booking"}
                      onChange={(e: any) => setAddPlayer(e.target.value)}
                      className="addPlayer"
                    />
                    <Box
                      width={"fit-content"}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <p
                        style={{
                          fontFamily: "var(--font-regular)",
                          color: "rgba(34, 53, 109)",
                        }}
                      >
                        {t('customers.Select all')}
                      </p>
                      <label style={{ margin: "10px" }} className="container">
                        <input
                          checked={
                            selectedPrices?.length === FilteredPendingPaymnets?.length
                          }
                          onChange={handleparentChange}
                          type="checkbox"
                        />
                        <span className="mark"></span>
                      </label>
                    </Box>
                  </Box>
                  <Box maxHeight={"330px"} height={"auto"} id="table-person">
                    <Box className="customerList">
                      {FilteredPendingPaymnets.length > 0 ? (
                        FilteredPendingPaymnets?.map((item) => (
                          <ListDesign
                            setSelectedPrices={setSelectedPrices}
                            selectedPrices={selectedPrices}
                            item={item}
                            checkbox={true}
                          />
                        ))
                      ) : (
                        <Typography
                          sx={{
                            background: "#889AB64D !important",
                            textAlign: "center",
                            fontFamily: "var(--font-regular)",
                            color: "rgba(34, 53, 109, 0.80)",
                          }}
                        >
                          {t('customers.No Pending Payments Found')}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    paddingY={"18px"}
                  >
                    <p
                      style={{
                        fontFamily: "var(--font-semibold)",
                        color: activeColor,
                      }}
                    >
                      {t('customers.Pending Payment')}
                    </p>
                    <Box sx={{ fontFamily: 'var(--font-regular)', color: 'white', textAlign: "center" }} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                   <Typography sx={{ paddingX:'5px',background: '#FF0000',color:'white',width:'-webkit-fill-available',opacity:pendingPayments?.length>0?1:'0.3' ,borderRadius: '5px', marginBottom: "7px",fontFamily:'var(--font-regular)'}}>{addThousandSeparator(pendingPayments?.reduce((acc:any,item:any)=>acc + item?.serv?.players[0]?.pending_price,0),"IDR")} IDR</Typography>
                        { 
                        selectedPrices?.length >0? <Box onClick={()=>setShowPayment(true)} sx={{ background: "#22356D", borderRadius: '7px',border:"1px solid #EEEFEE", padding:"7px 17px", width:"100%", height:'37px', cursor:'pointer',boxShadow:'0px 4px 4px 0px #0000001A' }}>Open Payments</Box>
                        :<Box sx={{ background: "#22356D66", borderRadius: '5px', width:'-webkit-fill-available', cursor:'pointer' }}>Pay 0</Box>
                        }
                    </Box>

                  </Box>
                  <form onSubmit={(e: any) => UpdateUSerDetails(e)}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      marginTop={2}
                    >
                      <button
                        style={{ ...style, zIndex: "12", color: activeColor }}
                        onClick={() =>
                          customerdata !== ""
                            ? setDeletePl(true)
                            : setShow(false)
                        }
                      >
                        {customerdata !== "" ? t("customers.Delete Customer") : t("common.Cancel")}
                      </button>
                      <button
                        type="submit"
                        style={{
                          ...style,
                          color: "white",
                          zIndex: "12",
                          background: activeColor,
                        }}
                      >
                        {customerdata !== "" ?t("common.Save"):t("customers.Add new customer")}
                      </button>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value="Stats" sx={{ padding: "10px 0" }}>
                  <Box>
                    <Box
                      marginBlock={2}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        fontFamily: "var(--font-regular)",
                        color: "#22356D",
                      }}
                    >
                      <Box sx={{ ...commonStyle }}>
                        <p>{t('customers.Total Bookings')}</p>
                        <Box width={"160px"} color={"#22356DB2"}>
                          {stats?.totalBookings}
                        </Box>
                      </Box>
                      {/* <Box sx={{ ...commonStyle }}>
                        <p>App Bookings</p>
                        <Box width={"160px"} color={"#22356DB2"}>
                          {stats?.totalappBookings}
                        </Box>
                      </Box> */}
                      <Box sx={{ ...commonStyle }}>
                        <p>{t('customers.Cancelled Bookings')}</p>
                        <Box width={"160px"} color={"#22356DB2"}>
                          {stats?.totalCancelledBooking}
                        </Box>
                      </Box>
                      <Box sx={{ ...commonStyle }}>
                        <p>{t('customers.No-Show Bookings')}</p>
                        <Box width={"160px"} color={"#22356DB2"}>
                          {stats?.totalNoShowBookings}
                        </Box>
                      </Box>
                      <Box sx={{ ...commonStyle }}>
                        <p>{t('customers.Total Spent')}</p>
                        <Box width={"160px"} color={"#22356DB2"}>
                          <Cleave
                            readOnly
                            value={stats?.totalSpent}
                            style={{
                              width: "auto",
                              color: "rgba(34, 53, 109,0.9)",
                              fontFamily: "var(--font-regular)",
                              fontSize: "14px",
                              lineHeight: "17px",
                              padding: "5px 8px",
                              backgroundColor: "transparent",
                            }}
                            options={{
                              numeral: true,
                              numeralThousandsGroupStyle: "thousand",
                              delimiter: "\u2024",
                              prefix: "Rp. ",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ ...commonStyle }}>
                        <p>{t('customers.Pending Payment')}</p>
                        <Box width={"160px"} color={"#22356DB2"}>
                          <Cleave
                            readOnly
                            value={stats?.totalPendingPayment}
                            style={{
                              width: "auto",
                              color: "rgba(34, 53, 109,0.9)",
                              fontFamily: "var(--font-regular)",
                              fontSize: "14px",
                              lineHeight: "17px",
                              padding: "5px 8px",
                              backgroundColor: "transparent",
                            }}
                            options={{
                              numeral: true,
                              numeralThousandsGroupStyle: "thousand",
                              delimiter: "\u2024",
                              prefix: "Rp. ",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <form onSubmit={(e: any) => UpdateUSerDetails(e)}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        marginTop={2}
                      >
                        <button
                          style={{ ...style, color: activeColor }}
                          type="button"
                          onClick={() =>
                            customerdata !== ""
                              ? setDeletePl(true)
                              : setShow(false)
                          }
                        >
                          {customerdata !== "" ? t("customers.Delete Customer") : t("common.Cancel")}
                        </button>
                        <button
                          style={{
                            ...style,
                            color: "white",
                            background: activeColor,
                          }}
                          type="submit"
                        >
                           {customerdata !== "" ?t("common.Save"):t("customers.Add new customer")}
                        </button>
                      </Box>
                    </form>
                  </Box>
                </TabPanel>
                <TabPanel value="Customer Wallets" sx={{ padding: "10px 0" }}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "var(--font-regular)",
                          color: "#22356D",
                        }}
                      >
                        {t('customers.Wallet Settings')}
                      </Typography>
                      <Button
                        onClick={() => setVoucherADD(true)}
                        style={{
                          color: "rgba(34, 53, 109, 0.70)",
                          fontFamily: "var(--font-regular)",
                          fontSize: "14px",
                          gap: "10px",
                          textTransform: "none",
                        }}
                      >
                        {t('customers.Add Voucher')}
                        <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
                      </Button>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "var(--font-semibold)",
                          color: "#22356D",
                          fontSize: "14px",
                          opacity: "0.7",
                        }}
                      >
                        {t('customers.Active Credits')}
                      </Typography>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Button onClick={()=>setShowEditWallet(true)} sx={{ ...style, color: activeColor,padding:'0px 2px',textTransform:'capitalize',marginBottom:'5px' }}>{t('common.Edit')}</Button>
                        {ClubWallet?.map((item: any) => (
                          <Typography
                            sx={{
                              fontFamily: "var(--font-semibold)",
                              color: "#22356D",
                              fontSize: "14px",
                              opacity: "0.7",
                            }}
                          >
                            {addThousandSeparator(
                              item?.balance,
                              item?.currency
                            )}{" "}
                            {item?.currency}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ marginTop: "20px" }}>
                      <Typography
                        sx={{
                          fontFamily: "var(--font-semibold)",
                          color: "#22356D",
                          fontSize: "14px",
                          opacity: "0.7",
                        }}
                      >
                        {t('customers.Voucher Purchase History')}
                      </Typography>
                    </Box>

                    <Grid container sx={{display:'grid',gridTemplateColumns:{ xs: "repeat(2, 1fr)", sm: "repeat(4, 1fr)" }}}>
                      <Typography
                        sx={{
                          display: { md: "block", xs: "none" },
                          fontFamily: "var(--font-semibold)",
                          color: "#22356D",
                          fontSize: "14px",
                          opacity: "0.7",
                        }}
                      >
                        {t('customers.Purchase Date')}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "var(--font-semibold)",
                          color: "#22356D",
                          fontSize: "14px",
                          opacity: "0.7",
                        }}
                      >
                        {t('customers.Voucher Name')}
                      </Typography>
                      <Typography
                        sx={{
                          display: { md: "block", xs: "none" },
                          fontFamily: "var(--font-semibold)",
                          color: "#22356D",
                          fontSize: "14px",
                          opacity: "0.7",
                        }}
                      >
                        {t('common.Price')}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "var(--font-semibold)",
                          color: "#22356D",
                          fontSize: "14px",
                          opacity: "0.7",
                        }}
                      >
                        {t('customers.Credit Amount')}
                      </Typography>
                    </Grid>
                     <Box id="table-person" style={{paddingLeft:'10px',height:'auto',maxHeight:'120px',padding:'5px',marginTop:'0px'}}>
                      {AllVouchers?.map((item: any) => (
                          <Grid container sx={{display:'grid',gridTemplateColumns:{ xs: "repeat(2, 1fr)", sm: "repeat(4, 1fr)" }}}>
                            <Typography
                              sx={{
                                display: { md: "block", xs: "none" },
                                fontFamily: "var(--font-regular)",
                                color: "#22356D",
                                fontSize: "14px",
                              }}
                            >
                              {moment(item?.created_at).format("DD/MM/YYYY")}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "var(--font-regular)",
                                color: "#22356D",
                                fontSize: "14px",
                              }}
                            >
                              {item?.voucher?.voucher_name || (item?.credit_type==="Wallets"? "Manual Edit":"Custom Voucher")}
                            </Typography>
                            <Typography
                              sx={{
                                display: { md: "block", xs: "none" },
                                fontFamily: "var(--font-regular)",
                                color: "#22356D",
                                fontSize: "14px", 
                              }}
                            >
                              {item?.voucher?.price || item?.price  ? addThousandSeparator(item?.voucher?.price || item?.price,item?.currency||item?.voucher?.currency):"-"} {item?.voucher?.currency}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "var(--font-regular)",
                                color: "#22356D",
                                fontSize: "14px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {addThousandSeparator(
                                item?.voucher?.value ||item?.value,
                                item?.currency || item?.voucher?.currency
                              )} {item?.currency || item?.voucher?.currency}
                            </Typography>
                          </Grid>
                      ))}
                     </Box>
                  </Box>
                </TabPanel>
                <TabPanel value="Membership" sx={{ padding: "10px 0" }}>
                  <MembershipTab GetAllCustomer={GetAllCustomer} AllMemberShip={AllMemberShip} refetch={MemberRefetch} profile_url={profile_url} customerSetting={customerSetting} userData={customerdata} />
                </TabPanel>
              </TabContext>
            </Box>
          </Box>

          

          {/*Adding Payment popup */}
          <Dialog
            sx={{
              ".MuiDialog-paper": {
                minWidth: "700px",
              },
            }}
            onClose={handleCloseMakePayment}
            open={showPayment}
          >
            <DialogContent>
              <form onSubmit={(e) => SubmitPayment(e)}>
                <Typography
                  sx={{
                    fontFamily: "var(--font-medium)",
                    color: "#22356D",
                    marginRight: "5rem",
                  }}
                >
                  {t('locationmanager.Payment Settings')}
                </Typography>
                <Typography
                  sx={{ fontFamily: "var(--font-regular)", color: "#22356D" }}
                ></Typography>
            <Box
              sx={{
                display: "flex",
                marginTop:'10px',
                alignItems: {xs:"baseline", sm:"center"},
                gap: "5px",
                ">img":{display:{xs:"none", sm:"block"}}
              }}
            >
              <img style={{width:'60px',height:'60px',borderRadius:'50%'}} src={profile_url? profile_url:"/images/Reserved.svg"}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap:"10px"
                }}
              >
                  <Typography
                  sx={{
                    fontFamily: "var(--font-regular)",
                    color: "#22356D",
                    textAlign: "left",
                  }}
                >
                  {customerdata?.first_name} {customerdata?.last_name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "flex-start",
                      flexDirection:'row'
                    }}
                  >
                    {
                    customerdata?.customerTypes?.map((item:any)=> 
                    <Typography key={item?.id}
                    sx={{
                      fontFamily: "var(--font-regular)",
                      color: "#22356D",
                      padding: "0px 7px",
                      font: "12px",
                      borderRadius: "4px",
                      border: " 0.8px solid rgba(136, 154, 182, 0.22)",
                      background:
                        "var(--Main-Colors-Blue-Secondary-Transparency-2, rgba(136, 154, 182, 0.15))",
                    }}
                  >{item?.name}
                    </Typography>)
                    }
                  </Box>
                </Box>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "var(--font-medium)",
                    color: "#22356D",
                    marginRight: "5rem",
                  }}
                >
                  {t('title.Payments')}
                </Typography>
               {
                selectedPrices?.map((item:any)=> <Box sx={{marginTop:'10px'}}> 
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Box
                  sx={{
                    width: "62px",
                    height: "21px",
                    borderRadius: "4px",
                    fontSize: "0.9rem",
                    fontFamily: "var(--font-regular)",
                    textAlign: "center",
                    background:
                      dynamicColor[item?.service_type]
                        .bgColor,
                    color:
                      dynamicColor[item?.service_type].color,
                  }}
                >
                  {t(`common.${item?.service_type}`)}
                </Box>
                <Stack
                  sx={{
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "baseline", sm: "center" },
                    gap: { xs: "0", sm: "10px" },
                  }}
                  spacing={1}
                  fontFamily={"var(--font-regular)"}
                  color={"#22356D"}
                >
                  <Box sx={{ marginTop: "4px !important" }}>
                    {moment(item?.serv?.date).format("ddd D/M/YYYY")}
                  </Box>
                  <span style={{ ...divider }} className="show-row"></span>
                  <Box sx={{ marginTop: "4px !important" }}>{`${moment(
                    item?.start_time,
                    "HH:mm:ss"
                  ).format("HH:mm")} - ${moment(
                    item?.end_time,
                    "HH:mm:ss"
                  ).format("HH:mm")}`}</Box>
                  <span style={{ ...divider }} className="show-row"></span>
                  <Box
                    sx={{ marginTop: "4px !important" }}
                  >{`${item?.courts[0]?.court_name} - ${item?.location.location_name}`}</Box>
                </Stack>
                </Stack>
                <SingleCustomerPaymentRow
                setPayments={setPayments}
                item={item}
                PaymentMethods={AllPaymentMethodsOfCustomer}
                 /></Box>
                )
               }
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "baseline", sm: "flex-end" },
                    flexDirection: "column",
                    marginTop:'10px'
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignITems: "center",
                      gap: "8px",
                      justifyContent: "space-between",
                      width: { xs: "100%", sm: "auto" },
                    }}
                  >
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                      {t('common.Price')}
                    </p>

                    <Typography
                      sx={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign: "right",
                      }}
                    >
                      {addThousandSeparator(TotalPrice,"IDR")} IDR
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignITems: "center",
                      gap: "8px",
                      width: { xs: "100%", sm: "auto" },
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                      {t('common.Discount')}
                    </p>

                    <Cleave
                      readOnly
                      value={isNaN(totalDiscount) ? 0 : totalDiscount}
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign: "right",
                      }}
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                        delimiter: "\u2024",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignITems: "center",
                      gap: "8px",
                      width: { xs: "100%", sm: "auto" },
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                      Total
                    </p>
                    <Cleave
                      value={isNaN(finalPrice) ? 0 : finalPrice}
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign: "right",
                      }}
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                        delimiter: "\u2024",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  marginTop={2}
                >
                  <button
                  type="reset"
                    style={{ ...style, color: activeColor }}
                    onClick={handleClosePayment}
                  >
                    {t('common.Cancel')}
                  </button>
                  <button
                    type="submit"
                    style={{
                      ...style,
                      color: "white",
                      background: activeColor,
                    }}
                  >
                    {t('common.Save')}
                  </button>
                </Box>
              </form>
            </DialogContent>
          </Dialog>

          {/*Adding voucher popup */}
          <Dialog
            sx={{
              ".MuiDialog-paper": {
                minWidth: {
                  xs: "auto",
                  sm: "auto",
                  md:
                    SelectedVoucher?.voucher_name === "manual credits"
                      ? "975px"
                      : "800px",
                },
              },
            }}
            onClose={() => setVoucherADD(false)}
            open={voucherAdd}
          >
            <DialogContent>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "var(--font-medium)",
                    color: "#22356D",
                    marginRight: "5rem",
                  }}
                >
                  {t('customers.Voucher Purchase')}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "var(--font-medium)",
                    color: "#22356D",
                    marginRight: "5rem",
                  }}
                >
                  {t('title.Payments')}
                </Typography>
                <Box
                  display={"flex"}
                  marginTop={2}
                  gap={"27px"}
                  flexDirection={'column'}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: { xs: "column", sm: "column", md: "row" },
                      gap: "5px",
                    }}
                  >
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                      src={profile_url ? profile_url : "/images/Reserved.svg"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "var(--font-regular)",
                          color: "#22356D",
                          textAlign: "left",
                        }}
                      >
                        {customerdata?.first_name} {customerdata?.last_name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        {customerSetting?.customerTypes?.map((item: any) => (
                          <Typography
                            sx={{
                              fontFamily: "var(--font-regular)",
                              color: "#22356D",
                              padding: "0px 5px",
                              font: "12px",
                              borderRadius: "4px",
                              border: " 0.8px solid rgba(136, 154, 182, 0.22)",
                              background:
                                "var(--Main-Colors-Blue-Secondary-Transparency-2, rgba(136, 154, 182, 0.15))",
                            }}
                          >
                            {item?.name}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "column", md: "row" },
                      gap: { xs: "10px", sm: "10px", md: "27px" },
                    }}
                  >
                    <FormControl>
                      <Select
                        IconComponent={KeyboardArrowDownIcon}
                        value={SelectedVoucher}
                        id="customdropdown"
                        displayEmpty
                        renderValue={(value) => {
                          if (value) {
                            return value.voucher_name;
                          }
                          return <em>Select Voucher</em>;
                        }}
                        style={{
                          width: "180px",
                          height: "35px",
                          border: "1px solid #EEEEFE",
                          borderRadius: "7px",
                          fontSize: "14px",
                          opacity: "1",
                          fontFamily: "var(--font-regular)",
                          color: "#22356D",
                        }}
                        MenuProps={{ sx: dopdowncustomcss }}
                      >
                        {voucherList?.map((location) => (
                          <MenuItem
                            onClick={() => handleClick(location)}
                            key={location.id}
                            value={location}
                          >
                            {location.voucher_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* {SelectedVoucher?.voucher_name === "manual credits" && ( */}
                      <FormControl>
                        <Select
                          displayEmpty
                          IconComponent={KeyboardArrowDownIcon}
                          placeholder="Location"
                          value={selectedLocation}
                          id="customdropdown"
                          renderValue={(value: Location) => {
                            if (!!value) {
                              return value?.location_name;
                            }
                            return <em>Location</em>;
                          }}
                          style={{
                            width: "180px",
                            height: "35px",
                            border: "1px solid #EEEEFE",
                            borderRadius: "7px",
                            fontSize: "14px",
                            opacity: "1",
                            fontFamily: "var(--font-regular)",
                            color: "#22356D",
                          }}
                          MenuProps={{ sx: dopdowncustomcss }}
                        >
                          {Locations?.map((location: any) => (
                            <MenuItem
                              onClick={(e) => {
                                setSelectedLcoation(location);
                                setlocation_id(location?.id);
                              }}
                              key={location?.id}
                              value={location}
                            >
                              {location?.location_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    {/* )} */}
                    <FormControl>
                      <Select
                        displayEmpty
                        IconComponent={KeyboardArrowDownIcon}
                        placeholder="Customer type"
                        value={selectedMethods}
                        id="customdropdown"
                        renderValue={(value: any) => {
                          if (!!value) {
                            return value?.method_name;
                          }
                          return <em>Payment Method</em>;
                        }}
                        style={{
                          width: "180px",
                          height: "35px",
                          border: "1px solid #EEEEFE",
                          borderRadius: "7px",
                          fontSize: "14px",
                          opacity: "1",
                          fontFamily: "var(--font-regular)",
                          color: "#22356D",
                        }}
                        MenuProps={{ sx: dopdowncustomcss }}
                      >
                        {PaymentMethods?.map((location) => (
                          <MenuItem
                            onClick={(e) => setSelectedMethod(location)}
                            key={location?.id}
                            value={location}
                          >
                            {location?.method_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {SelectedVoucher?.voucher_name === "manual credits" && (
                      <>
                        {" "}
                        <div className="flex flex-row items-center gap-1">
                          <p
                            style={{
                              color: "rgba(34, 53, 109)",
                              fontFamily: "var(--font-medium)",
                              fontSize: "14px",
                              lineHeight: "17px",
                            }}
                          >
                            Credits
                          </p>
                          <Cleave
                            value={credits}
                            style={{
                              width: "100px",
                              color: "rgba(34, 53, 109)",
                              fontFamily: "var(--font-regular)",
                              fontSize: "14px",
                              lineHeight: "17px",
                              borderRadius: "7px",
                              padding: "5px 8px",
                              border: "1px solid #EEEFEE",
                            }}
                            onChange={(event: any) =>
                              setCredits(event.target.rawValue)
                            }
                            placeholder="100"
                            options={{
                              numeral: true,
                              numeralThousandsGroupStyle: "thousand",
                              delimiter: "\u2024",
                            }}
                          />
                        </div>
                        <div className="flex flex-row items-center gap-1">
                          <p
                            style={{
                              color: "rgba(34, 53, 109)",
                              fontFamily: "var(--font-medium)",
                              fontSize: "14px",
                              lineHeight: "17px",
                            }}
                          >
                            {t('common.Price')}
                          </p>

                          <Cleave
                            value={ManualPrice}
                            style={{
                              width: "100px",
                              color: "rgba(34, 53, 109)",
                              fontFamily: "var(--font-regular)",
                              fontSize: "14px",
                              lineHeight: "17px",
                              borderRadius: "7px",
                              padding: "5px 8px",
                              border: "1px solid #EEEFEE",
                            }}
                            onChange={(event: any) =>
                              setManualPrice(event.target.rawValue)
                            }
                            placeholder="100"
                            options={{
                              numeral: true,
                              numeralThousandsGroupStyle: "thousand",
                              delimiter: "\u2024",
                            }}
                          />
                        </div>
                      </>
                    )}
                    {SelectedVoucher?.voucher_name !== "manual credits" && (
                      <div className="flex flex-row items-center gap-1">
                        <p
                          style={{
                            color: "rgba(34, 53, 109)",
                            fontFamily: "var(--font-medium)",
                            fontSize: "14px",
                            lineHeight: "17px",
                          }}
                        >
                           {t('common.Price')}
                        </p>

                        <Cleave
                          value={SelectedVoucher?.price}
                          readOnly
                          style={{
                            width: "100px",
                            color: "rgba(34, 53, 109)",
                            fontFamily: "var(--font-regular)",
                            fontSize: "14px",
                            lineHeight: "17px",
                            borderRadius: "7px",
                            padding: "5px 8px",
                            border: "1px solid #EEEFEE",
                          }}
                          placeholder="100"
                          options={{
                            numeral: true,
                            numeralThousandsGroupStyle: "thousand",
                            delimiter: "\u2024",
                          }}
                        />
                      </div>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <div className="flex flex-row items-center gap-1">
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                        {t('common.Price')}
                    </p>

                    <p
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                      }}
                    >
                      {addThousandSeparator(
                        SelectedVoucher?.price || ManualPrice,
                        "IDR"
                      )}{" "}
                      IDR
                    </p>
                  </div>
                 {SelectedVoucher?.voucher_name !== "manual credits" && <div className="flex flex-row items-center gap-1">
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                      Credits
                    </p>

                    <p
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                      }}
                    >
                      {addThousandSeparator(
                        SelectedVoucher?.value||0,
                        "IDR"
                      )}{" "}
                      IDR
                    </p>
                  </div>}
                  <div className="flex flex-row items-center gap-1">
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                      Total
                    </p>

                    <p
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "14px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                      }}
                    >
                      {addThousandSeparator(
                        SelectedVoucher?.price || ManualPrice,
                        "IDR"
                      )}{" "}
                      IDR
                    </p>
                  </div>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  marginTop={2}
                >
                  <button
                    style={{ ...style, color: activeColor }}
                    onClick={() => setVoucherADD(false)}
                  >
                      {t('common.Cancel')}
                  </button>
                  <button
                    onClick={handleAddVoucher}
                    style={{
                      ...style,
                      color: "white",
                      background: activeColor,
                    }}
                  >
                      {t('common.Save')}
                  </button>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>

          {/*Player Block popup */}
          <Dialog onClose={() => setBlock(false)} open={block}>
            <DialogContent>
              {/* ---- for blocking user ---- */}
              {block && (
                <Box padding={2}>
                 { StaffPermission?.role!=="OTHER" ?<Box>
                <Typography sx={{fontFamily:"var(--font-medium)",color:"#22356D",marginBottom:'5px'}}>{t('common.You are not allowed to')} {t('common.block this player')}</Typography>
                <Stack direction={'row'} spacing={1} marginBottom={4}>
                    <img src="/images/info.svg" alt="info" />
                    <Typography sx={{color:"#22356DB2",fontFamily:"var(--font-regular)",}}>{t('common.Only staff managers are allowed to')} {t('common.block players.')}</Typography>
                </Stack>
                <button style={{ ...style, color: activeColor }}  onClick={() => setBlock(false)}>{t('common.Close')}</button>
                  </Box>:
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-medium)",
                        color: "#22356D",
                      }}
                    >
                     {t('customers.Are you sure you want to block this player ?')}
                    </Typography>
                    <Stack direction={"row"} spacing={1} marginBottom={4}>
                      <img src="/images/info.svg" alt="info" />
                      <Typography
                        sx={{
                          color: "#22356DB2",
                          fontFamily: "var(--font-regular)",
                        }}
                      >
                        {t('customers.This will prevent the player from using the app and make any booking.')}
                      </Typography>
                    </Stack>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      marginTop={2}
                    >
                      <button
                        style={{ ...style, color: activeColor }}
                        onClick={() => setBlock(false)}
                      >
                       {t('customers.No_undo')}
                      </button>
                      <button
                        style={{
                          ...style,
                          color: "white",
                          background: activeColor,
                        }}
                        onClick={handleBlock}
                      >
                        {t('customers.Yes_block')}
                      </button>
                    </Box>
                  </Box>}
                </Box>
              )}
            </DialogContent>
          </Dialog>

          {/*deleting customer popup */}
          <Dialog onClose={() => setDeletePl(false)} open={deletePl}>
            <DialogContent>
              {/* ---- for delete user ---- */}
              {deletePl && (
                <Box padding={2}>
                 {StaffPermission?.role!=="OTHER" ? <Box>
                <Typography sx={{fontFamily:"var(--font-medium)",color:"#22356D",marginBottom:'5px'}}>{t('common.You are not allowed to')} {t('delete this player')}</Typography>
                <Stack direction={'row'} spacing={1} marginBottom={4}>
                    <img src="/images/info.svg" alt="info" />
                    <Typography sx={{color:"#22356DB2",fontFamily:"var(--font-regular)",}}>{t('common.Only staff managers are allowed to')} {t('common.delete players.')}</Typography>
                </Stack>
                <button style={{ ...style, color: activeColor }}   onClick={() => setDeletePl(false)}>{t('common.Close')}</button>
            </Box>:
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-medium)",
                        color: "#22356D",
                        marginRight: "5rem",
                      }}
                    >
                     {t('customers.Are you sure you want to delete this player ?')}
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      marginBottom={4}
                      marginRight={"5rem"}
                    >
                      <img src="/images/info.svg" alt="info" />
                      <Typography
                        sx={{
                          color: "#22356DB2",
                          fontFamily: "var(--font-regular)",
                        }}
                      >
                        {t('customers.This will erase all the player information.')}{" "}
                      </Typography>
                    </Stack>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      marginTop={2}
                    >
                      <button
                        style={{ ...style, color: activeColor }}
                        onClick={() => setDeletePl(false)}
                      >
                        {t('common.No_undo')}
                      </button>
                      <button
                        onClick={handleDelete}
                        style={{
                          ...style,
                          color: "white",
                          background: activeColor,
                        }}
                      >
                         {t('customers.Yes, Cancel')}
                      </button>
                    </Box>
                  </Box>}
                </Box>
              )}
            </DialogContent>
          </Dialog>

          {!StaffPermission.edit_permissions && <StaffModal />}

          {/*edit customer credits popup */}
          <Dialog onClose={() => setShowEditWallet(false)} open={showeditWallet}>
            <DialogContent>
               {StaffPermission?.role!=="OTHER"?  <Box>
                <Typography sx={{fontFamily:"var(--font-medium)",color:"#22356D",marginBottom:'5px'}}>{t('common.You are not allowed to')} {t('common.edit this information')}</Typography>
                <Stack direction={'row'} spacing={1} marginBottom={4}>
                    <img src="/images/info.svg" alt="info" />
                    <Typography sx={{color:"#22356DB2",fontFamily:"var(--font-regular)",}}>{t('common.Only staff managers are allowed to')} {t('common.edit this information')}</Typography>
                </Stack>
                <button style={{ ...style, color: activeColor }}   onClick={() => setShowEditWallet(false)}>{t('common.Close')}</button>
            </Box>: <Box padding={2}>
                  <Box>
                 {walletdata===undefined && <Box sx={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}>
                    <Typography
                      sx={{
                        color: "#22356D",
                        fontFamily: "var(--font-semibold)",
                      }}
                    >
                     Wallet IDR
                    </Typography>
                    </Box>}
                    <form onSubmit={handleSubmit(handleWalletEdit)}>
                   {
                    walletdata?.map((item:any,index:number)=> <>
                      <Box sx={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}>
                    <Typography
                      sx={{
                        color: "#22356D",
                        fontFamily: "var(--font-semibold)",
                      }}
                    >
                     Wallet {item?.currency}
                    </Typography>
                    </Box>
                    <Box
                    sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',gap:'10px'}}
                    >
                       <Typography
                        sx={{
                          color: "#22356D",
                          fontFamily: "var(--font-regular)",
                          marginRight:'70px'
                        }}
                      >
                       {t('customers.Active Credits')}
                      </Typography>
                      <Cleave
                      style={borderInput}
                      value={item?.balance}
                      placeholder="Wallet price"
                      onChange={(e:any)=>setFormvalue(`walletdata.${index}.balance`,e.target.rawValue)}
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: "thousand",
                          delimiter:item?.currency==="IDR"? "\u2024":',',
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#22356D",
                          fontFamily: "var(--font-regular)",
                          marginRight:'10px'
                        }}
                      >
                      {item?.currency}
                      </Typography>
                    </Box></>)
                   }
                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      marginTop={2}
                    >
                      <button
                      type="submit"
                        style={{
                          ...style,
                          color: "white",
                          background: activeColor,
                        }}
                      >
                       {t('common.Save')}
                      </button>
                    </Box>
                    </form>
                  </Box>
                </Box>}
            </DialogContent>
          </Dialog>
        </Box>
        {!StaffPermission.edit_permissions && <StaffModal />}
      </DialogContent>
    </Dialog>
  );
};

export default CustomerInfo;


const filterData = (array:any, searchTerm:string) => {
  const lowercasedTerm = searchTerm.toLowerCase();

  return array.filter((item:any) => {
    const matchesPrice = item?.serv?.players[0].pending_price?.toString()?.includes(lowercasedTerm);
    const matchesServiceType = item?.service_type?.toLowerCase()?.includes(lowercasedTerm);
    const matchesLocationName = item?.location.location_name?.toLowerCase()?.includes(lowercasedTerm);
    const matchesCourtName = item?.courts[0]?.court_name?.toLowerCase()?.includes(lowercasedTerm);
    const matchesDate = moment(item?.serv?.date)?.format("ddd D/M/YYYY")?.toLowerCase()?.includes(lowercasedTerm);

    return (
      matchesPrice ||
      matchesServiceType ||
      matchesLocationName ||
      matchesCourtName ||
      matchesDate
    );
  });
};

const borderInput = {
  width: "106px",
  opacity: "0.7",
  padding: "5px 8px",
  fontSize: "14px",
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  color: "#22356D",
};