import { useState } from "react";
import { apiCaller } from "./ApiCaller";
import { getClubId } from "./CalendarBooking";
import { useQuery } from "react-query";



interface Location {
    id: number;
  }
  
  interface Booking {
    id: number;
  }
  
  interface Event {
    id: number;
  }
  
  interface Lesson {
    id: number;
  }
  
  interface Membership {
    membership_name: string;
    duration: string;
    uses_limit: string;
    price: number;
    currency: string;
    locations: Location[];
    bookings: Booking[];
    events: Event[];
    lessons: Lesson[];
  }

  interface custMember{
    
  "membership_id": string,
  "customer_id": number,
  "start_date": string,
  "payment_method_id": number,
  "location_id": number,
  "price": number
  }
  

export const CreateMembership = async (data: any) => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.post(`${club_id}/memberships`, data);
        return res;
    } catch (error:any) {
        let err = error.respone.data?.message;
        return err;
    }
}

export const updateMembership = async (id:number, data: any) => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.patch(`${club_id}/memberships/${id}`, data);
        return res;
    } catch (error:any) {
        let err = error.respone.data?.message;
        return err;
    }
}


export const deleteMembership = async (id:number) => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.delete(`${club_id}/memberships/${id}`);
        return res;
    } catch (error:any) {
        let err = error.respone.data?.message;
        return err;
    }
}

export const getAllMemeberShip = async () => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.get(`${club_id}/memberships`);
        return res.data.data;
    } catch (error:any) {
        let err = error.respone.data?.message;
        return err;
    }
}

export const useGetAllMemeberShip = () => {  
    const club_id = getClubId();
  
    return useQuery(
      ["AllMemberships"],
      async () => {
        const response = await apiCaller.get(`${club_id}/memberships`);
        return response.data.data;
      },
      {
        staleTime: 1000 * 60 * 5,
        retry: false,
      }
    );
  
  };

export const getAllMemeberShipSales = async (data:any) => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.get(`${club_id}/memberships/membership/records?start_date=${data?.date_start}&end_date=${data?.date_end}`);
        return res.data.data;
    } catch (error:any) {
        let err = error.respone.data?.message;
        return err;
    }
}



export const addMembershipToCust = async (data:custMember) => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.post(`${club_id}/memberships/customermembership`,data);
        return res.data.message;
    } catch (error:any) {
        let err = error.respone.data?.message;
        throw error;
    }
}

export const useGetMembershipOfCust = () => {
      let club_id = getClubId();
    const [user_id,setUser_Id] = useState<number>();

    const query =  useQuery(
      ["getMembershipByCust",user_id],
      async () => {
        const response :any= await apiCaller.get(`${club_id}/memberships/${user_id}`);
       return response.data.data;
      },
      { retry: false, enabled: !!user_id }
    );
    return {setUser_Id,...query}
  };


// export const getMembershipByCust = async (id:number) => {
//     try {
//           let club_id = getClubId();
//         let res = await apiCaller.get(`${club_id}/memberships/${id}`);
//         return res.data.data;
//     } catch (error:any) {
//         let err = error.respone.data?.message;
//         throw error;
//     }
// }