import React, { useContext, useEffect, useState } from 'react';
import { activeColor } from 'src/constants/color';
import { GetServiceLogs, getLogs } from 'src/api/CalendarBooking';
import { Box, Grid, Stack } from '@mui/material';
import "../tableStyling.css";
import moment from 'moment';
import CustomSVG from './CustomSVG';
 
const LogsComponent = ({service_id}:{service_id:number}) => {
  const [logDetails, setLogDetails] = useState<any>([]);

  const getAllLogs = async ( serviceId:number ) =>{
    try {
      let res = await GetServiceLogs(serviceId);
      setLogDetails(res?.data?.data);
    } catch (error:any) {
      console.log(error);
    }
  }

  useEffect(()=>{
    getAllLogs(service_id);
  },[])

  const isMorethanThree = logDetails?.length >3;


  return (
    <Box sx={{zIndex:'10',width:'100%'}} >
      <Box  marginBottom={2}>
        <Box fontFamily={'var(--font-semibold)'} color={activeColor}>Logs</Box>
        <Grid container sx={{marginTop:isMorethanThree?'0px':'20px',paddingLeft:{xs:'14px', sm:isMorethanThree?'15px':'0px'},maxHeight:isMorethanThree?'200px':'100%',height:'100%', paddingBottom:{xs:"15px", sm:"0px"},padding:{xs:'0',sm:isMorethanThree?'10px':'0px'}}} id={isMorethanThree?'table-person':''} >
          <Grid item xs={0.5}>
          <CustomSVG/>
          </Grid>
          <Grid item xs={11}>
            {
              logDetails?.map((item:any)=> {

                const role =   item?.user?.role?.name.replace('_'," ").toLowerCase();
                const user = item?.log?.includes("Player -")?item?.log.split("-")[1]:"";
                const AddedUser = item?.log?.includes("player added")?item?.log.split("-")[1]:"";
                const full_name = user?.includes("show status changed to true")? user?.replace("show status changed to true",""):user?.includes("show status changed to false")? user?.replace("show status changed to false",""):user?.includes("uncanceled")? user?.replace("uncanceled",""):user?.includes("canceled")&& user?.replace("canceled","")
                const type = item?.log?.includes("show")?"show":item?.log?.includes("cancel")?"cancel":item?.log?.includes("Created")?"created":item?.log?.includes("updated")?"updated":item?.log?.includes("Booking - Cancelled")?"Booking Cancel":item?.log?.includes("player added")?"Player Added":item.log.includes('refunded')?"Refunded":'';

                const isnowshow = item?.log?.includes("show status changed to true")?true:false;
                const isCancel = item?.log?.includes("uncanceled")?false:true;

                return (<Box className='logs'>
              <p>{moment(item?.created_at).format('HH:mm')} {moment(item?.created_at).format('DD/MM/YYYY')}</p>
              {
               type==="created" && <>
                  <p>Booking {item?.log}</p>
                 <p style={{textTransform:'capitalize'}}>By {role} - {item?.user?.full_name}</p>
              </>
              }
               {
               type==="show" && <>
                  <p>{full_name} updated to {isnowshow?"no show":'show'} status</p>
                 <p style={{textTransform:'capitalize'}}>By {role} - {item?.user?.full_name}</p>
              </>
              }
               {
               type==="cancel" && <>
                   <p>{full_name} updated to {isCancel?"cancel":'no cancel'} status</p>
                 <p style={{textTransform:'capitalize'}}>By {role} - {item?.user?.full_name}</p>
              </>
              }
               {
               type==="Booking Cancel" && <>
                  <p>{item?.log}</p>
                 <p style={{textTransform:'capitalize'}}>By {role} - {item?.user?.full_name}</p>
              </>
              }
               {
               type==="updated" && <>
                  <p>Booking updated</p>
                 <p style={{textTransform:'capitalize'}}>By {role} - {item?.user?.full_name}</p>
              </>
              }
              {
               type==="Player Added" && <>
                  <p>New Player Added ({AddedUser})</p>
                 <p style={{textTransform:'capitalize'}}>By {role} - {item?.user?.full_name}</p>
              </>
              }
              {
                type==="Refunded" && <>
                 <p>{item.log}</p>
                 <p style={{textTransform:'capitalize'}}>By {role} - {item?.user?.full_name}</p>
                </>
              }
            </Box>)})
            }
            {logDetails?.lastNote && <Box className='logs'>
              <p>{moment(logDetails?.lastNote?.created_at).format('HH:mm')} {moment(logDetails?.lastNote?.created_at).format('DD/MM/YYYY')}</p>
              <p>Booking Updated by Made ({logDetails?.lastNote?.user?.role?.name})</p>
              <p>{logDetails?.lastNote?.internal_note} </p>
            </Box>}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default LogsComponent;