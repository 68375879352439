import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { BsChevronDown } from "react-icons/bs";
import { FristCompo } from "./FristCompo";
import { FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";


function filterDataByLocation(data:any, selectedLocation:any) {
  // Extract the ids from selectedLocation for easier comparison
  const selectedLocationIds = selectedLocation.map((location:any) => location.id);
  return data.map((category:any) => {
      const filteredChildren = category.children.filter((child:any) => 
          selectedLocationIds.includes(child.location_id)
      );
      // Only return categories with children that match the location filter
      return {
          ...category,
          children: filteredChildren
      };
  }).filter((category:any) => category.children.length > 0); // Filter out categories with no matching children
}

interface ServiceSelectionProps {
  text: string;
  setSelecteServices: any;
  selectedServices: any[];
  error: any;
  getOpen:any;
  allServices:any;
  selectedlocations:any[]
}

const ServiceSelection: React.FC<ServiceSelectionProps> = ({
  text,
  allServices,
  getOpen,
  setSelecteServices,
  selectedServices,
  selectedlocations,
  error,
}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const [AllData,setAllData] =useState<any[]>([]);
  const [inputValue,setInputValue] = useState<string>('');

  const handleShow = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(true);
  };

  const FilteredData = filterDataByLocation(allServices,selectedlocations);

  let treeData = selectedlocations?.length>0 ? FilteredData : allServices

  const searchTransformedData = (data:any, query:string) => {
    const lowerQuery = query.toLowerCase();

    return data.map((category:any) => {
        const filteredChildren = category.children.filter((child:any) =>
            child.label.toLowerCase().includes(lowerQuery)
        );

        return {
            ...category,
            children: filteredChildren
        };
    }).filter((category:any) => category.children.length > 0);
};

useEffect(() => {
  if(inputValue){
    const updatedData = searchTransformedData(treeData,inputValue);
  setAllData(updatedData) 
  }else{
  setAllData(treeData) 
  }
}, [inputValue,treeData])


  useEffect(() => {
    document.addEventListener("click", () => {
      setOpen(false);
      setInputValue('');
    });
  }, []);

  const handleSelection = (event: any, item: any) => {
    event.stopPropagation();
    if (item === "Select All") {
      setSelecteServices((prev: any) =>
        prev?.length === treeData?.length ? [] : treeData
      );
    }
  };

  const handleClickk =(event:any)=>{
    event?.stopPropagation();
  }

  useEffect(()=>{
    getOpen(open)
  },[open])

  let isPArentSelected = compareChildrenLength(selectedServices, treeData);

  return (
    <Box width={"250px"} position={"relative"}>
       <Box sx={{backgroundColor:'white',borderRadius:'7px',display:'flex',flexDirection:'row',alignItems:'center',height:'35px',fontFamily:'var(--font-regular)',textTransform:'unset',color:'#22356D',fontSize:'14px'}} onClick={handleShow}>
     {t('common.Add Service')}
      <FiPlus
        style={{
          top: "10px",
          right: "5.5px",
          fontSize: "0.8rem",
        }}
      />
    </Box>
     
      <Box position={"absolute"} top={""} left={0} zIndex={9}>
        <div
          id="table-person"
          style={{ maxHeight: "220px", overflowY: "auto", marginTop: "0px" }}
        >
          <Box width={"-webkit-fill-available"} sx={{ background: "white" }}>
            {open && (
              <Box>
                <Box onClick={handleClickk} sx={{position:'relative'}}>
                  <input
                    id={error == "events" ? "customerror" : ""}
                    type="text"
                    onChange={(e:any)=>setInputValue(e.target.value)}
                    placeholder={`Select ${text}`}
                    style={{
                      width: "100%",
                      border: "1px solid #EEEEFE",
                      borderRadius: "7px",
                      opacity: 0.7,
                      color: "#22356D",
                      height:'30px',
                      fontFamily: "var(--font-regular)",
                    }}
                  />
                  <BsChevronDown
                    style={{
                      position: "absolute",
                      top: "6.5px",
                      right: "5.5px",
                      fontSize: "0.8rem",
                    }}
                  />
         </Box>
              <Box
                sx={{
                  background: isPArentSelected ? "#22356DB2" : "#889AB61A",
                  color: isPArentSelected ? "white" : "#22356D",
                  padding: "8px 10px",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#22356DB2",
                    color: "#FFFFFF",
                  },
                }}
                display={"flex"}
                justifyContent={"space-between"}
                gap={"20px"}
                onClick={(event) => handleSelection(event, "Select All")}
              >
                <p style={{ fontFamily: "var(--font-regular)" }}>
                  All Services
                </p>
                <label className="container relative">
                  <input readOnly type="checkbox" checked={isPArentSelected} />
                  <span className="mark" style={{ left: "-9px" }}></span>
                </label>
              </Box>
              </Box>
            )}

            {open &&
              AllData.map((item: any, index: number) => (
                <FristCompo
                  setSelected={setSelecteServices}
                  selected={selectedServices}
                  arr={item}
                  key={index}
                  text={item.label}
                />
              ))}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default ServiceSelection;

// function for checking child nodes are selected
function compareChildrenLength(selectedData: any, treeData: any) {
  // Check if the length of selected data is equal to tree data
  if (selectedData.length !== treeData.length) {
    return false;
  }

  // Iterate through each object in selected data
  for (let i = 0; i < selectedData.length; i++) {
    const selectedObj = selectedData[i];
    let found = false;

    // Find corresponding object in tree data
    for (let j = 0; j < treeData.length; j++) {
      const treeObj = treeData[j];
      if (selectedObj.id === treeObj.id) {
        // Check if the length of children arrays are equal
        if (selectedObj.children.length !== treeObj.children.length) {
          return false;
        }
        found = true;
        break;
      }
    }

    // If corresponding object not found in tree data, return false
    if (!found) {
      return false;
    }
  }

  // If all objects in selected data have matching objects in tree data with equal children lengths
  return true;
}
