import Box from "@mui/material/Box";
import { IoMdAdd } from "react-icons/io";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from 'react';
import SuperAdmin from 'src/features/Admin/SuperAdmin';
import AdminPopUp from '../components/Admin/AdminPopUp';
import { getAllClub, getClubsNames, insertClubAdmin, useGetAllCLubs } from 'src/api/SuperAdmin';
import MainContext from 'src/context/MainContext';
import { RxHamburgerMenu } from 'react-icons/rx';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Select, SelectItem } from "@nextui-org/react";
import { FaAngleDown } from "react-icons/fa";

const common = {
  display:"flex", alignItems:"center"
}

const Admin = () => {
  const {MobileSideBar, setMobileSideBar} = React.useContext(MainContext);
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false);
  const [allClubs, setAllClubs] = useState([]);
  const [clubNames, setClubNames] = useState<any>([]);
  const [FilteredClub, setFilteredClub] = useState<any>([]);
  const [showInfo, setShowInfo] = useState(false);
  const [isloading,setIsloading] = useState(true);
  const {data,isSuccess,isError,refetch:ClubRefetch} = useGetAllCLubs();

  const handleAddClub = async (final_data:any) => {
    try {
      const response:any = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admins/clubs`, final_data,
        {
          headers:{
            "Authorization":`Bearer ${localStorage.getItem('accessToken')}`
          }
        }
      );
      toast.success("Created Successfully!");
      // fetchData();
      setOpen(false);
      return response.data;
    } catch (error:any) {
        let err = error.response?.data?.message;
        // toast.error(err)
    }
  }

  const handleClubAdmin = (id:number, data:any) => {
    insertClubAdmin(id, data);
    setOpen(false);
    setShowInfo(false);
    // fetchData();
  }


  useEffect(() => {
    if(isSuccess){
      const clubNames= data?.map((item:any)=>
         {

          return {
            club_name:item?.club_name,
            id:item?.id
          }
         }
      )
      setClubNames(clubNames);
      setAllClubs(data);
      setFilteredClub(data);
    }else{
      setClubNames([]);
      setAllClubs([]);
      setFilteredClub([]);
    }
   
  }, [data,isSuccess,isError])
  
 
  // const fetchData = async (name?:string) => {
  //   setIsloading(true)
  //   try {
  //     const data = await getAllClub(name);
  //     setAllClubs(data);
  //     setIsloading(false)
  //   } catch (error) {
  //     // toast.error('Error fetching clubs');
  //     setIsloading(false)
  //   }
  // };

  // const fetchNames = async () => {
  //   try {
  //     const data = await getClubsNames();
  //     setClubNames(data)
  //   } catch (error) {
  //     // toast.error('Error fetching clubs');
  //   }
  // }

  const filterClub = (item:number) => {
    setSelected(item);
    const selectedClub = allClubs
   if(item!==0){
    const filterd = selectedClub?.filter((club:any)=>club?.id===item);
   setFilteredClub(filterd);
   }else{
    setFilteredClub(selectedClub);
   }
    
  }
 
  // useEffect(()=>{
  //   fetchData();
  //   fetchNames();
  // },[]);

  const openMobileSidebar=(event:any)=>{
    event.stopPropagation();
    setMobileSideBar(true)
 }

 const club_id = JSON.parse(
  localStorage.getItem("Club_id") || JSON.stringify("")
);
const user = JSON.parse(localStorage.getItem("user") || JSON.stringify({}));

 useEffect(() => {
  if(user?.role.name === "SUPER_ADMIN"){
    ClubRefetch();
  }
}, [])
 
  
  useEffect(()=>{},[allClubs, selected]);


  return (
    <Box marginBottom={"4.5rem"} height={allClubs?.length<3 ? "100vh" : ''} sx={{paddingLeft:{ lg:'4rem', xl:"4rem"}, paddingInline:{xs:'20px', sm:"20px"}}}>                         
      <Typography sx={{...common, gap:"10px", paddingTop:"2.5rem", textAlign:{xs:"left",sm:"left",md:"center"}, fontFamily:"var(--font-medium)",color:"#22356D", fontSize:{xs:"1.2rem", sm:"1.25rem", md:'1rem'}}}><RxHamburgerMenu onClick={openMobileSidebar} className='MobileHamburger'/>Super Admin Manager</Typography>
      <Box sx={{...common}} gap={2} marginBlock={4}>
        <Box>
          <Box fontFamily={'var(--font-medium)'} sx={{...common, flexWrap:"wrap", borderRadius:"7px",boxShadow:"0px 4px 4px 0px #0000000D inset", padding:"5px"}}>
            <button style={{padding:"0px 20px", background:selected===0?'white':"",borderRadius:"7px",color:selected===0?'#22356D':"#22356D99"}} onClick={()=>filterClub(0)}>All Club</button>
            {clubNames?.map((item:any, index:number)=>
              <button style={{padding:"0px 20px",marginBottom:"7px", background:selected===item.id?'white':"",borderRadius:"7px",color:selected===item.id?'#22356D':"#22356D99"}} onClick={()=>filterClub(item.id)} key={index}>{item?.club_name}</button>
            )}
            <IoMdAdd style={{padding:"0px 20px", color:"#22356D99", width:'50px', cursor:'pointer'}} onClick={()=>{setOpen(true);setShowInfo(false)}} />
          </Box>
        </Box>
      </Box>
      <Box sx={{display:'grid', gridTemplateColumns:{xs:"auto",sm:"repeat(2, 1fr)", md:"repeat(3, 1fr)"}, gap:"30px"}}>
        { FilteredClub?.map((item:any)=>
          <SuperAdmin club={item} fetchData={ClubRefetch} selected={selected} setSelected={setSelected} />
        )}
      </Box>
      <AdminPopUp  open={open} setOpen={setOpen} showInfo={showInfo} setShowInfo={setShowInfo} handleClub={ClubRefetch} />
    </Box>
  )
}

export default Admin;