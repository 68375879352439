import CustomDropdown from "../../components/CustomDropdown";
import React, { useContext, useEffect, useState } from "react";
import { allTimeFormats, days, hours, postion } from "src/constants/variables";
import { BsInfoCircle } from "react-icons/bs";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { LuChevronsUpDown } from "react-icons/lu";
import {
  CreateCoachByClubId,
  DeleteCoachByID,
  GetCoachAppSettings,
  UpdateCoachAppSettings,
  UpdateCoachByID,
} from "src/api/Coach";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import MainContext from "src/context/MainContext";
import { useTranslation } from "react-i18next";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

const divcss: any = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
};
const selectedTab = {
  textDecoration: "underline",
  textDecorationColor: "#22356D",
  padding: "0px",
  textUnderlineOffset: "5px",
};
const textStyle = {
  color: "#22356D",
  opacity: 0.7,
  fontFamily: "var(--font-regular)",
};
const headingStyle: any = {
  color: "#22356D",
  fontFamily: "var(--font-semibold)",
  textTransform: "capitalize",
};
const borderStyle = {
  borderRadius: "7px",
  color: "#22356D",
  opacity: 0.7,
  border: "1px solid #EEEFEE",
  background: "#FFF",
  padding: "5px 10px",
  fontFamily: "var(--font-regular)",
};

const AppSettingsTab = ({
  CoachData,
  handleCoachClose,
  Coach_id,
  sport_id,
  GetCoachData,
  UserProfile,
  setError
}: {
  CoachData: any;
  handleCoachClose: any;
  Coach_id: any;
  sport_id: any;
  GetCoachData:any;
  UserProfile:any;
  setError:any;
}) => {
  const {t} = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
   const StaffPermission = useGetPermissions();
  const {Notification,setNotification,setIsPermissionOn} = useContext(MainContext);
  const {  email, phone_number, full_name, description,profile_url} = CoachData();
  const [bookabledays, setBookableDays] = useState("");
  const [minTime, setminTime] = useState("");
  const [maxTime, setmaxTime] = useState("");
  const [CoachPosition, setCoachPosition] = useState("");

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleBookingchange = (event: SelectChangeEvent) => {
    setBookableDays(event.target.value as string);
  };

  const handleMintime = (event: SelectChangeEvent) => {
    setminTime(event.target.value as string);
  };

  const handleMaxtime = (event: SelectChangeEvent) => {
    setmaxTime(event.target.value as string);
  };

  const handleCoachPosition = (event: SelectChangeEvent) => {
    setCoachPosition(event.target.value as string);
  };

  useEffect(() => {
    GetCoachAPPSETTINGS();
  }, []);


  const GetCoachAPPSETTINGS = async () => {
    const response = await GetCoachAppSettings(Coach_id);
    if (response !== undefined && response !== null && response?.length !== 0) {
      setBookableDays(response?.bookable_days);
      setminTime(response?.minimum_book_time);
      setmaxTime(response?.maximum_cancel_time);
      setCoachPosition(response?.position_list);
      setIsChecked(response?.profile_visible);
    }
  };

  const finalData = {
    profile_visible: isChecked,
    bookable_days: bookabledays,
    minimum_book_time: minTime,
    maximum_cancel_time: maxTime,
    position_list: CoachPosition,
  };

  const updateClick = async (CoachId: number) => {
   
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleCoachClose()
      return;
    }



    let response:boolean;
    let response2:boolean;
   

    try {
      const response: any = await UpdateCoachAppSettings(
        CoachId,
        finalData
      );
    } catch (error:any) {
      setNotification({message:error,type:'error',subMessage:'',isShow:true})
      return
    }

    if (!full_name) {
      setError("full_name");
      setTimeout(() => setError(""), 2000);
      return;
  } 
    const formData = new FormData();  
    formData.append('full_name', full_name);
    formData.append('description', description);
    formData.append('phone_number', phone_number);
    formData.append('email', email);
    !!UserProfile && formData.append('image', UserProfile);
    
    try {
      response2 =  await UpdateCoachByID(CoachId, formData);
    } catch (error:any) {
      setNotification({message:error,type:'error',subMessage:'',isShow:true})
      return
    }
    //for updating coaches
    setNotification({message:"Coach settings updated",type:'success',subMessage:'',isShow:true})
      GetCoachData();
      handleCoachClose();
  };


  const handleClick = async () => {
    
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleCoachClose()
      return;
    }


    if (!full_name) {
      setError("full_name");
      setTimeout(() => setError(""), 2000);
      return;
  } 
    const formData = new FormData();  
    formData.append('full_name', full_name);
    formData.append('description', description);
    formData.append('phone_number', phone_number);
    formData.append('email', email);
   !!UserProfile && formData.append('image', UserProfile);
    const response: any = await CreateCoachByClubId(
      sport_id,
      formData
    );
    if (response?.id !== undefined) {
      updateClick(response?.id);
      handleCoachClose();
    }
  };

  const handleDelete = async () => {
    
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleCoachClose()
      return;
    }


    const response: any = await DeleteCoachByID(Coach_id);
    GetCoachData();
    handleCoachClose();
  };

  return (
    <div className="flex flex-col gap-4">
      <div style={{ ...divcss, gap: "10px", justifyContent: "unset" }}>
        <p
          style={{
            ...headingStyle,
            fontFamily: "var(--font-regular)",
            textTransform: "unset",
          }}
        >
          {t('services.coach.Profile Visible in the App')}
        </p>
        <label className="switch -mt1">
          <input
            checked={isChecked}
            onChange={handleCheckboxChange}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
      </div>
      <div style={divcss}>
        <div style={{ ...divcss, gap: "5px", justifyContent: "unset" }}>
          <p
            style={{
              ...headingStyle,
              fontFamily: "var(--font-regular)",
              textTransform: "unset",
            }}
          >
            {t('services.coach.Bookable days for users')}
          </p>
          <BsInfoCircle fontSize={"12px"} />
        </div>
        <FormControl>
          <Select
           MenuProps={{ sx: dopdowncustomcss }} 
           renderValue={(value) => {
                if (value !== "") {
                  return  `${value} days`;
                }
                return <em>Placeholder</em>;
              }}
              value={!!bookabledays ? bookabledays : '12 days'}
            onChange={handleBookingchange}
           
            id="customdropdown"
            style={{
              width: "110px",
              height: "27px",
              border: "1px solid #EEEEFE",
              borderRadius: "7px",
              fontSize: "13px",
              opacity: "0.7",
              fontFamily: "var(--font-regular) !important",
              color: "#22356D",
            }}           
            IconComponent={LuChevronsUpDown}
          >
            {days.map((location) => (
              <MenuItem
                style={{
                  fontSize: "14px",
                  opacity: "0.7",
                  fontFamily: "var(--font-regular) !important",
                  color: "#22356D",
                }}
                key={location}
                value={location}
              >
                {location} days
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={divcss}>
        <div style={{ ...divcss, gap: "5px", justifyContent: "unset" }}>
          <p
            style={{
              ...headingStyle,
              fontFamily: "var(--font-regular)",
              textTransform: "unset",
            }}
          >
            {t('services.coach.Min time to book')}
          </p>
          <BsInfoCircle fontSize={"12px"} />
        </div>
        <FormControl>
          <Select
           renderValue={(value) => {
            if (value !== "") {
              return  `${value} hours`;
            }
            return <em>Placeholder</em>;
          }}
          value={!!minTime ? minTime : '6 hours'}
          MenuProps={{ sx: dopdowncustomcss }}
            onChange={handleMintime}
            id="customdropdown"
            style={{
              width: "110px",
              height: "27px",
              border: "1px solid #EEEEFE",
              borderRadius: "7px",
              fontSize: "13px",
              opacity: "0.7",
              fontFamily: "var(--font-regular) !important",
              color: "#22356D",
            }}
            IconComponent={LuChevronsUpDown}
          >
            {hours.map((location) => (
              <MenuItem
                style={{
                  fontSize: "14px",
                  opacity: "0.7",
                  fontFamily: "var(--font-regular) !important",
                  color: "#22356D",
                }}
                key={location}
                value={location}
              >
                {location} hours
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={divcss}>
        <div style={{ ...divcss, gap: "5px", justifyContent: "unset" }}>
          <p
            style={{
              ...headingStyle,
              fontFamily: "var(--font-regular)",
              textTransform: "unset",
            }}
          >
           {t('services.coach.Max time to cancel')}
          </p>
          <BsInfoCircle fontSize={"12px"} />
        </div>
        <FormControl>
          <Select
            MenuProps={{ sx: dopdowncustomcss }} 
            renderValue={(value) => {
                 if (value !== "") {
                   return `${value} hours`;
                 }
                 return <em>Placeholder</em>;
               }}
               value={!!maxTime ? maxTime : '12 hours'}
            onChange={handleMaxtime}
            id="customdropdown"
            style={{
              width: "110px",
              height: "27px",
              border: "1px solid #EEEEFE",
              borderRadius: "7px",
              fontSize: "13px",
              opacity: "0.7",
              fontFamily: "var(--font-regular) !important",
              color: "#22356D",
            }}
            IconComponent={LuChevronsUpDown}
          >
            {hours.map((location) => (
              <MenuItem
                style={{
                  fontSize: "14px",
                  opacity: "0.7",
                  fontFamily: "var(--font-regular) !important",
                  color: "#22356D",
                }}
                key={location}
                value={location}
              >
                {location} hours
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={divcss}>
        <div style={{ ...divcss, gap: "5px", justifyContent: "unset" }}>
          <p
            style={{
              ...headingStyle,
              fontFamily: "var(--font-regular)",
              textTransform: "unset",
            }}
          >
            {t('services.coach.Position in coach list')}
          </p>
          <BsInfoCircle fontSize={"12px"} />
        </div>
        <FormControl>
          <Select
             MenuProps={{ sx: dopdowncustomcss }} 
             renderValue={(value) => {
                  if (value !== "second") {
                    const index :number= Number(value) - 1
                    return `${postion[index]}`;
                  }
                  return <em>second</em>;
                }}
                value={!!CoachPosition ? CoachPosition : 'second'}
            id="table-person customdropdown"
            onChange={handleCoachPosition}
            style={{
              width: "110px",
              height: "27px",
              border: "1px solid #EEEEFE",
              borderRadius: "7px",
              fontSize: "13px",
              opacity: "0.7",
              fontFamily: "var(--font-regular) !important",
              color: "#22356D",
            }}
            IconComponent={LuChevronsUpDown}
          >
            {postion.map((position, index: number) => (
              <MenuItem
                style={{
                  fontSize: "14px",
                  opacity: "0.7",
                  fontFamily: "var(--font-regular) !important",
                  color: "#22356D",
                }}
                key={position}
                value={index+1}
              >
                {position}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        marginTop={"20px"}
      >
        <Button
          onClick={Coach_id !== undefined ? handleDelete : handleCoachClose}
          style={{
            textTransform: "unset",
            fontFamily: "var(--font-semibold)",
            width: "104px",
            height: "37px",
            color: "#22356D",
            backgroundColor: "white",
            fontSize: "16px",
            padding: "10px 30px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {Coach_id !== undefined ? t("common.Delete") :t("common.Cancel")}
        </Button>
        <Button
          onClick={() => {
            Coach_id !== undefined ? updateClick(Coach_id) : handleClick();
          }}
          style={{
            textTransform: "unset",
            fontFamily: "var(--font-semibold)",
            color: "white",
            backgroundColor: "#22356D",
            width: "92px",
            height: "37px",
            fontSize: "16px",
          }}
        >
          {t('common.Save')}
        </Button>
      </Grid>
    </div>
  );
};

export default AppSettingsTab;
