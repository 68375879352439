import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import TabSliderStats from './TabSliderStats';

const data = [
  { date: '1 February', Cash: 10, 'Credit Card': 10, 'Apple Pay': 10, "Total Sale":10 },
  { date: '2 February', Cash: 30, 'Credit Card': 35, 'Apple Pay': 40, "Total Sale":45 },
  { date: '3 February', Cash: 50, 'Credit Card': 60, 'Apple Pay': 70, "Total Sale":80 },
  { date: '4 February', Cash: 70, 'Credit Card': 80, 'Apple Pay': 90, "Total Sale":120 },
  { date: '5 February', Cash: 100, 'Credit Card': 110, 'Apple Pay': 120, "Total Sale":160 },
  { date: '2 March', Cash: 130, 'Credit Card': 140, 'Apple Pay': 150, "Total Sale":180 },
];

const LocationStats = ({showGraph,setShowGraph,fontStyle, badge}:{showGraph:string,setShowGraph:(showGraph:string) => void,fontStyle: any, badge: any}) => {
  interface CustomTooltipProps {
    active?: boolean;
    payload?: Array<{ value: number; dataKey: string }>;
    label?: string;
  }
  
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0];
      const lineName = dataPoint.dataKey; // Get the name of the hovered line
      const value = dataPoint.value; // Get the value of the hovered line
  
      return (
        <div style={{background:"white", borderRadius:"7px", padding:"10px", color:"#22356D", fontFamily:"var(--font-regular)", fontSize:"0.95rem",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
          <div>{moment(label).format("ddd DD MMM")}</div>
          {payload.map((item)=>
            <div className="custom-tooltip">
              <p>{item.dataKey}: {item.value}</p>
            </div>
          )}
        </div>
      );
    }
    return null;
  };
      
  return (
    <Box sx={{background:'white', padding:"20px 20px 10px 0px", borderRadius:"7px", width:"100%"}}>
      <Box sx={{display:"flex", gap:"10px", alignItems:"center", paddingLeft:"20px"}}>
        <Typography sx={{...fontStyle, fontFamily:"var(--font-medium)", color:"#22356D"}}>Sales by location</Typography>
        <TabSliderStats arr={['Payment Method', "Service Type", "Location"]} showGraph={showGraph} setShowGraph={setShowGraph} />
      </Box>
      <Stack sx={{">div:first-child":{width:{xs:"100% !important", sm:"80% !important"},height:"380px !important"},flexDirection:{xs:"column", sm:"row"}}} alignItems={"center"} gap={'1.5'}>
        <ResponsiveContainer width="80%" height="100%">
          <LineChart width={600} height={400} data={data}>
            <XAxis dataKey="date" ticks={['1 February', '2 March']} />
            <YAxis ticks={[0, 200]} />
            <Tooltip content={<CustomTooltip />} /> 
            {/* <Legend /> */}
            <Line type="monotone" dataKey="Total Sale" stroke="#22356D" />
            <Line type="monotone" dataKey="Cash" stroke="#22356D99" />
            <Line type="monotone" dataKey="Apple Pay" stroke="#0000001A" />
            <Line type="monotone" dataKey="Credit Card" stroke="#22356D33" />
          </LineChart>
        </ResponsiveContainer>
        <Box sx={{padding:'0px 15px 0px 20px'}}>
          <Box sx={{display:"flex", alignItems:"center", justifyContent:'center'}}>
            <Box sx={{display:"grid", gridTemplateColumns:{xs:"repeat(2, 1fr)", sm:"repeat(1, 1fr)"}, gap:{xs:"15px", sm:"5px"},'>div':{borderBottom:"1px solid #EEEFEE", padding:{xs:'0px 5px 5px 0px', sm:"0px 65px 5px 0px"}}, ">div:last-child":{borderBottom:"none"}, "div:nth-last-child(2)":{borderBottom:{xs:"none", sm:"1px solid #EEEFEE"}}}}>
              <Box>
                <Typography sx={{...fontStyle, color:"#22356D"}}>Total Sales <span style={{...badge, background:"#22356D"}}></span> </Typography>
                <Typography sx={{...fontStyle}}>150.000.000 IDR</Typography>
              </Box>
              <Box>
                <Typography sx={{...fontStyle}}>Pererenan <span style={{...badge, background:"#22356D99"}}></span> 62%</Typography>
                <Typography sx={{...fontStyle}}>150.000 IDR</Typography>
              </Box>
              <Box>
                <Typography sx={{...fontStyle}}>Canguu <span style={{...badge, background:"#22356D33"}}></span> 13%</Typography>
                <Typography sx={{...fontStyle}}>1.100.000 IDR</Typography>
              </Box>
              <Box>
                <Typography sx={{...fontStyle}}>Uluwatu <span style={{...badge, background:"#0000001A"}}></span> </Typography>
                <Typography sx={{...fontStyle}}>1.000.000 IDR</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}

export default LocationStats;