import Box from "@mui/material/Box";
import { GoPlus } from "react-icons/go";
import BookingSettings from "./BookingServices";
import React, { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { GetALLBookingDataByLocationAndSport } from "src/api/ServiceSettings";
import BookingsRow from "./BookingsRow";
import { Button, Dialog } from "@mui/material";
import { useGetAllbookingsServices } from "src/hooks/useGetAllBokingsService";
import { useTranslation } from "react-i18next";



const Bookings = ({
  sports,
  selectedSport,
  selectedLocation,
}: {
  selectedSport: any;
  sports:any;
  selectedLocation: any;
}) => {
  const { t } = useTranslation();
  const [showBookingSettings, setshowBookingSettings] = useState(false);
  const [bookingData, setBookingData] = useState<any>([]);
  const [BookingId, setBookingID] = useState<number>();
  const [sport_id, setsport_id] = useState<number>();
  const {data,isSuccess,isError,refetch,setSportId,setlocation_id}  = useGetAllbookingsServices();
  
  useEffect(() => {
   if(!!selectedLocation){
    setlocation_id(selectedLocation?.id);
    setSportId(0)
   }
  }, [selectedLocation]);

  useEffect(() => {
    if(isSuccess){
      getBookingDATA(data);
    }else{
      setBookingData([])
    }
  }, [data,isSuccess,isError])
  


  const getBookingDATA = async (data:any) => {
      const final = filterBySport(data);
      setBookingData(final);
  };


  function filterBySport(response: any) {
    const sports: any = {};
    response?.forEach((item: any) => {
      const sportName = item?.sport?.sport_name;
      if (!sports[sportName]) {
        sports[sportName] = [];
      }
      sports[sportName] = [...sports[sportName], item];
    });

    return sports;
  }

  const handleClicked = (data: any) => {
    setsport_id(undefined);
    setBookingID(data.id);
    setshowBookingSettings(true);
  };

  const handleAdd = (sport_id: number) => {
    setBookingID(undefined);
    setsport_id(sport_id);
    setshowBookingSettings(true);
  };

  const handleClose = () => {
    setshowBookingSettings(false);
  };
  
  return (
    <Box
      sx={{ background:"white", padding: {xs:"0px", sm:"0px", md:"33px 25px"}, borderRadius: "7px" }}
      className="bg-white"
    >
      <Box sx={{display:{xs:"none", sm:"none", md:"block"}}}>
        <span
          style={{
            color: "#22356D",
            fontFamily: "var(--font-semibold)",
            fontSize: "16px",
            borderBottom: "1px solid #22356D",
          }}
        >
          {t('common.Bookings')}
        </span>
      </Box>
      {sports?.map((item: any) => {
        return selectedSport?.sport_name == item?.sport_name ||
          selectedSport?.sport_name == "All Sports" ? (
          <Box key={item?.id}>
            <h3
              style={{
                marginTop: "20px",
                color: "#22356D",
                fontFamily: "var(--font-semibold)",
                fontSize: "16px",
              }}
            >
              {item?.sport_name}
            </h3>
            <Button
              onClick={() => handleAdd(item.id)}
              className="items-center flex flex-row justify-normal"
              style={{
                padding: "0",
                width: "190px",
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                gap: "10px",
                textTransform: "unset",
              }}
            >
              {t('services.bookings.Add new Service')}
              <GoPlus style={{ verticalAlign: "middle", fontSize: "14px" }} />
            </Button>
            <Box>
              <Box
                style={{
                  marginTop: "10px",
                  borderRadius: "7px",
                  boxShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.1)",
                  background:
                    "linear-gradient(0deg, rgba(238, 239, 238, 0.05) 0%, rgba(238, 239, 238, 0.05) 100%), #FFF",
                  border: "0.5px solid #EEEFEE",
                }}
              >
                <Box
                  id={ selectedSport?.sport_name == "All Sports"?"table-person":''}
                  className="overflow-hidden"
                  style={{
                    marginTop: "0px",
                    width: "-webkit-fill-available",
                    maxHeight: selectedSport?.sport_name == "All Sports"? "210px":'',
                    overflowY: "auto",
                  }}
                >
                  {bookingData[`${item.sport_name}`]?.sort((a:any,b:any)=>a.duration-b.duration)?.map(
                    (item: any, index: number) => (
                      <div key={index} onClick={() => handleClicked(item)}>
                        <BookingsRow
                          index={index}
                          key={index}
                          Data={item}
                          selectedLocation={selectedLocation}
                        />
                      </div>
                    )
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          ""
        );
      })}

      <Dialog
        sx={{
          ".MuiDialog-paper": {
            minWidth:{xs:"325px",sm:"525px", md:"850px"}
          },
        }}
        onClose={handleClose}
        open={showBookingSettings}
      >
        <DialogContent>
          <BookingSettings selectedSport={selectedSport} GetBookingDAta={refetch} handleClose={handleClose} sport_id={sport_id} BookingId={BookingId} selectedLocation={selectedLocation} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Bookings;
