import {Grid } from '@mui/material'
import React from 'react'
import LessonsLeft from './LessonsLeft';
import LessonsRight from './LessonsRight';


const Lessons = ({selectedSport,selectedLocation,sports}:{sports:any,selectedLocation:any,selectedSport:any}) => {

    return (
        <div style={{ padding: '33px 25px',borderRadius:'7px' }} className='bg-white'>
            <Grid spacing={'25px'} container >
            <LessonsLeft selectedLocation={selectedLocation} sports={sports} selectedSport={selectedSport}/>
          
           <LessonsRight selectedLocation={selectedLocation} sports={sports} selectedSport={selectedSport} />
   
            </Grid>
           
        </div>
    )
}

export default Lessons