import { Box } from '@mui/material'
import React from 'react'

const TabSliderStats = ({arr, showGraph, setShowGraph}:{arr:string[],showGraph:string, setShowGraph:(showGraph: string)=>void}) => {
  
  return (
    <Box sx={{width:{xs:arr.length==3 ? "236px" : '184px', sm:"auto"},display:'flex', alignItems:"center", flexWrap:"wrap", background:'#889AB626', padding:"3.5px", boxShadow:"0px 4px 4px 0px #0000000D inset",borderRadius:"7px", ">div":{cursor:"pointer",borderRadius:"7px", padding:"2px 10px"}}}>
      {arr.map((item)=>
        <Box sx={{background:showGraph==item ? "white" : "transparent", color:showGraph==item ? "#22356D" : "#22356D99", fontFamily:showGraph==item ? "var(--font-medium)" : "var(--font-regular)"}} onClick={()=>setShowGraph(item)}>{item}</Box>
      )}
    </Box>
  )
}

export default TabSliderStats;