import React, { useState } from "react";
import { allTimeFormats } from "src/constants/variables";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { LuChevronsUpDown } from "react-icons/lu";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import moment from "moment";
import { useTranslation } from "react-i18next";

function BreakTimeRangePicker({
  getTime,
  preTime,
  handleClose
}: {
  getTime: any;
  preTime: any;
  handleClose:any;
}) {
  const {t} = useTranslation();
  const [start_break, setstartTime] = useState(preTime?.start_time !== undefined ? preTime.start_time:"08:00:00");
  const [end_break, setend_time] = useState(preTime?.end_time !== undefined ? preTime.end_time:"22:00:00");

 
  const returnTime = (startTime: any, endTime: any) => {
    const timeBreak = { start_break, end_break };
  
    if (startTime !== null && endTime !== null) {
      if (preTime?.length>0) {
        getTime([...preTime, timeBreak]);
      } else {
        getTime([timeBreak]);
      }
    }
  };


  const handleSave = ()=>{
    returnTime(start_break, end_break);
    handleClose()
  }

  return (<Box>
    <div className="flex flex-row items-center gap-1">
      <Select 
            placeholder="select time" value={start_break === null ?"08:00:00" :start_break}
            style={{
              width: "110px",
              height: "27px",
              border: "1px solid #EEEEFE",
              borderRadius: "7px",
              fontSize: "13px",
              fontFamily: "var(--font-regular) !important",
              color: "#22356D",
            }}
            MenuProps={{sx:dopdowncustomcss}}
            IconComponent={LuChevronsUpDown}>
              {
              allTimeFormats.slice(1,allTimeFormats?.length).map((item:any)=> <MenuItem
              onClick={()=>setstartTime(item)}
              style={{
                fontSize: "14px",
                fontFamily: "var(--font-regular) !important",
                color: "#22356D",
              }}
              key={item}
              value={item}
            >
           {  moment(item, "HH:mm:ss").format("h:mm A")}
            </MenuItem>)  
              }

      </Select>
      to
      <Select placeholder="select time" value={end_break === null ?"10:00:00" :end_break}
            style={{
              width: "110px",
              height: "27px",
              border: "1px solid #EEEEFE",
              borderRadius: "7px",
              fontSize: "13px",
              fontFamily: "var(--font-regular) !important",
              color: "#22356D",
            }}
            MenuProps={{sx:dopdowncustomcss}}
            IconComponent={LuChevronsUpDown}>
              {
              allTimeFormats.slice(1,allTimeFormats?.length).map((item:any)=> <MenuItem
              onClick={()=>setend_time(item)}
              style={{
                fontSize: "14px",
                fontFamily: "var(--font-regular) !important",
                color: "#22356D",
              }}
              key={item}
              value={item}
            >
               {  moment(item, "HH:mm:ss").format("h:mm A")}
            </MenuItem>)  
              }

      </Select>

    </div>
    <Box  marginTop={"30px"} sx={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
      <Button onClick={handleSave}
          style={{
            textTransform: "unset",
            fontFamily: "var(--font-semibold)",
            color: "white",
            backgroundColor: "#22356D",
            width: "92px",
            height: "37px",
            fontSize: "16px",
          }}
        >
          {t('common.Save')}
        </Button>
        </Box>
    </Box>
  );
}

export default BreakTimeRangePicker;
