import * as React from "react";
import { ToggleButton, Box, ToggleButtonGroup, styled } from "@mui/material";
import MainContext from "../context/MainContext";
import { useTranslation } from "react-i18next";

const StyledBox = styled(Box)`
  box-shadow: 0px 4px 4px 0px #0000000d inset; /* Adjust the values to customize the shadow */
`;

export default function CustomTabs({tabValue, setTabValue,selectView, tabTextArray,setSelectedSport,setSelectView } : {tabValue:string, setTabValue:any,selectView:string, tabTextArray: string[],setSelectedSport?:any,setSelectView:any }) {

  const context = React.useContext(MainContext);
  const setActiveTab = context?.setActiveTab;
  const {t} = useTranslation();

  const handleChange = (newAlignment: any) => {
    if (newAlignment !== null) {
     if(selectView!=="Monthly"){
      setSelectView('Daily')
     }
     setTabValue(newAlignment.target.value);
    }
  };

  React.useEffect(() => {
   
    if (setActiveTab) setActiveTab(tabValue);

    if (tabValue && setSelectedSport) setSelectedSport(tabValue);
  }, [tabValue, setActiveTab]);

  return (
    <StyledBox
      sx={{
        padding: "0px",
        borderRadius: "7px", // Set the Box border radius
        display: "inline-block", // Wrap the ToggleButtonGroup
      }}>
      <ToggleButtonGroup
        value={tabValue}
        exclusive
        onChange={handleChange}

        sx={{
          "& .MuiToggleButtonGroup-grouped": {
            border: "none", // Remove border from buttons
            borderRadius: "7px", // Rounded corners for all buttons
            padding:{xs:"1px 10px", sm:"0px 20px"},
            fontFamily:'var(--font-regular) !important',
            fontSize:"1rem",
            margin: (theme) => theme.spacing(0.5),
            "&.Mui-selected": {
              color: "#22356D",
              backgroundColor: "#fff", // White background for the selected button
              fontFamily:'var(--font-medium) !important',
              "&:hover": {
                backgroundColor: "#fff", // Keep the background white when hovered
              },
            },
            "&:not(.Mui-selected)": {
              backgroundColor: "transparent", // Transparent background for unselected
              color: "#22356D99",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight shade when hovered
              },
            },
          }, 
        }}>
        {tabTextArray.map((item) => (
          <ToggleButton
            key={item}
            value={item}
            sx={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "5px",
              paddingBottom: "5px",
              textTransform: "capitalize",
            }}>
            {t(`common.${item}`)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </StyledBox>
  );
}
