import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import React, { useContext, useEffect, useState } from "react";
import PersonList from "./PersonList";
import { activeColor } from "src/constants/color";
import ScoreList from "../ScoreList";
import MainContext from "src/context/MainContext";
import { Permission, UpdateServicesPlayers,getClubId, getPlayerList, useGetAllWaitingList, useGetPermissions, useGetPlayersNotes } from "src/api/CalendarBooking";
import WaitingList from "./WaitingList";
import { apiCaller } from "src/api/ApiCaller";
import TeamMatesList, { pairData } from "./TeamMatesList";
import "../tableStyling.css";
import WaitingTeam from "./WaitingTeam";
import { useTranslation } from "react-i18next";
import ChatApp from "./ChatApp";
import PersonTableRow from "./PersonTableRow";

interface User {
  id: number;
  name: string;
  image: string;
  paid: Boolean;
  noShow: Boolean;
  cancel: Boolean;
}

const waitingList: User[] = [
  {
    id: 1212,
    name: "Joaquin Reyesw",
    image: "",
    paid: true,
    noShow: false,
    cancel: false,
  },
  {
    id: 1212,
    name: "Tashfeen",
    image: "",
    paid: false,
    noShow: false,
    cancel: false,
  },
];

export function fillWithPairedData(data:any, maxCapacity:any) {
  const filledData :any= [];

  filledData.push(...data);
  const dummydata ={
    customer:null,
    pairedData:[]
  }

  const TotalReamingnig =  maxCapacity/2 - data?.length 
  Array(TotalReamingnig).fill("-")?.map((_)=>
  filledData.push(dummydata))
  return filledData;
}


export function organizePlayers(data:any, maxCapacity:any) {
  // Step 1: Sort the data based on position
  const sortedData = data.filter((item:any)=>!item.is_canceled && !item.is_show).sort((a:any, b:any) => a.position - b.position);
  
  // Step 2: Create an array to hold the organized players
  const organizedData = [];

  // Step 3: Iterate from 1 to maxCapacity and fill in the gaps
  for (let i = 1; i <= maxCapacity; i++) {
    const existingPlayer = sortedData.find((player:any) => player.position === i);
    if (i % 2 !== 0) {
      // For odd positions, place the player in the main data
      if (existingPlayer) {
        organizedData.push({ ...existingPlayer, pairedData: [] });
      } else {
        // If no player exists at this position, add an empty placeholder
        organizedData.push({
          customer: null,
          pairedData: []
        });
      }
    } else {
      // For even positions, place the player inside pairedData
      if (existingPlayer) {
        organizedData[organizedData.length - 1].pairedData.push(existingPlayer);
      }
    }
  }

  return organizedData;
}


 
const EventTab = ({
  columnsArray,
  updateList,
  update,
  fromWh,
  events,
  userData,
  selectedOpenRegular,
  setSave,
  selectedBookingType,
  setCustomerList,
  setPayPlayers,
  selectEvent,
  selectAll,
  handleCheckboxChange,
  handleMasterCheckboxChange,
} : {
  updateList:any;
  fromWh:any;
  update:any;
  columnsArray:any;
  events: any;
  userData: any;
  selectedOpenRegular: string;
  setSave: any;
  selectedBookingType:string;
  setCustomerList: any;
  selectEvent: any;
  setPayPlayers: any;
  selectAll: boolean;
  handleCheckboxChange: any;
  handleMasterCheckboxChange: any;
}) => {
  const {t} = useTranslation();
   const StaffPermission = useGetPermissions();
  const context = useContext(MainContext);
  const {CancelledPlayer,setCancelledPlayer,setIsPermissionOn} = useContext(MainContext);
  const event = context?.event;
  const max_capacity = events?.service;
  const [value, setValue] = React.useState("1");
  const [playerList, setPlayerList] = useState([]);
  const [waitPlayers, setWaitPlayers] = useState([]);
  const {data,isSuccess,isError,setService_id,refetch}= useGetAllWaitingList();
  const [teams, setTeams] = useState<any>([]);
  const [waitTeam, setWaitTeam] = useState<any>([]);
  const [AllPlayers,setAllPlayers] = useState<any[]>([]);
  const [teamMates, setTeamMates] = useState([]);
  const [addTeam, SetAddTeam] = useState<number[]>([]);
  const {data:PlayNot,isSuccess:PlayNotSuccess,isError:PlayNotError,refetch:PlayNotRefetch,setService_id:SetServiceID} = useGetPlayersNotes();
  const {data:waitingTeam, isSuccess:succ, isError: err, refetch: upd, setService_id:setServiceID} = useGetAllWaitingList();

  function isEmptyObjectOrUndefined(value:any) {
    return value === undefined || (typeof value === 'object' && Object.keys(value).length === 0);
  }

  const handleSelectTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleEditInfo = async (e: any, item: any, type: string) => {
    e.stopPropagation();
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      return;
    }
    
    if (type === "show") {
      const response = await UpdateServicesPlayers(item.id, type);
      if (response?.length !== 0) {
        PlayNotRefetch();
        if(event?.service?.booking_type==="Open Match" || event?.service?.event_type==="Double"){
          getAllPlayerList(event?.service_id)
        }
        updateList();
      }
    } else if (type === "cancel") {
      const response = await UpdateServicesPlayers(item.id, type);
      if (response?.length !== 0) {
        PlayNotRefetch();
        if(event?.service?.booking_type==="Open Match" || event?.service?.event_type==="Double"){
          getAllPlayerList(event?.service_id)
        }
        updateList();
      }
    }
  };

   
  const getAllPlayerList = async (service_id:number) => {
    if(service_id){
     try {
       let res: any = await getPlayerList(service_id);
       let data = res?.data?.data;
       setTeams(data);
       setAllPlayers(data)
       
      //  let filterData = pairData(data);
       const maxxxxxx =  selectEvent?.maximum_capacity || events?.service?.event?.maximum_capacity || events?.service?.booking?.maximum_capacity;
       const UpdatedDataPositionaly :any= organizePlayers(data,maxxxxxx);
      //  const finaDAtataa = fillWithPairedData(filterData,maxxxxxx);
       const canclledPlayer = data?.filter((pla:any)=>pla?.is_canceled||pla?.is_show);
       setCancelledPlayer(canclledPlayer);
       setTeamMates(UpdatedDataPositionaly);
       if(Object.keys(events).length>0){
         let max_capacity = selectEvent?.maximum_capacity || events?.service?.event?.maximum_capacity || events?.service?.booking?.maximum_capacity;
         const val = Math.floor(max_capacity/2) - UpdatedDataPositionaly.length;
         let arr = new Array(val)?.fill(0)
         SetAddTeam(arr);
       }
       
     } catch (error:any) {
       let err = error?.response?.data?.message;
       if(Object.keys(events).length>0 && events?.service?.event_type==="Double" ||events?.service?.booking_type ==="Open Match"){
         let max_capacity = selectEvent?.maximum_capacity || events?.service?.event?.maximum_capacity || events?.service?.booking?.maximum_capacity || 10;
         const val = Math.floor(max_capacity/2);
         let arr = new Array(val)?.fill(0)
         const finaDAtataa :any= organizePlayers([],max_capacity);
         setTeamMates(finaDAtataa);
        //  SetAddTeam(arr);
       }
     }
    }
   }


  useEffect(() => {
    if(PlayNotSuccess){
    const cancelledPlayer =   PlayNot?.serviceDetails?.players?.filter((item:any)=>item?.is_canceled||item?.is_show);

    const NotCancell = PlayNot?.serviceDetails?.players?.filter((item:any)=>!item?.is_canceled && !item?.is_show);
      setPlayerList(NotCancell);
      setCancelledPlayer(cancelledPlayer);
    }
  }, [PlayNot,PlayNotSuccess,PlayNotError])

  useEffect(() => {
    if(isSuccess){
      setWaitPlayers(data);
    }else{
      setWaitPlayers([]);
    }
  }, [data,isSuccess,isError])
   
 
  useEffect(()=>{
    if(Object.keys(event).length>0){
      // findPlayerList(event?.service?.id);
     event?.service?.id && setService_id(event?.service_id);
     SetServiceID(event?.service_id)
    }else{
      setPlayerList([]);
      setWaitPlayers([]);
    }
  }, [event, value]);

  useEffect(() => {
    if(succ){
        setWaitTeam(data);
    }else{
        setWaitTeam([]);
    }
  }, [data, isSuccess, err])

  useEffect(()=>{
      if(Object.keys(events).length>0){
        events?.service_id && setServiceID(events?.service_id)
      }else{
        setWaitTeam([]);
      }
    }, [events]);

  useEffect(() => {}, [userData, playerList, selectedOpenRegular,selectedBookingType]);

  useEffect(() => {
    if(CancelledPlayer?.length===0){
      setValue('1')
    }
  }, [CancelledPlayer])
  

  return(
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          columnGap: "5px",
          rowGap: "5px",
         
        }}>
          <TabContext value={value}>
            <Box>
              <TabList onChange={handleSelectTab} aria-label="people list" TabIndicatorProps={{sx: {backgroundColor: activeColor}}} 
                sx={{
                  ".MuiTab-root":{
                   paddingBottom:"0px",
                   textTransform:'capitalize',
                   color:"#22356D99",
                   fontSize:"1rem"
                  },
                 ".Mui-selected": {
                 color: "#22356D !important",
                 fontFamily: 'var(--font-semibold)',
               },}}
              >
                <Tab label={`${t('common.Players')} ${!isEmptyObjectOrUndefined(events) ? events?.players?.filter((player:any)=>!player?.is_canceled && !player?.is_show && !player.is_wating)?.length : 0}/${!isEmptyObjectOrUndefined(selectEvent) ? selectEvent?.maximum_capacity: 0}`} value="1" sx={{
                  fontFamily: 'var(--font-regular)',
                   zIndex:'14',
                  position:'relative'
                }} />
                {selectedOpenRegular!=="Regular" && <Tab label={selectedBookingType!=="Booking" ? `${t('Calendar.Waiting List')} ${waitPlayers?.length}`: 'Score'} value="2" sx={{ fontFamily: 'var(--font-regular)', zIndex:'14',position:'relative'}} />}

                {(selectedOpenRegular === 'Open Match' || selectedOpenRegular === 'Single' || selectedOpenRegular === 'Double') && ( <Tab label='Chat' value="3" sx={{ fontFamily: 'var(--font-regular)',zIndex:'14',position:'relative' }} /> )}
                {CancelledPlayer?.length>0 && ( <Tab label={`Cancelled ${CancelledPlayer?.length}`} value="4" sx={{ fontFamily: 'var(--font-regular)',zIndex:'14',position:'relative' }} /> )}
              </TabList>
            </Box>
            {/* -------- people listed ------- */}
            <TabPanel value="1" sx={{padding:"10px 0",zIndex:'0'}}>
              {selectedOpenRegular=="Double" ||  selectedOpenRegular=="Open Match" ? 
              ( <TeamMatesList SetAddTeam={setTeamMates} AllPlayers={AllPlayers} addTeam={addTeam} teamMates={teamMates} fromWh={fromWh} updatePAB={update} handleEditInfo={handleEditInfo} location={columnsArray} events={events} serviceLists={updateList} updateTab={PlayNotRefetch} selectEvent={selectEvent} setTeammatesData={setCustomerList} setTeams={setTeams} getAllPlayerList={getAllPlayerList} waitTeam={waitTeam} /> )
              : 
              <PersonList fromWh={fromWh} updatePAB={update} location={columnsArray} update={PlayNotRefetch} serviceLists={updateList} events={events} userData={playerList} updateList={setPlayerList} handleMasterCheckboxChange={handleMasterCheckboxChange} selectedOpenRegular={selectedOpenRegular} handleCheckboxChange={handleCheckboxChange} setSave={setSave} handleEditInfo={handleEditInfo} setCustomerList={setCustomerList} setPayPlayers={setPayPlayers} selectEvent={selectEvent}  />}
            </TabPanel>
            {/* ------ waiting list ----- */}
            <TabPanel value="2" sx={{padding:"10px 0",zIndex:'0'}}>
              {selectedBookingType!=="Booking" 
              ? 
              selectedOpenRegular ==="Double" ? 
              <WaitingTeam events={events} serviceLists={updateList} selectEvent={selectEvent} PlayNotRefetch={PlayNotRefetch} teams={teams} setWaitTeam={setWaitTeam} />
              :
              <WaitingList userData={waitPlayers} playerList={PlayNotRefetch} waitingList={refetch} updateList={setWaitPlayers} handleMasterCheckboxChange={handleMasterCheckboxChange} selectAll={selectAll} selectedOpenRegular={selectedOpenRegular} handleCheckboxChange={handleCheckboxChange} />
              :
              <ScoreList userData={waitingList} handleMasterCheckboxChange={handleMasterCheckboxChange} selectAll={selectAll} selectedOpenRegular={selectedOpenRegular} handleCheckboxChange={handleCheckboxChange} /> 
              }
            </TabPanel> 
            <TabPanel value="3" sx={{padding:"10px 0",zIndex:'0'}}>
              <ChatApp />
            </TabPanel>
            <TabPanel value="4" sx={{padding:"10px 0",zIndex:'0'}}>
            <Box id="table-person" sx={{marginTop:"0px !important", maxHeight:"313px !important"}}>
              <Box className="person-list">
                {
                  CancelledPlayer?.map((player:any,index:number)=>  <PersonTableRow isCheckBox={true} selectedCheckbox={[]} setSelectedCheckBox={()=>console.log('')} index={index} userData={player} selectedOpenRegular={selectedOpenRegular} handleCheckboxChange={handleCheckboxChange} handleEditInfo={handleEditInfo} setCustomerList={setCustomerList} />)
                }
            
                </Box>   
              </Box>
            </TabPanel>
          </TabContext>
      </Grid>
    </>
  ) 
} 

export default EventTab;
