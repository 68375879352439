import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";

function CustomDropdownMinutes({
  listData,
  setSelectedOption,
  placeholder,
  zIndex,
  selectedOption,
  type,
  width,
  setDropdown
}: {
  zIndex?: any;
  placeholder?: any;
  listData: any;
  setSelectedOption: any;
  selectedOption: any;
  type?: any;
  width: any;
  setDropdown: any;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (e: any) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener("click", () => {
      setIsOpen(false);
    });
  }, []);

  useEffect(()=>{
    if(isOpen){
      setDropdown(true);
    }else{
      setDropdown(false);
    }
  },[isOpen])

  const maincss = {
    marginTop: "-10px",
    color: "#22356D",
    fontFamily: "var(--font-regular)",
    fontSize: "14px",
  };
  return (
    <div style={{ width: `${width}` }} className="custom-dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        <p style={maincss}>
          {selectedOption ? `${selectedOption} ${type}` : placeholder}
        </p>
        <span style={maincss} className={`arrow`}>
          <FaChevronDown opacity={0.7} fontSize={"10px"} />
        </span>
      </div>
      <div >
      {isOpen && (
        <div id='table-person' style={{ zIndex: `${zIndex}` ,marginTop:'0px', width:"100%"}} className="options">
          {listData.map((option: any, index: any) => (
            <div
              key={index}
              className={`option ${
                selectedOption == option ? "preselectedOption" : ""
              }`}
              onClick={(e) => handleOptionClick(option)}
            >
              {option} {type}
            </div>
          ))}
        </div>
      )}
    </div>
    </div>
  );
}

export default CustomDropdownMinutes;
