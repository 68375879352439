import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CourtSettingsTab from "./CourtSettingsTab";
import AppSettingsTab from "./AppSettingsTab";
import { GetCoachByID } from "src/api/Coach";
import { useForm } from "react-hook-form";
import MainContext from "src/context/MainContext";
import { useTranslation } from "react-i18next";
import { error } from "jquery";

const selectedTab = {
  textDecoration: "underline",
  textDecorationColor: "#22356D",
  padding: "0px",
  textUnderlineOffset: "5px",
};
const textStyle = {
  color: "#22356D",
  opacity: 0.7,
  fontFamily: "var(--font-regular)",
};
const headingStyle: any = {
  color: "#22356D",
  fontFamily: "var(--font-semibold)",
  textTransform: "capitalize",
};
const borderStyle = {
  borderRadius: "7px",
  color: "#22356D",
  opacity: 0.7,
  border: "1px solid #EEEFEE",
  background: "#FFF",
  padding: "5px 10px",
  fontFamily: "var(--font-regular)",
  width: "inherit",
};

interface FormInput{
  email:string,
  phone_number:number,
  full_name:string, 
  description:string,
  profile_url:string
}

const CoachSettings = ({
  sport_id,
  Coach_id,
  selectedlocation,
  handleCoachClose,
  GetCoachData,
}: {
  sport_id: any;
  GetCoachData: any;
  Coach_id: any;
  selectedlocation: any;
  handleCoachClose: any;
}) => {
  const {t} = useTranslation();
  const [tabvalue, setTabValue] = useState("1");
  const {Notification,setNotification,setIsPermissionOn} = useContext(MainContext);
  const [image, setImage] = useState();
  const { register, handleSubmit, setValue, watch, reset } = useForm<FormInput>();
  const [CoachSettingsData, setCoachSettingsData] = useState<any>([]);
  const { email, phone_number, full_name, description,profile_url } = watch();
  const [error,setError] = useState('');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (Coach_id) {
      getCoachData();
    }
  }, []);

  const getCoachData = async () => {
    try {
      const response = await GetCoachByID(Coach_id);
      if (!!response) {
        reset(response);
      }
    } catch (error) {}
  };


  
  const uploadHandler=(event:any)=> {
    const imageSize:number= Math.round(Number(event.target.files[0].size/1024))
      if (imageSize > 10*1024) {
        setNotification({isShow:true,message:"Image not uploaded",subMessage:'Please upload a file smaller than 10 MB.',type:'success'})
    }else{
      setImage(event.target.files[0])
      setValue("profile_url",URL.createObjectURL(event.target.files[0]))
    }
  }

  return (
    <Box
      sx={{
        background: "white",
        padding: { xs: "20px",sm: "20px", md: "40px 40px 20px" },
        width: { xs: "auto",sm: "auto", md: "730px" },
      }}
    >
      <div>
        <p style={headingStyle}>{t('services.coach.Coach Info')}</p>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginBottom: "2rem",
            padding: { xs: "15px",sm: "auto", md: "10px 20px" },
            borderRadius: "7px",
            border: "0.5px solid #EEEFEE",
            background:
              "linear-gradient(0deg, rgba(136, 154, 182, 0.10) 0%, rgba(136, 154, 182, 0.10) 100%), #FFF",
            boxShadow:
              "-4px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 4px 4px 4px 0px rgba(0, 0, 0, 0.05) inset",
          }}
        >
          <div className="flex items-center gap-4 flex-row ">
             <Box
                sx={{ position: "relative", width: "100px", height: "100%" }}
              >
                <img
                  style={{
                    borderRadius: "50%",
                    width: "70px",
                    height: "70px",
                    objectFit: "cover",
                  }}
                  src={profile_url ? profile_url : "/images/Reserved.svg"}
                />
                <input
                  onChange={(e) => uploadHandler(e)}
                  style={{
                    zIndex: 4,
                    position: "absolute",
                    opacity: "0",
                    width: "30px",
                    height: "30px",
                    bottom: "0",
                    right: "3px",
                  }}
                  type="file"
                  name="file"
                />

                <img
                  style={{ position: "absolute", bottom: "5px", right: "6px" }}
                  src="/images/Group 106.svg"
                />
              </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "auto",sm:"auto", md: "repeat(2, 1fr)" },
                width: { xs: "95%",sm: "auto",md: "100%" },
                gap: "5px",
                "& > input:last-child": {
                  gridColumn: { xs: "auto",sm: "auto", md: "span 2" },
                },
              }}
            >
              <Box sx={{width: "inherit"}}>
              <input
              id={error==="full_name" ? "customerror":''}
                placeholder="Enter Coach name"
                {...register("full_name", { required: true })}
                style={borderStyle}
                type="text"
              />
               {/* { error==="full_name" && (
                 
                )} */}
                </Box>
              <input
                placeholder="Enter Coach phone number"
                {...register("phone_number")}
                style={borderStyle}
                type="text"
              />
              <input
                type="email"
                placeholder="Enter Coach email"
                {...register("email")}
                style={{ ...borderStyle }}
              />
            </Box>
          </div>
          <div>
            <p className="flex flex-row items-center" style={textStyle}>
              {t('services.coach.Working days')}:{" "}
              {CoachSettingsData?.is_checked
                ? CoachSettingsData?.workinghours?.map(
                    (item: any, index: number) => {
                      let shortDay: any;
                      if (item?.is_checked) {
                        shortDay = item?.days?.slice(0, 3);
                      }
                      return (
                        <p key={index}>
                          {item?.is_checked ? `${shortDay}, ` : ""}
                        </p>
                      );
                    }
                  )
                : "Mon, Tue, Wed, Thu, Fri, Sat, Sun"}
            </p>
            <input
              placeholder="description"
              {...register("description", { required: true })}
              style={{ ...textStyle, backgroundColor: "transparent" }}
            />
          </div>
        </Box>
        <TabContext value={tabvalue}>
          <Box>
            <TabList className="CoachSettingsTabs" onChange={handleChange}>
              <Tab
                style={
                  tabvalue == "1"
                    ? { ...selectedTab, ...headingStyle }
                    : { ...headingStyle, opacity: "0.7", paddingLeft: "0" }
                }
                label={t('services.coach.Coach Settings')}
                value="1"
              />
              <Tab
                style={
                  tabvalue == "2"
                    ? { ...selectedTab, ...headingStyle }
                    : { ...headingStyle, opacity: "0.7" }
                }
                label={t("courts.App Settings")}
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel style={{ color: "#22356D", padding: "0px" }} value="1">
            <CourtSettingsTab
            setError={setError}
            UserProfile={image}
              GetCoachData={GetCoachData}
              sport_id={sport_id}
              handleCoachClose={handleCoachClose}
              Coach_id={Coach_id}
              getWokingHours={setCoachSettingsData}
              CoachData={watch}
            />
          </TabPanel>
          <TabPanel style={{ color: "#22356D", padding: "0px" }} value="2">
            <AppSettingsTab
            setError={setError}
              UserProfile={image}
              GetCoachData={GetCoachData}
              Coach_id={Coach_id}
              sport_id={sport_id}
              handleCoachClose={handleCoachClose}
              CoachData={watch}
            />
          </TabPanel>
        </TabContext>
      </div>
    </Box>
  );
};

export default CoachSettings;
