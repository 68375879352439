import { activeColor, pending } from "src/constants/color";
import { IoMdAdd } from "react-icons/io";
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { getClubId, getPlayerList, Permission, useGetAllCustomerList, useGetPermissions } from "src/api/CalendarBooking";
import SearchTeammates from "./SearchTeammates";
import MainContext from "src/context/MainContext";
import { customSettingsObj } from "src/pages/Customers";
import { useGetAllCustomSettings } from "src/api/Customer";
import { apiCaller } from "src/api/ApiCaller";
import PaymentSettings from "../../components/PaymentSettings";
import "./teamMates.css";
import { addThousandSeparator } from "src/constants/SportSEparator";
import { Dialog, DialogContent, Paper } from "@mui/material";
import StatusWarning from "./StatusWarning";
import TeamMatesPer from "./TeamMatesPer";
import { fillWithPairedData, organizePlayers } from "./EventTab";
import { useTranslation } from "react-i18next";


    // pairing with teammates -----
    export const pairData = (data: any) => {
      const paired: any = [];
    
      data.forEach((item:any) => {
      if(!item.is_canceled && !item.is_show){
          
        const pairedItem = paired?.find((p:any) => p.id === item.other_player);
    
        if (pairedItem) {
          pairedItem.pairedData.push(item);
        } else {
          paired.push({
            ...item,
            pairedData: [],
          });
        }
      }
      });
      return paired;
    };





const TeamMatesList = ({ SetAddTeam,location,updatePAB,fromWh,teamMates,getAllPlayerList, handleEditInfo,addTeam,events, serviceLists, updateTab, selectEvent, setTeammatesData ,setTeams, waitTeam,AllPlayers}:{location:any, events: any,updatePAB:any,fromWh:any, serviceLists: any, updateTab:any, selectEvent: any, setTeammatesData:any, setTeams:any, waitTeam:any,handleEditInfo:any,AllPlayers:any,addTeam:any,teamMates:any,getAllPlayerList:any,SetAddTeam:any }) => {
    const {t} = useTranslation();
    const context = useContext(MainContext);
    const {Notification,setNotification, setIsPermissionOn} = useContext(MainContext);
    const {CancelledPlayer, setCancelledPlayer} = useContext(MainContext);
    const setSelectdPlayers = context?.setSelectdPlayers;
    const setSelectedCustomerId = context?.setSelectedCustomerId;
    const SelectedLocation = context?.SelectedLocation;
     const StaffPermission = useGetPermissions();
    const [customerList, setCustomerList] = useState<any>([]);
    const [voucherAdd, setVoucherADD] = useState(false);
    const [payments,setPayments]= useState<any[]>([]);
    const [selectedCheckbox,setSelectedCheckBox] = useState<any[]>([]);
    const [selectedRefunds,setSelectedRefunds] =useState<any>([]);
    const [selectedPayment,setSelectedPayment]=useState<any[]>([]);
    const {data:CustomerData,isSuccess,isError,refetch} = useGetAllCustomerList();
    const [customFields,setCustomFields] = useState<any[]>([]);
    const {data:CustomData,isSuccess:CustomSetSuccess,isError:CustomSettError} = useGetAllCustomSettings();
    

    
    // function isEmptyObjectOrUndefined(value:any) {
    //   return value === undefined || (typeof value === 'object' && Object.keys(value).length === 0);
    // }    

    useEffect(() => {
      if(isSuccess){
        if(!!AllPlayers?.length){
        const data = CustomerData?.filter((item:any)=> !AllPlayers?.some((ett:any)=>item?.id=== ett?.customer?.id));
          let arr_id:number[] = [];
          teamMates?.forEach((item:any)=>{
            arr_id.push (item.customer?.id);
            if(item?.pairedData.length>0){
              arr_id.push(item?.pairedData[0]?.customer?.id)
            }
          })  
          waitTeam.forEach((item:any)=>{
            arr_id.push (item.customer?.id);
          })
          let filters = data.filter((item:any) => !arr_id.includes(item.id))
          setCustomerList([{first_name:'Create',last_name:'new +'},...filters]);
        }else{
          setCustomerList([{first_name:'Create',last_name:'new +'},...CustomerData]);
        }
      }
    }, [CustomerData,isSuccess,isError,AllPlayers])

    const addTeammate =  useCallback(() => {
      
      let obj:any = {};
      let data = {};

      return async (value:any) => {
        const {index, newValue, customer} = value;
        if(Object.keys(events).length==0){
          if (obj.hasOwnProperty(`${index}`)) {
            const playernumber:any= (index*2) + (value.text==="first"?1:2);
            obj[index][`customer${value.text==="first"?1:2}Id`] = newValue ;
            obj[index][`customer${value.text==="first"?1:2}Position`] =playernumber
          } else {
            const playernumber:any= (index*2) + (value.text==="first"?1:2);
            obj[index] = { [`customer${value.text==="first"?1:2}Id`]: newValue };
            obj[index][`customer${value.text==="first"?1:2}Position`] =playernumber
          }
          setSelectdPlayers(Object.values(obj))
        }else{
          const playernumber:any= (index*2) + (value.text==="first"?1:2);
          if(value.text=="first"){
            data = {
              customerId:value.newValue,
              watingPlayer: false,
              position:playernumber
            }
          }else{
            data = {
              customerId: value.newValue,
              playerId: value.customerId,
              watingPlayer: false,
              position:playernumber
            }
          }
          if(!StaffPermission.edit_permissions){
            setIsPermissionOn(true);
            return;
          }
    
          try {
              let club_id = getClubId();
            let res = await apiCaller.post(`${club_id}/calendars/services/players/${events?.service?.id}/${events?.service_id}`, data);
            getAllPlayerList(events?.service_id);
            updateTab(events?.service_id);
            serviceLists();
            if(fromWh=="payments" || fromWh==="bookings"){
              updatePAB();
            }
            return res.data.data;
          } catch (error:any) {
            const err = error?.response?.data?.message;
            setNotification({isShow:true, message:err,subMessage:'',type:'error'})
            return error;
          }
        }
        
      }
        
    },[])

    const add = useMemo(()=>{
      return addTeammate();
    },[addTeammate])

    useEffect(()=>{
      getAllPlayerList(events?.service_id);

      // document.addEventListener("click", () => {
      //   setShowList(false);
      // })
    },[]);

    useEffect(() => {
    if(Object.keys(selectEvent||{}).length>0 && Object.keys(events).length===0){
        let max_capacity = selectEvent?.maximum_capacity;
       const finaDAtataa :any= organizePlayers([],max_capacity);
        SetAddTeam(finaDAtataa);
      }
    }, [selectEvent])
    

    // payemnts functions ------------------
    const handleParentClick =()=>{
      setSelectedCheckBox((prev:any)=> prev?.length===AllPlayers?.length ? []:AllPlayers)
    }
     
    useEffect(() => {
      setSelectedPayment(selectedCheckbox?.filter((item:any)=>item.status==="Pending payment"));
      setSelectedRefunds(selectedCheckbox?.filter((item:any)=>item.status==="Paid"));
    }, [selectedCheckbox])
  

    const handleClosePayment = () => {
      setSelectedCheckBox([])
      setSelectedPayment([]);
      setSelectedRefunds([]);
      setPayments([]);
      setVoucherADD(false);
    }
    
    //get customsettings fields
    useEffect(() => {
      if(CustomSetSuccess){
        const filtered = CustomData?.filter((item:customSettingsObj)=>item.columnName!=="userId");
        setCustomFields(filtered);
      }
    }, [CustomData,CustomSetSuccess,CustomSettError]);

    let totalDiscount = payments?.length === 0 ? 0: payments?.reduce((item,acc:any)=>item+acc?.discount,0)
    let TotalPrice = payments?.reduce((acc,item:any)=>acc+item?.pending_price,0);
    let finalPrice= TotalPrice-totalDiscount;
    let TotalRefunds = selectedRefunds?.reduce((acc:any,item:any)=>acc+item?.refund_price,0);
    const isPAid = AllPlayers?.every((item:any)=>item?.status==="Paid");


    return (
        <Box>
          <Box sx={{display:"flex", justifyContent:'flex-end', alignItems:"center", gap:'10px', paddingRight:"10px",marginTop:"-13px"}}>
            <p style={{fontSize:"17px", fontFamily:"var(--font-regular)", color:"#22356D"}}>{t('customers.Select all')}</p>
            <label className="container">
              <input checked={selectedCheckbox?.length===AllPlayers?.length} onClick={handleParentClick} type="checkbox" />
              <span className="mark"></span>
            </label>
          </Box>
          
          <Box id="table-person" sx={{marginTop:"0px !important", maxHeight:"313px !important"}}>
            <Box className="person-list">
              <Box>
                {/* showing added teammates */}
                {teamMates.map((item:any, index:number) => 
                <TeamMatesPer updateCustList={refetch} events={events} update={()=> getAllPlayerList(events?.service_id)} add={add} selectEvent={selectEvent} AllPlayers={AllPlayers} customerList={customerList} setSelectedCheckBox={setSelectedCheckBox} selectedCheckbox={selectedCheckbox} handleEditInfo={handleEditInfo} SelectedLocation={SelectedLocation} setSelectedCustomerId={setSelectedCustomerId} setTeammatesData={setTeammatesData} item={item} index={index}/>
                )}
                {/*|| events?.service?.Players[0]?.price*/}

                {/* for adding the team mates */}
              


              </Box>
            </Box>
          </Box>

          <Box display={'flex'} justifyContent={'space-between'} paddingY={'18px'}>
            <p style={{ fontFamily: 'var(--font-semibold)', color: activeColor }}>{t('common.Pending Payment')}</p>
            <Box sx={{ fontFamily: 'var(--font-regular)', color: 'white', textAlign: "center",display:'flex',flexDirection:'column',alignItems:'center'}}>
              {
                isPAid && <p style={{ background: pending,opacity:'0.3', borderRadius: '5px', marginBottom: "7px", width:'-webkit-fill-available',fontFamily:'var(--font-regular)'}}>{SelectedLocation?.currency} 0 k</p>
              }
            {
                !isPAid ? AllPlayers?.reduce((acc:any,item:any)=>acc + item?.pending_price,0) >1000 ? 
                <p style={{ background: pending, borderRadius: '5px', marginBottom: "7px", minWidth:"55px", paddingInline:"12px",fontFamily:'var(--font-regular)'}}>{SelectedLocation?.currency} {addThousandSeparator(AllPlayers?.reduce((acc:any,item:any)=>acc + item?.pending_price,0), SelectedLocation?.currency)} </p>:<p style={{ background: pending, borderRadius: '5px', marginBottom: "7px", minWidth:"55px", paddingInline:"12px",fontFamily:'var(--font-regular)'}}>{SelectedLocation?.currency} {addThousandSeparator(AllPlayers?.reduce((acc:any,item:any)=>acc + item?.pending_price,0), SelectedLocation?.currency)} </p>:''
              }
              { 
                selectedCheckbox?.length >0?<Box onClick={()=>setVoucherADD(true)} sx={{ background: "#22356D", borderRadius: '7px',border:"1px solid #EEEFEE", padding:"7px 17px", width:'141px', height:'37px', cursor:'pointer',boxShadow:'0px 4px 4px 0px #0000001A' }}>{t('common.Open Payments')}</Box>
                :<Box sx={{ background: "#22356D66", borderRadius: '5px', width:'-webkit-fill-available', cursor:'pointer' }}>Pay 0</Box>
              }
            </Box>
          </Box>
          
          <PaymentSettings fromwh={fromWh} updatePAB={updatePAB} PendingPlayers={selectedPayment} RefundingPlayers={selectedRefunds} handleClose={handleClosePayment} open={voucherAdd} selectedLocationID={SelectedLocation?.id} serviceID={events?.service_id} update={()=>{
            getAllPlayerList(events?.service_id);
            serviceLists();
          }} />
        </Box>
    )
}

export default TeamMatesList;