import { useState } from "react";
import { useQuery } from "react-query";
import { apiCaller } from "src/api/ApiCaller";
import { getClubId } from "src/api/CalendarBooking";

export const useGetAllvouchersByCustId = () => {  
    const club_id = getClubId();
    const [customer_id,setCustomer_id] = useState();
  
    const query = useQuery(
      ["VouchersByCust",customer_id],
      async () => {
        const response = await apiCaller.get(`${club_id}/customers/vouchers/${customer_id}`);
        return response.data.data;
      },
      {
        enabled:!!customer_id,
        staleTime: 1000 * 60 * 5,
        retry: false,
      }
    );
  
    return {setCustomer_id,...query}
  };