import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import React, { useEffect, useState } from 'react';
import Typography from "@mui/material/Typography";
import MainContext from 'src/context/MainContext';
import Box from "@mui/material/Box";
import moment from 'moment';

interface WeekObject {
    weekNo: number,
    startDay: number,
    endDay: number
}

const arrow = {
    width: "29px",
    padding: "0px 6px",
    color: "#22356D",
    fontSize: "22px"
}

const WeekSlider = ( { selectedWeek, setSelectedWeek, handleWeek } : { selectedWeek:number, setSelectedWeek:any, handleWeek:any } ) => {
    const context = React.useContext(MainContext);
    const selectedMonth = context?.selectedMonth;
    const dateObject = new Date(selectedMonth);
    const year = dateObject.getFullYear();
    const [currentIndex, setCurrentIndex] = useState(1);
    const [weeks, setWeeks] = useState<WeekObject[]>([]);
    const month = dateObject.getMonth();

    // get all weeks of current month
    useEffect(() => {
        if (selectedMonth) {
            // Calculate the start and end dates of the selected month
            const startDate = moment().year(year).month(month).startOf('month');
            const endDate = moment().year(year).month(month).endOf('month');
    
            let startOfWeek = startDate.clone().startOf('week');
            let endOfWeek = startOfWeek.clone().endOf('week');
    
            let arr: WeekObject[] = [];
            let weekNo = 1;

            while (startOfWeek.isBefore(endDate)) {
                const weekData: WeekObject = {
                    weekNo: weekNo,
                    startDay: Number(startOfWeek.format('D')),
                    endDay: Number(endOfWeek.format('D'))
                };
                arr.push(weekData);
                startOfWeek.add(1, 'week');
                endOfWeek = startOfWeek.clone().endOf('week');
                weekNo++; // Increment week number
            }

            // Find the index of the week containing the current date
            const currentDate = moment().date(); // Get the current date

            // Find the week object containing the current date
            const currentWeek = arr.find((week:any) => {
                if (week.startDay <= week.endDay) {
                    return currentDate >= week.startDay && currentDate <= week.endDay;
                } else {
                    return (currentDate >= week.startDay && currentDate <= 31) || (currentDate >= 1 && currentDate <= week.endDay);
                }
            });

            if (currentWeek) {
                setSelectedWeek(currentWeek.weekNo);
                handleWeek(currentWeek);

                if (currentWeek.weekNo < 3) {
                    setCurrentIndex(0);
                } else if (currentWeek.weekNo > 3) {
                    setCurrentIndex(2);
                } else {
                    setCurrentIndex(1);
                }
            }
                setWeeks(arr);
                
            }
    }, [selectedMonth]);


    const handleNext = () => {
        setCurrentIndex((prevIndex: number) => Math.min(prevIndex + 1, weeks.length - 1));
        setSelectedWeek((prevSelected: number) => {
            if (prevSelected !== 5) {
                handleWeek(weeks[prevSelected]);
                return prevSelected + 1;
            }
            handleWeek(weeks[prevSelected]);
            return prevSelected;
        });
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex: number) => Math.max(prevIndex - 1, 0));
        setSelectedWeek((prevSelected: number) => {
            if (prevSelected !== 0) {
                handleWeek(weeks[prevSelected - 2]);
                return prevSelected - 1;
            }
            handleWeek(weeks[prevSelected]);
            return (prevSelected);
        });
    };

    return (
        <Box display={'flex'} alignItems={'center'}>
            <button onClick={handlePrevious} disabled={currentIndex === 0} style={{ ...arrow }}><HiOutlineChevronLeft /></button>
            
            {weeks.slice(currentIndex, currentIndex + 3).map((item, index) =>{
                return (
                    <Box key={index} sx={{ background: selectedWeek === item.weekNo ? "white" : "", borderRadius: "7px", padding: '6px 25px', textAlign: 'center', cursor: "pointer" }} onClick={() => {setSelectedWeek(item.weekNo);handleWeek(item)}}>
                        <Typography sx={{ fontFamily: "var(--font-regular)", color: '#22356D' }}>Week {item.weekNo}</Typography>
                        <Typography sx={{ fontFamily: "var(--font-semibold)", fontSize: "1.2rem", color: "#22356D" }}>{item.startDay}-{item.endDay}</Typography>
                    </Box>
                )
            })}

            <button onClick={handleNext} disabled={currentIndex + 3 >= weeks.length} style={{ ...arrow }}><HiOutlineChevronRight /></button>
            
        </Box>
    )
}

export default WeekSlider;