import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown } from "react-icons/io";

function CustomDropdownBookings({ listData, getTime, preTime, zIndex }: { zIndex?: any, listData: any, getTime?: any, preTime?: any }) {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();
    
    const toggleDropdown = (e: any) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option: any) => {
      const selectedIndex = preTime.findIndex((preTime:any) => preTime.id === option.id);
      if (selectedIndex === -1) {
        getTime([...preTime, option]);
      } else {
          const newSelectedOptions = [...preTime];
          newSelectedOptions.splice(selectedIndex, 1);
          getTime(newSelectedOptions);
      }
    };

    useEffect(() => {
        window.addEventListener('click', () => {
            setIsOpen(false);
        });

    }, []);

    useEffect(()=>{
      const finaloption = preTime?.map((item:any)=>{
        let {...newitem} = item;
        let price = undefined;
        return{price,...newitem}
      })
      getTime(finaloption);
    },[])

    const maincss = { marginTop: '-10px', color: '#22356D', fontFamily: "var(--font-regular)", fontSize: '14px', opacity: '0.7' };
    const borderInput = { opacity: '0.7', padding: '5px 8px', fontSize: '14px', border: '1px solid #EEEFEE', borderRadius: '7px', color: '#22356D' };

    return (
        <div style={{ maxWidth: '180px',marginTop:'5px' }} className="custom-dropdown frombookings">
            <div className="dropdown-header" onClick={toggleDropdown}>
                <span style={maincss}>
                 {t('services.lesson.Add Coach')}
                </span>
               {
isOpen ?  <IoIosArrowDown style={{ marginTop: "-14px",rotate:'180deg' }} opacity={0.7} /> : <IoIosArrowDown style={{ marginTop: "-14px" }} opacity={0.7} />

               }
            </div>
            {isOpen && (
                <div id='table-person' style={{ zIndex: `${zIndex}`, marginTop: '0px',width:'100%' }} className="options">
                    {listData.map((option: any) => (    
                        <div
                            key={option?.id}
                            className={`option ${preTime.some((selectedOption:any) => selectedOption.id == option.id) ? 'preselectedOption' : ''}`}
                            onClick={(e) => handleOptionClick(option)}
                        >
                            {option?.full_name || option?.first_name} 
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default CustomDropdownBookings;
