import { Box, Dialog, DialogContent, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { activeColor } from 'src/constants/color';
import MainContext from 'src/context/MainContext';

const StaffModal = () => {
  const {t} = useTranslation();
  const {isPermissionOn,setIsPermissionOn} =useContext(MainContext);


  
const style = {
  fontFamily: "var(--font-semibold)",
  background: "white",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};

  
  return (
    <Dialog onClose={() => setIsPermissionOn(false)} open={isPermissionOn}>
    <DialogContent>
    <Box>
      <Typography sx={{fontFamily:"var(--font-medium)",color:"#22356D",marginBottom:'5px'}}>{t('common.You are not allowed to')} {t('common.edit this information')}</Typography>
      <Stack direction={'row'} spacing={1} marginBottom={4}>
          <img src="/images/info.svg" alt="info" />
          <Typography sx={{color:"#22356DB2",fontFamily:"var(--font-regular)",}}>{t('common.Only staff managers are allowed to')} {t('common.edit this information')}</Typography>
      </Stack>
      <button style={{...style, color: activeColor }}  onClick={() =>setIsPermissionOn(false)}>{t('common.Close')}</button>
  </Box>
  </DialogContent>
  </Dialog>
  )
}

export default StaffModal