import moment from "moment";

const convertTimeFormat = (time:any)=> {

    if(time!=="Select Time"){
        const formattedTime = moment(time, "HH:mm:ss").format("h:mm A");
        return formattedTime;
    }else{
        return "Select Time"
    }
    
}


export default convertTimeFormat