import React, { useEffect, useState } from "react";
import CustomDropdown from "./CustomDropdown";

function TimeRangePicker({
  listData,
  getTime,
  zIndex,
  TimeRange,
  isCoach,
}: {
  listData: any;
  getTime: any;
  zIndex?: any;
  TimeRange: any;
  isCoach?:any
}) {

  const [start_time, setstartTime] = useState<string>();
  const [end_time, setendTime] = useState<string>();


  const finalTime = { start_time, end_time };
  const returnTime = (updatedStartTime: any, updatedEndTime: any) => {
    // Handle potential null values for updated times
    const newStartTime =
      updatedStartTime !== null ? updatedStartTime : TimeRange?.start_time;
    const newEndTime =
      updatedEndTime !== null ? updatedEndTime : TimeRange?.end_time;

    // Check if both times have changed or if either one has changed
    if (TimeRange == null || TimeRange == undefined) {
      if (updatedStartTime != null && updatedEndTime != null) {
        getTime(finalTime);
      }
    } else if (
      newStartTime !== TimeRange?.start_time ||
      newEndTime !== TimeRange?.end_time
    ) {
      const updatedTime = { start_time: newStartTime, end_time: newEndTime };
      getTime(updatedTime);
    }
  };

  useEffect(() => {
  if(start_time==="Unavailable" || end_time==="Unavailable"){
    returnTime("Unavailable", "Unavailable");
  }else{
    returnTime(start_time, end_time);
  }
  }, [start_time, end_time]);


  useEffect(()=>{
if(TimeRange?.start_time !== undefined && TimeRange?.end_time !==undefined && !!TimeRange){
  setstartTime(TimeRange?.start_time);
  setendTime(TimeRange?.end_time)
}else if(TimeRange==="" || TimeRange===undefined || Object.keys(TimeRange).length === 0 && TimeRange.constructor === Object ){
  setstartTime("08:00:00");
  setendTime("22:00:00")
}
  },[TimeRange])

  return (
    <div className="flex flex-row items-center gap-unit-2">
      <CustomDropdown
        isCoach={isCoach}
        zIndex={zIndex}
        preTime={start_time}
        getTime={setstartTime}
        listData={listData}
      />
      to
      <CustomDropdown
       isCoach={isCoach}
        zIndex={zIndex}
        preTime={end_time}
        getTime={setendTime}
        listData={listData}
      />
    </div>
  );
}

export default TimeRangePicker;
