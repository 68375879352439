import { Box, Typography } from '@mui/material';
import { Select, SelectItem } from '@nextui-org/react';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import { TfiWorld } from "react-icons/tfi";
import { MyDetails } from 'src/api/CalendarBooking';
import { useGetAllCLubs } from 'src/api/SuperAdmin';
import MainContext from 'src/context/MainContext';

const TopUserInfo = () => {
    const { t, i18n } = useTranslation();
    const {LoginData,setLoginData} = useContext(MainContext);
    const {
      data: ClubsData,
      isSuccess: ClubsSucces,
      isError: ClubError,
      refetch: ClubRefetch,
    } = useGetAllCLubs();
    const [AllClubs, setAllclubs] = useState<any[]>([]);
    const [selectedClub, setSelectedClub] = useState<any>();
     
 const changleLanguage = (lng:number) => {
    window.localStorage.setItem('Lng',JSON.stringify(lng));
       i18n.changeLanguage(lng===1?"en":lng===2?"es":"ru");
     };   
  
   const Lang = window.localStorage.getItem("Lng")||1;

   const club_id = JSON.parse(
    localStorage.getItem("Club_id") || JSON.stringify("")
  );
  const user = JSON.parse(localStorage.getItem("user") || JSON.stringify({}));

  const handleClubChange = (data: any) => {
    localStorage.setItem("Club_id", data?.id);
    setSelectedClub(data);
   setTimeout(()=>{
    window.location.reload();
   },600)
  };



  useEffect(() => {
    if (!!AllClubs?.length && club_id) {
      const PreselectedClub = AllClubs?.filter(
        (item: any) => item?.id === club_id
      )[0];
      setSelectedClub(PreselectedClub);
    }
  }, [club_id, AllClubs]);

  useEffect(() => {
    if(user?.role.name === "SUPER_ADMIN"){
      ClubRefetch();
    }
    GetUserInfo();
  }, [])
  
  
  const GetUserInfo = async()=>{
    try {
      const response  = await MyDetails();
      setLoginData(response);
    } catch (error) {
      
    }
  }
  

  useEffect(() => {
    if (user?.role.name === "SUPER_ADMIN" && ClubsSucces) {
      setAllclubs(ClubsData);
      if (!club_id) {
        localStorage.setItem("Club_id", ClubsData[0]?.id);
        setSelectedClub(ClubsData[0]);
        setTimeout(()=>{
          window.location.reload();
         },600)
      }
    } else {
      setAllclubs([]);
    }
  }, [ClubsData, ClubsSucces, ClubError]);

   
  return (
    <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'5px',justifyContent:'flex-end',flexWrap:'wrap',marginTop:'10px'}}>
       {user?.role.name === "SUPER_ADMIN" && (
                        <Select
                        classNames={
                          {
                           "mainWrapper":'!bg-red-600' 
                          }
                        }
                          aria-label="Select Club"
                          placeholder="Select club"
                          labelPlacement="outside"
                          size="sm"
                          className="max-w-xs min-w-32"
                          disableSelectorIconRotation
                          // style={{width:'50%'}}
                          selectedKeys={[String(selectedClub?.id)]}
                          selectorIcon={<FaAngleDown />}
                        >
                          {AllClubs?.map((item: any) => (
                            <SelectItem
                              onClick={() => handleClubChange(item)}
                              key={item?.id}
                              value={item}
                              style={{
                                fontFamily: "var(--font-regular) !important",
                                color: "#22356DB2",
                              }}
                            >
                              {item?.club_name}
                            </SelectItem>
                          ))}
                        </Select>
                      )}
   {user?.role.name !== "SUPER_ADMIN" && <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'5px'}}>
        <TfiWorld style={{color:'#22356DB2',marginRight:'2px'}}/>
         <Select style={{width:'70px', fontFamily: "var(--font-regular) !important",
                      color: "#22356DB2",fontSize:'13px'}}
                aria-label="Select Lang"
                placeholder="Select Lang"
                labelPlacement="outside"
                size="sm"
                className="max-w-xs "
                disableSelectorIconRotation
                selectedKeys={[String(Lang)]}
                selectorIcon={<FaAngleDown />}
              >
                {[1,2,3]?.map((item: number) => (
                  <SelectItem
                    onClick={(e) =>  changleLanguage(item)}
                    key={item}
                    value={item}
                    style={{
                      fontFamily: "var(--font-regular) !important",
                      color: "#22356DB2",
                    }}
                  >
                    {item===1 ?"EN":item===2?'ES':'RU'}
                  </SelectItem>
                ))}
              </Select>
    </Box>}
      <Box
      sx={{
        display:{xs:'flex',md:'flex'},
        flexDirection:'row',
        gap:'5px'
      }}
    > 
   {user?.role.name !== "SUPER_ADMIN" &&  <Typography
    sx={{
      textTransform: "capitalize",
      fontSize: "14px",
      background: "white",
      color: "#22356D",
      fontFamily: "var(--font-semibold)",
      paddingX: "8px",
      paddingTop: "4px",
      borderRadius: "7px",
      boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px inset",
      height:'28px'
    }}
  >
    {user?.club_name}
  </Typography>}
      <Typography
        sx={{
          textTransform: "capitalize",
          fontSize: "14px",
          background: "white",
          color: "#22356D",
          fontFamily: "var(--font-semibold)",
          paddingX: "8px",
          paddingTop: "4px",
          borderRadius: "7px",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px inset",
          height:'27px'
        }}
      >
        {user?.full_name} -{" "}
        {user?.role.name.replace("_", " ").toLowerCase()}
      </Typography>
    </Box>
    </Box>
  )
}

export default TopUserInfo