import { useState } from "react";
import { useQuery } from "react-query";
import { apiCaller } from "src/api/ApiCaller";
import { getClubId } from "src/api/CalendarBooking";

export const useGetAllEventsServices = () => {
    const [sportId, setSportId] = useState<number>();
    const [location_id, setlocation_id] = useState<number>();
  
    const club_id = getClubId();
  
    const query = useQuery(
      ["AllEvents", location_id,sportId],
      async () => {

        const sport =
        sportId !== 0 ? `?sport_id=${sportId}` : "";
        const response = await apiCaller.get(`${club_id}/services/events-all/${location_id}${sport}`);
        return response.data.data;
      },
      {
        enabled: !!location_id,
        staleTime: 1000 * 60 * 5,
        retry: false,
      }
    );
  
    return {...query, setSportId,setlocation_id};
  };