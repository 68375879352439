import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Cleave from 'cleave.js/react';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { GetAllPaymentMethods, useGetAllPaymentMethodsByCust } from 'src/api/Voucher';
import { dopdowncustomcss } from 'src/constants/dropdownCSS';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useGetAllCustomSettings, useGetClubWalletOfCustomer } from 'src/api/Customer';
import CustomerInfo from '../../components/CustomerInfo';
import MainContext from 'src/context/MainContext';
import { addThousandSeparator } from 'src/constants/SportSEparator';
import { Grid } from '@mui/material';

interface walletTypes{
  currency:string,
  balance:number,
  id:number,
}

const PaymentsRow = ({selectedLocationID,item,setPayments,type,customFields}:{type:string,setPayments:any,selectedLocationID:number,item:any,customFields?:any}) => {
    const [PaymentMethods,setPaymentMethods] =  useState<any>([]);
    const [open,setOpen] = useState<boolean>(false);
    const [discount,setdiscount] = useState<number>();
    const [price,setPrice] = useState<number>(0);
    const context = useContext(MainContext);
    const SelectedLocation = context?.SelectedLocation;
    const {Notification,setNotification} = useContext(MainContext);
    const [SelectedVoucher,setSelectedVoucher] = useState<any>();
    const {data,isSuccess,isError,setCustomer_id,refetch:RefetchClubWallet} = useGetClubWalletOfCustomer();
    const {data:PaymentMethodsData,isSuccess:PaymentMethodSuccess,isError:PaymentMethodError,setCustomer_id:SetPCustomer_id,setLocation_id} = useGetAllPaymentMethodsByCust();

  
  useEffect(() => {
    setPrice(item?.paid_price);
    setCustomer_id(item?.customer?.id);
    SetPCustomer_id(item?.customer?.id);
    setLocation_id(selectedLocationID);
  }, [item.id,selectedLocationID]);
  
  useEffect(() => {
    if(isSuccess && PaymentMethodSuccess){
      const updated = data?.map((item:walletTypes)=>{
        const method_name = `Wallet ${addThousandSeparator(item.balance,item.currency)} ${item.currency}`;
        return {...item,method_name}
      })
      setPaymentMethods([...updated,...PaymentMethodsData?.paymentMethods]);
    }else if (PaymentMethodSuccess) {
     if(type==="Refunds"){
      const newWallet = {
        "method_name": "Wallet 0 IDR",
        "isNewly":true
      }
      setPaymentMethods([newWallet,...PaymentMethodsData?.paymentMethods])
     }else{
      setPaymentMethods(PaymentMethodsData?.paymentMethods)
     }
     
    }else if(isSuccess){
      const updated = data?.map((item:walletTypes)=>{
        const method_name = `Wallet ${addThousandSeparator(item.balance,item.currency)} ${item.currency}`;
        return {...item,method_name}
      })
      setPaymentMethods(updated)
    }else{
      setPaymentMethods([])
    }
    
  }, [data,isSuccess,isError,PaymentMethodsData,PaymentMethodSuccess,PaymentMethodError,type]);
  
  
const PAssData = useCallback(()=>{

    if(type==="Refunds"){
      let apidata :any= {
        "player_id": item?.id,
        "refund_price": Number(discount||0),
    }
     if(SelectedVoucher?.hasOwnProperty('balance')){
      apidata.method_type ="Wallet";
        return apidata
    } else if(SelectedVoucher?.hasOwnProperty('method_name') && !SelectedVoucher?.hasOwnProperty('isNewly')){
      apidata.method_type = 'Custom';
     apidata.method_id =SelectedVoucher.id;
     return apidata
    }else if(SelectedVoucher?.hasOwnProperty('isNewly')){
      apidata.method_type ="Wallet";
      return apidata;
    }
  }
},[discount,SelectedVoucher,price])


useEffect(()=>{
    setPayments((prev:any)=>{ 
        const newFormattedData = PAssData();
        if (!newFormattedData || newFormattedData === undefined) {
          return prev;
        }
        if(prev?.length>0){
          const indexToUpdate = prev?.findIndex(
              (item: any) => item?.player_id === newFormattedData?.player_id
            );
            // If an object with the same id is found, update its data
            if (indexToUpdate !== -1) {
              const updatedState = [...prev];
              updatedState[indexToUpdate] = newFormattedData;
              return updatedState;
            } else if (prev.length !== 0) {
              return [...prev, newFormattedData];
            } 
        }
        else {
          return [newFormattedData];
        }
      })
},[PAssData,discount,SelectedVoucher,setPayments]);

const customerINFODetials = {
  id:item?.customer_id,
  ...item?.customer
}

const handleDiscountChange =(event:any)=>{
  if(Number(event.target.rawValue)===price || Number(event.target.rawValue)<price){
    setdiscount(event.target.rawValue);
  }else{
    setNotification({isShow:true,message:"Refund price should be less than booking price",subMessage:'',type:'error'});
    setdiscount(price);
  }
}

useEffect(()=>{
  if(SelectedVoucher && SelectedVoucher?.balance && type==="Payments"){
    if(SelectedVoucher?.balance<price){
      setNotification({isShow:true,message:"Customer doesn’t have sufficient wallet credits",subMessage:'',type:'error'});
      setSelectedVoucher({});
    }
  }
},[SelectedVoucher]);


  return (
          <Box
            marginTop={2}
            sx={{alignItems: {xs:"baseline", sm:"center"},gap:{xs:"0px", sm:"2rem"}}}
          >
            <Box onClick={()=>setOpen(true)}
              sx={{
                display: "flex",
                alignItems: {xs:"baseline", sm:"center"},
                gap: {xs:"5px",sx:"15px"},
                ">img":{display:{xs:"none", sm:"block"}}
              }}
            >
              <img style={{width:'40px',height:'40px',borderRadius:'50%'}} src={item?.customer?.profile_url?item?.customer?.profile_url:"/images/Reserved.svg"}
              />
              <Box
                sx={{
                  display: "flex",
                  gap:{sm:'5px',xs:''},
                  flexDirection:'column',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "var(--font-regular)",
                    color: "#22356D",
                    textAlign: "left",
                  }}
                >
                {item?.customer?.first_name} {item?.customer?.last_name}
                </Typography>
                <Box sx={{ display:"flex",gap:"10px", marginBottom:'5px',flexDirection:{xs:"row", md:"row"}, flexWrap:'wrap',alignItems:{xs:"baseline"}}}>
                            {
                              item?.customer?.customerMemberships?.map((cust:any)=>{
                               return cust?.membership?.membership_name && <span key={cust?.id} style={{background:"#889AB626", border:"1px solid #889AB638", borderRadius:"4px", fontSize:"0.8rem",padding:'2px 6px', color:"#22356D"}}>{ cust?.membership?.membership_name}</span>})
                            }
                          </Box>
              </Box>
            </Box>
            <Box sx={{display:"flex", flexDirection:{xs:"column", sm:"row"}, alignItems:{xs:"end", sm:"center"}, gap:"5px", flex:1, justifyContent:{xs:"unset",sm:'space-between'},marginTop:'5px'}}>
            <FormControl>
              <Select
                IconComponent={KeyboardArrowDownIcon}
                placeholder="Customer type"
                value={
                  !!SelectedVoucher ? SelectedVoucher : "Payment Method"
                }
                id="customdropdown"
                renderValue={(value) => {
                  if (value?.method_name) {
                    return value?.method_name;
                  }
                  return <em>{type==="Payments"?"Payment Method":"Refund Method"}</em>;
                }}
                style={{
                  width: "180px",
                  height: "35px",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontSize: "14px",
                  opacity: "1",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                }}
                MenuProps={{ sx: dopdowncustomcss }}
              >
                {PaymentMethods?.map((location:any) => (
                  <MenuItem onClick={(e:any)=>setSelectedVoucher(location)} key={location.id} value={location}>
                    {location?.method_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="flex flex-row items-center gap-1">
              <p
                style={{
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-medium)",
                  fontSize: "14px",
                  lineHeight: "17px",
                }}
              >
              {type==="Payments"?"Discount: ":"Refund: "}
              </p>
              <Cleave
                value={discount}
                style={{
                  width: "100px",
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  lineHeight: "17px",
                  borderRadius: "7px",
                  padding: "5px 8px",
                  border: "1px solid #EEEFEE",
                }}
                onChange={handleDiscountChange}
                placeholder={type==="Payments"?"Discount":"Amount"}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter:'\u2024'
                }}
              />
            </div>

            <div className="flex flex-row items-center gap-1">
              <p
                style={{
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-medium)",
                  fontSize: "14px",
                  lineHeight: "17px",
                }}
              >
               {type==="Refunds"&&"Paid"} {SelectedLocation?.currency}
              </p>

              <Cleave
              readOnly={type==="Refunds" && true}
              onChange={(e:any)=>setPrice(e.target.rawValue)}
              value={price}
                style={{
                  width: "100px",
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  lineHeight: "17px",
                  borderRadius: "7px",
                  padding: "5px 8px",
                  border: "1px solid #EEEFEE",
                }}
                placeholder="100"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter:SelectedLocation?.currency==="IDR"?'\u2024':','
                }}
              />
            </div>
            </Box>
            {/* {open && <CustomerInfo hidebackdrop={false} GetAllCustomer={(data:any)=>console.log(data)} customerdata={customerINFODetials} customfield={customFields}  fromCustomer={true}
            show={open} setShow={setOpen}
            />} */}
          </Box>
)
}



export default PaymentsRow