import { Box, Button, FormControl, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useGetClubWalletOfCustomer } from "src/api/Customer";
import { useGetAllPaymentMethodsByCust } from "src/api/Voucher";
import { addThousandSeparator } from "src/constants/SportSEparator";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import MainContext from "src/context/MainContext";
import Cleave from "cleave.js/react";
import { useTranslation } from "react-i18next";
import { useGetAllProducts } from "src/api/POS";
import CheckboxTree from "./CustomCategorySelector";

const common = {
  display: "flex",
  alignItems: "center",
  gap: "10px",
};

const style = {
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  height: "37px",
  color:'#22356D'
};

interface walletTypes {
  currency: string;
  balance: number;
  id: number;
}

const ProductList = ({
  currentFields,
  AllCoupons,
  player_id,
  selectedLocationID,
  reset,
  splitIndex,
  index,
  register,
  splidata,
  PendingPlayers,
  setValue,
  unregister,
  setIsOpen,
}: {
  register: any;
  index: number;
  currentFields: any;
  reset: any;
  splitIndex: number;
  splidata: any;
  AllCoupons: any;
  player_id: number;
  PendingPlayers: any;
  selectedLocationID: number;
  setValue: any;
  unregister:any;
  setIsOpen:any;
}) => {
 
  const {data,isSuccess,isError,refetch} = useGetAllProducts();
  const {t} = useTranslation();
  const [AllProducts,setAllProducts] = useState<any[]>([]);
  const [selectedCoupon, setselectedCoupon] = useState<any>();
  const { Notification, setNotification } = useContext(MainContext);
  const [selectedItems, setSelectedItems] = useState<any>();



  const addVoucher = (index: number, splitIndex: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].products[splitIndex].showCoupon = true;
    // updatedPayments[index].products[splitIndex].coupon_id = ;
    reset({ payments: updatedPayments });
  };

  const removeVoucher = (index: number, splitIndex: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].products[splitIndex].showCoupon = false;
    updatedPayments[index].products[splitIndex].discount = 0;
    unregister(`payments.${index}.products.${splitIndex}.coupon_id`);
    setselectedCoupon('');
    reset({ payments: updatedPayments });
  };

  const addSplitPayment = (index: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].products.push({
      discount: 0,
      showCoupon: false,
    });
    reset({ payments: updatedPayments });
  };

  const handleRemoveSplit = (index: number, splitIndex: number) => {
    const updatedPayments = [...currentFields];
    updatedPayments[index].products.splice(splitIndex, 1);
    reset({ payments: updatedPayments });
  };


  const handleSelectCoupon = (data: any) => {
    if (data?.type === "Amount") {
      setValue(
        `payments.${index}.products.${splitIndex}.discount`,
        data?.discount
      );
    } else {
      const price = currentFields[index].products[splitIndex].price;
      const discount = (price * data?.discount) / 100;
      setValue(
        `payments.${index}.products.${splitIndex}.discount`,
        discount
      );
    }
    setselectedCoupon(data);
    setValue(
      `payments.${index}.products.${splitIndex}.coupon_id`,
      data?.id
    );
  };


  const handleSelectProduct = (data:any)=>{
    const price = data.price.replace(".00",'');
    setValue(`payments.${index}.products.${splitIndex}.price`,price);
    setValue(`payments.${index}.products.${splitIndex}.product_name`,data.product_name);
    setValue(`payments.${index}.products.${splitIndex}.product_id`,data.id);
    setSelectedItems(data);
  }

  useEffect(() => {
    if(isSuccess){
        setAllProducts(data)
    }else{
        setAllProducts([]);
    }
  }, [data,isSuccess,isError])

  const result = AllProducts?.filter((item:any)=>item?.locations?.some((loc:any)=>loc?.id===selectedLocationID))?.reduce((acc:any, product:any) => {
    const { category } = product;
    const categoryObj = acc.find((obj:any) => obj.title === category.category_name);

    const productDetails = {
        id: product.id,
        product_name: product.product_name,
        category_id: product.category_id,
        club_id: product.club_id,
        price: product.price,
        status: product.status,
    };

    if (categoryObj) {
        categoryObj.arr.push(productDetails);
    } else {
        acc.push({
            title: category.category_name,
            sequence: category.sequence,
            arr: [productDetails],
        });
    }

    return acc;
}, []);

const handleAddProduct =() =>{
  const updatedPayments = [...currentFields];
  updatedPayments[index].products = [
      ...updatedPayments[index].products,
      {
          price: 0,
          product_name: '',
          product_id: '',
          discount: 0,
          showCoupon: false
      }
  ];
  reset({ payments: updatedPayments });
}

result.sort((a:any, b:any) => a.sequence - b.sequence);

const formattedResult = result.map(({ title, arr }:{ title:any, arr:any }) => ({ title, arr }));
  
const isShowCoupon =   currentFields?.some((item:any)=>{
  return item?.products?.some((spli:any)=>spli?.showCoupon)
});


const handleDiscountChange = (value:number)=>{
  if(Number(value)>Number(splidata?.price)){
   setNotification({
     isShow: true,
     message: "Discount must be less than or equal to booking",
     subMessage: "",
     type: "error",
   });
   setValue(
     `payments.${index}.products.${splitIndex}.discount`,
     0
   )
  }else{
   setValue(
     `payments.${index}.products.${splitIndex}.discount`,
     value
   )
  }
 }

  return (
      <Box sx={{ width: "100%",marginTop:'24px' ,position:'relative'}}>
         {splitIndex!==0 &&  <Box sx={{position:'absolute',right:{sm:'-20px'},top:'12px',display:{xs:'none',sm:'block'}}} onClick={() => handleRemoveSplit(index, splitIndex)}>
              <RxCross2 style={{fontSize:'12px'}} />
            </Box>}
        <Box sx={{marginLeft:{xs:'none',sm:isShowCoupon && !splidata.showCoupon ?"250px":"0px"} }}>
          <Box
            sx={{
              position: "relative",
              ">p": {
                fontFamily: "var(--font-regular)",
                fontSize: "0.8rem",
                color: "#22356DB2",
              },
            }}
          >
           {splitIndex===0 && <Button
              sx={{ position: "absolute", left: {xs:"0.2rem", md:"0.2rem"}, top: {xs:"-2rem", md:"-2rem"}, fontFamily: "var(--font-regular)",
              color: "#22356D",textTransform:'capitalize' }}
              onClick={() => handleAddProduct()}
            >
               {t("POS.Add products")} +
            </Button>}
            <Button
              sx={{ position: "absolute", left: {xs:"0.2rem", md:"10.2rem"}, top: {xs:"2.4rem", md:"-2rem"}, fontFamily: "var(--font-regular)",
              color: "#22356D",textTransform:'capitalize' }}
              onClick={() => {splidata?.showCoupon ? removeVoucher(index,splitIndex):addVoucher(index, splitIndex)}}
            >
              {splidata?.showCoupon ? t("common.Cancel Coupon"):t("common.Add Coupon +")}
            </Button>
          </Box>
          {/* this below row to repeat when click on split payment */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              gap: {sm:"15px",xs:"10px"},
              marginBottom: "10px",
              ">div>p": { fontFamily: "var(--font-regular) !important" },
            }}
          >
            <CheckboxTree setisOpen={setIsOpen} splitIndex={splitIndex} inputValue={currentFields[index].products[splitIndex].product_name} selectedItem={selectedItems} setSelectedItem={handleSelectProduct} data={formattedResult}/>
            {/* will show when any one click on add coupon */}
            {splidata.showCoupon && (
              <Box sx={{ ...common, position: "relative", justifyContent:{xs:"space-between", md:"unset"}, width:{xs:"100%", md:"unset"}, marginTop:{xs:"10px", md:"0px"}, ">div>div":{width:{xs:"100%", md:"180px !important"}} }}>
                <Typography>{t('title.Coupon')}</Typography>
                <FormControl sx={{width:{xs:"100%", md:"unset"}}}>
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    placeholder="Customer type"
                    value={!!selectedCoupon ? selectedCoupon : "Select Coupon"}
                    id="customdropdown"
                    renderValue={(value) => {
                      if (value?.name) {
                        return value?.name;
                      }
                      return <em>{"Select Coupon"}</em>;
                    }}
                    sx={{
                      width: {xs:"100%", md:"180px"},
                      height: "35px",
                      border: "1px solid #EEEEFE",
                      borderRadius: "7px",
                      fontSize: "14px",
                      opacity: "1",
                      fontFamily: "var(--font-regular)",
                      color: "#22356D",
                    }}
                    MenuProps={{ sx: dopdowncustomcss }}
                  >
                    {AllCoupons?.map((location: any) => (
                      <MenuItem
                        onClick={(e: any) => handleSelectCoupon(location)}
                        key={location.id}
                        value={location.id}
                      >
                        {location?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <input type="text" placeholder='coupon' {...register(`payments.${index}.products.${splitIndex}.coupon_id`)} style={{...style, width:"155px"}} /> */}
              </Box>
            )}
            <Box sx={{...common, marginTop:{xs:"10px", md:"0px"}, justifyContent:{xs:"space-between", sm:"unset"}, width:{xs:"100%", md:"unset"}}}>
              <Typography>{t("common.Discount")}</Typography>
              <Cleave
                readOnly={splidata.showCoupon}
                value={splidata?.discount}
                placeholder={t("common.Discount")}
                onChange={(e:any)=>handleDiscountChange(e.target.rawValue)}
                style={{ ...style, width: "110px" }}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter: "\u2024",
                }}
              />
              
            </Box>
            <Box sx={{...common, width:{xs:"100%", md:"unset"}, justifyContent:{xs:"space-between", sm:"unset"}}}>
              <Typography>{t('common.Price')}</Typography>
              <Cleave
               value={currentFields[index].products[splitIndex].price}
                placeholder="price"
                // onChange={(e:any)=>
                //   setValue(
                //   `payments.${index}.products.${splitIndex}.price`,
                //   e.target.rawValue
                // )}
                style={{ ...style, width:"110px" }}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter: "\u2024",
                }}
              />
               {splitIndex!==0 &&  <Box sx={{display:{xs:'block',sm:'none'}}} onClick={() => handleRemoveSplit(index, splitIndex)}>
              <RxCross2 style={{fontSize:'12px'}} />
            </Box>}
            </Box>
          </Box>
          {/* ))} */}
        </Box>       
      </Box>
       );
};

export default ProductList;
