export default function validateWorkingHours(working_hours:any) {
    // Check if the length of working_hours is 7
    if (working_hours.length !== 7) {
        return true;
    }

    // Get the first object to compare with others
    const firstObj = working_hours[0];
    
    // Loop through other objects starting from the second one
    for (let i = 1; i < working_hours.length; i++) {
        const currentObj = working_hours[i];
        
        // Check if start_time, end_time, and break_times are equal to the first object
        if (currentObj.start_time !== firstObj.start_time ||
            currentObj.end_time !== firstObj.end_time ||
            arraysEqual(firstObj.break_times, currentObj.break_times)) {
            return true;
        }
    }

    // All conditions passed
    return false;
}

// Helper function to compare two arrays
const arraysEqual = (arr1:any, arr2:any) => {

    if (arr1.length !== arr2.length) return true;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].start_break !== arr2[i].start_break || arr1[i].end_break !== arr2[i].end_break) return true;
    }
    return false;
  };
  