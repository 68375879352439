import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { IoMdAdd } from "react-icons/io";
import { IoClose, IoMenu } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AddCustomerType } from "src/api/Customer";
import { createLocation, updatePayment, updateRegions } from "src/api/ClubAdmin";
import { useTranslation } from "react-i18next";
import { createCategory } from "src/api/POS";
import MainContext from "src/context/MainContext";

const style = {
  fontFamily: "var(--font-semibold)",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 30px",
  borderRadius: "7px",
};
const commonStyle = { display: "flex", alignItems: "center", gap: "7px" };
const inputStyle = {
  border: "1px solid #EEEEFE",
  width: "100%",
  padding: "7px 0px 7px 12px",
  marginTop: "15px",
  fontSize: "14px",
  color: "#22356D",
};

const EditFieldModel = ({
  text,
  arr,
  editField,
  getAllcustomertype,
  open,
  setOpen,
  addRegion,
}: {
  text?: string;
  arr?:any;
  editField: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  addRegion?: any;
  getAllcustomertype:any;
}) => {
  const {t} = useTranslation();
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("body");
  const { reset } = useForm();
  const [array, setArray] = React.useState<string[]>(arr);

  const handleClose = () => {
    setOpen(false);
  };
  
  React.useEffect(()=>{
    const final = arr?.map((item:any)=>item.name)
    setArray(final)
  },[arr])

  const handleAdd = () => {
    setArray(prevArray => [...prevArray, ""]);
  };


// remove
const handleRemove = (index: number) => {
    setArray(prevArray => prevArray.filter((_, idx) => idx !== index));
};


const handleSubmit = async(e:any)=>{
  e.preventDefault();
  const APIdata:any= {
    customer_types:array?.map((item:any,index:number)=>{
    const obj ={
      "name":item,
      "sequence":index+1,
    }
  return obj

    })
  }
  const response = await AddCustomerType(APIdata)

  if(response.data.status === "success"){
  handleClose();
  getAllcustomertype();
  }
}


  useEffect(() => {
    reset();
  }, []);



    // updation for particuklar sport
    const handleUpdate = (i:number,event:any)=>{
      const updatedData = [...array];
      updatedData[i] = event.target.value;
      setArray(updatedData)
    }


  // on drag updatedata
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return; // Dragged outside the list, do nothing
    }

    const { source, destination } = result;

    if (source.index === destination.index) {
      return; // Item dropped in the same position, do nothing
    }

    const updatedItems = Array.from(array);
    const [removed] = updatedItems.splice(source.index, 1); // Remove the item from its original position
    updatedItems.splice(destination.index, 0, removed); // Insert the item into the new position

    setArray(updatedItems);
    // Update state or perform any necessary actions with the updatedItems array
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        ".MuiDialog-paper": {
          width: "630px !important",
          maxWidth: "820px !important",
        },
      }}
    >
      <DialogContent>
        <Box>
          <form onSubmit={handleSubmit}>
            <Typography sx={{ fontFamily: "var(--font-medium)" }}>
              {text ? t(`common.${text}`) : `${editField.label} settings`}
            </Typography>
            <Typography
              onClick={handleAdd}
              sx={{
                ...commonStyle,
                cursor: "pointer",
                fontFamily: "var(--font-regular)",
                padding: "10px 0px 10px 10px",
                width: "fit-content",
              }}
            >
              {text?.includes("Regions")
                ? t("common.Regions")
                : text?.includes("Payment")
                ? t("common.Payment Methods")
                : t("common.Add new customer type")}{" "}
              &nbsp; &nbsp;{" "} 
              {(text?.includes("Regions") && t('common.Add region')) ||
                (text?.includes("Payment") && t("common.Add payment method"))}{" "}
              <IoMdAdd style={{ cursor: "pointer" }} />
            </Typography>
            <Box sx={{ paddingLeft: "20px" }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {array?.map((item: any, index: any) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              sx={{ ...commonStyle, marginBottom: "10px" }}
                              key={index}
                            >
                              <IoMenu
                                style={{ color: "#22356D", fontSize: "1.5rem" }}
                              />
                              <input
                                onChange={(e) => handleUpdate(index, e)}
                                value={item}
                                type="text"
                                placeholder={t("common.Enter a label")}
                                style={{
                                  ...inputStyle,fontFamily:'var(--font-regular)',
                                  marginTop: "0px !important",
                                }}
                              />
                              <IoClose
                                style={{ cursor: "pointer", width: "20px" }}
                                onClick={() => handleRemove(index)}
                              />
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={2}
            >
              <button style={{ ...style, color: "#22356D" }} type="button">
                {t('common.Delete')}
              </button>
              <button
                style={{ ...style, color: "white", background: "#22356D" }}
                type="submit"
              >
                {t('common.Save')}
              </button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditFieldModel;



export const EditRegionModel = ({
  text,
  regionName,
  arr,
  editField,
  open,
  setOpen,
  type,
  updateLocationName,
  updateLocationList
}: {
  text?: string;
  arr?:any;
  regionName:any;
  editField: any;
  open: boolean;
  type:string;
  setOpen: (open: boolean) => void;
  updateLocationList:any;
  updateLocationName:any;
}) => {
  const {t} = useTranslation();
  const {setNotification} = useContext(MainContext);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("body");
  const [array, setArray] = React.useState<string[]>(arr);

  const handleClose = () => {
    setOpen(false);
  };
  
  React.useEffect(()=>{
    if(type=="region"){
      const final = arr?.map((item:any)=>item.region_name)
      setArray(final)
    }else if(type=="location"){
      const final = arr?.map((item:any)=>item.location_name)
      setArray(final)
    }else if(type=="category"){
      const final = arr?.map((item:any)=>item.category_name)
      setArray(final)
    }
   
  },[arr])

  const handleAdd = () => {
    setArray(prevArray => [...prevArray, ""]);
  };


// remove
const handleRemove = (index: number) => {
    setArray(prevArray => prevArray.filter((_, idx) => idx !== index));
};


const handleSubmit= async(e:any)=>{
  e.preventDefault();
  let APIdata:any;
 if(type==="region"){
  APIdata={
    regions:array?.map((item:any,index:number)=>{
    const obj ={
      "region_name":item,
      "sequence":index+1,
    }
  return obj
    })}

   try {
    const response = await updateRegions(APIdata);
    regionName();
    setOpen(false);
    setNotification({isShow:true,type:'success',message:response.message,subMessage:''});
   } catch (error:any) {
    setNotification({isShow:true,type:'error',message:error,subMessage:''});
   }
   

 }else if(type==="location"){
  APIdata={
    locations:array?.map((item:any,index:number)=>{
    const obj ={
      "location_name":item,
      "sequence":index+1,
    }
  return obj})}


  try {
    const response = await createLocation(APIdata);
    updateLocationName();
    updateLocationList();
    setOpen(false);
    setNotification({isShow:true,type:'success',message:response.message,subMessage:''});
  } catch (error:any) {
    setNotification({isShow:true,type:'error',message:error,subMessage:''});
  }

 

 }else if(type==="category"){
  APIdata={
    product_categories:array?.map((item:any,index:number)=>{
    const obj ={
      "category_name":item,
      "sequence":index+1,
    }
  return obj})}


 try {
  const response = await createCategory(APIdata);
  updateLocationName();
  updateLocationList();
  setOpen(false);
  setNotification({isShow:true,type:'success',message:response.message,subMessage:''});  
 } catch (error:any) {
  setNotification({isShow:true,type:'error',message:error,subMessage:''});  
 }
 
 }
  
}



// updation for particuklar sport
const handleUpdate = (i:number,event:any)=>{
    const updatedData = [...array];
    updatedData[i] = event.target.value;
    setArray(updatedData)
  }


  // on drag updatedata
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return; // Dragged outside the list, do nothing
    }

    const { source, destination } = result;

    if (source.index === destination.index) {
      return; // Item dropped in the same position, do nothing
    }

    const updatedItems = Array.from(array);
    const [removed] = updatedItems.splice(source.index, 1); // Remove the item from its original position
    updatedItems.splice(destination.index, 0, removed); // Insert the item into the new position

    setArray(updatedItems);
    // Update state or perform any necessary actions with the updatedItems array
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        ".MuiDialog-paper": {
          width: "630px !important",
          maxWidth: {xs:"auto", sm:"820px !important"},
        },
      }}
    >
      <DialogContent>
        <Box>
          <form onSubmit={handleSubmit}>
            <Typography sx={{ fontFamily: "var(--font-medium)" }}>
              {text ? t(`common.${text}`) : `${editField.label} settings`}
            </Typography>
            <Typography
              onClick={handleAdd}
              sx={{
                ...commonStyle,
                cursor: "pointer",
                fontFamily: "var(--font-regular)",
                padding: "10px 0px 10px 10px",
                width: "fit-content",
              }}
            >
              {text?.includes("Regions")
                ? t("common.Regions")
                : text?.includes("Location")
                ? t("common.Locations")
                : t("common.Add new label")}{" "}
              &nbsp; &nbsp;{" "}
              {(text?.includes("Regions") && t("common.Add region")) ||
                (text?.includes("Location") && t("common.Add Location"))}{" "}
              <IoMdAdd style={{ cursor: "pointer" }} />
            </Typography>
            <Box sx={{ paddingLeft: "20px" }}>
              {/* {editField.options?.map((item: any, index: number) => (
                <Box sx={{ ...commonStyle, marginBottom: "10px" }} key={index}>
                  <IoMenu style={{ color: "#22356D", fontSize: "1.5rem" }} />
                  <input
                    type="text"
                    onChange={(e: any) => handleUpdate(index, e)}
                    value={item}
                    style={{ ...inputStyle, marginTop: "0px !important" }}
                  />
                </Box>
              ))} */}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {array?.map((item: any, index: any) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              sx={{ ...commonStyle, marginBottom: "10px" }}
                              key={index}
                            >
                              <IoMenu
                                style={{ color: "#22356D", fontSize: "1.5rem" }}
                              />
                              <input
                                onChange={(e) => handleUpdate(index, e)}
                                value={item}
                                type="text"
                                placeholder={t("common.Enter a label")}
                                style={{
                                  ...inputStyle,fontFamily:'var(--font-regular)',
                                  marginTop: "0px !important",
                                }}
                              />
                              <IoClose
                                style={{ cursor: "pointer", width: "20px" }}
                                onClick={() => handleRemove(index)}
                              />
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>

              {/* {array.map((item, index) =>
                                <Box sx={{ ...commonStyle, marginBottom: "10px" }} key={index}>
                                    <IoMenu style={{ color: "#22356D", fontSize: "1.5rem" }} />
                                    <Controller
                                        name={`options[${index}]`}
                                        control={control}
                                        defaultValue={item}
                                        render={({ field }) => (
                                        <input {...field} type='text' placeholder='Enter a label' style={{ ...inputStyle, marginTop: "0px !important" }} />
                                        )}
                                    />
                                    <IoClose style={{ cursor: "pointer" }} onClick={() => handleRemove(index)} />
                                </Box>
                            )} */}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              marginTop={2}
            >
              <button
                style={{ ...style, color: "white", background: "#22356D" }}
                type="submit"
              >
               {t("common.Save")}
              </button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};



export const EditPaymentType = ({
  locationID,
  text,
  arr,
  editField,
  open,
  setOpen,
  addRegion,
  updatePayments
}: {
  text?: string;
  locationID:number;
  arr?:any;
  editField: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  addRegion?: any;
  updatePayments:any;
}) => {
  const {t} = useTranslation();
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("body");
  const [array, setArray] = React.useState<string[]>(!!arr?.length ?arr:[]);

  const handleClose = () => {
    setOpen(false);
  };
  
  React.useEffect(()=>{
    const final = arr?.map((item:any)=>item?.method_name)
    setArray(final)
  },[arr])

  const handleAdd = () => {
    setArray((prevArray:any) => !!prevArray?.length ?[...prevArray, ""]:['']);
  };


// remove
const handleRemove = (index: number) => {
    setArray(prevArray => prevArray.filter((_, idx) => idx !== index));
};


const handleSubmit= async(e:any)=>{
  e.preventDefault();
  const APIdata:any= {
    payment_methods:array?.map((item:any,index:number)=>{
    const obj ={
      "method_name":item,
      "sequence":index+1,
    }
  return obj

    })
  }
  const response = await updatePayment(locationID,APIdata)
  if(response.data.status === "success"){
    updatePayments();
  handleClose();
  }
}


// updation for particuklar sport
const handleUpdate = (i:number,event:any)=>{
    const updatedData = [...array];
    updatedData[i] = event.target.value;
    setArray(updatedData)
  }


  // on drag updatedata
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return; // Dragged outside the list, do nothing
    }

    const { source, destination } = result;

    if (source.index === destination.index) {
      return; // Item dropped in the same position, do nothing
    }

    const updatedItems = Array.from(array);
    const [removed] = updatedItems.splice(source.index, 1); // Remove the item from its original position
    updatedItems.splice(destination.index, 0, removed); // Insert the item into the new position

    setArray(updatedItems);
    // Update state or perform any necessary actions with the updatedItems array
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        ".MuiDialog-paper": {
          width: {xs:'auto', sm:"630px !important"},
          maxWidth: {xs:"auto", sm:"820px !important"},
        },
      }}
    >
      <DialogContent>
        <Box>
          <form onSubmit={handleSubmit}>
            <Typography sx={{ fontFamily: "var(--font-medium)" }}>
              {text ? t(`common.${text}`) : `${editField.label} settings`}
            </Typography>
            <Typography
              onClick={handleAdd}
              sx={{
                ...commonStyle,
                cursor: "pointer",
                fontFamily: "var(--font-regular)",
                padding: "10px 0px 10px 10px",
                width: "fit-content",
              }}
            >
              {text?.includes("Regions")
                ? t("common.Regions")
                : text?.includes("Payment")
                ? t("common.Payment Methods")
                : t("common.Add new label")}{" "}
              &nbsp; &nbsp;{" "}
              {(text?.includes("Regions") && t("common.Add region")) ||
                (text?.includes("Payment") && t("common.Add payment method"))}{" "}
              <IoMdAdd style={{ cursor: "pointer" }} />
            </Typography>
            <Box sx={{ paddingLeft: "20px" }}>
              {/* {editField.options?.map((item: any, index: number) => (
                <Box sx={{ ...commonStyle, marginBottom: "10px" }} key={index}>
                  <IoMenu style={{ color: "#22356D", fontSize: "1.5rem" }} />
                  <input
                    type="text"
                    onChange={(e: any) => handleUpdate(index, e)}
                    value={item}
                    style={{ ...inputStyle, marginTop: "0px !important" }}
                  />
                </Box>
              ))} */}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {array?.map((item: any, index: any) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              sx={{ ...commonStyle, marginBottom: "10px" }}
                              key={index}
                            >
                              <IoMenu
                                style={{ color: "#22356D", fontSize: "1.5rem" }}
                              />
                              <input
                                onChange={(e) => handleUpdate(index, e)}
                                value={item}
                                type="text"
                                placeholder={t("common.Enter a label")}
                                style={{
                                  ...inputStyle,
                                  marginTop: "0px !important",
                                }}
                              />
                              <IoClose
                                style={{ cursor: "pointer", width: "20px" }}
                                onClick={() => handleRemove(index)}
                              />
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>

              {/* {array.map((item, index) =>
                                <Box sx={{ ...commonStyle, marginBottom: "10px" }} key={index}>
                                    <IoMenu style={{ color: "#22356D", fontSize: "1.5rem" }} />
                                    <Controller
                                        name={`options[${index}]`}
                                        control={control}
                                        defaultValue={item}
                                        render={({ field }) => (
                                        <input {...field} type='text' placeholder='Enter a label' style={{ ...inputStyle, marginTop: "0px !important" }} />
                                        )}
                                    />
                                    <IoClose style={{ cursor: "pointer" }} onClick={() => handleRemove(index)} />
                                </Box>
                            )} */}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={2}
            >
              <button style={{ ...style, color: "#22356D" }} type="button">
                {t('common.Delete')}
              </button>
              <button
                style={{ ...style, color: "white", background: "#22356D" }}
                type="submit"
              >
                {t('common.Save')}
              </button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
