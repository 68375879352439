import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  typography: {
    // fontFamily: 'Ciutadella', // Add your font fallbacks here
    fontSize: 14,
    fontWeightLight: 400,
    allVariants: {
      color: "#22356D", // Replace 'desired-color' with your color value
    },
  },
});

export default theme;
