import { Box, Button, IconButton, InputBase, Paper, Stack, Typography } from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart';
import React, { useEffect, useState } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { IoMdTrendingUp } from "react-icons/io";
import { IoMdTrendingDown } from "react-icons/io";
import { GrSearch } from 'react-icons/gr';

const dummuyData = [
    {
      name:"Normal Customer",
      percentage:"48%"
    },
    {
      name:"Black Card",
      percentage:"23%"
    },
    {
      name:"White Card",
      percentage:"16%"
    },
    {
      name:"Kitas",
      percentage:"13%"
    },
    {
      name:"Normal Customer",
      percentage:"48%"
    }
]

const fontStyle = {display:"flex", alignItems:"center", gap:"10px",fontSize:"1.1rem", fontFamily:"var(--font-regular)", color:"#22356DB2"};
const badge = {display:"block",width:"11.87px", height:"8px", borderRadius:'3px'};
const scrollText = {...fontStyle, gap:"3px", FontSize:"1rem"};
const scroll = {display:"flex", flexDirection:"column", height:"100%", maxHeight:"125px", gap:"15px",borderRadius:"7px", background:'white', padding:"10px 15px"};

const CustomerDashboard = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [view, setView] = useState('Laptop');

    const handleWheel = (event:any) => {
        // if(view!=="Mobile"){
        //     const delta = event.deltaY;
        //     if(delta>0){
        //       if(currentIndex<22){
        //         setCurrentIndex((prev:number) => prev + 1)
        //       }
        //     }else{
        //       if(currentIndex>0){
        //         setCurrentIndex((prev:number) => prev - 1)
        //       }
        //     }
        // }else{
        //     return;
        // }
        return;
        
    }

    useEffect(()=>{
        if (window.matchMedia('(max-width: 600px)').matches ) {
            setView("Mobile");
            // transform: {xs:"none", sm:`translateX(-${currentIndex * 10}%)`}
        }else{
            setView("Laptop")
        }
    },[]);

    return (
        <Box sx={{paddingTop:{xs:'20px', sm:"0px"},marginBlock:{xs:"0px", sm:"20px"}}}>
            {/* horizontal scroll bar */}
            <Box  sx={{display:"grid", gridAutoRows:"126px", gridTemplateColumns:{xs:"auto", sm:"repeat(5, 1fr)"}, alignItems:'center', gap:"20px",
            overflow: 'hidden'}} onWheel={handleWheel}>
                {/* Box 1 */}
                <Box sx={{...scroll, gap:"10px"}}>
                    <Box sx={{color:"#22356DB2 !important", height:"35px", ">p":{lineHeight:"19px"}}}>
                        <Typography sx={{...fontStyle, fontSize:"18px"}}>Total Customers</Typography>
                        <Typography sx={{...scrollText, fontSize:"1rem"}}><BsFillInfoCircleFill style={{fontSize:"0.8rem"}} /> All time</Typography>
                    </Box>
                    <Box sx={{height:"45px"}}>
                        <Typography sx={{...fontStyle,fontFamily:"var(--font-medium)", fontSize:"1.565rem"}}>12.500</Typography>
                        <Typography></Typography>
                    </Box>
                </Box>
                {/* Box 2 */}
                <Box sx={{...scroll, gap:"10px"}}>
                    <Box sx={{color:"#22356DB2 !important", height:"35px", ">p":{lineHeight:"19px"}}}>
                        <Typography sx={{...fontStyle, fontSize:"18px"}}>Active Customers</Typography>
                        <Typography sx={{...scrollText, fontSize:"1rem"}}><BsFillInfoCircleFill style={{fontSize:"0.8rem"}} /> Active last 30 days</Typography>
                    </Box>
                    <Box sx={{height:"45px"}}>
                        <Typography sx={{...fontStyle, fontSize:"1.565rem"}}>361</Typography>
                        <Typography sx={{...scrollText, fontSize:"1rem"}}><IoMdTrendingUp style={{fontSize:'16px',color:'#42A731'}} /> <span style={{color:"#42A731", fontFamily:"var(--font-semibold)"}}>62%</span> &nbsp; compared to last period</Typography>
                    </Box>
                </Box>
                {/* Box 3 */}
                <Box sx={{...scroll, gap:"10px"}}>
                    <Box sx={{color:"#22356DB2 !important", height:"35px", ">p":{lineHeight:"19px"}}}>
                        <Typography sx={{...fontStyle, fontSize:"18px"}}>New Customers</Typography>
                        <Typography></Typography>
                    </Box>
                    <Box sx={{height:"45px"}}>
                        <Typography sx={{...fontStyle, fontSize:"1.565rem"}}>142</Typography>
                        <Typography sx={{...scrollText, fontSize:"1rem"}}><IoMdTrendingDown style={{fontSize:'16px',color:'#F14F4A'}} /> <span style={{color:"#F14F4A", fontFamily:"var(--font-semibold)"}}>62%</span> &nbsp; compared to last period</Typography>
                    </Box>
                </Box>
                {/* Box 4 */}
                <Box sx={{...scroll, gap:"10px"}}>
                    <Box sx={{color:"#22356DB2 !important", height:"35px", ">p":{lineHeight:"19px"}}}>
                        <Typography sx={{...fontStyle, fontSize:"18px"}}>Returning Customers</Typography>
                    </Box>
                    <Box sx={{height:"45px"}}>
                        <Typography sx={{...fontStyle, fontSize:"1.565rem"}}>87</Typography>
                        <Typography sx={{...scrollText, fontSize:"1rem"}}><IoMdTrendingUp style={{fontSize:'16px',color:'#42A731'}} /> <span style={{color:"#42A731", fontFamily:"var(--font-semibold)"}}>12%</span> &nbsp; compared to last period</Typography>
                    </Box>
                </Box>
                {/* Box 5 */}
                <Box sx={{...scroll, gap:"10px"}}>
                    <Box sx={{color:"#22356DB2 !important", height:"35px", ">p":{lineHeight:"19px"}}}>
                        <Typography sx={{...fontStyle, fontSize:"18px"}}>Average spent by customers</Typography>
                    </Box>
                    <Box sx={{height:"45px"}}>
                        <Typography sx={{...fontStyle, fontSize:"1.565rem"}}>312.000 IDR</Typography>
                        <Typography sx={{...scrollText, fontSize:"1rem"}}><IoMdTrendingDown style={{fontSize:'16px',color:'#F14F4A'}} /> <span style={{color:"#F14F4A", fontFamily:"var(--font-semibold)"}}>8%</span> &nbsp; compared to last period</Typography>
                    </Box>
                </Box>
                {/* Box 6 - pie chart */}
                <Box sx={{position:"relative",width:{xs:"100%", sm:"288px !important"},display:"grid", gridTemplateColumns:"repeat(3, 1fr)", gap:"3px", background:"white", borderRadius:"7px", marginTop:"0px !important", height:"130px !important", boxShadow:"none !important", maxHeight:"125px", padding:"10px 15px"}} id="table-person">
                    <Box sx={{gridColumn:'1 / 3'}}>
                        {dummuyData.map((item, index)=><Box sx={{display:"flex", gap:"5px", alignItems:"center"}} key={index+1}>
                        <span style={{...badge, background:"#22356D"}}></span>
                        <p>{item.percentage}</p>
                        <p>{item.name}</p>
                        </Box>)}
                    </Box>
                    <Box sx={{position:"sticky", top:"18px", right:"0", bottom:"0", height:'82px'}}>
                        <PieChart
                        colors={["#22356D", "#22356D99", "#22356D33", "#00000033"]} // 
                        series={[
                            {
                            data:[{ label: 'Bookings', value: 40 },
                            { label: 'Events', value: 30 },
                            { label: 'Events', value: 20 },
                            { label: 'Lessons', value: 10 }],
                            innerRadius: 22,
                            outerRadius: 39,
                            paddingAngle: 2,
                            cornerRadius: 4,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 34,
                            cy: 34,
                            } 
                        ]}
                        width={80}
                        height={80}
                        tooltip={{trigger:'none'}}
                        slotProps={{
                            legend: { hidden: true },
                        }}
                        />
                    </Box>
                </Box> 
            </Box>
            {/* end of horizontal scroll bar */}

            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Paper
                component="form"
                sx={{ background:"transparent !important", p: '2px 4px', display: 'flex', alignItems: 'center', boxShadow: '0px 4px 4px 0px #0000000D inset ', borderRadius:"7px !important", width:{xs:"100%", md:"250px"}, height:"33px", marginBlock:"20px"}}
                >
                    <InputBase 
                        sx={{ ml: 1, flex: 1, color:'#22356D99', fontFamily:"var(--font-regular)"}}
                        className="placeholder"
                        placeholder="Search Customer"
                        inputProps={{ 'aria-label': 'search' }}
                        value={''}
                        // onChange={handleInputChange}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" >
                        <GrSearch style={{fontSize:'15px', color:'#9ea6bf'}} />
                    </IconButton>
                </Paper>
                <Button sx={{display:{xs:"none", sm:"block"}, width:"150px",height:"37px",border:'1px solid #C6CBDA', borderRadius:"7px", background:"white", padding:"5.5px 20px", fontSize:"0.8rem", color:'#22356D99'}}>Export to CSV</Button>    
            </Stack>
            <Box sx={{background:'white', borderRadius:"7px", boxShadow:"4px 4px 4px 0px #0000000D"}}>
                <Box sx={{display:"grid", gridTemplateColumns:{xs:"repeat(2, 1fr)", sm:"repeat(5, 1fr)"}, gap:"20px", ">div":{textAlign:"center", padding:'11px 0px 8px 28px', color:'#22356DB2', fontFamily:"var(--font-medium)"}}}>
                    <Box sx={{textAlign:"left !important"}}>Full Name</Box>
                    <Box sx={{display:{xs:"none", sm:'block'}}}>Total Hours Booked</Box>
                    <Box sx={{display:{xs:"none", sm:'block'}}}>Total N° Bookings</Box>
                    <Box>Total Amount Spent</Box>
                    <Box sx={{display:{xs:"none", sm:'block'}}}>Last Booking</Box>
                </Box>   
                <Box sx={{">div:nth-child(odd)":{background:"#889AB61A"}}}>
                    {[1,2,3,4,5,6,7,8,9].map((item)=>
                        <Box sx={{display:"grid", gridTemplateColumns:{xs:"repeat(2, 1fr)", sm:"repeat(5, 1fr)"}, gap:"20px", ">div":{textAlign:"center", padding:"11px 0px 8px 28px"}}}>
                            <Box sx={{textAlign:"left !important"}}>Jay</Box>
                            <Box sx={{display:{xs:"none", sm:'block'}}}>6.0</Box>
                            <Box sx={{display:{xs:"none", sm:'block'}}}>6</Box>
                            <Box>Rp 0</Box>
                            <Box sx={{display:{xs:"none", sm:'block'}}}>31/12/2024</Box>
                        </Box>   
                    )} 
                </Box>
            </Box>
        </Box>
    )
}

export default CustomerDashboard;