import { Box, Button, Dialog, DialogContent, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { GoPlus } from 'react-icons/go';
import LessonsCoachRow from './LessonsCoachRow';
import CoachSettings from './CoachSettings';
import filterBySport from 'src/constants/SportSEparator';
import { useGetAllCoachesServices} from 'src/hooks/useGetAllCoachesServices';
import { useGetAllLessonsServices } from 'src/hooks/useGetAllLessonsService';
import { useTranslation } from 'react-i18next';


const LessonsRight = ({sports,selectedSport,selectedLocation}:{sports:any,selectedSport:any,selectedLocation:any}) => {
    const [showCoachSetting,setshowCoachSetting] = useState(false);
    const [CoachID, setCoachID] = useState<number>();
    const { t } = useTranslation();
    const [sport_id, setsport_id] = useState<number>();
    const [CoachData,setCoachData] = useState<any>([]);
    const [AllLesson,setAllLesson] = useState<any>([]);
    const {data:LessonData,isSuccess:LessonSuccess,isError:LessonError,refetch:LessonRefetch,setSportId:SetSportID,setlocation_id:SetLocationID} = useGetAllLessonsServices();
    const {data,isSuccess,isError,refetch,setSportId,setlocation_id} = useGetAllCoachesServices();

    
    useEffect(() => {
      if(!!selectedLocation){
        setSportId(0);
        setlocation_id(selectedLocation?.id);
        SetLocationID(selectedLocation?.id);
        SetSportID(0);
      }
    }, [selectedLocation])

    
    useEffect(() => {
      if(isSuccess){
        getBookingDATA(data);
      }else{
        setCoachData([]);
      }
    }, [data,isSuccess,isError])

    useEffect(() => {
      if(isSuccess){
        setAllLesson(LessonData);
      }else{
        setAllLesson([]);
      }
    }, [LessonData,LessonSuccess,LessonError]);

    // console.log(AllLesson,'lessons')


    const getBookingDATA = async (response:any) => {
        const final = filterBySport(response);
        setCoachData(final);
      };
        
     
    const handleAddCoach = (sport_id: number) => {
      setCoachID(undefined);
      setsport_id(sport_id);
      setshowCoachSetting(true);
    };
  
  
    const updateCoach = (data:any) =>{
      setsport_id(undefined);
      setCoachID(data);
      setshowCoachSetting(true);
    }
  
    const handleCoachClose=()=>{
      setshowCoachSetting(false);
    }
  

  return (
        <Grid item xs={12} sm={12} md={6}>
            <Box sx={{display:{xs:"none", sm:"none", md:"block"}}}>
        <span
          style={{
            color: "#22356D",
            fontFamily: "var(--font-semibold)",
            fontSize: "16px",
            borderBottom: "1px solid #22356D",
          }}
        >
          {t('common.Coaches')}
        </span>
      </Box>
      {sports?.map((item: any) => {
        return selectedSport?.sport_name == item?.sport_name ||
          selectedSport?.sport_name == "All Sports" ? (
          <Box key={item?.id}>
            
            <h3
              style={{
                marginTop: "20px",
                color: "#22356D",
                fontFamily: "var(--font-semibold)",
                fontSize: "16px",
              }}
            >
              {item?.sport_name}
            </h3>
            <Button
              onClick={() => handleAddCoach(item.id)}
              className="items-center flex flex-row justify-normal"
              style={{
                padding: "0",
                width: "190px",
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                gap: "10px",
                textTransform: "unset",
              }}
            >
             {t('services.coach.Add new coach')}{" "}
              <GoPlus style={{ verticalAlign: "middle", fontSize: "14px" }} />
            </Button>
            <Box>
              <Box
                style={{
                  marginTop: "10px",
                  borderRadius: "7px",
                  boxShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.1)",
                  background:
                    "linear-gradient(0deg, rgba(238, 239, 238, 0.05) 0%, rgba(238, 239, 238, 0.05) 100%), #FFF",
                  border: "0.5px solid #EEEFEE",
                }}
              >
                <Box
                  id={ selectedSport?.sport_name == "All Sports"?"table-person":''}
                  className="overflow-hidden"
                  style={{
                    marginTop: "0px",
                    width: "-webkit-fill-available",
                    maxHeight: selectedSport?.sport_name == "All Sports"? "210px":'',
                    overflowY: "auto",
                  }}
                >
                  {CoachData[`${item.sport_name}`]?.map(
                    (item: any, index: number) => (
                      <div onClick={() => updateCoach(item?.id)} key={index} >
                        <LessonsCoachRow
                        AllLesson={AllLesson}
                          index={index}
                          key={index}
                          Data={item}
                        />
                      </div>
                    )
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          ""
        );
      })}

              <Dialog
              sx={{".MuiDialog-paper":{
                maxWidth:{xs:"325px",sm:"625px", md:"900px"}
              }}}
              onClose={handleCoachClose}
              open={showCoachSetting}>
                <DialogContent style={{marginTop:"0px",padding:"0px"}} id='table-person'>
                  <CoachSettings GetCoachData={refetch} handleCoachClose={handleCoachClose} selectedlocation={selectedLocation} sport_id={sport_id} Coach_id={CoachID}/>
                </DialogContent>
              </Dialog>
            </Grid>
  )
}

export default LessonsRight