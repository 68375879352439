import { Box, InputBase, Typography } from '@mui/material';
import React, { useState } from 'react';
import { LuSendHorizonal } from 'react-icons/lu';

const ChatApp = () => {

    const handleKeyPress = async (event: any) => {
        if (event.key === "Enter") {
          let data = {
            notes: event.target.value
          }
        //   if(Object.keys(events).length>0){
            // updateNotes(data);
        //   }
        }
    };

  return (
    <Box 
        sx={{
            boxShadow:"4px 4px 4px 0px #0000000D inset",
            border:"0.5px solid #EEEFEE",
            borderRadius:"7px",
            width:'100%',
            maxHeight:'535px',
            padding:'13px',
            marginTop:"0px !important"
        }}
        id="table-person"
    >
        <Box 
            sx={{
                display:"flex",
                justifyContent:"flex-start",
                marginBottom:"10px"
            }}
        >
            <Box 
                sx={{
                    boxShadow: "0px 4px 4px 0px #0000001A",
                    background:"#889AB61A",
                    width:"fit-content",
                    padding:"7px",
                    color:"#22356D",
                    ">div>p":{fontFamily:"var(--font-regular) !important"},
                    display:"flex",
                    gap:"15px",
                    borderRadius:"7px"
                }}
            >
                <Box>
                    <Typography>Mike Solana (Player)</Typography>
                    <Typography sx={{fontSize:"14px"}}>Hey there! I’m joining the match</Typography>
                </Box>
                <Box sx={{display:"flex", alignItems:"flex-end"}}>
                    <Typography sx={{color:"#22356D80", fontSize:"13px"}}>0:18</Typography>
                </Box>
            </Box>
        </Box>
        <Box 
            sx={{
                display:"flex",
                justifyContent:"flex-start",
                marginBottom:"10px"
            }}
        >
            <Box 
                sx={{
                    background:"#889AB61A",
                    width:"fit-content",
                    padding:"7px",
                    color:"#22356D",
                    ">div>p":{fontFamily:"var(--font-regular) !important"},
                    display:"flex",
                    gap:"15px",
                    borderRadius:"7px",
                    boxShadow: "0px 4px 4px 0px #0000001A"
                }}
            >
                <Box>
                    <Typography>Mike Solana (Player)</Typography>
                    <Typography sx={{fontSize:"14px"}}>Hey there! I’m joining the match</Typography>
                </Box>
                <Box sx={{display:"flex", alignItems:"flex-end"}}>
                    <Typography sx={{color:"#22356D80", fontSize:"13px"}}>0:18</Typography>
                </Box>
            </Box>
        </Box>
        <Box 
            sx={{
                display:"flex",
                justifyContent:"flex-end",
                marginBottom:"10px"
            }}
        >
            <Box 
                sx={{
                     boxShadow: "0px 4px 4px 0px #0000001A",
                    background:"#22356DB2",
                    width:"fit-content",
                    padding:"7px",
                    color:"white !important",
                    ">div>p":{fontFamily:"var(--font-regular) !important",color:"white !important",},
                    display:"flex",
                    gap:"15px",
                    borderRadius:"7px"
                }}
            >
                <Box>
                    <Typography>Mike Solana (Player)</Typography>
                    <Typography sx={{fontSize:"14px"}}>Hey there! I’m joining the match</Typography>
                </Box>
                <Box sx={{display:"flex", alignItems:"flex-end"}}>
                    <Typography sx={{color:"white", fontSize:"13px"}}>0:18</Typography>
                </Box>
            </Box>
        </Box>
        <Box 
        sx={{border: "1px solid #EEEFEE",borderRadius: "7px",marginTop:'10px'}} position={"relative"}
        >
            <InputBase
              sx={{
                flex: 1,
                width:{xs:'90%',sm:"95%"},
                pl: "10px",
                color: '#22356D',
                fontFamily: "var(--font-regular)",
              }}
              placeholder="Enter notes..."
              type="text"
            //   value={notes}
              onKeyDown={handleKeyPress}
            //   onChange={(e) => {
            //     setNotes(e.target.value);
            //   }}
            />
            <LuSendHorizonal style={{position:'absolute', right:'10px', top:"7px", cursor:"pointer", color:"#22356D"}}  />
        </Box>
    </Box>
  )
}

export default ChatApp