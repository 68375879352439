import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./payments.css";
import AddIcon from "@mui/icons-material/Add";
import CustomSelectCheckBox from "../../components/CustomSelectBox";
import MembershipModel from "./MemberShipModel";
import { useGetLocation } from "src/api/CourtSettings";
import { useTranslation } from "react-i18next";
import { GetAllServicesList } from "src/api/Voucher";
import { StructureTheData } from "./VoucherSettingsPopup";
import moment from "moment";
import { Duration } from "./DurationSelector";
import { getAllMemeberShip, getAllMemeberShipSales } from "src/api/membership";
import { addThousandSeparator } from "src/constants/SportSEparator";
import { FinalCalednarasdmnf } from "../../components/customTimeSeletor";
import { locationTypes } from "src/context/MainContext";
import StaffModal from "src/Notification/StaffModal";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

const commonStyle = {
  width: "150px",
  color: "#22356D",
  height: "32px",
  fontFamily: "var(--font-regular)",
};

const paymentsDesgin = {
  background:"white", 
  borderRadius:'7px', 
  padding:"20px 26px 20px 20px", 
  gap:'5px'
}

const justifyContent = {
  display:"grid",
  gridTemplateColumns:"repeat(4, 1fr)",
  fontFamily:"var(--font-regular)",
  color:"#22356DB2",
  gap:'30px'   
}

interface location {
  id:number
}


const Memberships = () => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const [data, setData] = useState({});
   const StaffPermission = useGetPermissions();
  const [Location,setLocation] = useState<any[]>([]);
  const [allServices,setAllServices] = useState<any>();
  const [AllSales,setAllSales] = useState<any[]>([]);
  const [selectedDay,setSelectedDays] = useState<any>({date_start:moment(new Date()).format('YYYY-MM-DD'),date_end:moment(new Date()).format('YYYY-MM-DD')});
  const [selectedLocation,setSelectedLocation] = useState<locationTypes>({ id: 0, location_name: "All locations",currency:"IDR" });
  const [AccountingLocation,setAccountingLocation] = useState<locationTypes | any>();
  const {data:locationdata,isError:locationerror,isSuccess:locationSucess} = useGetLocation();
  const [allMembership, setallMembership] = useState<any>([]);
  const [filterdMembership, setfilterdMembership] = useState<any>([]);
  const [AccountingFiltered, setAccountingFiltered] = useState<any>([]);
  const AllSalesTotal:any= [];

  // function to convert it into excel format
  const exportToCSV = () => {
      let csvContent = "Membership_Name, Duration, Services, Uses, Locations, Price,Total_Uses\n";

      allMembership.forEach((item: any) => {
         const { membership_name, duration,locations,uses_limit, bookings,lessons,events,price,totalUses } = item;
          let allLocation = locations.map((item:any)=> item.location_name).join("-");
          const serviceCount = bookings?.length +lessons?.length + events?.length;
          const allservicesCount = allServices?.Bookings?.length +allServices?.Lessons?.length + allServices?.Events?.length || 0
          const row = `${membership_name}, ${duration}, ${serviceCount+`/`+ allservicesCount}, ${totalUses},${allLocation},${price}, ${uses_limit}\n`;
          csvContent += row;
      });

      const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const csvURL = URL.createObjectURL(csvBlob);

      const link = document.createElement("a");
      link.href = csvURL;
      link.download = "Membership.csv";
      link.click();
  };

  // export to csv
  const handleExportButtonClick = () => {
      exportToCSV();
  };
  

  const getMembership = async () => {
      try {
          let res = await getAllMemeberShip();
          setallMembership(res);
      } catch (error) {
          console.log(error);
          setallMembership([]);
      }
  }

  const getServices = async() =>{
    try {
      const res =  await GetAllServicesList();
      // const finaldata = StructureTheData(res)
      setAllServices(res)
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getAllMembershipSales();
  }, [selectedDay])
  

  const getAllMembershipSales = async()=>{
    const response = await getAllMemeberShipSales(selectedDay);
    const filtered =response?.memberships?.map((item:any)=>{
      const {price,paymentMethod,membership,location} = item;
      const payment_method =paymentMethod?.method_name;
      return {price,payment_method,location:location?.location_name,membership_name:membership?.membership_name}

    })
    setAllSales(filtered)
  }

  //for membership
  useEffect(() => {
    if(selectedLocation.location_name!== "All locations"){
      const filtered = allMembership?.filter((item:any)=>item.locations?.some((loc:any)=>loc.id===selectedLocation.id))
      setfilterdMembership(filtered)
    } else{
      setfilterdMembership(allMembership)
    } 
    }, [selectedLocation,allMembership])
  
  //for accounting
  useEffect(() => {
    const filtered = allMembership?.filter((item:any)=>item.locations?.some((loc:any)=>loc.id===AccountingLocation.id))
    setAccountingFiltered(filtered)
  }, [AccountingLocation,allMembership])
  

  useEffect(()=>{
    getMembership();
      getServices();
  },[])

  useEffect(() => {
    if(locationSucess){
        setLocation(locationdata);
        setAccountingLocation(locationdata[0])
    }else{
        setLocation([])
    }
}, [locationdata,locationSucess,locationerror]);


const GetAllMembershipSales = (data:any,membership_name:string,paymentmethod:string) => {

const result  = data?.filter((item:any)=>item?.membership_name===membership_name && item?.payment_method?.toLowerCase() === paymentmethod?.toLowerCase())?.reduce((acc:any,item:any)=>acc + item?.price,0);

return result;

}

const TotalServices = (Locations:location[])=>{
  return allServices?.reduce((total:any, root:any) => total + root?.children?.filter((rot:any)=> Locations?.some((loc:any)=>loc?.id===rot?.location_id))?.length, 0);
 }

const totalChildrenCount = allServices?.reduce((total:any, root:any) => total + root?.children?.length, 0);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "baseline", md: "center" },
          marginBlock: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: "10px",
            alignItems: { xs: "baseline", md: "center" },
          }}
        >
          <CustomSelectCheckBox
            background={false}
            text={"Select location"}
            value={AccountingLocation}
            setValue={setAccountingLocation}
            arr={ Location}
          />
          <Box width={"201px"} position={'relative'}>
            <FinalCalednarasdmnf type={"range"} containercss={{width:'201px',borderRadius:'7px',backgroundColor:'white',padding:'0px 4px 0px 0px'}} icon={true} placeholder={"select days"} selectedDay={selectedDay} setSelectedDays={setSelectedDays} style={{width:"90%", height:"33px",color:"#22356D",fontFamily:"var(--font-regular)"}}/>
        </Box>
          <Button
            onClick={() => {
              setData({});
              setOpen(true);
            }}
            style={{
              color: "rgba(34, 53, 109, 0.70)",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",
              gap: "10px",
              paddingLeft: "0px",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            {t('payments.Add new Membership')}
            <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
          </Button>
        </Box>
       
        <Button
          className="mobile-res"
          style={{
            ...commonStyle,
            borderRadius: "7px",
            padding: "0px",
            border: "#C6CBDA 1px solid",
            opacity: "0.7",
            background: "white",
            textTransform: "capitalize",
          }}
          onClick={handleExportButtonClick}
        >
          {t('common.Export To CSV')}
        </Button>
      </Box>
      <Box sx={{display:{xs:'none', sm:"none", md:"block"},marginBottom:'20px'}}>
                  <Grid container gap={5} marginTop={2}>
                     <Grid item xs={7} sx={{...paymentsDesgin,padding:'20px'}}>
                        <Box sx={{...justifyContent,borderBottom:"1px solid #22356D26", fontFamily:"var(--font-medium)",padding:'5px 0'}}>
                           <p>{t('payments.Membership Name')}</p>
                           <p>{t('payments.Paid by Credit Card')}</p>
                           <p>{t('payments.Paid by Cash')}</p>
                           <p style={{textAlign:'right'}}>{t('payments.Total Sales')}</p>
                        </Box>
                        {AccountingFiltered?.map((item:any)=> {
                           const TotalSalesByCredit = GetAllMembershipSales(AllSales,item?.membership_name,"credit card");
                           const TotalSalesByCash = GetAllMembershipSales(AllSales,item?.membership_name,"cash");
                           const TotalSales = TotalSalesByCash+TotalSalesByCredit
                           AllSalesTotal.push(TotalSales);

                           return <Box sx={{...justifyContent, borderBottom:"1px solid #22356D12"}}>
                            <p>{item?.membership_name}</p>
                            <p>{addThousandSeparator(TotalSalesByCredit,AccountingLocation?.currency)} {AccountingLocation?.currency}</p>
                            <p>{addThousandSeparator(TotalSalesByCash,AccountingLocation?.currency)} {AccountingLocation?.currency}</p>
                            <p style={{textAlign:'right'}}>{addThousandSeparator(TotalSales,AccountingLocation?.currency)} {AccountingLocation?.currency}</p>
                         </Box>
                           }
                        )} 
                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between', fontFamily:"var(--font-medium)"}}>  
                           <p>Total</p>
                           <p>{addThousandSeparator(AllSalesTotal?.reduce((acc:any,sal:any)=>acc+sal,0),AccountingLocation?.currency)}</p>
                        </Box>

                     </Grid>
                  </Grid>
      </Box>
      <Box sx={{width:'180px',marginBottom:'20px'}}>
      <CustomSelectCheckBox
            background={false}
            text={"Select location"}
            value={selectedLocation}
            setValue={setSelectedLocation}
            arr={ [{id:0,location_name:'All Location'},...Location]}
          />
          </Box>
      <Box
        sx={{
          padding: { xs: "10px 0px", md: "10px 20px" },
          background: "white",
          borderRadius: "7px",
          boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
        }}
      >
        <table style={{ width: "100%" }} id="paymentTable">
          <thead className="couponHead">
            <tr>
              <th
                style={{ width: "18%", textAlign: "left", paddingLeft: "10px" }}
              >
               {t('payments.Membership Name')}
              </th>
              <th
                style={{ width: "12%", textAlign: "left" }}
                className="hide-mobile"
              >
                {t('services.bookings.Duration')}
              </th>
              <th
                style={{ width: "10%", textAlign: "left" }}
                className="hide-mobile"
              >
                {t('title.Services')}
              </th>
              <th style={{ width: "13%", textAlign: "left" }}>{t('payments.Uses per Customer')}</th>
              <th style={{ width: "15%", textAlign: "left" }}>{t('common.Locations')}</th>
              <th
                style={{ width: "12%", textAlign: "left" }}
                className="hide-mobile"
              >
                {t('common.Price')}
              </th>
              <th
                style={{ width: "13%", textAlign: "left" }}
                className="hide-mobile"
              >
                {t('payments.Total Uses')}
              </th>
              <th
                style={{ width: "5%", textAlign: "center" }}
                className="hide-mobile"
              >
                {t('common.Details')}
              </th>
            </tr>
          </thead>
          <tbody className="coupon">
            {filterdMembership.map((item: any) => (
              <tr>
                <td>{item.membership_name}</td>
                <td className="hide-mobile" style={{textTransform:'capitalize'}}>{Duration.some((dur:any)=>dur===item.duration)?item.duration:moment(item.duration).format('DD/MM/YYYY')}</td>
                <td className="hide-mobile">{item?.bookings?.length + item?.lessons?.length + item?.events?.length || 0}/{TotalServices(item?.locations) || 0}</td>
                <td>{item.uses_limit!==-1?item.uses_limit:"Unlimited"}</td>
                <td>{item?.locations?.length>1?`${item?.locations[0]?.location_name} + ${item?.locations?.length-1}`:item?.locations
                ?.map((loc:any)=><p>{loc.location_name} </p>)}</td>
                <td className="hide-mobile">{addThousandSeparator(item.price,item.currency)} {item.currency}</td>
                <td className="hide-mobile">{item.totalUses}</td>
                <td className="hide-mobile">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      width: "80px",
                      borderRadius: "7px",
                      padding: "5px 15px",
                      border: "1px solid #EEEFEE",
                      opacity: "0.9",
                      background: "var(--Main-Colors-White, #FFF)",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.07)",
                      fontFamily: "var(--font-regular)",
                      "&:hover": {
                        background: "#22356D !important",
                        color: "white !important",
                      },
                    }}
                    onClick={() => {
                      setData(item);
                      setOpen(true);
                    }}
                  >
                    {t('common.Details')}
                  </Typography>
                </td>
              </tr>
            ))}
            {filterdMembership.length == 0 && (
              <tr>
                <td className="hide-mobile"></td>
                <td className="hide-mobile"></td>
                <td></td>
                <td>{t('payments.No Membership Found')}</td>
                <td></td>
                <td className="hide-mobile"></td>
                <td className="hide-mobile"></td>
                <td className="hide-mobile"></td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>

      {!StaffPermission.edit_permissions && <StaffModal />}

      <MembershipModel allServices={allServices} data={data} open={open} handleClose={()=>setOpen(false)} locationdata={Location} update={getMembership} />
    </Box>
  );
};

export default Memberships;
