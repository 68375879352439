import { apiCaller } from "./ApiCaller";
import { getClubId } from "./CalendarBooking";


export const getAllCoupon = async () => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.get(`${club_id}/coupons`);
        return res.data.data;
    } catch (error:any) {
        let err = error.respone.data?.message;
        return err;
    }
}

interface Coupon{
    "name":string, 
    "allowBookings": boolean,
    "allowEvents": boolean,
    "allowLessons": boolean,
    "type": string,
    "discount": number,
    "status": string
  
}

export const createCoupon = async (data: Coupon) => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.post(`${club_id}/coupons`, data);
        return res;
    } catch (error:any) {
        let err = error.respone.data?.message;
        return err;
    }
}

export const GetCouponDiscount = async (date: any) => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.get(`${club_id}/coupons/coupon/discount?startDate=${date?.date_start}&endDate=${date?.date_end}`);
        return res?.data?.data;
    } catch (error:any) {
        let err = error.respone.data?.message;
        return err;
    }
}

export const updateCoupon = async (id:number, data: Coupon) => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.put(`${club_id}/coupons/${id}`, data);
        return res;
    } catch (error:any) {
        let err = error.respone.data?.message;
        return err;
    }
}

export const deleteCoupon = async (id:number) => {
    try {
          let club_id = getClubId();
        let res = await apiCaller.delete(`${club_id}/coupons/${id}`);
        return res;
    } catch (error:any) {
        let err = error.respone.data?.message;
        return err;
    }
}

