import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "../tableStyling.css";
import { IoAddOutline } from "react-icons/io5";
import CustomerInfo from "../../components/CustomerInfo";
import { useTranslation } from "react-i18next";

const AddPlayer = ({width,text,isPlayer, addPlayer, handleChange, showList,isneedEmail,maxHeight, setShowList, data, selectedUser,update}:{update?:any,text?:string, addPlayer:string, handleChange:any, showList:boolean, setShowList:(showList: boolean)=>void, data:any, selectedUser:any,isPlayer?:any,width?:any,isneedEmail?:any,maxHeight?:any}) => {
  const [open,setOpen] = useState<boolean>(false);
  const {t} = useTranslation();

  const handleClick = (event: any) => {
    event.stopPropagation();
    setShowList(true)
  }

  useEffect(()=>{},[data]);

  const handleClickAdd = (item:any) => {
    setOpen(true);
  };

  const handleUpdate=(data:any)=>{
    if(!data){
      update();      
      setOpen(data);
    }
  }

  return (
    <Box position={'relative'}>
      <Stack>
        <input type='text' value={addPlayer} placeholder={text?text:t("Calendar.Add Player")} className="addPlayer" onChange={handleChange} onClick={handleClick}/>
      </Stack>
      {showList && 
      <Box position={'absolute'} zIndex={'99'} sx={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", background:"white", width:width?width:"280px", marginTop:'0px !important', maxHeight:maxHeight?maxHeight:"273px"}} id="table-person">
        {isPlayer && 
        <Box onClick={handleClickAdd} sx={{display:'flex',flexDirection:'row',alignItems:'center',padding:"10px 15px",background:"#889AB61A", color:"#22356D", cursor:'pointer'}}>
          {t('Calendar.Create New')}{" "} <IoAddOutline/>
        </Box>}
        {data?.map((item:any)=>
          <Box sx={{padding:"10px 15px",background:"#889AB61A", color:"#22356D", "&:hover":{background:"#22356D", color:"white"},cursor:'pointer'}} onClick={()=>selectedUser(item)}>
            <p>{item.first_name} {item.last_name}</p>
           {!isneedEmail && <p>{item.email}</p>}
          </Box>
        )}
        {data.length==0 && <Box sx={{padding:"10px 15px",background:"#889AB61A", color:"#22356D", "&:hover":{background:"#22356D", color:"white"}}} >
          <p>{t('Calendar.No User Found')}</p>
        </Box>}
      </Box>}
      <CustomerInfo hidebackdrop={false} GetAllCustomer={(data:any)=>console.log(data)} customerdata={''} customfield={[]}  fromCustomer={false} show={open} setShow={(data:any)=>handleUpdate(data)} />
    </Box>
  )
}

export default AddPlayer;