import { Box, Button, Dialog, DialogContent, FormControl, MenuItem, Select, Stack, Tab, Typography } from '@mui/material'
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { useSSR, useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import { GetCustomerLevelByClub, GetCustomerLevelBySport, updateCustomerLevel, updateCustomerLevelByClub } from 'src/api/Customer';
import { activeColor } from 'src/constants/color';
import { dopdowncustomcss } from 'src/constants/dropdownCSS';
import { LevelRange } from 'src/constants/variables';
import { RxCross1 } from "react-icons/rx";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import MainContext from 'src/context/MainContext';
import StaffModal from 'src/Notification/StaffModal';
import { Permission, useGetPermissions } from 'src/api/CalendarBooking';

const commonStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  
  const style = {
    fontFamily: "var(--font-semibold)",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    padding: "7px 30px",
    borderRadius: "7px",
  };

const LevelModal = ({open,setOpen,userData,AllHistory,update,sport_id}:{open:boolean,setOpen:any,userData:any,AllHistory:any,update:any,sport_id:number}) => {
    const {t} = useTranslation();
    const {isPermissionOn,setIsPermissionOn,Notification,setNotification} =useContext(MainContext);
    const [Level,setLevel] = useState<any>('');
    const [value,setValue] = useState('User');
    const [openEdit,setOpenEdit] = useState(false);
    const [WarningShow,setWarningShow] = useState(false);
    const [MinLevel,setMinLevel] = useState<string>();
    const [MaxLevel,setMaxLevel] = useState<string>();
     const StaffPermission = useGetPermissions();


    useEffect(() => {
      getAllClucbsbbsbs();
      getLevelHistory();
    }, []);

    const getLevelHistory = async()=>{
      const response = await GetCustomerLevelBySport(userData?.id,sport_id);
      console.log(response,'resoponsnene');
    }

    const getAllClucbsbbsbs = async()=>{
      try {
        const response = await GetCustomerLevelByClub();
      setMaxLevel(String(response?.max_level));
      setMinLevel(String(response?.min_level));
      } catch (error) {
        setMaxLevel(String(7));
      setMinLevel(String(0));
      }
    }
    


    const handleSave = async()=>{
        const apiData = {
            level:Level
        }
        try {
            const response= await updateCustomerLevel(userData?.id,apiData);
            setWarningShow(false);
            setOpenEdit(false);
            update();
            setNotification({isShow:true,message:'Customer level updated successfully',subMessage:'',type:'success'});
        } catch (error:any) {
          setNotification({isShow:true,message:error,subMessage:'',type:'error'});
        }
    }

    const handleClubLevel = async()=>{
      if(StaffPermission?.role!=="OTHER"){
        setIsPermissionOn(true);
        setOpenEdit(false);
        return;
      }

        const apiDAta = {
            min_level:Number(MinLevel),
            max_level:Number(MaxLevel)
        }
        try {
            const response = await updateCustomerLevelByClub(apiDAta);
            setOpen(false);
            setNotification({isShow:true,message:'Club levels updated successfully',subMessage:'',type:'success'});
        } catch (error:any) {
          setNotification({isShow:true,message:error,subMessage:'',type:'error'});
        }
    }

    const handleSaveClick = ()=>{
      if(StaffPermission?.role!=="OTHER"){
        setIsPermissionOn(true);
        setOpenEdit(false);
      }else{
        setWarningShow(true);
      }
    }

    const handleSelectTab = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
      };

     
  return (
    <Dialog onClose={() => setOpen(false)} open={open}
    sx={{ ".MuiDialog-paper": { minWidth: "800px !important" } }}
    >
        <DialogContent>
          <Box padding={2}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "var(--font-medium)",
                    color: "#22356D",
                  }}
                >
                  Level Settings
                </Typography>
                <TabContext value={value}>
                <TabList
                  className="tbsssfasddasd"
                  onChange={handleSelectTab}
                  aria-label="tabs example"
                  TabIndicatorProps={{
                    sx: { backgroundColor: 'transparent' },
                  }}
                  sx={{
                    ".MuiTab-root": {
                      paddingBottom: "0px",
                      paddingInline: "10px",
                      textTransform: "none",
                    },
                    ".Mui-selected": {
                      color: "#22356D !important",
                      fontFamily: "var(--font-semibold)",
                    },
                  }}
                >
                  <Tab
                    label={t('customers.User Level Settings')}
                    value={'User'}
                    sx={{
                      fontSize:"1rem",
                      fontFamily: "var(--font-regular)",
                      color: "rgba(34, 53, 109, 0.60)",
                      borderBottom:value==="User" ? '2px solid #22356D':'2px solid white',
                      paddingBottom:"5px"
                    }}
                  />
                  <Tab
                    label={t('customers.Club Level Settings')}
                    value={'Club'}
                    sx={{
                      fontSize:"1rem",
                      fontFamily: "var(--font-regular)",
                      color: "rgba(34, 53, 109, 0.60)",
                      borderBottom:value==="Club" ? '2px solid #22356D':'2px solid white',
                    }}
                  />
                    </TabList>
                    <TabPanel value={"User"} sx={{ padding: "10px 0" }}>
                      <Box>
                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'20px',marginTop:'20px',justifyContent:'space-between'}}>
                          <Typography sx={{fontFamily:'var(--font-medium)',color:'#22356DB2',fontSize:'14px'}}>Current User Level</Typography>
                          <Typography sx={{fontFamily:'var(--font-medium)',color:'#22356DB2',fontSize:'14px', display:"flex", gap:"25px", alignItems:'center'}}><span>{AllHistory[0]?.level}</span> <Button onClick={()=>setOpenEdit(true)} sx={{color:'#22356DB2',fontFamily:'var(--font-regular)', boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",padding:'0px 6px',textTransform:'capitalize',marginLeft:'5px', border:"1px solid #EEEFEE", borderRadius:"7px"}}>Edit</Button></Typography>
                          
                        </Box>
                        <Box sx={{">p":{color:"#22356DB2 !important"},">div>p":{color:"#22356DB2 !important"}}}>
                          <Typography sx={{fontFamily:'var(--font-medium)',fontSize:'14px', marginTop:"15px",paddingLeft:"7px"}}>{t('customers.Ranking History')}</Typography>
                          <Box sx={{display:'grid',gridTemplateColumns:'1fr 2fr 1fr',gap:'40px',mx:'10px',mt:'5px',paddingLeft:"20px"}}>
                            <Typography sx={{fontFamily:'var(--font-medium)',color:'#22356D',fontSize:'14px'}}>Date and Time</Typography>
                            <Typography sx={{fontFamily:'var(--font-medium)',color:'#22356D',textAlign:'left',fontSize:'14px'}}>{t('customers.Made by')}</Typography>
                            <Typography sx={{fontFamily:'var(--font-medium)',color:'#22356D',textAlign:'right',fontSize:'14px'}}>Level</Typography>
                          </Box>
                          {/* next div */}
                          {
                            AllHistory?.map((item:any)=> {

                              const formatString = (str:string) => {
                                const parts = str?.split(' - ');
                                if (parts?.length > 1) {
                                  parts[parts?.length - 1] = parts[parts.length - 1].replace('_'," ").toLowerCase();
                                }
                                return parts?.join(' - ');
                              };

                              const log = formatString(item?.made_by);

                              return <Box sx={{display:'grid',gridTemplateColumns:'1fr 2fr 1fr',gap:'40px',mx:'10px',paddingLeft:"20px"}}>
                            <Typography sx={{fontFamily:'var(--font-regular)',color:'#22356D',fontSize:'14px'}}>{moment(item?.date).format('DD/MM/YYYY HH:mm')}</Typography>
                            <Typography sx={{fontFamily:'var(--font-regular)',color:'#22356D',textAlign:'left',fontSize:'14px', textTransform:"capitalize"}}>{log}</Typography>
                            <Typography sx={{fontFamily:'var(--font-regular)',color:'#22356D',textAlign:'right',fontSize:'14px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end',gap:'5px'}}>{item?.level} <RxCross1 style={{fontSize:'12px'}}/></Typography>
                            </Box>
                            })
                          }
                        
                        </Box>
                        </Box>
                      </TabPanel>
                      <TabPanel value={"Club"} sx={{ padding: "10px 0" }}>
                        <Box>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        marginTop={2}
                        marginBottom={4}
                        marginRight={"5rem"}
                      >
                        <img src="/images/info.svg" alt="info" />
                        <Typography
                          sx={{
                            color: "#22356DB2",
                            fontFamily: "var(--font-regular)",
                          }}
                        >
                    {t('customers.Default level is 0 - 7.0 but you can change this based on your club needs.')} 
                    </Typography>
                  </Stack>
                  <Stack sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'20px'}}>
                  <FormControl>
                    <Select
                    value={MinLevel?MinLevel:"Select Min Level"}
                    renderValue={(selected) => {
                      if (selected) {
                        return `${selected}`;
                      }
                      return <em>select minLEvel</em>;
                    }}
                    onChange={(e:any)=>setMinLevel(e.target.value)}
                      id="customdropdown"
                      style={{
                        height: "27px",
                        border: "1px solid #EEEEFE",
                        borderRadius: "7px",
                        fontSize: "14px",
                        opacity: "1",
                        fontFamily: "var(--font-regular)",
                        color: "#22356D",
                        width:'150px'
                      }}
                      MenuProps={{ sx: {...dopdowncustomcss, '& .Mui-selected::after': {content:'none'}}}}
                      IconComponent={FaAngleDown}
                    >
                      {LevelRange?.map((day:string) => (
                        <MenuItem
                          style={{
                            fontSize: "14px",
                            opacity: "1",
                            fontFamily: "var(--font-regular) !important",
                            color: "#22356D",
                          }}
                          key={day}
                          value={day}
                        >
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
              <Select
              value={MaxLevel?MaxLevel:"Select Max Level"}
              renderValue={(selected) => {
                if (selected) {
                  return `${selected}`;
                }
                return <em>select MaxLevel</em>;
              }}
              onChange={(e:any)=>setMaxLevel(e.target.value)}
                id="customdropdown"
                style={{
                  height: "27px",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontSize: "14px",
                  opacity: "1",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                  width:'150px'
                }}
                MenuProps={{ sx: {...dopdowncustomcss, '& .Mui-selected::after': {content:'none'}}}}
                IconComponent={FaAngleDown}
              >
                {LevelRange?.map((day:string) => (
                  <MenuItem
                    style={{
                      fontSize: "14px",
                      opacity: "1",
                      fontFamily: "var(--font-regular) !important",
                      color: "#22356D",
                    }}
                    key={day}
                    value={day}
                  >
                    {day}
                  </MenuItem>
                ))}
              </Select>
                  </FormControl>
                  </Stack>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        marginTop={2}
                      >
                        <button
                          style={{ ...style, color: 'white',background:activeColor }}
                          onClick={handleClubLevel}
                        >
                          {t('common.Save')}
                        </button>
                      </Box>
                      </TabPanel>
                    </TabContext>
                    </Box>
                  </Box>

            {/* customer Edit  */}
            <Dialog sx={{ ".MuiDialog-paper": { minWidth: "400px !important" }}} onClose={() => setOpenEdit(false)} open={openEdit}>
            <DialogContent>
            <Box padding={2}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "var(--font-medium)",
                    color: "#22356D",
                    marginRight: "5rem",
                  }}
                >
                 {t('customers.Edit User Level')}
                </Typography>
                <Stack
                 sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginTop:'5px'}}
                >
                  <Typography
                    sx={{
                      color: "#22356DB2",
                      fontFamily: "var(--font-regular)",
                    }}
                  >
                   {t('customers.New Level')}
                  </Typography>
                  <input
                    value={Level}
                    type="text"
                    placeholder={'Enter Level'}
                    onChange={(e:any)=>setLevel(e.target.value)}
                    style={{
                    height: "33px",
                    width: "160px",
                    border: "1px solid #EEEEFE",
                    borderRadius: "7px",
                    }}
                />
                </Stack>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  marginTop={2}
                >
                  <button
                    onClick={handleSaveClick}
                    style={{
                      ...style,
                      color: "white",
                      background: activeColor,
                    }}
                  >
                    {t('common.Save')}
                  </button>
                </Box>
              </Box>
            </Box>
        </DialogContent>
           </Dialog>
           {/* Warning  */}
          {StaffPermission?.role!=="OTHER" ? <StaffModal/>:
                <Dialog onClose={() => setWarningShow(false)} open={WarningShow}>
              <DialogContent>
                {/* ---- for delete user ---- */}
                  <Box padding={2}>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "var(--font-medium)",
                          color: "#22356D",
                          marginRight: "5rem",
                        }}
                      >
                        {t('customers.Are you sure you want to edit this customer level?')}
                      </Typography>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        marginBottom={4}
                        marginRight={"5rem"}
                      >
                        <img src="/images/info.svg" alt="info" />
                        <Typography
                          sx={{
                            color: "#22356DB2",
                            fontFamily: "var(--font-regular)",
                          }}
                        >
                          {t('customers.This will change the user level. ')}{" "}
                        </Typography>
                      </Stack>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        marginTop={2}
                      >
                        <button
                          style={{ ...style, color: activeColor }}
                          // onClick={() => setDeletePl(false)}
                        >
                          {t('common.Cancel')}
                        </button>
                        <button
                          onClick={handleSave}
                          style={{
                            ...style,
                            color: "white",
                            background: activeColor,
                          }}
                        >
                          {t('customers.Yes, proceed')}
                        </button>
                      </Box>
                    </Box>
                  </Box>
              </DialogContent>
                </Dialog>}
              </DialogContent>
            </Dialog>
    )
}

export default LevelModal