import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Bookings from "../components/Services/Bookings";
import Lessons from "../components/Services/Lessons";
import Events from "../components/Services/Events";
import { useGetLocation } from "src/api/CourtSettings";
import CustomTabComponent from "../components/CustomTabComponent";
import CustomSelectCheckBox from "../components/CustomSelectBox";
import { useSportQuery } from "src/hooks/useSportQuery";
import { RxHamburgerMenu } from "react-icons/rx";
import MainContext from "src/context/MainContext";
import LessonsLeft from "../components/Services/LessonsLeft";
import LessonsRight from "../components/Services/LessonsRight";
import NotificationToast from "src/Notification/NotificationToast";
import { useTranslation } from "react-i18next";
import TopUserInfo from "../components/TopUserInfo";
import StaffModal from "src/Notification/StaffModal";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

const Services = () => {
  const [view, setView] = useState([]);
   const StaffPermission = useGetPermissions();
  const { t } = useTranslation();
  const [tab, selectedTab] = useState<any>([]);
  const [viewSelection, setViewSelection] = useState<any>(view[0]);
  const [tabValue, setTabValue] = useState<any>();
  const { data, isSuccess, isError, refetch } = useGetLocation();
  const [selected, setSelected] = useState(1);
  const { MobileSideBar, setMobileSideBar } = React.useContext(MainContext);
  const {
    data: sportData,
    isError: sportError,
    refetch: sportRefetch,
    isSuccess: sportSuccess,
    setlocation_id, 
  } = useSportQuery();


  //lcoation
  useEffect(() => {
    if (isSuccess) {
      getLoccation(data);
      setlocation_id(data[0]?.id);
    }
  }, [data, isSuccess, isError]);

  useEffect(() => {
    setlocation_id(viewSelection?.id);
  }, [viewSelection]);

  const getLoccation = async (response: any) => {
    setView(response);
    setViewSelection(response[0]);
    getSportById(response[0]?.id);
  };

  useEffect(() => {
    if (sportSuccess) {
      getSportById(sportData);
    } else {
      selectedTab([]);
      setTabValue("");
    }
  }, [sportData, sportSuccess, sportError]);

  const getSportById = async (response: any) => {
    if (!!response?.length) {
      const newtab = {
        id: 0,
        sport_name: "All Sports",
      };
      selectedTab([newtab, ...response]);

      setTabValue(newtab);
    }
  };

  const openMobileSidebar=(event:any)=>{
    event.stopPropagation();
    setMobileSideBar(true)
  }

  return (
    <div
      style={{ paddingBottom: "100px", minHeight: "100vh", overflowX: "auto" }}
    >
      <TopUserInfo/>
       <NotificationToast/>
      <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
        <Box
          sx={{
            display: { xs: "block",sm:"block", md: "none" },
            marginTop: { xs: "64px",sm:"64px", md: "0" },
            marginLeft: { xs: "10px",sm:"10px", md: "0" },
            cursor:"pointer"
          }}
          onClick={openMobileSidebar}
        >
          <RxHamburgerMenu className="MobileHamburger" />
        </Box>
        <h3 className="headingasdfsdf" style={{ width: "100%" }}>
          {t('title.Services')}
        </h3>
      </Box>
      {
        <Grid
          container
          sx={{
            width: "100%",
            height: "100%",
            paddingTop: { xs: "0px",sm:"0px", md: "20px" },
            marginTop: "10px",
            paddingLeft: "20px",
            fontFamily: "var(--font-regular) !important",
          }}
          spacing={1}
        >
          <Box width={"100%"}>
            <Grid container flexDirection={"column"} xs={12}>
              <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"center"}
                sx={{ gap: { xs: "10px",sm: "10px", md: "0px" } }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={2}
                  className='filter'
                >
                  <img src="/images/view1.png" style={{width:"22px", height:"22px"}} alt="calender" />
                  <CustomSelectCheckBox
                    background={true}
                    text={"Select location"}
                    value={viewSelection}
                    setValue={setViewSelection}
                    arr={view}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={10}>
                  <CustomTabComponent
                    type={"service"}
                    getSportById={sportRefetch}
                    locationid={viewSelection?.id}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    tabTextArray={tab}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* for above mobile view only */}
            <Box sx={{ display: { xs: "none", sm: "none", md:"block" } }}>
              <Grid container spacing={"25px"} sx={{ marginTop: "20px" }}>
                <Grid item xs={3}>
                  <Bookings
                    sports={tab.slice(1, tab?.length)}
                    selectedLocation={viewSelection}
                    selectedSport={tabValue}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Lessons
                    sports={tab.slice(1, tab?.length)}
                    selectedLocation={viewSelection}
                    selectedSport={tabValue}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Events
                    sports={tab.slice(1, tab?.length)}
                    selectedLocation={viewSelection}
                    selectedSport={tabValue}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* for mobile view only */}
            <Box
              sx={{
                display: { xs: "block", sm: "block", md:"none" },
                background: "white",
                borderRadius: "7px",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  padding: "20px",
                  paddingBottom: "0px",
                  ">div": { paddingBottom: "3px" },
                }}
              >
                {["Bookings", "Lessons", "Coaches", "Events"].map(
                  (item, index) => (
                    <Box
                      sx={{
                        color: selected == index + 1 ? "#22356D" : "#22356D99",
                        borderBottom:
                          selected == index + 1 ? "1px solid #22356D" : "none",
                      }}
                      onClick={() => setSelected(index + 1)}
                    >
                      {item}
                    </Box>
                  )
                )}
              </Box>
              <Box sx={{ padding: "20px", paddingTop: "0px" }}>
                {selected == 1 && (
                  <Box>
                    <Bookings
                      sports={tab.slice(1, tab?.length)}
                      selectedLocation={viewSelection}
                      selectedSport={tabValue}
                    />
                  </Box>
                )}
                {selected == 2 && (
                  <Box>
                    <LessonsLeft
                      selectedLocation={viewSelection}
                      sports={tab.slice(1, tab?.length)}
                      selectedSport={tabValue}
                    />
                  </Box>
                )}
                {selected == 3 && (
                  <Box>
                    <LessonsRight
                      selectedLocation={viewSelection}
                      sports={tab.slice(1, tab?.length)}
                      selectedSport={tabValue}
                    />
                  </Box>
                )}
                {selected == 4 && (
                  <Box>
                    <Events
                      sports={tab.slice(1, tab?.length)}
                      selectedLocation={viewSelection}
                      selectedSport={tabValue}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      }
       {!StaffPermission.edit_permissions && <StaffModal />}
    </div>
  );
};

export default Services;
