import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import "./payments.css";
import AddIcon from "@mui/icons-material/Add";
import CustomSelectCheckBox from '../../components/CustomSelectBox';
import CouponModel from './CouponModel';
import { getAllCoupon, GetCouponDiscount } from 'src/api/Coupon';
import { useGetLocation } from 'src/api/CourtSettings';
import { useTranslation } from 'react-i18next';
import { FinalCalednarasdmnf } from '../../components/customTimeSeletor';
import moment from 'moment';
import { addThousandSeparator } from 'src/constants/SportSEparator';
import StaffModal from 'src/Notification/StaffModal';
import { Permission, useGetPermissions } from 'src/api/CalendarBooking';

const commonStyle = {
    width: '150px',
    color: "#22356D",
    height: "32px",
    fontFamily: "var(--font-regular)",
}

interface location{
    id:number,
    location_name:string,
    currency:string
}

const Coupon = () => {
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();
    const [data, setData] = useState({});
     const StaffPermission = useGetPermissions();
    const [allCoupon, setAllCoupon] = useState<any>([]);
    const [filteredCoupon,setFilteredCoupon] = useState<any[]>([]);
    const [selectedDay,setSelectedDays] = useState<any>({date_start:moment(new Date()).format('YYYY-MM-DD'),date_end:moment(new Date()).format('YYYY-MM-DD')});
    const [Location,setLocation] = useState<any[]>([]);
    const [Discount,setDisount] = useState<any>([]);
    const [selectedLocation,setSelectedLocation] = useState<location>({ id: 0, location_name: "All locations",currency:"IDR" });
    const {data:locationdata,isError:locationerror,isSuccess:locationSucess} = useGetLocation();

    // function to convert it into excel format
    const exportToCSV = () => {
        let csvContent = "Coupon_Name, Services, locations, Discount, Status, Total_Users\n";
    
        allCoupon.forEach((item: any) => {
            const { name, allowBookings, allowEvents, allowLessons,locations,type, discount, status, usage_count } = item;
            let value:any = `${discount}` +" "+ `${type}`;
            let allLocation = locations.map((item:any)=> item.location_name).join("-");
            const row = `${name}, ${getServicesName({allowBookings,allowLessons,allowEvents})},${allLocation}, ${value}, ${status}, ${usage_count}\n`;
            csvContent += row;
        });
    

        const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        const csvURL = URL.createObjectURL(csvBlob);
    
        const link = document.createElement("a");
        link.href = csvURL;
        link.download = "Coupon.csv";
        link.click();
    };
    
    // export to csv
    const handleExportButtonClick = () => {
        exportToCSV();
    };

    const getServicesName = (value:any) => {
        const allServices = [
            { key: 'allowBookings', name: 'Bookings' },
            { key: 'allowEvents', name: 'Events' },
            { key: 'allowLessons', name: 'Lessons' },
            { key: 'allowProducts', name: 'Products' }
        ];
    
        const selectedServices = allServices.filter(service => value[service.key]);
    
        if (selectedServices.length === allServices.length) {
            return ["All Services"];
        } else {
            return selectedServices?.length >0 ? selectedServices?.length >1? `${selectedServices[0]?.name} + ${selectedServices?.length-1}`:selectedServices[0]?.name:"-";
        }
    }

    function getLocationNames(arr1:any, arr2:any) {
        let arrId = arr2.map((item:any)=>item.id);
        let filteredLocations= arr1.filter((location:any) => arrId.includes(location.id));
        
        let val = filteredLocations.map((location:any) => location.location_name);
        if(val.length==arr1.length){
            return "All Locations";
        }else if(val.length<3){
            return val.join(", ");
        }else{
            let value:any = `${val[0]}` +" + "+ `${val.length-1}`;
            return value;
        }
    }

    const getCoupon = async () => {
        try {
            let res = await getAllCoupon();
            setAllCoupon(res);
        } catch (error) {
            console.log(error);
            setAllCoupon([]);
        }
    }

    const getAllDiscount  = async()=>{
        const response = await GetCouponDiscount(selectedDay);
        setDisount(response)
    }

    useEffect(() => {
        if (!allCoupon) return;
      
        const filtered = allCoupon?.filter((coupon: any) =>
          coupon?.locations?.some((item: any) => 
            selectedLocation?.id === 0 || item?.id === selectedLocation?.id
          )
        );
      
        const mergedData = filtered.map((coupon: any) => {
          const discountInfo = Discount?.[coupon.id] || { totalDiscount: 0 };
          return {
            ...coupon,
            ...discountInfo,
          };
        });
      
        setFilteredCoupon(mergedData);
      }, [selectedLocation, allCoupon, Discount]);
      

    useEffect(() => {
       getAllDiscount()
    }, [selectedDay])
    


    useEffect(()=>{
            getCoupon();
    },[]);

    useEffect(() => {
        if(locationSucess){
            setLocation(locationdata)
        }else{
            setLocation([])
        }
      
    }, [locationdata,locationSucess,locationerror])
    

    return (
        <Box>
            <Box sx={{display:"flex", justifyContent:'space-between', alignItems:{xs:"baseline", md:"center"}, marginBlock:"20px"}}>
                <Box sx={{display:'flex', flexDirection:{xs:"column", md:'row'}, gap:"10px", alignItems:{xs:"baseline", md:"center"}}}>
                    <CustomSelectCheckBox 
                        background={false} 
                        text={"Select location"} 
                        value={selectedLocation} 
                        setValue={setSelectedLocation} 
                        arr={[{ id: 0, location_name: "All locations" }, ...Location]} 
                    />
                <Box width={"201px"} position={'relative'}>
                    <FinalCalednarasdmnf type={"range"} containercss={{width:'201px',borderRadius:'7px',backgroundColor:'white',padding:'0px 4px 0px 0px'}} icon={true} placeholder={"select days"} selectedDay={selectedDay} setSelectedDays={setSelectedDays} style={{width:"90%", height:"33px",color:"#22356D",fontFamily:"var(--font-regular)"}}/>
                </Box>
                    <Button
                        onClick={() => {setData({}); setOpen(true)}}
                        style={{
                            color: "rgba(34, 53, 109, 0.70)",
                            fontFamily: "var(--font-regular)",
                            fontSize: "14px",
                            gap: "10px",
                            paddingLeft: "0px",
                            textTransform: "none",
                            display:'flex',
                            alignItems:"center"
                        }}
                    >
                        {t('common.Add new coupon')}
                        <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
                    </Button>
                </Box>
                <Button className="mobile-res" 
                style={{
                    ...commonStyle,
                    borderRadius:'7px',
                    padding:'0px',
                    border:'#C6CBDA 1px solid',
                    opacity:'0.7',
                    background:'white',
                    textTransform:'capitalize'
                }}
                onClick={handleExportButtonClick}
                >
                    {t('common.Export To CSV')}
                </Button>
            </Box>
            <Box
            sx={{
            padding: {xs:"10px 0px", md:"10px 20px"},
            background: "white",
            borderRadius: "7px",
            boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
            }}
        >
            <table style={{ width: "100%" }} id="paymentTable">
                <thead className="couponHead">
                    <tr>
                        <th style={{width:"15%", textAlign:'left', paddingLeft:"10px"}}>{t('payments.Coupon Name')}</th>
                        <th style={{width:"15%", textAlign:'left'}} className='hide-mobile'>{t('title.Services')}</th>
                        <th style={{width:"15%", textAlign:'left'}} className='hide-mobile'>{t('common.Locations')}</th>
                        <th style={{width:"15%", textAlign:'left'}}>{t('common.Discount')}</th>
                        <th style={{width:"12%", textAlign:'left'}}>{t('common.Status')}</th>
                        <th style={{width:"12%", textAlign:'left'}} className='hide-mobile'>{t('payments.Applied')}</th>
                        <th style={{width:"13%", textAlign:'left'}}>{t('payments.Total Discount')}</th>
                        <th style={{width:"10%", textAlign:'center'}} className='hide-mobile'>{t('common.Details')}</th>
                    </tr>
                </thead>
                <tbody className='coupon'>
                    {filteredCoupon?.length > 0 ? filteredCoupon.map((item:any)=>
                    <tr>
                        <td>{item.name}</td>
                        <td className='hide-mobile'>{getServicesName(item)}</td>
                        <td className='hide-mobile'>{getLocationNames(Location, item?.locations)}</td>
                        <td>{addThousandSeparator(item.discount,item?.currency||"IDR")} {item.type==="Percentage" ? "%" : item?.currency}</td>
                        <td>
                            <span style={{background:"#889AB626", borderRadius:'7px', color:"#22356DB2", padding:"5px 8px"}}>{item.status}</span>
                        </td>
                        <td className='hide-mobile'>{item.usage_count} {t('common.times')}</td>
                        <td className='hide-mobile'>{item.totalDiscount}</td>
                        <td className='hide-mobile'>
                            <Typography
                                sx={{
                                cursor:'pointer',
                                width:'80px',
                                borderRadius: "7px",
                                padding: "5px 15px",
                                border: "1px solid #EEEFEE",
                                opacity: "0.9",
                                background: "var(--Main-Colors-White, #FFF)",
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.07)",
                                fontFamily:'var(--font-regular)',
                                "&:hover":{
                                    background:"#22356D !important",
                                    color:"white !important"}
                                }}
                                onClick={()=>{
                                    setData(item);
                                    setOpen(true);
                                }}
                            >
                                {t('common.Details')}
                            </Typography>
                        </td>
                    </tr>
                    ):<tr>
                    <td className='hide-mobile'></td>
                    <td className='hide-mobile'></td>
                    <td></td>
                    <td>{t('common.No Coupon Found')}</td>
                    <td></td>
                    <td></td>
                    <td className='hide-mobile'></td>
                    <td className='hide-mobile'></td>
                </tr>}
                </tbody>
            </table>
            </Box>

            {!StaffPermission.edit_permissions && <StaffModal />}

            <CouponModel data={data} open={open} handleClose={()=>setOpen(false)} locationdata={Location} update={getCoupon} />
        </Box>
    )
}

export default Coupon