import Box from '@mui/material/Box';
import { t } from 'i18next';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { addThousandSeparator } from 'src/constants/SportSEparator';
import { locationTypes } from 'src/context/MainContext';

const VoucherRow = ({data,index,alllocations}:{data?:any,index?:any,alllocations:any}) => {
    const {t} = useTranslation(); 
    const firststyle = {color:'rgba(34, 53, 109,1)',fontFamily:"var(--font-regular)",fontSize:'16px'}
    const secondStyle = {color:'rgba(34, 53, 109,1)',fontFamily:"var(--font-regular)",fontSize:'16px'}
    const thirdStyle = {color:'rgba(34, 53, 109,1)',fontFamily:"var(--font-regular)",fontSize:'14px'}

    const selectedloc = alllocations?.filter((loc:locationTypes)=>data?.locations?.some((item:any)=>item?.id===loc?.id));

    const currency = selectedloc[0]?.currency ||"IDR"
  
  return (
    <div style={index%2==0 ? {backgroundColor:'rgba(136, 154, 182, 0.10)',padding:'8px 10px'}:{backgroundColor:'transparent',padding:'8px 10px'}} className=''>
      <Box sx={{display:{xs:"block", sm:"block", md:"flex"}}} justifyContent={"space-between"} alignItems={"center"} margin={'5px'} >
        <Box>
          <p style={firststyle}>{data.voucher_name} </p>
        </Box>
        <Box sx={{textAlign:{xs:"right", sm:"right", md:"right"}}}>
          <p style={secondStyle}>{t('common.Price')}: 
          {addThousandSeparator(data.price,currency)} {currency}</p>
          <p style={secondStyle}>{t('payments.Value')}: 
          {addThousandSeparator(data.value,currency)} {currency}
          </p>
          <p style={thirdStyle}>{data.locations.length === alllocations.length-1 ?"All Locations" :   selectedloc.map((item:any)=><span key={item.id} style={{margin:'0px 3px',textTransform:'capitalize'}}>{item.location_name}</span>)}</p>
        </Box>
      </Box>
    </div> 
  )
}

export default VoucherRow
