import moment from "moment";

function getDateAtSpecificTime(date:any, timeString:string) {
    // Parse the time string to extract hour and minute
    const [hour, minute] = timeString?.split(':').map(Number);
    // Create a moment object from the provided date
    let momentDate = moment(date);
    // Set the time to the specified hour and minute
    momentDate.set({ hour: hour, minute: minute || 0, second: 0, millisecond: 0 });
    // Convert the moment object to a JavaScript Date object

    return momentDate.toDate()
  }

  export {getDateAtSpecificTime};