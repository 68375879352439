import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { getClubId, getInternalNotes, getLogs } from "src/api/CalendarBooking";
import { apiCaller } from "src/api/ApiCaller";
import moment from "moment";
import { addThousandSeparator } from "src/constants/SportSEparator";
import { locationTypes } from "src/context/MainContext";
import { useTranslation } from "react-i18next";
 
interface BookedSlotPopoverProps {
  eventType: any;
  anchorEl: HTMLElement | null; 
  handlePopoverClose: () => void;
  coachTiming?:any;
  selected?:any;
  viewType:string;
  SelectedLocation:locationTypes;
}

const commonStyle = {
  fontSize: "13px",
  color: "#22356D",
  fontFamily:'var(--font-regular) !important'
}

const booking = {
  cancelled:"repeating-linear-gradient( 131deg, #EBECFD99, #EBECFD99 10px, #EBECFD99 10px, #F3F4FEB2 10px, #F3F4FEB2 10px, #F3F4FEB2 20px ) !important",
  active:"#EBECFD99"
}

const lesson = {
  cancelled:"repeating-linear-gradient( 131deg,#FFFDD199,#FFFDD199 10px,#FFFDD199 10px, #FFFEE3B2 10px, #FFFEE3B2 10px, #FFFEE3B2 20px ) !important",
  active:"#FFFDD199"
}

const event = {
  cancelled:"repeating-linear-gradient( 131deg, #FEF6F099, #FEF6F099 10px, #FEF6F099 10px, #FEF6F0B2 10px, #FEF6F0B2 10px, #FEF6F0B2 20px ) !important",
  active:"#FDF0E699"
}

const CoachPopover: React.FC<BookedSlotPopoverProps> = ({
  eventType,
  anchorEl,
  coachTiming,
  selected,
  viewType,
  SelectedLocation,
  handlePopoverClose
}) => {
  const {t} = useTranslation();
  const open = Boolean(anchorEl);
  const [selectedDay,setSelecteday] = useState<any>();
  const type = eventType?.service_type || '';

  const checkBackground = () => {
    if(type==='Booking'){
      if(eventType?.service?.is_cancelled){
        return booking.cancelled;
      }else{
        return booking.active;
      }
    }else if(type=="Event"){
      if(eventType?.service?.is_cancelled){
        return event.cancelled;
      }else{
        return event.active;
      }
    }else if(type=="Lesson"){
      if(eventType?.service?.is_cancelled){
        return lesson.cancelled;
      }else{
        return lesson.active;
      }
    }else if(eventType?.service?.service_type){
      return "#e7ebf0";
    }else if(eventType?.type==="working_hours" || eventType?.type==="special_days" || eventType?.type ==="break_hours"){
      return '#e7ebf0';
    }
    else{
      return '';
    }
  }

useEffect(() => {
  const selectedDate = moment(eventType?.start_time).format('dddd');
const hell = coachTiming?.bookabledays?.filter((item:any)=>item?.days===selectedDate)[0];
setSelecteday(hell) 
}, [coachTiming,eventType])

  return (
    <Popover
      sx={{
        pointerEvents: "none",
        zIndex: 1000
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left", 
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus>
    { !!eventType&& <Paper
        elevation={2}
        sx={{
          background: checkBackground(),
          padding: 1.5,
          borderRadius:"7px",
          maxWidth: type==='Booking'?"236px":type==="Lesson"?"366px":type==="Event"?"366px":"206px",
        }}>
        {eventType?.type ==="working_hours" &&  <Grid
            item
            xs={12}>
               <Typography sx={{ 
              fontSize: "14px", 
              color: "#22356D", 
              fontFamily:'var(--font-regular) !important' }}>
      {moment(eventType?.start_time).format("HH:mm")}-{moment(eventType?.end_time).format("HH:mm")} | {t('common.OFF')}
            </Typography>
                {/* <Typography sx={{ 
              fontSize: "14px", 
              color: "#22356D", 
              fontFamily:'var(--font-regular) !important' }}>
         {viewType ==="daily" ?`${moment(selectedDay?.start_time,"HH:mm:ss").format("HH:mm")}-${moment(selectedDay?.end_time,"HH:mm:ss").format("HH:mm")}
      ` :`${moment(eventType?.start_time,"HH:mm:ss").format("HH:mm")}-${moment(eventType?.end_time,"HH:mm:ss").format("HH:mm")}}`} | {t('common.Working')}
            </Typography> */}
               
            <Typography sx={{ 
              fontSize: "14px", 
              marginTop:'10px',
              color: "#22356D", 
              fontFamily:'var(--font-regular) !important',textTransform:'capitalize' }}>
           {t('common.Coach')} {eventType?.full_name} - {t('common.Business Hours Settings')}
            </Typography>
          </Grid>}

          {eventType?.type ==="break_hours" &&  <Grid
            item
            xs={12}>
                  <Typography sx={{ 
              fontSize: "14px", 
              color: "#22356D", 
              fontFamily:'var(--font-regular) !important' }}>
      {moment(eventType?.start_time).format("HH:mm")}-{moment(eventType?.end_time).format("HH:mm")} | {t('common.OFF')}
            </Typography>
                {/* <Typography sx={{ 
              fontSize: "14px", 
              color: "#22356D", 
              fontFamily:'var(--font-regular) !important' }}>
         {viewType ==="daily" ?`${moment(selectedDay?.start_time,"HH:mm:ss").format("HH:mm")}-${moment(selectedDay?.end_time,"HH:mm:ss").format("HH:mm")}
      ` :`${moment(eventType?.start_time,"HH:mm:ss").format("HH:mm")}-${moment(eventType?.end_time,"HH:mm:ss").format("HH:mm")}}`} | {t('common.Working')}
            </Typography> */}
            <Typography sx={{ 
              fontSize: "14px", 
              marginTop:'10px',
              color: "#22356D", 
              fontFamily:'var(--font-regular) !important',textTransform:'capitalize' }}>
           {t('common.Coach')} {eventType?.full_name} - {t('common.Business Hours Settings')}
            </Typography>
          </Grid>}


           {eventType?.type ==="special_days" &&  <Grid
            item
            xs={12}>
                 <Typography sx={{ 
              fontSize: "14px", 
              color: "#22356D", 
              fontFamily:'var(--font-regular) !important' }}>
      {moment(eventType?.start_time).format("HH:mm")}-{moment(eventType?.end_time).format("HH:mm")} | {t('Calendar.Closed')}
            </Typography>
                <Typography sx={{ 
              fontSize: "14px", 
              color: "#22356D", 
              fontFamily:'var(--font-regular) !important' }}>
      {moment(eventType?.start_time,"HH:mm:ss").format("HH:mm")}-{moment(eventType?.end_time,"HH:mm:ss").format("HH:mm")} | {t('Calendar.Open')}
            </Typography>
            <Typography sx={{ 
              fontSize: "14px", 
              marginTop:'10px',
              color: "#22356D", 
              fontFamily:'var(--font-regular) !important' }}>
           {t('common.Coach')} {eventType?.full_name} - {t('common.Business Hours Settings')}
            </Typography>
          </Grid>}
      </Paper>}
    </Popover>
  );
};

export default CoachPopover;
