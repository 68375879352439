import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box,Button, Grid, Dialog, DialogContent, DialogProps, Typography, FormControl } from '@mui/material'
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import moment from "moment";
import MainContext from "src/context/MainContext";
import { SingleDate } from "../../components/customTimeSeletor";
import { activeColor } from "src/constants/color";

const common = {display:"flex", alignItems:'center', justifyContent:'space-between'};
const selectStyle = {
    "& fieldset": { border: "none" },
    "& .MuiSelect-select": { paddingRight: "6px !important" },
    minHeight: "3px !important",
    height: "33px !important",
    padding: "7px 14px 7px 0px !important",
    borderRadius: "7px",
    border: "1px solid #EEEFEE !important",
    fontFamily: "var(--font-regular) !important",
    width:"184px",
    color: "#22356D !important",
};
const fontD = {
    fontFamily: "var(--font-regular) !important",
    color: "#22356DB2",
};
const countingSequence = Array.from({ length: 1000 }, (_, i) => i + 1);
const Uses=[
    "Unlimited",...countingSequence
]

const singleDates = { 
    height: "40px", 
    alignItems: 'center',
    bordedRadius: "8px",
    border: "1px solid #EEEFEE",
    fontFamily: "var(--font-regular)",
    color: activeColor,
    width:'100%',
    marginTop:"2px",
    marginBottom: "2px"
}


const transformSelectedData = (data:any) => {
    const transformed = [];

    if (data.bookings && data.bookings.length > 0) {
        transformed.push({
            id: 1, // Static or dynamic ID based on your requirement
            label: "Bookings",
            children: data.bookings.map((booking:any) => ({
                id: booking.id,
                location_id : booking.location_id,
                label: `Booking - ${booking.sport.sport_name} - ${booking.duration} min`,
                sport_name:booking.sport.sport_name
            })),
        });
    }

    if (data.lessons && data.lessons.length > 0) {
        transformed.push({
            id: 2, // Static or dynamic ID based on your requirement
            label: "Lessons",
            children: data.lessons.map((lesson:any) => ({
                id: lesson.id,
                location_id : lesson.location_id,
                label: `Lesson - ${lesson?.sport?.sport_name} - ${lesson.lesson_name}`,
                sport_name:lesson.sport.sport_name
            })),
        });
    }

    if (data.events && data.events.length > 0) {
        transformed.push({
            id: 3, // Static or dynamic ID based on your requirement
            label: "Events",
            children: data.events.map((event:any) => ({
                id: event.id,
                location_id : event.location_id,
                label: `Events - ${event?.sport?.sport_name} - ${event.event_name}`,
                sport_name:event.sport.sport_name
            })),
        });
    }

    return transformed;
};



const MakePurchase = ({open, handleClose, locationdata, update}:{ open:boolean, handleClose:any, locationdata:any, update: any}) => {
    const {t} = useTranslation();
    const {Notification,setNotification} = useContext(MainContext);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const { register,watch, control, setValue, handleSubmit, formState: { errors }, reset} = useForm();
    const {Use,price,duration,duration_date,bookings,lessons,events,currency}=watch();
    const [isOpen,setIsopen] = useState<boolean>(false);
    const [selectedevents, setselectedevents] = useState<any>([]);
    const [selectedlocations, setSelectedLocations] = useState<any>([]);
    const [servicesopen, setservicesopen] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const locations = locationdata.map((item:any) => {
        const {location_name,id,currency} = item;
        const name = location_name
        return { name, id ,currency}
    });

    const transformData = (data:any) => {
        return data?.map((category:any) => ({
          id: category.id,
          label: category.label,
          children: category.children.map((child:any) => {
            let label = '';
            switch (category.label) {
              case 'Bookings':
                label = `Booking - ${child.sport.sport_name} - ${child.duration} min`;
                break;
              case 'Lessons':
                label = `Lesson - ${child.sport.sport_name} - ${child.lesson_name}`;
                break;
              case 'Events':
                label = `Events - ${child.sport.sport_name} - ${child.event_name}`;
                break;
              default:
                label = child.label || '';
            }
      
            return { id: child.id, label,location_id:child.location_id };
          }),
        }));
      };

  
      
    const onSubmit = async (value:any) => {
        const result :any= {};

        // Iterate through each category
        selectedevents.forEach((category:any) => {
        const categoryName = category.label.toLowerCase(); // Convert label to lowercase
        const itemIds = category.children.map((item:any) => item.id); // Extract IDs

        // Store the IDs in the result object
        result[categoryName] = itemIds;
        });

        let total_data = {
            membership_name:value.name,
            currency:selectedlocations[0]?.currency||currency,
            price:value.price,
            uses_limit:value.Use!=="Unlimited"?value.Use:-1,
            duration:value.duration||moment(value.duration_date,'DD/MM/YYYY').toDate(),
            bookings: result.bookings?.length>0 ?result.bookings:undefined,
            lessons: result.lessons?.length>0 ?result.lessons:undefined,
            events: result.events?.length>0 ?result.events:undefined,
            locations: selectedlocations.map((item:{name:string, id:number}) =>item.id)||[]
        }
       
        try{
            // if(Object.keys(data).length>0){
            //     let res = await updateMembership(data.id, total_data);
            // }else{;
            //     let res = await CreateMembership(total_data);
            // }
            update();
            handleClose();
        }catch(error){
            console.log(error);
        }
    }


    const removeBreak = (parentLabel: string, remove: any) => {

        // to find index to update
        const indexToUpdate = selectedevents?.findIndex(
          (selectedData: any) => selectedData?.label === parentLabel
        );
    
        //object to update
        const parent: any = selectedevents.find(
          (item: any) => item.label === parentLabel
        );
        const filterData = parent?.children.filter(
          (item: any) => item.label !== remove.label && item.id !== remove.id
        );
        const FINALEDATA = {
          id: parent.id,
          label: parent.label,
          children: filterData,
        };
    
        let asdfjkajsd;
        if (indexToUpdate !== -1) {
          const updatedState = [...selectedevents];
          updatedState[indexToUpdate] = FINALEDATA;
          asdfjkajsd = updatedState;
        }
    
        const filtered = asdfjkajsd?.filter((item) => item.children.length > 0);
        setselectedevents(filtered);
      };
     
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{".MuiDialog-paper":{
            paddingInline:{xs:"10px",sm:"0px", md:'1rem'},
            width:{xs:"auto !important", sm:'795px !important'}
          },
          ".MuiDialogContent-root":{
            padding:{xs:"12px 10px",sm:"20px 24px"}
          }
        }}
      >
        <DialogContent>
            <Box position={'relative'} sx={{marginBottom:{xs:"30px", sm:"0"}}}>
                <AiOutlineClose style={{position:'absolute', right:0, color:"#22356DB2", cursor:"pointer",zIndex:'10'}} onClick={handleClose} />
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{fontFamily:"var(--font-regular) !important", color:"#22356D"}}>
                    <Typography sx={{width:"fit-content",fontFamily:"var(--font-semibold)", borderBottom:'1px solid #22356D'}}>{t('POS.Product purchase')}</Typography>
                    <Box sx={{display:"flex", flexDirection:"column", gap:"15px", marginTop:"20px"}}>
                    <Box sx={common}>
                            <Box>{t('POS.Location')}</Box>
                            <Box width={'184px'}>
                            <FormControl>
                            <Select
                                IconComponent={KeyboardArrowDownIcon}
                                placeholder="Customer type"
                                // {...register("Use", {required: true})}
                                id="customdropdown"
                                value={Use?Use:'select Location'}
                                renderValue={(value) => {
                                  if (value) {
                                    return value;
                                  }
                                  return <em>{"Select Location"}</em>;
                                }}
                                style={{
                                width: "180px",
                                height: "35px",
                                border: "1px solid #EEEEFE",
                                borderRadius: "7px",
                                fontSize: "14px",
                                opacity: "0.7",
                                fontFamily: "var(--font-regular)",
                                color: "#22356D",
                                }}
                                MenuProps={{ sx: dopdowncustomcss }}
                            >
                                {locations?.map((location: any) => (
                                <MenuItem
                                    key={location}
                                    value={location}
                                >
                                    {location.name}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                            </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('POS.Select Product')}</Box>
                            <Box width={'184px'}>
                            <FormControl>
                            <Select
                                IconComponent={KeyboardArrowDownIcon}
                                placeholder="Customer type"
                                // {...register("Use", {required: true})}
                                id="customdropdown"
                                value={Use?Use:t('POS.Select Product')}
                                renderValue={(value) => {
                                  if (value) {
                                    return value;
                                  }
                                  return <em>{t('POS.Select Product')}</em>;
                                }}
                                style={{
                                width: "180px",
                                height: "35px",
                                border: "1px solid #EEEEFE",
                                borderRadius: "7px",
                                fontSize: "14px",
                                opacity: "0.7",
                                fontFamily: "var(--font-regular)",
                                color: "#22356D",
                                }}
                                MenuProps={{ sx: dopdowncustomcss }}
                            >
                                {locations?.map((location: any) => (
                                <MenuItem
                                    key={location}
                                    value={location}
                                >
                                    {location.name}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                            </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('POS.Quantity of Purchase')}</Box>
                            <Box sx={{...common,gap:'10px'}}>
                            <input 
                                type="text" 
                                placeholder={t('common.Enter Name')} 
                                style = {{border:'1px solid #EEEFEE', borderRadius:"7px", width:"140px", height:"33px", color:"#22356D"}} 
                                {...register("name", {required: true})}
                            />  {" "} Units
                            </Box>
                           
                        </Box>
                        <Box sx={common}>
                            <Box>{t('POS.Total Purchase Amount')}</Box>
                            <Box sx={{...common,gap:'10px'}}>
                            <input 
                                type="text" 
                                placeholder={t('common.Enter Name')} 
                                style = {{border:'1px solid #EEEFEE', borderRadius:"7px", width:"150px", height:"33px", color:"#22356D"}} 
                                {...register("name", {required: true})}
                            />  {" "} IDR
                            </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('POS.Uni')}</Box>
                            <Box sx={{...common,gap:'10px'}}>
                            <input 
                                type="text" 
                                placeholder={t('common.Enter Name')} 
                                style = {{border:'1px solid #EEEFEE', borderRadius:"7px", width:"150px", height:"33px", color:"#22356D"}} 
                                {...register("name", {required: true})}
                            />  {" "} IDR
                            </Box>
                        </Box>
                      
                        <Box  sx={common}>
                            <Box>{t('customers.Payment Method')}</Box>
                            <Box width={'184px'}>
                            <FormControl>
                            <Select
                                IconComponent={KeyboardArrowDownIcon}
                                placeholder="Customer type"
                                // {...register("Use", {required: true})}
                                id="customdropdown"
                                value={Use?Use:t('POS.Select Product')}
                                renderValue={(value) => {
                                  if (value) {
                                    return value;
                                  }
                                  return <em>{t('POS.Select Product')}</em>;
                                }}
                                style={{
                                width: "180px",
                                height: "35px",
                                border: "1px solid #EEEEFE",
                                borderRadius: "7px",
                                fontSize: "14px",
                                opacity: "0.7",
                                fontFamily: "var(--font-regular)",
                                color: "#22356D",
                                }}
                                MenuProps={{ sx: dopdowncustomcss }}
                            >
                                {locations?.map((location: any) => (
                                <MenuItem
                                    key={location}
                                    value={location}
                                >
                                    {location.name}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                            </Box>
                        </Box>
                        <Box sx={common}>
                            <Box>{t('POS.Purchase Date')}</Box> 
                            <Box sx={{...common, width:"184px"}}>
                           
                            <SingleDate
                            IcPosition={"right"}
                            format={"DD/MM/yyyy"}
                            type={"single"}
                            containercss={{ width:"300px" }}
                            placeholder={"Select days"}
                            selectedDay={new Date()}
                            setSelectedDays={(data: any) => {
                                const okay = moment(
                                data,
                                "DD/MM/YYYY"
                                ).toDate();
                                // setSelectedDate(okay);
                            }}
                            icon={false}
                            style={singleDates}
                            />
                            </Box>
                        </Box>
                        <Box>
                            <Box>{t('Calendar.Internal notes')}</Box>
                            <input 
                                type="text" 
                                placeholder={t('common.Enter Name')} 
                                style = {{border:'1px solid #EEEFEE', borderRadius:"7px", width:"100%", height:"33px", color:"#22356D",marginTop:'5px'}} 
                                {...register("name", {required: true})}
                            />
                        </Box>
                      
                    </Box>
                    <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginTop={servicesopen ?"150px":"20px"}
                    >
                        <Button
                            type='reset'
                            // onClick={()=>handleDelete()}
                            style={{
                                textTransform: "unset",
                                fontFamily: "var(--font-semibold)",
                                width: "150px",
                                height: "37px",
                                color: "#22356D",
                                backgroundColor: "white",
                                fontSize: "16px",
                                padding: "10px 30px",
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                            }}
                        >
                        {t('common.Cancel')}
                        </Button>
                        <Button
                            type="submit"
                            style={{
                                zIndex: 1,
                                textTransform: "unset",
                                fontFamily: "var(--font-semibold)",
                                color: "white",
                                backgroundColor: "#22356D",
                                width: "92px",
                                height: "37px",
                                fontSize: "16px",
                            }}
                        >
                            {t('common.Save')}
                        </Button>
                    </Grid>
                </Box>
            </form>
        </DialogContent>
      </Dialog>
  )
}

export default MakePurchase