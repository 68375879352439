export default function filterBySport(response: any) {
    const sports: any = {};
    response?.forEach((item: any) => {
      const sportName = item?.sport?.sport_name;
      if (!sports[sportName]) {
        sports[sportName] = [];
      }
      sports[sportName] = [...sports[sportName], item];
    });

    return sports;
  }

  export function addThousandSeparator(number: number, currency: string) {
    const numString = String(number);

    if (currency === "IDR") {
        // Handle float and integer separately for IDR
        if (numString.includes(".")) {
            // For float, use comma for decimal separator and dot for thousand separator
            const [integerPart, decimalPart] = numString.split(".");
            const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return `${formattedInteger},${decimalPart}`;
        } else {
            // For integer, use dot for thousand separator
            return numString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }
    } else {
        // For other currencies
        if (numString.includes(".")) {
            // Use comma for thousand separator and dot for decimal separator
            const [integerPart, decimalPart] = numString.split(".");
            const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return `${formattedInteger}.${decimalPart}`;
        } else {
            // Use comma for thousand separator
            return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
}


