import { apiCaller } from "./ApiCaller";
import { useQuery } from "react-query";
import { getClubId } from "./CalendarBooking";

export const useGetRegions = () => {
  const club_id = getClubId();

  return useQuery(
    ["AllRegions" ],
    async () => {
      const response = await apiCaller.get(`${club_id}/admins/regions`);
      return response.data.data;
    },
    {
      enabled: true,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );

};


export const updateRegions = async (apiData:any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(`${club_id}/admins/regions`,apiData);
    return response.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


export const updatePayment = async (location_id:number,apiData:any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(`${club_id}/payments/methods/${location_id}`,apiData);
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};



export const getVouchersByCustomer = async (customer_id:number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(`${club_id}/customers/vouchers/${customer_id}`);
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    console.log(err)
  }
};



export const AddVoucherToCustomer = async (customer_id:number,apidata:any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(`${club_id}/customers/vouchers/${customer_id}`,apidata);
    return true;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

interface AddStaffData {
  userId:number;
  locationId:number;
}

export const AddStaffToLocation = async (data:AddStaffData) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(`${club_id}/admins/otherLocation/user`,data);
    return response?.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};



export const DeleteVoucherByCustomer = async (customer_id:number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.delete(`${club_id}/customers/vouchers/${customer_id}`);
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};



export const DeleteManualByCustomer = async (customer_id:number,apidata:any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.delete(`${club_id}/customers/manual-credits/${customer_id}`,{data:apidata});
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


const getLocationName = async (region?: string) => {
    let club_id = getClubId();
  try {
    let baseURL1 = `${club_id}/admins/locations/names`;
    let baseURL2 = `${club_id}/admins/locations/names?region=${region}`;
    let response: any = await apiCaller.get(region ? baseURL2 : baseURL1);
    let res = response.data;
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    console.log(err)
  }
};

export const useGetAllRegionsLocation= () => {
  const club_id = getClubId();

  return useQuery(
    ["AllRegionsLocation" ],
    async () => {
      const response = await apiCaller.get(`${club_id}/admins/locations`);
      return response.data.data;
    },
    {
      enabled: true,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  ); 
};


export const useGetAllLocationName= () => {
  const club_id = getClubId();

  return useQuery(
    ["getLocationName" ],
    async () => {
      const response = await apiCaller.get(`${club_id}/admins/regions/locations`);
      return response.data.data;
    },
    {
      enabled: true,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  ); 
};

const createLocation = async (data: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/admins/locations/sequence`,
      data
    );
    let res = response.data;
    return res;

  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const updateLocationByID = async (id: number, data: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.patch(
      `${club_id}/admins/locations/${id}`,
      data
    );
   let res = response.data; 
    return res;

  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const deleteLocation = async (id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.delete(
      `${club_id}/admins/locations/${id}`
    );
    let res = response.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const addLocationUser = async (data: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/admins/locations/users`,
      data
    );
    let res = response.data;
    return true;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const getUser = async (id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(`${club_id}/admins/users/${id}`);
    let res = response.data;
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
}; 

export const useGetAllStaff = () => {
    let club_id = getClubId();
  const query = useQuery(
    ["GetAllStaff"],
    async () => {
      const response :any= await apiCaller.get(`${club_id}/admins/staff`);
     return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: true }
  );
  return query
};


export const getAllStaff = async () => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(`${club_id}/admins/staff`);
    let res = response.data;
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const updateUser = async (user_id: number, user_data: any) => {
  console.log(user_data);
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.patch(
      `${club_id}/admins/locations/users/${user_id}`,
      user_data
    );
    let res = response.data;
    return true;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const deleteUser = async (user_id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.delete(
      `${club_id}/admins/locations/users/${user_id}`
    );
    let res = response.data;
    console.log(res);
    return true;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

export {
    getLocationName,
  createLocation,
  updateLocationByID,
  deleteLocation,
  addLocationUser,
  getUser,
  updateUser,
  deleteUser,
};
