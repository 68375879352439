import { useQuery } from "react-query";
import { apiCaller } from "./ApiCaller";
import { getClubId } from "./CalendarBooking";

export const createCategory = async (data: any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.post(
        `${club_id}/products/product-category`,
        data
      );
      let res = response.data;
      return res;
  
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };
  

  export const getCategory = async () => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.get(
        `${club_id}/products/product-category`);
      let res = response.data?.data;
      return res;
  
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  export const CreateProduct = async (data: any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.post(
        `${club_id}/products`,
        data
      );
      let res = response.data;
      return res;
  
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  export const RefundProduct = async (payment_id:number,data: any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.patch(
        `${club_id}/products/pospayment/${payment_id}`,
        data
      );
      let res = response.data;
      return res;
  
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };
  
  export const useGetAllProducts = () => {
    const club_id = getClubId();
  
    return useQuery(
      ["allProducts"],
      async () => {
        const response = await apiCaller.get(`${club_id}/products`);
        return response.data.data;
      },
      {
        enabled: true,
        staleTime: 1000 * 60 * 5,
        retry: false,
      }
    );
  };
  
  
  export const GetProducts = async () => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.get(
        `${club_id}/products`);
      let res = response.data?.data;
      return res;
  
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  export const UpdateProduct = async (id:number,data: any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.patch(
        `${club_id}/products/${id}`,
        data
      );
      let res = response.data;
      return res;
  
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  export const DeleteProduct = async (id:number) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.delete(
        `${club_id}/products/${id}`);
      let res = response.data;
      return res;
  
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };
  

  
  export const MakePaymentOfProduct = async (data:any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.post(
        `${club_id}/products/pospayment`,data);
      let res = response.data;
      return res;
  
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };
  
  export const GetAllProductsSale = async (location_id:number,date:any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.get(
        `${club_id}/products/pospayment?location_id=${location_id}&start_date=${date.date_start}&end_date=${date.date_end}
  `);
      let res = response.data?.data;
      return res;
  
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };
  
  

  
  
  
  