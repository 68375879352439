import Box from "@mui/material/Box";
import React from "react";

export const FristCompo = ({
  text,
  arr,
  selected,
  setSelected,
}: {
  text: string;
  arr: any;
  selected: any;
  setSelected: any;
}) => {
  const handleSelection = (event: any, item: any) => {
    const indexToUpdate = selected?.findIndex(
      (selectedData: any) => selectedData?.id === arr?.id
    );
    const parent = selected.find((node: any) => node.id === arr.id);

    const finaldata = parent?.children?.some(
      (child: any) => child.id === item.id
    );

    event.stopPropagation();
    // check if clicked on parent checkbox
    if (item === "Select All") {
      setSelected((prev: any) =>
        prev?.some((ok: any) => ok.id === arr.id)
          ? prev?.filter((nasdf: any) => nasdf.id !== arr.id)
          : [...prev, arr]
      );
    } else {
      // check if clicked on child checkbox which is already checked
      if (finaldata) {
        const filterData = parent?.children?.filter(
          (childrenid: any) => childrenid.id !== item.id
        );
        const FINALEDATA = {
          id: arr.id,
          label: arr.label,
          children: filterData,
        };

        let asdfjkajsd;
        if (indexToUpdate !== -1) {
          const updatedState = [...selected];
          updatedState[indexToUpdate] = FINALEDATA;
          asdfjkajsd = updatedState;
        }

        const filtered = asdfjkajsd?.filter((item) => item.children.length > 0);
        setSelected(filtered);
      } else {
        // check if clicked on child checkbox which is not checked
        setSelected((prev: any) => {
          if (prev.some((item: any) => item.id === arr.id)) {
            if (parent?.children?.length > 0) {
              const FINALEDATA = {
                id: arr.id,
                label: arr.label,
                children: [...parent?.children, item],
              };

              const updatedState = [...prev];
              updatedState[indexToUpdate] = FINALEDATA;

              return updatedState;
            } else {
              const FINALEDATA = {
                id: arr.id,
                label: arr.label,
                children: [item],
              };
              return [FINALEDATA];
            }
          } else {
            const FINALEDATA = {
              id: arr.id,
              label: arr.label,
              children: [item],
            };
            return [...prev, FINALEDATA];
          }
        });
      }
    }
  };

  const preSelect = selected?.filter((item: any) => item.id === arr.id);
  const isPArentSelected =
    preSelect[0]?.children.length === arr.children.length;
  return (
    <Box width={"-webkit-fill-available"} sx={{ background: "white" }}>
      {/* Add Select All option */}
      <Box
        sx={{
          background: isPArentSelected ? "#22356DB2" : "#889AB61A",
          color: isPArentSelected ? "white" : "#22356D",
          padding: "10px",
          cursor: "pointer",
          "&:hover": {
            background: "#22356DB2",
            color: "#FFFFFF",
          },
        }}
        display={"flex"}
        justifyContent={"space-between"}
        gap={"20px"}
        onClick={(event) => handleSelection(event, "Select All")}
      >
        <p style={{ fontFamily: "var(--font-regular)" }}>All {text}</p>
        <label className="container relative">
          <input readOnly type="checkbox" checked={isPArentSelected} />
          <span className="mark" style={{ left: "-9px" }}></span>
        </label>
      </Box>
      {/* Render other options */}
      {arr.children.map((item: any) => {
        const isSelected = preSelect[0]?.children?.some(
          (okay: any) => okay.id === item.id
        );

        return (
          <Box
            key={item.id}
            sx={{
              background: isSelected ? "#22356DB2" : "#889AB61A",
              color: isSelected ? "white" : "#22356D",
              padding: "10px 10px 10px 20px",
              cursor: "pointer",
              "&:hover": {
                background: "#22356DB2",
                color: "#FFFFFF",
              },
            }}
            display={"flex"}
            justifyContent={"space-between"}
            gap={"20px"}
            onClick={(event) => handleSelection(event, item)}
          >
            <p style={{ fontFamily: "var(--font-regular)" }}>{item.label}</p>
            <label className="container relative">
              <input type="checkbox" readOnly checked={!!isSelected ?isSelected:false} />
              <span className="mark" style={{ left: "-9px" }}></span>
            </label>
          </Box>
        );
      })}
    </Box>
  );
};
