import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from 'react'
import { useTranslation } from "react-i18next";
import { addThousandSeparator } from "src/constants/SportSEparator";

const BookingsRow = ({Data,index, selectedLocation}:{Data:any,index:any, selectedLocation:{id:number, location_name:string, currency:string}}) => {
   const { t } = useTranslation();
   const innerWidth = Number(window.innerWidth) >= 1200 && Number(window.innerWidth) <= 1360;
    const firststyle = {color:'rgba(34, 53, 109,1)',fontFamily:"var(--font-regular)",fontSize:'16px'}
    const secondStyle = {color:'rgba(34, 53, 109,1)',fontFamily:"var(--font-regular)",fontSize:'14px'}
  return (
    <div style={index%2==0 ? {backgroundColor:'rgba(136, 154, 182, 0.10)',padding:'8px 10px'}:{backgroundColor:'transparent',padding:'8px 10px'}} className=''>
     <Box margin={'5px'}>
      <Box display={'flex'} position={'relative'} justifyContent={"space-between"}>
          <p style={firststyle}>{Data.duration} {t('common.minutes')}</p>
          {Data.is_open_match &&<Typography sx={{...firststyle,backgroundColor:'rgba(136, 154, 182, 0.15)',padding:'2px 5px',borderRadius:'7px',position:'absolute',top:'85%',left:'28%',fontSize:'14px',display:innerWidth?"none":"block"}}>{t('Calendar.Open Match')}</Typography>}
          <p style={firststyle}>{addThousandSeparator(Data.price, selectedLocation.currency)} {selectedLocation.currency}</p>
      </Box>
      <Box display={'flex'} justifyContent={"space-between"}>
          <p style={secondStyle}>Mon-Sun</p>
          <p style={secondStyle}>{Data.minimum_capacity}-{Data.maximum_capacity} player</p>
      </Box>
    </Box>
    </div> 
  )
}

export default BookingsRow
