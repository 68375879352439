import React, { useEffect } from 'react'

const Logout = () => {

    useEffect(() => {
        window.location.href = "/signin"; 
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('Club_id');
    localStorage.removeItem('permission');
    }, [])
    
    
  return (
    <div style={{minHeight:'100vh'}}></div>
  )
}

export default Logout