import { apiCaller } from "./ApiCaller";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import MainContext from "src/context/MainContext";

export const getClubId = () => {

  const user_data = JSON.parse(
    localStorage.getItem("user") || JSON.stringify({})
  );

  if (user_data?.role?.name === "SUPER_ADMIN") {
    const club_id = JSON.parse(
      localStorage.getItem("Club_id") || JSON.stringify({})
    );
    return club_id;
  } else {
    let club_id = user_data?.club_id;
    return club_id;
  }
};


export const useGetClubId = () => {
  const {LoginData} = useContext(MainContext);
  const getClubId = () => {
  
    if (LoginData?.role?.name === "SUPER_ADMIN") {
      const club_id = JSON.parse(
        localStorage.getItem("Club_id") || JSON.stringify({})
      );
      return club_id;
    } else {
      let club_id = LoginData?.club?.id;
      return club_id;
    }
  };
  
  const club_id = getClubId()
  return club_id
  }


  export const useGetPermissions = () => {
    const {LoginData} = useContext(MainContext);
    if (LoginData?.role.name === "STAFF") {
      const permission = LoginData?.otherPermissions;
       return permission
    }else{
      return {edit_permissions:true,role:'OTHER'}
    }
    }
  

export const Permission =()=>{
  const user_data = JSON.parse(
    localStorage.getItem("user") || JSON.stringify({})
  );
  if (user_data?.role.name === "STAFF") {
    const permission = JSON.parse(localStorage.getItem("permission") || JSON.stringify({}));
     return permission?.otherPermissions
  }else{
    return {edit_permissions:true,role:'OTHER'}
  }
}



export const MyDetails = async () => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(
      `${club_id}/admins/user/getme`
    );
    let res = response?.data;
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};





//done
//get list of internal notes
const getInternalNotes = async (service_id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(
      `${club_id}/services/notes/${service_id}`
    );
    let res = response.data.data;
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

//get details
export const useGetBookingsEvents = () => {
    let club_id = getClubId();
  const [sport_id, setsport_id] = useState<number>();
  const [viewType, setviewType] = useState<string>();

  const query = useQuery(
    ["GetBookingsEvents", sport_id, viewType],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/calendars/${viewType}/${sport_id}`
      );
      return response.data.data;
    },
    { staleTime: 1000 * 3, retry: false, enabled: !!sport_id && !!viewType }
  );

  return { setviewType, setsport_id, ...query };
};

// get lessons as per location
const getLessonsData = async (location_id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(
      `${club_id}/calendars/lessons/${location_id}`
    );
    let res = response.data;
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

export const getAllServices = async (location_id: number, date: any) => {
  try {
      let club_id = getClubId();
    let response: any = await apiCaller.get(
      `${club_id}/calendars/services/${location_id}/${date}`
    );
    return response.data.data;
  } catch (error: any) {
    const err = error.response?.data?.message;
    throw err;
  }
};

export const useGetAllServices = () => {
    let club_id = getClubId();
  const [location_id, setLocation_id] = useState<number>();
  const [date, setDate] = useState<string>();

  const query = useQuery(
    ["calendar-daily-date", location_id, date],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/calendars/services/${location_id}/${date}`
      );
      return response.data?.data;
    },
    {
      enabled: !!location_id && !!date,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return { setDate, setLocation_id, ...query };
};

export const useGetAllStats = () => {
    let club_id = getClubId();
  const [location_id, setLocation_id] = useState<number>();
  const [sports, setSports] = useState([]);
  const [date, setDate] = useState<string>();

   const queryString = sports.map(id => `sportId=${id}`).join('&');

  const query = useQuery(
    ["calendar-daily-stats", location_id, date,sports],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/calendars/services/stats/${location_id}/${date}?${queryString}`
      ); 
      return response.data?.data;
    },
    {
      enabled: !!location_id && !!date,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return { setDate, setLocation_id, sports, setSports, ...query };
};

// get all logs
const getLogs = async (service_id: number) => {
  try {
      let club_id = getClubId();
    let res = await apiCaller.get(
      `${club_id}/calendars/services/view-logs/${service_id}`
    );
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    console.log(err);
    return err;
  }
};

// get all logs
export const GetServiceLogs = async (service_id: number) => {
  try {
      let club_id = getClubId();
    let res = await apiCaller.get(`${club_id}/services/logs/${service_id}`);
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    console.log(err);
    return err;
  }
};

// getting total of booking, evens, lesson in % and other thing
const getAllRatings = async (location_id: number, date: any) => {
  try {
      let club_id = getClubId();
    let response: any = await apiCaller.get(
      `${club_id}/calendars/calculation/${location_id}/${date}`
    );
    return response;
  } catch (error: any) {
    const err = error.response?.data?.message;
    console.log(err);
  }
};

// get service by the id
const getServiceById = async (service_id: number) => {
  try {
      let club_id = getClubId();
    let response: any = await apiCaller.get(
      `${club_id}/calendars/services/${service_id}`
    );
    return response;
  } catch (error: any) {
    const err = error.response?.data?.message;
    throw err;
  }
};

export const getPendingPaymentsById = async (service_id: number) => {
  try {
      let club_id = getClubId();
    let response: any = await apiCaller.get(
      `${club_id}/payments/services/pending-payments/${service_id}`
    );
    return response?.data?.data;
  } catch (error: any) {
    const err = error.response?.data?.message;
    throw err;
  }
};

// create service
const createBooking = async (data: any) => {
  try {
      let club_id = getClubId();
    let response: any = await apiCaller.post(
      `${club_id}/calendars/services`,
      data
    );
    return response;
  } catch (error: any) {
    const err = error.response?.data?.message;
    throw err;
  }
};

// update service
const updateBookingData = async (
  service_id: number,
  service_booking_id: number,
  data: any
) => {
  try {
      let club_id = getClubId();
    let res: any = await apiCaller.put(
      `${club_id}/calendars/servics/${service_id}`,
      data
    );
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// update conflict service
const updateSchBooking = async (
  service_id: number,
  service_booking_id: number,
  data: any
) => {
  try {
      let club_id = getClubId();
    let res: any = await apiCaller.put(
      `${club_id}/calendars/schedule-bookings/${service_id}/${service_booking_id}`,
      data
    );
    return res?.data?.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// delete conflict service
const deleteSchService = async (service_booking_id: number) => {
  try {
      let club_id = getClubId();
    let res: any = await apiCaller.delete(
      `${club_id}/calendars/schedule-bookings/${service_booking_id}`
    );
    console.log(res);
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

export const useGetAllCustomerList = () => {
  const club_id = getClubId();

  return useQuery(
    ["CustomersList"],
    async () => {
      const response = await apiCaller.get(`${club_id}/services/customers`);
      return response.data.data;
    },
    {
      enabled: true,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
};

export const useGetPlayersNotes = () => {
  const club_id = getClubId();
  const [Service_id, setService_id] = useState<number>();

  const query = useQuery(
    ["GetPlayerNotes", Service_id],
    async () => {
      const response = await apiCaller.get(
        `${club_id}/calendars/services/view/${Service_id}`
      );
      return response.data.data;
    },
    {
      enabled: !!Service_id,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return { setService_id, ...query };
};

// add player in waiting list
const UpdateServicesPlayers = async (playerId: number, type: string) => {
  try {
      let club_id = getClubId();
    let res: any = await apiCaller.patch(
      `${club_id}/calendars/services/players/${playerId}?type=${type}`
    );
    return res.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

export const HideService = async (service_id: number,apidata:any) => {
  try {
      let club_id = getClubId();
    let res: any = await apiCaller.patch(
      `${club_id}/calendars/services/hide/${service_id}`,apidata
    );
    return res.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// get all players list if any
const getPlayerList = async (service_id: number) => {
  try {
      let club_id = getClubId();
    let res = await apiCaller.get(
      `${club_id}/calendars/services/players/list/${service_id}`
    );
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    return err;
  }
};

export const useGetAllPlayerList = () => {
  const club_id = getClubId();
  const [service_id, setService_id] = useState<number>();

  const query = useQuery(
    ["getallDoubleplayer-list", service_id],
    async () => {
      const response = await apiCaller.get(
        `${club_id}/calendars/services/players/list/${service_id}`
      );
      return response.data.data;
    },
    {
      enabled: !!service_id,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return { setService_id, ...query };
};

export const useGetAllWaitingList = () => {
  const club_id = getClubId();
  const [service_id, setService_id] = useState<number>();

  const query = useQuery(
    ["getallwaiting-list", service_id],
    async () => {
      const response = await apiCaller.get(
        `${club_id}/calendars/services/players/waiting-list/${service_id}`
      );
      return response.data.data;
    },
    {
      enabled: !!service_id,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return { setService_id, ...query };
};

// add player in service
const addPlayerInList = async (service_id: number, data: any) => {
  try {
      let club_id = getClubId();
    let res: any = await apiCaller.post(
      `${club_id}/calendars/services/players/${service_id}`,
      data
    );
    // console.log(res);
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// add waiting list for team
const addWaitingTeam = async (service_id: number, data: any) => {
  try {
      let club_id = getClubId();
    let res = await apiCaller.post(
      `${club_id}/calendars/services/players/waiting-list/double/${service_id}`,
      data
    );
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// delete the service
const deleteService = async (service_id: number,apiData:any) => {
  try {
      let club_id = getClubId();
    let res: any = await apiCaller.patch(
      `${club_id}/calendars/services/${service_id}`,apiData
    );

    console.log(res);
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// add internal note
const addNotes = async (serviceId: number, data: { notes: string }) => {
  try {
      let club_id = getClubId();
    let res = await apiCaller.post(
      `${club_id}/services/notes/${serviceId}`,
      data
    );
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// Payments share logic screen
export const UpdatePayments = async (serviceId: number, data: any) => {
  try {
      let club_id = getClubId();
    let res = await apiCaller.post(
      `${club_id}/payments/services/${serviceId}`,
      data
    );
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const transformData = (data: any) => {
  const transformedData: any = {};
  data.forEach((item: any) => {
    const courtId = item.court_id;
    if (!transformedData[courtId]) {
      transformedData[courtId] = {
        court_name: item.court_name,
        court_id: courtId,
        sport: item.sport,
        occupancy: [],
      };
    }
    transformedData[courtId].occupancy.push(item);
  });
  const result = Object.values(transformedData);
  return result;
};

// get all service of a month
const getMonthsService = async (
  view_type: string,
  month_year: any,
  location_id: number
) => {
  try {
      let club_id = getClubId();
    let res = await apiCaller.get(
      `${club_id}/calendars/occupancy-rate/${location_id}/${view_type}/${month_year}`
    );
    let data = res?.data?.data;
    if (view_type === "courts") {
      let setData = transformData(data);
      return setData;
    }
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    console.log(err);
    return err;
  }
};

export {
  getInternalNotes,
  getLogs,
  getLessonsData,
  createBooking,
  getMonthsService,
  getAllRatings,
  getServiceById,
  updateBookingData,
  updateSchBooking,
  deleteSchService,
  getPlayerList,
  addPlayerInList,
  addWaitingTeam,
  UpdateServicesPlayers,
  deleteService,
  addNotes,
};
