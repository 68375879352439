import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react'
import { IoCloseOutline } from "react-icons/io5";

const LessonsServicesRow = ({data,index,id,setSelectedCoaches,selectedlocation,defaultPrice}:{data:any,id?:number,index:number,selectedlocation:{id:number,location_name:string,currency:string}, setSelectedCoaches?:any,defaultPrice:any}) => {

  const [coachPrice,setcoachPrice] = useState<number>(data?.price);

  
  const handleREmove = () => {
    setSelectedCoaches((prev:any)=>{
      const filteredBreaks = prev.filter(
        (item: any) => item?.id !== id
      );
      return filteredBreaks
    }
    )
  };

  useEffect(() => {
   setSelectedCoaches((prev:any)=>{
    let {price,...newitem} = data;
    price = coachPrice
   const finalcoachdata =  {price,...newitem}
    const updatedState = [...prev];
    updatedState[index] = finalcoachdata
    return updatedState
   })
  }, [coachPrice]);


  useEffect(() => {
 if(!data.price){
  setcoachPrice(defaultPrice);
 }
  }, [defaultPrice])
  

   
  const borderInput = {opacity:'0.7',padding:'5px 8px',fontSize:'14px',border:'1px solid #EEEFEE',borderRadius:'7px',color:'#22356D', width:"inherit"}
  const maincss = {color:'#22356D',fontFamily:"var(--font-regular)",fontSize:{xs:"0.8rem", sm:'16px'}}
  const style = {color:'rgba(34, 53, 109,1)',fontFamily:"var(--font-regular)",fontSize:'14px'}
  return (
    <div style={index%2==0 ? {backgroundColor:'rgba(136, 154, 182, 0.10)',padding:'8px 10px'}:{padding:'8px 10px'}} >
     <Box display={'flex'} flexDirection={"row"} justifyContent={'space-between'} alignItems={'center'} margin={'5px'} sx={{gap:{xs:"10px", sm:"40px"}}}>
        <Box display={'flex'} flexDirection={"row"} gap={'5px'} alignItems={'center'}>
          <img style={{width:'40px',height:'40px',borderRadius:'50%'}} src={data.profile_url?data.profile_url:"/images/Reserved.svg"}/>
          <Typography sx={maincss}>Coach {data.full_name}</Typography>
        </Box>
        <Box display={'flex'} flexDirection={"row"}  gap={'5px'} alignItems={'center'}>
          <Box sx={{width:{xs:"65px",}}}>
            <input placeholder='price' type='number' value={data.price} onChange={(e:any)=>setcoachPrice(e.target.value)} className='bg-white' style={borderInput}/>
          </Box>
          <Typography sx={maincss}>{selectedlocation.currency}</Typography>
          <div onClick={()=>handleREmove()} style={{marginLeft:'15px'}}><IoCloseOutline /></div>
          </Box>
       
      </Box>
    </div> 
  )
}

export default LessonsServicesRow
