import React from "react";
import { Tabs, Tab } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

export default function TabSlider({style,text, dynam,isDisable, tabOptions, selected, fullWidth = false, callback }: {isDisable?:any,text?:string,style?:any, tabOptions: string[], selected?:any,dynam?:boolean, fullWidth?: boolean, callback: (tab: string) => void}) {

    const {t} = useTranslation();

    function capitalizeWords(string: string) {
        return string.split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ');
    } 

    return (
        <Tabs style={{cursor:isDisable&&"not-allowed",...style}} isDisabled={isDisable} size="sm" aria-label="Options" selectedKey={selected} fullWidth={fullWidth} onSelectionChange={(key: any) =>callback(key)}
            classNames={{
                tabList: "bg-color w-auto gap-mobile",
                tab:`h-6 text-base ${text=='SubService'?"dynamic-width":"const-width"}`,
                tabContent:`color`
            }}
            id={text=="search" ? 'tabSlider-search': ''}
        >
            {
                tabOptions.map((tabOption) => {
                    return <Tab  style={{textTransform:'capitalize',zIndex:'8',height:'30px'} as any} isDisabled={selected!==tabOption && isDisable===true ? true:false} key={tabOption} title={capitalizeWords(dynam?tabOption:t(`Calendar.${tabOption}`))}
                    > 
                    </Tab>
                })
            }
        </Tabs>
    );
} 
