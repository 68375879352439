import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { MakePaymentOfPendingPalyer } from 'src/api/Customer';
import { useFieldArray, useForm } from 'react-hook-form';
import MainContext from 'src/context/MainContext';
import { getAllCoupon } from 'src/api/Coupon';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PendingPlayersList from '@components/PendingPlayersList';
import ProductList from './ProductList';
import { Permission, useGetAllCustomerList, useGetPermissions } from 'src/api/CalendarBooking';
import SelectedPayment from './SelectedPayment';
import CustomerSelector from './CustomerSelector';
import { MakePaymentOfProduct } from 'src/api/POS';
import AddPlayer from '../../components/Calendar/AddPlayer';
import { addThousandSeparator } from 'src/constants/SportSEparator';

const common = {
    display:"flex",
    alignItems:'center',
    gap:"10px",
}

const style={
    border:"1px solid #EEEFEE",
    borderRadius:"7px",
    height:"37px"
}

type splitPayments={
    method_type: string;
    discount: number;
    coupon_id?: number;
    showCoupon: boolean;
    wallet_id?:number;
    method_id?:number;
    product_id?:number;
    price:number;
    product_name:number;
}

type Payment = {
  customer:number,
  location_id:number,
  method_id:number,
  splitPayments:splitPayments[]
  };
  
  type FormData = {
    payments: Payment[];
  };
const MakeSale = ({ selLocation,update,open, setOpen,selectedLocationID}:{selectedLocationID:number,open:boolean, setOpen:any,update:any,selLocation:any}) => {
    const { control, register, handleSubmit, setValue,unregister , getValues,reset,watch } = useForm<FormData>();
    const {t} = useTranslation();
     const StaffPermission = useGetPermissions();
    const [addedPlayer, setAddedPlayer] = useState<any>([]);
    const [addPlayer, setAddPlayer] = useState<string>('');
    const [showList, setShowList] = useState<boolean>(false);
    const {Notification,setNotification,setIsPermissionOn} = useContext(MainContext);
    const [isOpen,setIsOpen] = useState(false);
    const {SelectedLocation,event} = useContext(MainContext);
    const {data,isSuccess,isError,refetch} = useGetAllCustomerList();
    const [FilteredPlayer,setFilteredPlayer] = useState<any[]>([]);
    const [allPlayer,setAllPlayer] = useState<any[]>([]);
    const [selectedPlayers,setSelectedPlayers] = useState<any[]>([]);
    const [pendingPlayers,setPendingPlayers] =useState<any[]>([]);
    const currentFields = watch('payments');
    const [AllCoupons,setAllCoupons]= useState<any[]>([]);
    const [isClickable,setIsClickable] = useState(false);
    const [Refunds,setRefunds] = useState<any[]>([]);
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'payments'
    });

      useEffect(() => {
     if(isClickable){
      setTimeout(() => {
        setIsClickable(false)
       }, 2000);
     }
      }, [isClickable])

      useEffect(() => {
       const transfromed =  selectedPlayers?.length>0 ? transformData(selectedPlayers):[
        {  location_id:selectedLocationID,
          method_id:'',
          products: [{
            "discount": 0,
            "showCoupon": false
        }]
           
        }
    ];
       reset({payments:transfromed});
      }, [selectedPlayers,selLocation])
      

      const transformData = (backendData:any) => {
        const transformedData :any= [];
        const playerMap :any= {};
    
        backendData.forEach((item:any) => {
          const {id:customer, } = item;
          if (!playerMap[customer]) {
            playerMap[customer] = {
              customer,
              location_id:selectedLocationID,
              method_id:'',
              products: []
            };
            transformedData.push(playerMap[customer]);
          }
          playerMap[customer].products.push({
            discount: 0,
            showCoupon: false,
          });
        });
    
        return transformedData;
      };

      const handleClose = ()=>{
        setOpen(false)
        setSelectedPlayers([])
        const updatedat :any=[
          {  location_id:selectedLocationID,
            method_id:'',
            products: [{
              "discount": 0,
              "showCoupon": false
          }]
             
          }
      ]
        reset({payments:updatedat});
      }
      

    const onSubmit = async(data: FormData) => {
      if(!StaffPermission.edit_permissions){
        setIsPermissionOn(true);
        setOpen(false);
        return;
      }
  
      setIsClickable(true);
        const cleanedData = data.payments.map((payment:any) => ({
            location_id:payment.location_id,
            customer:payment.customer||undefined,
            method_id:payment.method_id||undefined,
            wallet_id:payment.wallet_id||undefined,
            products: payment.products.map((product:any) => {
              const { showCoupon,price,product_name, ...rest } = product;
              return rest;
            })
          }));

     try {
        const response = await MakePaymentOfProduct({products:cleanedData});
        handleClose();
        setRefunds([]);
        update();
        setPendingPlayers([]);
        const updatedat :any=[
          {  location_id:selectedLocationID,
            method_id:'',
            products: [{
              "discount": 0,
              "showCoupon": false
          }]
          }
      ]
        reset({payments:updatedat});
        setNotification({isShow:true,message:'Product purchased successfully',subMessage:'',type:'success'});
     } catch (error:any) {
      setNotification({isShow:true,message:error,subMessage:'',type:'error'});
     }
    };   

      const getAllCoupons = async()=>{
       try {
        const response = await getAllCoupon();

        const filteredCoupon = response?.filter((item:any)=>item?.allowProducts)
       
        setAllCoupons(filteredCoupon);
       } catch (error) {
        setAllCoupons([]);
       }
      }

      useEffect(() => {
        if(isSuccess){
        setAllPlayer(data);
        setFilteredPlayer(data)
        }else{
            setAllPlayer([])
            setFilteredPlayer([])
        }
      }, [isSuccess,isError,data]);

      useEffect(() => {
        getAllCoupons();
      }, [])      

const isShowCoupon =   currentFields?.some((item:any)=>{
  return item?.products?.some((spli:any)=>spli?.showCoupon)
});

const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  let val = event.target.value
  setAddPlayer(val);
  setShowList(true);
  let arr = allPlayer.filter((item:any) => {
    let first_name = item.first_name?.toLowerCase();
    let last_name = item.last_name?.toLowerCase();
    return first_name.includes(val?.toLowerCase()) || last_name.includes(val?.toLowerCase());
  });
 if(val){
  setFilteredPlayer(arr);
 }
}

const selectedUser = (data:any)=>{
  setSelectedPlayers((prev:any)=>{
    if(prev?.some((pre:any)=>pre.id===data.id)){
 return prev?.filter((pre:any)=>pre.id!==data.id);
    }else{
      return [data,...prev]
    }
  })
  setAddPlayer('')
}

useEffect(() => {
  document.addEventListener("click", () => {
    setShowList(false);
  })
}, [])

useEffect(() => {
 if(selectedPlayers?.length>0){
  const filtered = allPlayer?.filter((item:any)=>selectedPlayers?.some((sel:any)=>sel?.id!==item?.id));
  setFilteredPlayer(filtered);
 }
}, [allPlayer,selectedPlayers])

const allcurent = currentFields?.flatMap((item:any)=> item?.products)||[]
const PendingPRice = allcurent?.reduce((acc:any,item:any)=> acc+Number(item?.price||0),0);
const PendingDiscount = allcurent?.reduce((acc:any,item:any)=> acc+Number(item?.discount),0);
const TotalPending = PendingPRice-PendingDiscount||0;

const RefundPrice = Refunds?.reduce((acc:any,item:any)=> acc+Number(item?.refund_price),0);
  
    return (
        <Dialog
          sx={{
            ".MuiDialog-paper": {
            minWidth: {xs:"auto", sm:isShowCoupon?'1040px':"800px"},
            },
          }}
          onClose={handleClose}
          open={open}
          >
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{">p:not(:first-child)":{fontFamily:"var(--font-regular) !important", color:"#22356DB2"}}}>
                    <Typography sx={{fontFamily:"var(--font-semibold) !important", color:"#22356D"}}>{t("POS.Sale Settings")}</Typography>
                    <Typography>{moment(new Date()).format('dddd, DD MMMM, yyyy')} | {moment(new Date()).format('HH:mm')}</Typography>
                    <Typography>{selLocation?.location_name}</Typography>
                </Box>
                <Box sx={{marginBlock:"1rem"}}>
                    <Typography sx={{fontFamily:"var(--font-semibold)"}}>{t('title.Payments')}</Typography>
                    <AddPlayer maxHeight={'150px'} isneedEmail={true} width={'190px'}  update={refetch} isPlayer={false} addPlayer={addPlayer} handleChange={handleChange} showList={showList} setShowList={setShowList} data={FilteredPlayer} selectedUser={selectedUser} />                    
                    <Grid container rowGap={5} width={'100%'}>
                        
                    <Grid xs={12} md={9}>
                  {
                    currentFields?.map((item:any,index:any)=>
                        <Box key={index} sx={{...common,gap:'0 px',width:"100%",flexDirection:{xs:"column",md:'column'},alignItems:"flex-start !important", marginTop:'10px'}}>
                           {selectedPlayers?.filter((pl:any)=>pl?.id===item?.customer)?.map((cus:any,ind:number)=> <Box key={ind} >
                        <Box sx={{...common,width:"100%", flexDirection:{xs:"column", md:"row"}, alignItems:{xs:"baseline", md:"center"},">img":{display:{xs:"none !important", md:"block !important"}}}}>
                          <img src={cus?.profile_url ? cus?.profile_url:"/images/Reserved.svg"} alt="player" style={{width:"40px", height:"40px", borderRadius:"50%"}} />
                        <Box sx={{display:'flex',width:"100%",flexDirection:{xs:"row", md:"column"}, justifyContent:{xs:"space-between", md:"unset"}}}>
                          <Typography sx={{fontFamily:"var(--font-regular)"}}>{cus?.first_name} {cus?.last_name}</Typography>
                      </Box>
                    </Box>
                  </Box>)}
                  <Box sx={{display:'flex',flexDirection:{sm:'row',xs:'column'},gap:'10px'}}>
                  <Box sx={{width:"100%"}}>
                    {
                        <SelectedPayment SelectedLocation={selLocation} isNoCustomer={selectedPlayers?.length>0?false:true} selectedLocationID={selectedLocationID} currentFields={currentFields} index={index} setValue={setValue} unregister={unregister}  />
                    }
                  </Box> 
                  <Box sx={{display:'flex',flexDirection:'column', width:{xs:"100%", md:"unset"}}}>
                    {
                      item?.products?.map((splidata:any,splitIndex:number)=>
                        <ProductList setIsOpen={setIsOpen} unregister={unregister} key={splitIndex} setValue={setValue} AllCoupons={AllCoupons} player_id={item?.player_id} selectedLocationID={selectedLocationID} PendingPlayers={pendingPlayers} currentFields={currentFields} index={index} register={register} reset={reset} splidata={splidata} splitIndex={splitIndex}  />
                      )
                    }
                  </Box>
                  </Box>
                </Box>                      
                    )
                  }
                  <Box
                  sx={{
                    display: "flex",
                    alignItems: {xs:"baseline", sm:"flex-end"},
                    flexDirection: "column",
                    width:'100%',
                    marginLeft:isShowCoupon?{xs:"0", md:'250px'}:{xs:"0", md:'180px'}
                  }}
                >
                  <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", justifyContent:"space-between", width:{xs:"100%", sm:'auto'}}}>
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "16px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                      {t('common.Price')}
                    </p>

                    <Typography
                      sx={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "16px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign: 'right'
                      }}
                    >
                    {addThousandSeparator(PendingPRice,SelectedLocation?.currency)}
                    </Typography>
                  </Box>
                  <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", width:{xs:"100%", sm:'auto'},justifyContent:"space-between"}}>
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "16px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                      {t('common.Discount')}
                    </p>

                    <Cleave
                      readOnly
                      value={PendingDiscount||0}
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "16px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign:"right"
                      }}
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                        delimiter:'\u2024'
                      }}
                    />
                  </Box>
                  <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", width:{xs:"100%", sm:'auto'},justifyContent:"space-between"}}>
                    <p
                      style={{
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-medium)",
                        fontSize: "16px",
                        lineHeight: "17px",
                        marginRight: "10px",
                      }}
                    >
                      Total
                    </p>
                    <Cleave
                      value= {TotalPending}
                      style={{
                        width: "100px",
                        color: "rgba(34, 53, 109)",
                        fontFamily: "var(--font-regular)",
                        fontSize: "16px",
                        lineHeight: "17px",
                        padding: "5px 8px",
                        textAlign:'right'
                      }}
                      options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                        delimiter:SelectedLocation?.currency==="IDR"?'\u2024':','
                      }}
                    />
                  </Box>
                </Box>
                  </Grid>
                  </Grid>
                </Box>
                    <Grid marginTop={isOpen?'100px':'40px'} container justifyContent={"space-between"} alignItems={"center"}>
                    <Button
                      onClick={handleClose}
                      style={
                      {
                              textTransform: "unset",
                              fontFamily: "var(--font-semibold)",
                              width: "104px",
                              height: "37px",
                              color: "#22356D",
                              backgroundColor: "white",
                              fontSize: "16px",
                              padding: "10px 30px",
                              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                            }
                      }
                    >
                      {t('common.Cancel')}
                    </Button>
                    <Button
                      type='submit'
                      style={isClickable?{
                        textTransform: "unset",
                        fontFamily: "var(--font-semibold)",
                        color: "white",
                        backgroundColor: "#22356D",
                        lineHeight:'14px',
                        width: "92px",
                        height: "37px",
                        fontSize: "16px",
                        pointerEvents:'none'
                      }:{
                        textTransform: "unset",
                        fontFamily: "var(--font-semibold)",
                        color: "white",
                        backgroundColor: "#22356D",
                        lineHeight:'14px',
                        width: "92px",
                        height: "37px",
                        fontSize: "16px",
                        pointerEvents:'auto'
                      }}
                    >
                    {t('common.Save')}
                    </Button>
                </Grid>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default MakeSale;