import { getClubId } from "src/api/CalendarBooking";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Typography from "@mui/material/Typography";
import { activeColor } from 'src/constants/color';
import MainContext from "src/context/MainContext";
import localeEn from 'air-datepicker/locale/en';
import { useTranslation } from "react-i18next";
import { apiCaller } from "src/api/ApiCaller";
import AirDatepicker from 'air-datepicker';
import "air-datepicker/air-datepicker.css";
import "../../components/tableStyling.css";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./BookedRecurring.css";
import moment from "moment";

const divider = {
    display: "block",
    width: "0.8px",
    height: "20px",
    background: "#22356D"
}

const style={
    fontFamily:'var(--font-semibold)',
    boxShadow:"rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    padding: "7px 20px",
    borderRadius: '7px'
}

const BookedRecurring = ({setSave, conflictData,serviceData, setIsconflict, closeModel, serviceList}:{setSave: any, conflictData:any, serviceData:any, setIsconflict:(isConflict:boolean)=>void, closeModel:any, serviceList:any}) => {
    const {Notification,setNotification} = useContext(MainContext);
    const { t } = useTranslation();
    const [value, setValue] = React.useState('1');
    const [allBooking, setAllBooking] = useState<any>([]);
    const [conflictList, setConflictList] = useState<any>([]);
    const [approvedDates, setApproved] = useState<string[]>([]);
    const [selectDate, setSelectDate] = useState<any>('');
    const inputRef2 = useRef<any>(null);

    const handleSelectTab = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleDelete = async (date:string) =>{
        // try {
        //     deleteSchService(id);
        //     let data = conflictData.filter((item:any)=>{return item.id!==id});
        //     // setAllBooking(data);
        //     setConflictList(data);
        // } catch (error) {
        //     console.log(error)
        // }
        let filterered = approvedDates.filter((item)=>{ return item !== date } );
        let filterConflict = allBooking.filter((item:any) => {
            return item.date!==date;
        });
        let conflictLists = conflictList.filter((item:any)=>{ return item.date!==date });
        setConflictList(conflictLists); 
        setAllBooking(filterConflict);
        setApproved(filterered);
    };

    const handleSubmit = async () =>{
    
        let total_data = {
            ...serviceData,
            recurring_approved: true,
            approved_dates: approvedDates
        }
   
        try {
               let club_id = getClubId();
             let res = await apiCaller.post(`${club_id}/calendars/services`, total_data);
             console.log(res);
             serviceList();
             setIsconflict(false);
             closeModel();
             setSave(false);
             setNotification({isShow:true, message:'Recurrent booking created successfully',subMessage:'',type:'success'})
        } catch (error:any) {
            console.log(error);
            let err = error.response?.data?.message;
            setNotification({isShow:true,message:err,subMessage:'',type:'error'})
        }

    };

    useEffect(()=>{
        if(inputRef2.current){
            new AirDatepicker(inputRef2.current, {
                locale: localeEn,
                dateFormat: 'EEEE dd, MMMM yyyy',
                selectedDates: [new Date()],
                onSelect: (formate) =>{
                    setSelectDate(formate.formattedDate)
                }
            });
        }
    });

    useEffect(()=>{
        setApproved([]);
        setConflictList([]);
       if(conflictData.length>0){
        setAllBooking(conflictData)
        let arr:any = [];
        const uniqueDatesSet:any = new Set();

        conflictData.map((item:any) => {
            if (item.conflict) {
                arr.push(item);
            }
            uniqueDatesSet.add(item.date);
        });

        // Update the state with unique dates
        setApproved([...uniqueDatesSet]);
        // const uniqueDatesArray = [...new Set(arr)];
        setConflictList(arr)
       }
    },[conflictData])

    return (
        <Box>
            <Typography fontFamily={'var(--font-medium)'} paddingBottom={'7px'}>{t('Calendar.The following bookings were added to the calendar')}:</Typography>
            <Box display={'flex'} alignItems={'baseline'} gap={1}>
                <Box width={'25px'} paddingTop={'4px'}><img src="/images/info.svg" alt='information' style={{marginBottom:"-4px"}} /></Box>
                <Typography color={'#22356DB2'} fontFamily={'var(--font-regular)'}>{t('Calendar.Some of the desired time slots are busy. System offers the nearest time slot instead. Click the Edit button to select another time if needed.')}</Typography>
            </Box>
            <Box>
                <TabContext value={value}>
                    <Box>
                        <TabList onChange={handleSelectTab} aria-label="people list" TabIndicatorProps={{sx:{backgroundColor: activeColor}}} 
                            sx={{
                            ".MuiTab-root": {
                                paddingBottom:"0px"
                            },
                            ".Mui-selected": {
                                color: "#22356D !important",
                                fontFamily: 'var(--font-semibold)',
                            },
                            }}
                        >
                            <Tab label={`${t('Calendar.All New Bookings')} [${conflictData? conflictData.length: 0}]`} value="1" sx={{
                            fontFamily: 'var(--font-regular)'}} />
                            <Tab label={`${t('Calendar.Conflicting Schedule Bookings')} [0/${conflictList? conflictList.length: 0}]`} value="2" sx={{ fontFamily: 'var(--font-regular)'}} />
                        </TabList>
                    </Box> 
                    {/* -------- new booking listed ------- */}
                    <TabPanel value="1" sx={{padding:"10px 0"}}>
                        <Box id="table-person" height={'500px'} className="recur">
                            {allBooking?.map((item:any, index:number)=>
                            { 
                                const startTime = moment(item?.start_time,"HH:mm:ss").format('HH:mm');
                                const endTime = moment(item?.end_time,"HH:mm:ss").format('HH:mm');
                                return  <Stack direction={'row'} justifyContent={'space-between'} padding={2} key={item.id}>
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Paper sx={{padding:'1px 5px', height:'22px', fontFamily:"var(--font-regular)", color:"#22356D", fontSize:{xs:"0.8rem", sm:"1rem"}}}>{index + 1}</Paper>
                                    <Stack direction={'row'} spacing={1} fontFamily={'var(--font-regular)'} color={'#22356D'} sx={{flexDirection:{xs:"column", sm:"row"}, alignItems:{xs:"baseline", sm:"center"}}}>
                                        <Box>{item.date?.split("T")[0]}</Box>
                                        <span style={{...divider}} className="show-divider"></span>
                                        {item.conflict?
                                        <Box>
                                            <Box>{startTime}-{endTime}</Box>
                                            <Box sx={{background:'#FF131333',borderRadius:"7px", textDecoration:'line-through'}}>{startTime}-{endTime}</Box>
                                        </Box>
                                        :
                                        <Box>{startTime}-{endTime}</Box>}
                                        <span style={{...divider}} className="show-divider"></span>
                                        <Box>{item.court_name}</Box>
                                    </Stack>
                                </Stack>
                                <Box display={'flex'} alignItems={'center'} gap={1}>
                                    {/* <img src="/images/edit.svg" alt="edit" style={{cursor:'pointer'}} /> */}
                                    <img src='/images/del.svg' alt='delete' style={{cursor:'pointer'}} onClick={()=>handleDelete(item.date)} />
                                </Box>
                            </Stack>
                            }
                            )}
                        </Box>
                    </TabPanel>
                    {/* ------ conflict booking  list ----- */}
                    <TabPanel value="2" sx={{padding:"10px 0"}}>
                        <Box id="table-person" height={'500px'} className="recur">
                            {conflictList?.map((item:any, index:number)=>
                            {
                                const startTime = moment(item?.start_time,"HH:mm:ss").format('HH:mm');
                                const endTime = moment(item?.end_time,"HH:mm:ss").format('HH:mm')
                                return <Box key={item.id}>
                                <Stack direction={'row'} justifyContent={'space-between'} padding={2}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Paper sx={{padding:'1px 5px', height:'22px', fontFamily:"var(--font-regular)", color:"#22356D", fontSize:{xs:"0.8rem", sm:"1rem"}}}>{index + 1}</Paper>
                                        <Stack direction={'row'} spacing={1} sx={{flexDirection:{xs:"column", sm:"row"}, alignItems:{xs:"baseline", sm:"center"}}} fontFamily={'var(--font-regular)'} color={'#22356D'}>
                                            <Box>{item.date?.split("T")[0]}</Box>
                                            <span style={{...divider}} className="show-divider"></span>
                                            {item.conflict?
                                            <Box>
                                                <Box>{startTime}-{endTime}</Box>
                                                <Box sx={{background:'#FF131333',borderRadius:"7px", textDecoration:'line-through'}}>{startTime}-{endTime}</Box>
                                            </Box>
                                            :
                                            <Box>{startTime}-{endTime}</Box>}
                                            <span style={{...divider}} className="show-divider"></span>
                                            <Box>{item.court_name} </Box>
                                        </Stack>
                                    </Stack>
                                    <Box display={'flex'} alignItems={'center'} gap={1}>
                                        <img src='/images/del.svg' alt='delete' style={{cursor:'pointer'}} onClick={()=>handleDelete(item.date)} />
                                    </Box>
                                </Stack>
                            </Box>
                            }
                            )}
                        </Box>
                    </TabPanel>
                </TabContext>
                <Box display={'flex'} justifyContent={'space-between'} marginTop={2} >
                    <button style={{...style, color: activeColor}} onClick={()=>setSave(false)}>{t('common.Cancel')}</button>
                    <button style={{...style, color: 'white', background:activeColor}} onClick={()=>handleSubmit()}>{t('common.Save')}</button>
                </Box>
            </Box>
        </Box>
    )
}

export default BookedRecurring