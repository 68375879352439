import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { activeColor } from 'src/constants/color';
import { getLogs } from 'src/api/CalendarBooking';
import moment from 'moment';
import CustomSVG from './CustomSVG';


const BlockedLogs = ({events}:{events:any}) => {
 
    const [logDetails, setLogDetails] = useState<any>({});

    const getAllLogs = async (serviceId:number) =>{
        try {
          let res = await getLogs(serviceId);
          setLogDetails(res?.data?.data);
        } catch (error:any) {
          console.log(error);
        }
      }
    
      useEffect(()=>{
        getAllLogs(events?.id);
      },[])

    return (
        <Box>
            <Box marginBottom={2}>
                <Box fontFamily={'var(--font-semibold)'} color={activeColor}>Logs</Box>
                {Object.keys(events).length > 0 && <Stack direction={'row'} spacing={1}>
                    <Box display={'flex'} paddingInlineEnd={1} paddingBlock={1} alignItems={'center'} justifyItems={'center'} paddingTop={3}>
                       <CustomSVG/>
                    </Box>
                    <Box>
                        <Box className='logs'>
                            <p>{moment(logDetails?.lastLog?.created_at).format('HH:mm')} {moment(logDetails?.lastLog?.created_at).format('DD/MM/YYYY')}</p>
                            <p>Booking {logDetails?.lastLog?.log}</p>
                            <p>By {logDetails?.lastLog?.user?.role?.name} - {logDetails?.lastLog?.user?.full_name}</p>
                        </Box>
                        {/* {logDetails?.lastNote && <Box className='logs'>
                            <p>{moment(logDetails?.lastNote?.created_at).format('HH:mm')} {moment(logDetails?.lastNote?.created_at).format('DD/MM/YYYY')}</p>
                            <p>Booking Updated by Made ({logDetails?.lastNote?.user?.role?.name})</p>
                            <p>{logDetails?.lastNote?.internal_note} </p>
                        </Box>} */}
                    </Box>
                </Stack>}
            </Box>
        </Box>
    )
}

export default BlockedLogs