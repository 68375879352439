import React, {useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IoIosArrowDown } from "react-icons/io";
import { FormatSpecialResponse } from "src/constants/FormatResponse";
import { RxCross2 } from "react-icons/rx";
import CustomCalenderTime from "./CustomCalenderTimeSpecialDay";
import AddIcon from "@mui/icons-material/Add";
import { Button, Typography } from "@mui/material";
import BreakTimeRangePicker from "../../components/CustomDropdownBreakTimeRange";
import { useTranslation } from "react-i18next";

const leftborderInput = {
  width: "200px",
  height: "28px",
  opacity: "0.9",
  padding: "5px 5px 5px 0px",
  fontSize: "14px",
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  color: "#22356D",
};


const ConditionalDaysRow = ({
  conditionData,
  setServicesOpen,
  handleUpdateForm,
  setValue,
  index,
  remove,
}: {
  index: any;
  conditionData: any;
  handleUpdateForm: any;
  setValue: any;
  remove: any;
  setServicesOpen:any;
}) => {
  const [CalendarShow, setCalendarShow] = useState(false);
  const {t} = useTranslation();
  const [showBreak, setShowBreak] = useState(false);

  const handleClose = () => {
    setCalendarShow(false);
  };

  const removeBreak = (i: number) => {
    const updated = conditionData[index].break_times?.filter(
      (item: any, index: number) => index !== i
    );
    setValue(`Rows.${index}.break_times`, updated);
  };


  const handleUpdateBreak = (data: any) => {
    setValue(`Rows.${index}.break_times`, data);
    setShowBreak(false);

  };

  return (
    <Box>  
    <Box
      className="flex flex-row justify-between gap-4"
      sx={{ margin: "5px 0px" }}
    >
      <p
          className="flex items-center justify-between"
          onClick={() => setCalendarShow(true)}
          style={leftborderInput}
        >
          <input
            style={{
              width: "100%",
              fontSize: "14px",
              fontFamily: "var(--font-regular)",
            }}
            placeholder="Select special dates"
            value={FormatSpecialResponse(conditionData[index])}
          />
          <IoIosArrowDown fontSize={"16px"} opacity={0.7} />
        
        </p>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button
            onClick={() => setShowBreak(true)}
            style={{
              color: "rgba(34, 53, 109, 0.70)",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",
              gap: "10px",
              textTransform: "none",
            }}
          >
            {t('courts.Add time break')}
            <AddIcon
              style={{ opacity: 0.7, color: "rgba(34, 53, 109, 1)" }}
              fontSize="small"
            />
          </Button>
          <RxCross2 cursor={"pointer"} onClick={()=>remove(index)}/>
        </Box>
      
      
       
      <Dialog hideBackdrop={true} onClose={handleClose} open={CalendarShow}>
        <DialogContent>
          <CustomCalenderTime
          handleClose={handleClose}
            index={index}
            preTime={conditionData[index]}
            setGetDateTime={(e: any) => handleUpdateForm(e, index)}
            setShowCalendar={setCalendarShow}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            width: "auto",
            height: "200px",
          },
        }}
        hideBackdrop={true}
        onClose={()=>setShowBreak(false)}
        open={showBreak}
      >
        <DialogContent>
          <Typography
            sx={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              margin: "10px",
            }}
          >
            {t('courts.Add break times')}
          </Typography>
          <BreakTimeRangePicker
            handleClose={handleClose}
            preTime={conditionData[index]?.break_times}
            getTime={(e: any) => handleUpdateBreak(e)}
          />
        </DialogContent>
      </Dialog>
    </Box>
    <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap',gap:'5px',marginTop:'-5px'}}>
    {conditionData[index]?.break_times?.length
      ? conditionData[index]?.break_times?.map((item: any, index: any) => (
          <div
            key={index}
            style={{ marginRight: "5px" }}
            className="relative"
          >
            <span
              key={index}
              className="flex justify-between items-center"
              style={{
                height: "28px",
                width: "110px",
                padding: "5px 8px",
                rowGap: "5px",
                color: "#22356D",
                borderRadius: "7px",
                backgroundColor: "rgba(136, 154, 182, 0.15)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
              }}
            >
              <span>
                {item?.start_break.slice(0, 5)}-
                {item?.end_break.slice(0, 5)}
              </span>
              <Box
                onClick={() => removeBreak(index)}
                sx={{
                  marginTop: "-2px",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#22356DB2",
                    color: "#FFFFFF",
                  },
                }}
              >
                <RxCross2 />
              </Box>
            </span>
          </div>
        ))
      : ""}
  </Box>
    </Box>
  );
};
export default ConditionalDaysRow;
