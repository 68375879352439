import { apiCaller } from "./ApiCaller";

const LoginMe = async ({ email, password }: { email: string, password: string }) => {
  try {
    const response = await apiCaller.post("/users/login", {
      email: email,
      password: password,
    });
    return response.data;
  } catch (error:any) {
   throw error
  }
};

export { LoginMe };
