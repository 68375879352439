import { Box, Button, Dialog, DialogContent, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import LessonsRows from './LessonsRows';
import { GoPlus } from 'react-icons/go';
import LessonsSetting from './LessonsServices';
import { useGetAllLessonsServices } from 'src/hooks/useGetAllLessonsService';
import filterBySport from 'src/constants/SportSEparator';
import { useTranslation } from 'react-i18next';
import StaffModal from 'src/Notification/StaffModal';
import { Permission } from 'src/api/CalendarBooking';


const LessonsLeft = ({sports,selectedSport,selectedLocation}:{sports:any,selectedSport:any,selectedLocation:any}) => {
    const [LessonID, setLessonID] = useState<number>();
    const { t } = useTranslation();
    const [LessonData,setLessonData] = useState<any>([]);
    const [sport_id, setsport_id] = useState<number>();
    const [showLessonSetting,setshowLessonSetting] = useState(false);
    const {data,isSuccess,isError,refetch,setSportId,setlocation_id} = useGetAllLessonsServices();

    
    useEffect(() => {
      if(!!selectedLocation){
        setSportId(0);
        setlocation_id(selectedLocation?.id);
      }
    }, [selectedLocation])

    
    useEffect(() => {
      if(isSuccess){
        getBookingDATA(data);
      }else{
      setLessonData([]);
      }
    }, [data,isSuccess,isError])


    const getBookingDATA = async (response:any) => {
        const final = filterBySport(response);
        setLessonData(final);
      };
        

    
    const handleClicked = (data: any) => {
        setsport_id(data?.sport?.id);
        setLessonID(data.id);
        setshowLessonSetting(true);
      };
    
      const handleAdd = (sport_id: number) => {
        setLessonID(undefined);
        setsport_id(sport_id);
        setshowLessonSetting(true);
      };

      
  const handleClose = () => {
    setshowLessonSetting(false);
  };


  return (
    <Grid item xs={12} sm={12} md={6}>   
      <Box sx={{display:{xs:"none", sm:"none", md:"block"}}}>
      <span
        style={{
          color: "#22356D",
          fontFamily: "var(--font-semibold)",
          fontSize: "16px",
          borderBottom: "1px solid #22356D",
        }}
      >
        {t('common.Lessons')}
      </span>
    </Box>

    { sports?.map((item: any) => {
    return selectedSport?.sport_name == item?.sport_name ||
      selectedSport?.sport_name == "All Sports" ? (
      <Box key={item?.id}>
        <h3
          style={{
            marginTop: "20px",
            color: "#22356D",
            fontFamily: "var(--font-semibold)",
            fontSize: "16px",
          }}
        >
          {item?.sport_name}
        </h3>
        <Button
          onClick={() => handleAdd(item.id)}
          className="items-center flex flex-row justify-normal"
          style={{
            padding: "0",
            width: "190px",
            color: "rgba(34, 53, 109, 0.70)",
            fontFamily: "var(--font-regular)",
            fontSize: "14px",
            gap: "10px",
            textTransform: "unset",
          }}
        >
          {t('services.lesson.Add new lesson')}
          <GoPlus style={{ verticalAlign: "middle", fontSize: "14px" }} />
        </Button>
        <Box>
          <Box
            style={{
              marginTop: "10px",
              borderRadius: "7px",
              boxShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.1)",
              background:
                "linear-gradient(0deg, rgba(238, 239, 238, 0.05) 0%, rgba(238, 239, 238, 0.05) 100%), #FFF",
              border: "0.5px solid #EEEFEE",
            }}
          >
            <Box
              id={ selectedSport?.sport_name == "All Sports"?"table-person":''}
              className="overflow-hidden"
              style={{
                marginTop: "0px",
                width: "-webkit-fill-available",
                maxHeight: selectedSport?.sport_name == "All Sports"? "210px":'',
                overflowY: "auto",
              }}
            >
              {LessonData[`${item.sport_name}`]?.sort((a:any,b:any)=>a.duration-b.duration)?.map(
                (item: any, index: number) => (
                  <div onClick={() => handleClicked(item)} key={index} >
                    
                    <LessonsRows
                      index={index}
                      key={index}
                      Data={item}
                      selectedLocation={selectedLocation}
                    />
                  </div>
                )
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    ) : (
      ""
    );
    })}



    <Dialog
     sx={{".MuiDialog-paper":{
      minWidth:{xs:"325px",sm:"525px", md:"850px"}
    }}} 
    onClose={handleClose}
    open={showLessonSetting}>
      <DialogContent style={{marginTop:"0px"}} id='table-person'>
        <LessonsSetting GetLessonData={refetch} handleClose={handleClose} selectedlocation={selectedLocation} sport_id={sport_id} Lesson_id={LessonID}/></DialogContent>
    </Dialog>
    </Grid>
  )
}

export default LessonsLeft