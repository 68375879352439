import Box from "@mui/material/Box";
import { Select, SelectItem, Avatar } from "@nextui-org/react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { BsChevronDown } from 'react-icons/bs';
import { useGetAllCoachesServices } from "src/hooks/useGetAllCoachesServices";

interface CoachSelectorProps {
   locationId: number;
   selectedCoach: any[];
   setSelectedCoach: any;
   view:string;
   SelectedSport:any;
   setSelectedSport:any;
   slectedService:any;
 }

const CoachSelector: React.FC<CoachSelectorProps> = ({locationId,slectedService, selectedCoach,setSelectedSport, setSelectedCoach,view,SelectedSport}) => {
 
   const {t} = useTranslation();
  
   const [coachList, setCoachList] = useState([]);
   const {data,isSuccess,isError,refetch,setlocation_id,setSportId} =useGetAllCoachesServices();

   let arr:number[] = [];
   const handleCoachList = (id: number) => {
      setSelectedCoach((prev: any) => {
         if (prev?.length === 0 || prev === undefined) {
           return [id];
         } else if (prev?.includes(id)) {
           return prev.filter((old: any) => old !== id);
         } else {
           return [...prev, id];
         }
       });
   }

    useEffect(()=>{
     if(locationId){
      setlocation_id(locationId)
      setSportId(0);
     }
    },[locationId]);


    useEffect(() => {
     if(isSuccess){
      if(SelectedSport && view==="Courts"){
         const filtered = data?.filter((item:any)=>item?.sport.id===SelectedSport);
         setCoachList(filtered);
      }else if(view==="Coaches"){
          const filtered:any= selectedCoach?.map((element:any) => {
         return data?.filter((item:any)=>item.id===element)[0]
       });
         const allCoaches = data?.filter((item:any)=>item?.sport?.id===filtered[0]?.sport_id);
         setCoachList(allCoaches);
      }else{
         const allCoaches = data?.filter((item:any)=>item?.sport?.id===slectedService?.sport_id);
         setCoachList(allCoaches);
      }
     }else{
      setCoachList([]);
     }
    }, [data,isSuccess,isError,SelectedSport,view,selectedCoach]);


    useEffect(() => {
      const filtered:any= selectedCoach?.map((element:any) => {
         return coachList?.filter((item:any)=>item.id===element)[0]
       });
      //  if(!!filtered){
      //    setSelectedSport(filtered[0]?.sport_id);
      //  }
    }, [selectedCoach,coachList]);
    

   return(
      <Box sx={{width:{xs:"inherit", sm:"300px"} ,border:'1px solid #EEEEFE', borderRadius:"7px"}}>
         <Select
            selectedKeys={selectedCoach?.map((item:any)=>String(item))}
            items={coachList}
            label=""
            selectionMode="multiple"
            aria-label='coaches'
            placeholder={t("Calendar.Select Coach")}
            selectorIcon={<BsChevronDown style={{width:"14px !important", height:'14px !important',right:"0.5rem !important"}} />}
            labelPlacement="outside"
            classNames={{
               trigger:"bg-transparent shadow-none hover:bg-transparent user-select",
               value:"coach-placeholder"
            }} 
            renderValue={(items:any) => {
            return (
               <Box display={'flex'} gap={2}>
                  {items.map((item:any) => (
                     <div key={item.data.id} className="flex items-center gap-2">
                        <Avatar
                           alt={item.data.full_name}
                           className="flex-shrink-0"
                           size="sm"
                           src={item.data.profile_url?item.data.profile_url:"/images/Reserved.svg"}
                        />
                        <div className="flex flex-col">
                           <span style={{fontSize:"1rem", color:"#22356D", fontFamily:"var(--font-medium)"}}>{item.data.full_name}</span>
                        </div>
                     </div>
                  ))}
               </Box>
            )}}
         >
         {(coachList:any) => (
            <SelectItem key={coachList.id} textValue={coachList.full_name} className="user-item" onClick={()=>handleCoachList(coachList.id)}>
               <div className="flex gap-2 items-center">
                  <Avatar alt={coachList.full_name} className="flex-shrink-0" size="sm" src={coachList.profile_url?coachList.profile_url:"/images/Reserved.svg"} />
                  <div className="flex flex-col">
                     <span className="span-tag" style={{fontSize:"1rem", fontFamily:"var(--font-regular)"}}>{coachList.full_name}</span>
                  </div>
               </div>
            </SelectItem>
         )}
         </Select>
      </Box>
   )
}

export default CoachSelector;