import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Cleave from "cleave.js/react";
import { useForm } from "react-hook-form";
import { addThousandSeparator } from "src/constants/SportSEparator";
import { activeColor } from "src/constants/color";
import { SingleDate } from "../../components/customTimeSeletor";
import moment from "moment";
import { postion } from "src/constants/variables";
import { addMembershipToCust, getAllMemeberShip } from "src/api/membership";
import { useGetAllPaymentMethodsByLoc } from "src/hooks/useGetAllPaymentMethods";
import { useGetLocation } from "src/api/CourtSettings";
import MainContext from "src/context/MainContext";
import { useTranslation } from "react-i18next";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

interface formInput {
  selectedmembership: any;
  selectedLocation: any;
  selectedPayment: any;
  price: number;
  startingDate: Date;
}

const CustomerAddMembership = ({
  profile_url,
  customerdata,
  customerSetting,
  setIsOpen,
  update,
}: {
  profile_url: any;
  customerdata: any;
  customerSetting: any;
  setIsOpen: any;
  update:any
}) => {
  const {t} = useTranslation();
  const [AllMembersips, setAllMemberShips] = useState<any[]>([]);
   const StaffPermission = useGetPermissions();
  const {Notification,setNotification,isPermissionOn,setIsPermissionOn} = useContext(MainContext);
  const { register, watch, handleSubmit, setValue } = useForm<formInput>();
  const [paymentsMethod, setPaymentMethods] = useState<any[]>([]);
  const {
    data: PaymentMethodsByLoc,
    isError: PaymentMethodsByLocError,
    isSuccess: PaymentMethodsByLocSuccess,
    refetch: PaymentMethodsByLocRefetch,
    setlocation_id,
  } = useGetAllPaymentMethodsByLoc();
  const { data, isSuccess, isError } = useGetLocation();
  const [Locations, setLocations] = useState([]);
  const {
    selectedmembership,
    selectedPayment,
    price,
    startingDate,
    selectedLocation,
  } = watch();

  const style = {
    fontFamily: "var(--font-semibold)",
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    padding: "7px 20px",
    borderRadius: "7px",
  };

  const onSubmit = async () => {
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      setIsOpen(false);
      return;
    }

   try {
    const finaldata = {
      "membership_id": selectedmembership.id,
      "customer_id": customerdata.id,
      "start_date": moment(startingDate).format('YYYY-MM-DD'),
      "payment_method_id": selectedPayment.id,
      "location_id": selectedLocation.id,
      "price": selectedmembership.price
    }
    const res =  await addMembershipToCust(finaldata);
    setIsOpen(false);
    update();
    setNotification({isShow:true,message:'Membership assigned to customer',subMessage:'',type:'success'})
   } catch (error:any) {
    console.error(error,'err')
    // setNotification({isShow:true,message:error,subMessage:'',type:'error'})
   }
  };

  const getMembership = async () => {
    try {
      let res = await getAllMemeberShip();
      setAllMemberShips(res);
    } catch (error) {
      console.log(error);
      setAllMemberShips([]);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      if (!!selectedmembership) {
        const filtereLocation = data.filter((loc: any) => {
          return selectedmembership?.locations?.some(
            (ite: any) => ite.id === loc.id
          );
        });
        setLocations(filtereLocation);
      } else {
        setLocations([]);
      }
    } else {
      setLocations([]);
    }
  }, [data, isSuccess, isError, selectedmembership]);

  useEffect(() => {
    if (PaymentMethodsByLocSuccess) {
      setPaymentMethods(PaymentMethodsByLoc);
    } else {
      setPaymentMethods([]);
    }
  }, [
    selectedmembership,
    PaymentMethodsByLoc,
    PaymentMethodsByLocError,
    PaymentMethodsByLocSuccess,
  ]);

  useEffect(() => {
    getMembership();
  }, []);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{
            fontFamily: "var(--font-medium)",
            color: "#22356D",
            marginRight: "5rem",
          }}
        >
          {t('payments.Add new Membership')}
        </Typography>
        <Box
          display={"flex"}
          marginTop={2}
          gap={"27px"}
          flexDirection={"column"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              gap: "5px",
            }}
          >
            <img
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
              }}
              src={profile_url ? profile_url : "/images/Reserved.svg"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                  textAlign: "left",
                }}
              >
                {customerdata?.first_name} {customerdata?.last_name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {customerSetting?.customerTypes?.map((item: any) => (
                  <Typography
                    sx={{
                      fontFamily: "var(--font-regular)",
                      color: "#22356D",
                      padding: "0px 5px",
                      font: "12px",
                      borderRadius: "4px",
                      border: " 0.8px solid rgba(136, 154, 182, 0.22)",
                      background:
                        "var(--Main-Colors-Blue-Secondary-Transparency-2, rgba(136, 154, 182, 0.15))",
                    }}
                  >
                    {item?.name}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              gap: { xs: "10px", sm: "10px", md: "27px" },
            }}
          >
            <FormControl>
              <Select
                IconComponent={KeyboardArrowDownIcon}
                {...register("selectedmembership", { required: true })}
                value={selectedmembership}
                id="customdropdown"
                displayEmpty
                renderValue={(value) => {
                  if (value) {
                    return value.membership_name;
                  }
                  return <em>Select Membership</em>;
                }}
                style={{
                  width: "180px",
                  height: "35px",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontSize: "14px",
                  opacity: "1",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                }}
                MenuProps={{ sx: dopdowncustomcss }}
              >
                {AllMembersips?.map((location) => (
                  <MenuItem key={location.id} value={location}>
                    {location.membership_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <SingleDate
              IcPosition="right"
              format={"DD/MM/yyyy"}
              type={"single"}
              containercss={{
                border: "1px solid #EEEFEE",
                borderRadius: "7px",
                width: "160px",
              }}
              placeholder={"starting date"}
              selectedDay={startingDate}
              setSelectedDays={(data: any) => {
                const okay = moment(data, "DD/MM/YYYY").toDate();
                setValue("startingDate", okay);
              }}
              icon={true}
              style={{
                fontSize: "14px",
                postion: "relative",
                color: "#22356D",
                background: "transparent",
                width: "120px",
              }}
            />
            <FormControl>
              <Select
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                placeholder="Location"
                value={selectedLocation}
                id="customdropdown"
                renderValue={(value: any) => {
                  if (!!value) {
                    return value?.location_name;
                  }
                  return <em>Location</em>;
                }}
                style={{
                  width: "180px",
                  height: "35px",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontSize: "14px",
                  opacity: "1",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                }}
                MenuProps={{ sx: dopdowncustomcss }}
              >
                {Locations?.map((location: any) => (
                  <MenuItem
                    onClick={(e) => {
                      setlocation_id(location?.id);
                      setValue('selectedLocation',location)
                    }}
                    key={location?.id}
                    value={location}
                  >
                    {location?.location_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                placeholder="Customer type"
                value={selectedPayment}
                id="customdropdown"
                {...register("selectedPayment", { required: true })}
                renderValue={(value: any) => {
                  if (!!value) {
                    return value?.method_name;
                  }
                  return <em>Payment Method</em>;
                }}
                style={{
                  width: "180px",
                  height: "35px",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontSize: "14px",
                  opacity: "1",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                }}
                MenuProps={{ sx: dopdowncustomcss }}
              >
                {paymentsMethod?.map((location: any) => (
                  <MenuItem key={location?.id} value={location}>
                    {location?.method_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="flex flex-row items-center gap-1">
              <p
                style={{
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-medium)",
                  fontSize: "14px",
                  lineHeight: "17px",
                }}
              >
{selectedLocation?.currency||"IDR"}                
              </p>

              <Cleave
                value={selectedmembership?.price||0}
                readOnly
                style={{
                  width: "100px",
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  lineHeight: "17px",
                  borderRadius: "7px",
                  padding: "5px 8px",
                  border: "1px solid #EEEFEE",
                }}
                placeholder="100"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter: "\u2024",
                }}
              />
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          <div className="flex flex-row items-center gap-1">
            <p
              style={{
                color: "rgba(34, 53, 109)",
                fontFamily: "var(--font-medium)",
                fontSize: "14px",
                lineHeight: "17px",
                marginRight: "10px",
              }}
            >
              Price
            </p>

            <p
              style={{
                width: "100px",
                color: "rgba(34, 53, 109)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                lineHeight: "17px",
                padding: "5px 8px",
              }}
            >
              {addThousandSeparator(selectedmembership?.price || 0, selectedLocation?.currency||"IDR")} {selectedLocation?.currency||"IDR"}
            </p>
          </div>

          <div className="flex flex-row items-center gap-1">
            <p
              style={{
                color: "rgba(34, 53, 109)",
                fontFamily: "var(--font-medium)",
                fontSize: "14px",
                lineHeight: "17px",
                marginRight: "10px",
              }}
            >
              Total
            </p>

            <p
              style={{
                width: "100px",
                color: "rgba(34, 53, 109)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                lineHeight: "17px",
                padding: "5px 8px",
              }}
            >
              {addThousandSeparator(selectedmembership?.price||0, selectedLocation?.currency||"IDR")} {selectedLocation?.currency||"IDR"}
            </p>
          </div>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} marginTop={2}>
          <button
            style={{ ...style, color: activeColor }}
            onClick={() => setIsOpen(false)}
          >
            {t('common.Cancel')}
          </button>
          <button
            type="submit"
            style={{
              ...style,
              color: "white",
              background: activeColor,
            }}
          >
              {t('common.Save')}
          </button>
        </Box>
      </form>
    </Box>
  );
};

export default CustomerAddMembership;
