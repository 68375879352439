import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import React, { useContext, useEffect, useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import CourtSettingsRightRow from "./CourtSettingsRightRow";
import {
  CreateBookableSlot,
  DeleteCourtByID,
  GetBookableTimeSlotsByID,
  submitBookableSlotsByID,
} from "src/api/CourtSettings";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import { RiExpandUpDownLine } from "react-icons/ri";
import { days } from "src/constants/variables";
import ServicesSpecialRow from "../../components/Services/ServicesSpecialRow";
import TimeBasedCondition from "./TimeBasedConditon";
import MainContext from "src/context/MainContext";

interface Bookable {
  end_time: string;
  start_time: string;
  time_range: string;
}

interface FormValues {
  bookable: Bookable[];
}


const CourtSettingRight = ({
  Selectedcourt,
  selectedLocation,
  sport,
  fromaddCourt,
  update,
  BookableDaysFut,
  setBookableDaysFut
}: {
  Selectedcourt: any;
  selectedLocation: any;
  sport: any;
  fromaddCourt: boolean;
  update:any;
  BookableDaysFut:string;
  setBookableDaysFut:any;
}) => {
  const {t} = useTranslation();
  const {setNotification} =useContext(MainContext);
  const [AllDuration,setAllDurations] = useState<any[]>([]);
   const {control: SpecialControl,setValue: setSpecial,watch: specialWatch,} = useForm<any>({});
  const {fields: FieldsSpecial,remove: removeSpecial,append: specailAppend,insert: specialInsert,} = useFieldArray<any>({
    control: SpecialControl,
    name: "Special",
  });
  const { control, register, setValue, watch } = useForm();
  const { fields, append, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "Rows",
    }
  );
  const conditionData = watch("Rows");
  const specialData = specialWatch("Special");



  useEffect(() => {
    getBookableTimeSlotsByID();
  }, [Selectedcourt, fromaddCourt]);

  const updatedObj = specialData?.flatMap((item:any)=>{
    const {days,date_start,date_end,...newitem} =item;
    if(days?.length>0){
      return  days?.map((day:string)=>{
        return {days:day,...newitem}
       });
    }else{
      return item;
    }
  })

  const RestrictUpdate = conditionData?.flatMap((item:any)=>{
    const {days,date_start,date_end,duration,...newitem} =item;
    const allDuration = duration?.map((dur:any)=>dur.id);
    if(days?.length>0){
      return  days?.map((day:string)=>{
        return {days:day,booking_ids:allDuration,...newitem}
       });
    }else{
      return {date_start,date_end,booking_ids:allDuration,...newitem};
    }
  })


  const apiData = {
    no_gaps_between_bookings:updatedObj,
    time_based_service_restriction:RestrictUpdate,
    app_bookable_days_in_future:BookableDaysFut
  }

   
  useEffect(()=>{
    AllDuration?.map((item:any,index:number)=>{
      setValue(`Rows.${index}.duration`,item);
    })
  },[AllDuration])



  //get court by court_id
  const getBookableTimeSlotsByID = async () => {
    removeSpecial();
    remove();
    setAllDurations([]);
    if (Selectedcourt.court_id > 0 && fromaddCourt === false) {
     try {
      const response = await GetBookableTimeSlotsByID(Selectedcourt.court_id);
      const finalCondition = response?.noGapRestriction?.map(
        (item: any, index: number) => {
          let { days,court,...newitem } = item;
          const id = index;
          days !== null ? (days = [days]) : (days = undefined);
          specailAppend({days,...newitem })
          return { id, days, ...newitem };
        }
      );
      const Restriction = response?.serviceRestiction?.map(
        (item: any, index: number) => {
          let { days,court,bookings,...newitem } = item;
          const id = index;
          days !== null ? (days = [days]) : (days = undefined);
          setAllDurations((prev:any)=>[...prev,bookings]);
          append({days,...newitem })
          return { id, days, ...newitem };
        }
      );

     } catch (error) {
        //  remove();
     }
    } else {
      // remove();
    }
  };

  const handleDelete = async () => {
    if (
      Selectedcourt?.court_id > 0 &&
      Selectedcourt?.court_id !== undefined &&
      fromaddCourt === false
    ) {
      try {
        const response = await DeleteCourtByID(Selectedcourt?.court_id);
        setNotification({isShow:true,message:response.message,subMessage:'',type:'success'})
        update();
      } catch (error:any) {
        setNotification({isShow:true,message:error,subMessage:'',type:'error'})
        console.log(error)
      }
     
    }
  };

  // post court by court_id
  const Onsubmit = async () => {

    if(Selectedcourt?.court_id > 0 &&
      Selectedcourt?.court_id !== undefined){
       try {
        const response = await submitBookableSlotsByID(
          Selectedcourt.court_id,
          apiData
        );
        setNotification({isShow:true,message:response.message,subMessage:'',type:'success'})
       } catch (error:any) {
        setNotification({isShow:true,message:error,subMessage:'',type:'error'})
        console.log(error)
       }
    }else{
      handleSubmitByLocationAndSport(apiData)
    }
      };

  //update all courts
  const handleSubmitByLocationAndSport = async (apidata:any) => {
   try {
    const response = await CreateBookableSlot(
      sport?.id,
      apidata
    );
    setNotification({isShow:true,message:response.message,subMessage:'',type:'success'})
   } catch (error:any) {
    setNotification({isShow:true,message:error,subMessage:'',type:'error'})
    console.log(error)
   }
  };


  const handleAddSpecial = () => {
    const final = {
      start_time: "08:00:00",
      end_time: "22:00:00",
    };
    specailAppend(final);
  };

  const handleAddCondition = () => {
    const final = {
      start_time: "08:00:00",
      end_time: "22:00:00",
      duration:[]
    };
    append(final);
  };



  const handleUpdateSpecial = (data: any, index: number) => {
    setSpecial(`Special.${index}.start_time`, data[0].start_time);
    setSpecial(`Special.${index}.end_time`, data[0].end_time);
    const updatedObj = specialData[index];
    if (
      data[0].days !== undefined &&
      data[0].date_start == undefined &&
      data[0].date_end == undefined
    ) {
      delete updatedObj["date_start"];
      delete updatedObj["date_end"];
      setSpecial(`Special.${index}.days`, data[0].days);
    } else if (
      data[0]?.date_end !== undefined &&
      data[0]?.date_start !== undefined &&
      data[0]?.days === undefined
    ) {
      delete updatedObj["days"];
      setSpecial(`Special.${index}.date_start`, data[0].date_start);
      setSpecial(`Special.${index}.date_end`, data[0].date_end);
    }
  };

  const handleUpdateCondition = (data: any, index: number) => {
    setValue(`Rows.${index}.start_time`, data[0].start_time);
    setValue(`Rows.${index}.end_time`, data[0].end_time);
    const updatedObj = conditionData[index];
    if (
      data[0].days !== undefined &&
      data[0].date_start == undefined &&
      data[0].date_end == undefined
    ) {
      delete updatedObj["date_start"];
      delete updatedObj["date_end"];
      setValue(`Rows.${index}.days`, data[0].days);
    } else if (
      data[0]?.date_end !== undefined &&
      data[0]?.date_start !== undefined &&
      data[0]?.days === undefined
    ) {
      delete updatedObj["days"];
      setValue(`Rows.${index}.date_start`, data[0].date_start);
      setValue(`Rows.${index}.date_end`, data[0].date_end);
    }
  };



  return (

      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "white",
        padding: { xs: "0px", sm:"0px", md: "33px 50px 55px 50px" },
        borderRadius: "7px",
        marginLeft: { xs: "0px", sm:"0px", md: "60px" },
        marginTop: { xs: "0", sm:"0", md: "15px" },
        height: "100%",
      }}
    >
      <Grid>
        <Box
          sx={{
            display: { xs: "none", sm:"none", md: "block" },
            margin: "10px 0",
            color: "#22356D",
            fontFamily: "var(--font-semibold)",
            fontSize: "16px",
          }}
        >
          {fromaddCourt ? t(
            "courts.Create Court"
          ) : Selectedcourt.court_id === 0 || Selectedcourt === "" ? (
            <Box sx={{ color: "#22356D" }}>{t('courts.All Courts')}</Box>
          ) : (
            Selectedcourt?.court_name
          )}
        </Box>
        <Box
          sx={{
            width: "max-content",
            display: { xs: "none", sm:"none", md: "block" },
            margin: "10px 0",
            color: "#22356D",
            fontFamily: "var(--font-semibold)",
            fontSize: "16px",
            borderBottom: "1px solid #22356D",
          }}
        >
          {t('courts.App Settings')} - {t('courts.Booking Rules')}
        </Box>
        <Typography  sx={{
                color: "rgba(34, 53, 109, 0.70)",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                fontFamily: "var(--font-regular)",
                fontSize: { xs: "12.5px", sm:"0px", md: "14px" },
                lineHeight: "17px",
              }}> <BsInfoCircleFill
              style={{
                fontSize: "12px",
                border: "rgba(34, 53, 109, 0.70) solid 1px",
                borderRadius: "50%",
                color: "white",
                backgroundColor: "rgba(34, 53, 109, 0.70)",
              }}
            /> {t('courts.This settings will only affect users booking online')}</Typography>
        <Box
          className="flex flex-row justify-between items-center"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm:"column", md: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "baseline", sm:"baseline", md: "center" },
          }}
        >
            <Typography
              sx={{
                margin: "10px 0",
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: { xs: "14px", sm:"14px", md: "16px" },
              }}
            >
              {t('courts.No-gap between bookings')}
            </Typography>
          <Button
            onClick={handleAddSpecial}
            style={{
              width: "140px",
              color: "rgba(34, 53, 109, 0.70)",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",
              gap: "10px",
              textTransform: "unset",
            }}
          >
           {t('courts.Add time range')}
            <AddIcon style={{ verticalAlign: "middle" }} fontSize="small" />
          </Button>
        </Box>
        <Grid>
            <Box
              sx={{
                display:'flex',flexDirection:'column',gap:'5px',
                margin: { xs: "0px", sm:"0px", md: "0 20px" },
                marginBottom: { xs: "10px", sm:"10px", md: "0px" },
              }}
            >
             {FieldsSpecial?.length !== 0 &&
                FieldsSpecial?.map((item: any, index: any) => (
                  <ServicesSpecialRow
                isBreak={false}
                remove={removeSpecial}
                handleUpdateSpecial={handleUpdateSpecial}
                preSpecialDays={specialData}
                key={index}
                index={index}
                setSpecial={setSpecial}
              />
                ))}
            </Box>
        </Grid>
        <Box
          className="flex flex-row justify-between items-center"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm:"column", md: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "baseline", sm:"baseline", md: "center" },
          }}
        >
            <Typography
              sx={{
                margin: "10px 0",
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: { xs: "14px", sm:"14px", md: "16px" },
              }}
            >
              {t('courts.Time-based service restriction')}
            </Typography>
          <Button
            onClick={handleAddCondition}
            style={{
              width: "140px",
              color: "rgba(34, 53, 109, 0.70)",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",
              gap: "10px",
              textTransform: "unset",
            }}
          >
           {t('courts.Add time range')}
            <AddIcon style={{ verticalAlign: "middle" }} fontSize="small" />
          </Button>
        </Box>
        <Grid>
            <Box
              sx={{
                display:'flex',flexDirection:'column',gap:'5px',
                margin: { xs: "0px", sm:"0px", md: "0 20px" },
                marginBottom: { xs: "10px", sm:"10px", md: "0px" },
              }}
            >
             {conditionData?.length !== 0 &&
                conditionData?.map((item: any, index: any) => (
                  <TimeBasedCondition
                remove={remove}
                handleUpdateSpecial={handleUpdateCondition}
                preSpecialDays={conditionData}
                key={index}
                index={index}
                setValue={setValue}
              />
                ))}
            </Box>
        </Grid>
            <Typography
              sx={{
                margin: "10px 0",
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: { xs: "14px", sm:"14px", md: "16px" },
              }}
            >
              {t('courts.Courts bookable dates in future')}
            </Typography>
            <FormControl>
            <Select
            defaultValue={BookableDaysFut}
            value={BookableDaysFut ? BookableDaysFut : t("select")}
            renderValue={(value) => {
              if (value) {
                return `${value} days`;
              }
              return <em>{t("select")}</em>;
            }}
            onChange={(e:any)=>setBookableDaysFut(e.target.value)}
              id="customdropdown"
              style={{
                height: "27px",
                border: "1px solid #EEEEFE",
                borderRadius: "7px",
                fontSize: "14px",
                opacity: "1",
                fontFamily: "var(--font-regular)",
                color: "#22356D",
              }}
              MenuProps={{ sx: {...dopdowncustomcss, '& .Mui-selected::after': {content:'none'}}}}
              IconComponent={RiExpandUpDownLine}
            >
              {days.map((day:string) => (
                <MenuItem
                  style={{
                    fontSize: "14px",
                    opacity: "1",
                    fontFamily: "var(--font-regular) !important",
                    color: "#22356D",
                  }}
                  key={day}
                  value={day}
                >
                  {day} days
                </MenuItem>
              ))}
            </Select>
          </FormControl>
      </Grid>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Button
          onClick={handleDelete}
          style={
            Selectedcourt?.court_id !== 0 &&
            Selectedcourt?.court_id !== undefined
              ? {
                  textTransform: "unset",
                  fontFamily: "var(--font-semibold)",
                  width: "160px",
                  height: "37px",
                  color: "#22356D",
                  backgroundColor: "white",
                  fontSize: "16px",
                  padding: "10px 30px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                }
              : {
                  textTransform: "unset",
                  fontFamily: "var(--font-semibold)",
                  width: "104px",
                  height: "37px",
                  color: "#22356D",
                  backgroundColor: "white",
                  fontSize: "16px",
                  padding: "10px 30px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                }
          }
        >
          {Selectedcourt?.court_id !== 0 &&
          Selectedcourt?.court_id !== undefined &&
          fromaddCourt === false
            ? t("courts.Delete Court")
            : t("common.Cancel")}
        </Button>
        <Button
          onClick={Onsubmit}
          style={Selectedcourt.court_id === 0 || Selectedcourt === "" && !fromaddCourt ?{
            textTransform: "unset",
            fontFamily: "var(--font-regular)",
            color: "white",
            backgroundColor: "#22356D",
            lineHeight:'14px',
            width: "140px",
            height: "37px",
            fontSize: "16px",
          }:{
            textTransform: "unset",
            fontFamily: "var(--font-semibold)",
            color: "white",
            backgroundColor: "#22356D",
            lineHeight:'14px',
            width: "92px",
            height: "37px",
            fontSize: "16px",
          }}
        >
         { fromaddCourt ? t("common.Save") : Selectedcourt===""|| Selectedcourt.court_id === 0 ? t("courts.Save for all court"):t("common.Save") }
        </Button>
      </Grid>
      </Box>
  );
};

export default CourtSettingRight;
