import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, BarChart, Tooltip, Bar, Legend, Cell, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import TabSliderStats from './TabSliderStats';

const data = [
  { date: '1 February', Cash: 10, 'Credit Card': 10, 'Apple Pay': 10, "Total Sale":10 },
  { date: '2 February', Cash: 30, 'Credit Card': 35, 'Apple Pay': 40, "Total Sale":45 },
  { date: '3 February', Cash: 50, 'Credit Card': 60, 'Apple Pay': 70, "Total Sale":80 },
  { date: '4 February', Cash: 70, 'Credit Card': 80, 'Apple Pay': 90, "Total Sale":120 },
  { date: '5 February', Cash: 100, 'Credit Card': 110, 'Apple Pay': 120, "Total Sale":160 },
  { date: '2 March', Cash: 130, 'Credit Card': 140, 'Apple Pay': 150, "Total Sale":180 },
];

const data1 = [
  { category: "Total Sale", name: "Total Sale Voucher", value: 2300 },
  { category: "Summer Sale", name: "Summer Sale Voucher", value: 1800 },
  { category: "Winter Sale", name: "Winter Sale Voucher", value: 1200 },
  { category: "Rain Sale", name: "Rain Sale Voucher", value: 8000 },
  { category: "3.45 Million", name: "3.45 Million Voucher", value: 2300 },
  { category: "3 Million", name: "3 Million Voucher", value: 1800 },
  { category: "12.5 Million", name: "12.5 Million Voucher", value: 1200 },
  { category: "6 Million", name: " 6 MillionVoucher", value: 8000 },
  { category: "Happy Holi", name: "Happy Holi Voucher", value: 8000 },
  // Add more data points as needed
];

const colorMapping: { [key: string]: string } = {
  "Total Sale": '#22356D',
  "Summer Sale": '#22356DE5',
  "Winter Sale": '#22356DCC',
  "Rain Sale": '#22356DB2',
  "3.45 Million": '#22356D99',
  "3 Million": '#22356D80',
  "12.5 Million": '#22356D66',
  "6 Million": '#22356D4D',
  "Happy Holi": '#22356D33',
};


const PaymentMethod = ({showGraph,setShowGraph,fontStyle, badge}:{showGraph:string,setShowGraph:(showGraph:string) => void,fontStyle: any, badge: any}) => {
  interface CustomTooltipProps {
    active?: boolean;
    payload?: Array<{ value: number; dataKey: string }>;
    label?: string;
  }
  
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0];
      const lineName = dataPoint.dataKey; // Get the name of the hovered line
      const value = dataPoint.value; // Get the value of the hovered line
  
      return (
        <div style={{background:"white", borderRadius:"7px", padding:"10px", color:"#22356D", fontFamily:"var(--font-regular)", fontSize:"0.95rem",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
          <div>{moment(label).format("ddd DD MMM")}</div>
          {payload.map((item)=>
            <div className="custom-tooltip">
              <p>{item.dataKey}: {item.value}</p>
            </div>
          )}
        </div>
      );
    }
    return null;
  };
      
  return (
      <Box>
        <Box sx={{background:'white',padding:{xs:"20px 23px 10px 0px", sm:"20px 40px 10px 0px"},  borderRadius:"7px", width:"100%"}}>
          <Box sx={{display:"flex", gap:"10px", alignItems:"center", paddingLeft:"20px"}}>
            <Typography sx={{...fontStyle, fontFamily:"var(--font-medium)", color:"#22356D"}}>Total Sales by</Typography>
            <TabSliderStats arr={['Payment Method', "Service Type", "Location"]} showGraph={showGraph} setShowGraph={setShowGraph} />
          </Box>
          {/* line chart */}
          <Box display={'flex'} sx={{">div:first-child":{width:{xs:"100% !important", sm:"80% !important"},height:{xs:"430px !important", sm:"400px !important"}},flexDirection:{xs:"column", sm:"row"}}} alignItems={"center"} gap={'1.5'} >
            <ResponsiveContainer width="80%" height="100%">
              <LineChart data={data}>
                <XAxis dataKey="date" ticks={['1 February', '2 March']} />
                <YAxis ticks={[0, 200]} />
                <Tooltip content={<CustomTooltip />} /> 
                {/* <Legend /> */}
                <Line type="linear" dataKey="Total Sale" stroke="#22356D" />
                <Line type="linear" dataKey="Cash" stroke="#22356D99" />
                <Line type="linear" dataKey="Apple Pay" stroke="#0000001A" />
                <Line type="linear" dataKey="Credit Card" stroke="#22356D33" />
              </LineChart>
            </ResponsiveContainer>
            <Box sx={{padding:'0px 15px 0px 20px'}}>
              <Box sx={{display:"flex", alignItems:"center", justifyContent:'center'}}>
                <Box sx={{display:"grid", gridTemplateColumns:{xs:"repeat(2, 1fr)", sm:"repeat(1, 1fr)"}, gap:{xs:"15px", sm:"5px"},'>div':{borderBottom:"1px solid #EEEFEE", padding:{xs:'0px 5px 5px 0px', sm:"0px 65px 5px 0px"}}, ">div:last-child":{borderBottom:"none"}, "div:nth-last-child(2)":{borderBottom:{xs:"none", sm:"1px solid #EEEFEE"}}}}>
                  <Box>
                    <Typography sx={{...fontStyle, color:"#22356D"}}>Total Sales <span style={{...badge, background:"#22356D"}}></span> </Typography>
                    <Typography sx={{...fontStyle}}>150.000.000 IDR</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{...fontStyle}}>Pererenan <span style={{...badge, background:"#22356D99"}}></span> 62%</Typography>
                    <Typography sx={{...fontStyle}}>150.000 IDR</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{...fontStyle}}>Canguu <span style={{...badge, background:"#22356D33"}}></span> 13%</Typography>
                    <Typography sx={{...fontStyle}}>1.100.000 IDR</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{...fontStyle}}>Uluwatu <span style={{...badge, background:"#0000001A"}}></span> </Typography>
                    <Typography sx={{...fontStyle}}>1.000.000 IDR</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* bar chart */}
        <Stack sx={{gap:{xs:"15px", sm:"15px", md:"0px"}, ">div:first-child":{width:{xs:"100% !important", sm:"100% !important", md:"60% !important"},height:{xs:"430px !important",sm:"430px !important", md:"400px !important"}},flexDirection:{xs:"column", sm:"column", md:"row"},marginTop:"20px", background:'white', padding:{xs:"20px 23px 10px 0px", sm:"20px 23px 10px 0px", md:"20px 40px 10px 0px"}, borderRadius:"7px", width:"100%"}} alignItems={"center"} gap={'1.5'}>
          <ResponsiveContainer width="60%" height="100%">

            <BarChart width={600} height={400} data={data1}>
              <XAxis dataKey="name" hide={true} />
              <YAxis ticks={[0, 20000]} />
              <Tooltip />
              <Bar dataKey="value">
                {data1.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colorMapping[entry.category]} />
                  ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <Box sx={{padding:'0px 15px 0px 20px', marginLeft:{xs:"10px", sm:"10px", md:"17px"}}}>
            <Box sx={{display: 'grid', gridTemplateColumns:{xs:"repeat(2, 1fr)", sm:"repeat(3, 1fr)"}, gap:{xs:"10px", sm:"20px", md:"10px"},'>div':{borderBottom:"1px solid #EEEFEE", padding:"0px 5px 5px 0px", width:"max-content"}, ">div:last-child":{borderBottom:"none"}}}>
              {/* {[]} */}
              {[...Array(11)].map((item)=>
                <Box>
                  <Typography sx={{...fontStyle, color:"#22356D"}}>Total Vouchers Sales</Typography>
                  <Typography sx={{...fontStyle}}><span style={{...badge, background:"#22356D"}}></span> 150.000.000 IDR</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Stack>
      </Box>
  )
}

export default PaymentMethod