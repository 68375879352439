import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  CreateEventBySportID,
  DeleteEventByID,
  GeEventByID,
  UpdateEventByID,
} from "src/api/ServiceSettings";
import EventDiscountCondition from "./EventDiscountCondition";
import EventLevelRestrictions from "./EventLevelRestrictions";
import CustomDropdownBookings from "../../components/CustomDropdownBookings";
import EventServicesRow from "./EventServicesRow";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Cleave from "cleave.js/react";
import MainContext from "src/context/MainContext";
import { useGetAllCoachesServices } from "src/hooks/useGetAllCoachesServices";
import { useTranslation } from "react-i18next";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import { FaAngleDown } from "react-icons/fa";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useGetAllMemeberShip } from "src/api/membership";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

const flexStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

type FormInputs = {
  price: number;
  eventName: string;
  Duration: number;
  minimum_capacity: number;
  maximum_capacity: number;
  eventInfo: string;
};

const underlineCss = {
  marginBottom: "10px",
  color: "#22356D",
  fontFamily: "var(--font-semibold)",
  fontSize: "16px",
  borderBottom: "1px solid #22356D",
};
const borderInput = {
  padding: "5px 8px",
  fontSize: "14px",
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  color: "#22356D",
  height: "24px",
};
const righttext = {
  color: "#22356D",
  fontFamily: "var(--font-regular)",
  fontSize: "14px",
};

const EventServices = ({
  EventID,
  sport_id,
  selectedlocation,
  handleClose,
  update
}: {
  EventID: any;
  sport_id: any;
  selectedlocation: any;
  handleClose: any;
  update:any;
}) => {
  const {t} = useTranslation();
   const StaffPermission = useGetPermissions();
  const {control, register, watch, setValue } = useForm<FormInputs>();
  const { control:ConditonControl, setValue:setCondiiton, watch:conditionWatch } = useForm();
  const {Notification,setNotification,setIsPermissionOn} = useContext(MainContext);
  const [DiscountCondtions, setDiscountConditions] = useState<any>([]);
  const [coachlist, setCoachlist] = useState([]);
  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const [error, setError] = useState("");
  const {data:customType,isSuccess:customerTypeSuccess,isError:customerTypeError} = useGetAllMemeberShip();
  const {data:CoachData,isSuccess:CoachSuccess,isError:CoachError,refetch,setlocation_id,setSportId} =useGetAllCoachesServices();
  const [AllMemebrship,setAllMembership] = useState<any[]>([]);
  const {
    eventName,
    Duration,
    minimum_capacity,
    maximum_capacity,
    price,
    eventInfo,
  } = watch();
  const [isWaitingOn,setIsWaitingOn] = useState('Off');
  const [levelRestriction,setLevelRestriction] = useState({minimum_range:null,maximum_range:null});
  const [isAppAvail,setIsAppAvail] = useState(false);
  const { fields, append, remove, swap, move, insert } = useFieldArray(
    {
      control:ConditonControl,
      name: "Rows",
    }
  );
  const conditionData = conditionWatch("Rows");

  useEffect(() => {
    GetSelectedLesson();
    // GetCoaches();
  }, [EventID]);

  useEffect(() => {}, [selectedCoaches]);

  const GetSelectedLesson = async () => {
    if (EventID !== undefined) {
      const response = await GeEventByID(EventID);
      setDiscountConditions([])
      if (!!response) {
        const levelRest = {minimum_range:response?.min_level_restrication,maximum_range:response?.max_level_restrication}
        setValue("eventName", response.event_name);
        setValue("Duration", response.duration);
        setValue("minimum_capacity", response.minimum_capacity);
        setValue("maximum_capacity", response.maximum_capacity);
        setValue("price", response.price);
        setValue("eventInfo", response.event_info);
        setIsAppAvail(response?.app_available);
        setIsWaitingOn(response?.waiting_list?'On':'Off')
        setLevelRestriction(levelRest);
        const finalSelCoaches =
          response?.coaches?.length > 0
            ? response?.coaches?.map((item: any) => {
                let { coach, id, ...newitem } = item;
                let full_name = coach?.full_name;
                let profile_url = coach?.profile_url
                id = coach?.id;
                return { full_name, id,profile_url, ...newitem };
              })
            : [];
        setSelectedCoaches(finalSelCoaches);
        const finalcond = response?.discount_conditions?.map(
          (item: any, index: number) => {
            const {price,all_user,memberships} = item;
            const membership = memberships?.map((mem:any)=>{
              const {id,membership_name} =mem;
              return {id,name:membership_name}
            });
            setDiscountConditions((prev:any)=>[...prev,membership]);
            insert(index,{price,all_user});
          }
        );
      }
    }
  };


  useEffect(()=>{
    DiscountCondtions?.map((item:any,index:number)=>{
    const isAllUser = conditionData[index].all_user;
    const AllMemebrship = isAllUser ? [{id:0,name:'All User'},...item]:item;
      setCondiiton(`Rows.${index}.memberships`,AllMemebrship)
    })
  },[DiscountCondtions])


  const APIData = {
    duration: Duration,
    minimum_capacity: Number(minimum_capacity),
    maximum_capacity: Number(maximum_capacity),
    price: price,
    event_name: eventName,
    event_info: eventInfo,
    app_available:isAppAvail,
    min_level_restrication:levelRestriction?.minimum_range,
    max_level_restrication:levelRestriction?.maximum_range,
    waiting_list:isWaitingOn==="On"?true:false,
    coaches: selectedCoaches?.map((item: any) => {
      let { id, price } = item;
      let coach_id = id;
      return { coach_id };
    }),
    discount_conditions: conditionData?.map((item: any) => {
      let { memberships,price,all_user } = item;
      all_user = memberships?.some((mem:any)=>mem.name==="All User");
      const membership= memberships?.filter((mem:any)=>mem?.name!=="All User")?.map((item:any)=>item.id);
      return { all_user,membership,price };
    }),
  };


  useEffect(() => {
    if(sport_id){
      setlocation_id(selectedlocation?.id)
      setSportId(0)
    }
  }, [selectedlocation])

  useEffect(() => {
   if(CoachSuccess){
    const filtered = CoachData?.filter((item:any)=>item?.sport_id===sport_id)
    setCoachlist(filtered)
   }else{
    setCoachlist([]);
   } 
  }, [CoachData,CoachError,CoachSuccess]);
    

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleClose()
      return;
    }


    if (!eventName) {
      setError("event name");
      setTimeout(() => setError(""), 2000);
    } else if (!Duration) {
      setError("duration");
      setTimeout(() => setError(""), 2000);
    } else if (!minimum_capacity) {
      setError("minimum capacity");
      setTimeout(() => setError(""), 2000);
    } else if (!maximum_capacity) {
      setError("maximum capacity");
      setTimeout(() => setError(""), 2000);
    } else if (!price) {
      setError("price");
      setTimeout(() => setError(""), 2000);
    } else  if (Number(minimum_capacity) > Number(maximum_capacity)) {
      setError('capacity')
      setTimeout(() => setError(""), 2000);
    }else{
      setError("");
      EventID !== undefined ? updateEvent() : createEvent();
    }
  };

  const createEvent = async () => {
    if((APIData.min_level_restrication == null && APIData.max_level_restrication == null)){
      setNotification({message:"Select the level range",type:'error',subMessage:'',isShow:true});
      return;
    }

    try {    
      const response = await CreateEventBySportID(sport_id, APIData);
    if(response){
      setNotification({message:response,type:'success',subMessage:'',isShow:true})
      handleClose();
       update();
     }
    } catch (error) {
      // setNotification({message:error,type:'error',subMessage:'',isShow:true})
    }
    
  };


  const updateEvent = async () => {
    if((APIData.min_level_restrication == null && APIData.max_level_restrication == null)){
      setNotification({message:"Select the level range",type:'error',subMessage:'',isShow:true});
      return;
    }

    try {
      const response = await UpdateEventByID(EventID, APIData);
      if(response){
        setNotification({message:response,type:'success',subMessage:'',isShow:true})
        handleClose();
         update();
       }
    } catch (error) {
      // setNotification({message:error,type:'error',subMessage:'',isShow:true})
    }
   
  };

  const deleteEvent = async () => {
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleClose()
      return;
    }


    try {
      const response = await DeleteEventByID(EventID);
    if(response){
      setNotification({message:response,type:'success',subMessage:'',isShow:true})
      handleClose();
       update();
     }
    } catch (error) {
      // setNotification({message:error,type:'error',subMessage:'',isShow:true})
    }
  };

  const handleDiscountCount = () => {
    append({'memberships':[],price:0})
  };

  useEffect(() => {
    if(customerTypeSuccess){
      const filtered = customType?.map((item:any)=>{
        const {id,membership_name}=item;
        return {id,name:membership_name}
      })
      setAllMembership(filtered);
    }else{
      setAllMembership([])
    }
  }, [customType,customerTypeSuccess,customerTypeError]);


  return (
    <Box
      sx={{ background:"white", padding:{xs:"10px 18px",sm:"10px 18px", md:"40px 40px 20px"}, borderRadius: "7px" }}
    >
      <form onSubmit={handleFormSubmit}>
        <Grid>
          <span style={underlineCss}>{t('services.events.Event Settings')}</span>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1.5}
            marginTop={"10px"}
          >
            <Box sx={{ ...flexStyle }}>
              <Typography sx={{ ...righttext }}>{t('services.events.Event Name')}<span style={{color:'red'}}> *</span></Typography>
              <Box width={"150px"}>
                <input
                  id={error === "event name" ? "customerror" : ""}
                  {...register("eventName", {
                    required: true,
                  })}
                  type="text"
                  placeholder="Beginner Lesson"
                  style={{ ...borderInput, width: "-webkit-fill-available" }}
                />
                 {/* { (error === "event name") && (
                 
                )} */}
              </Box>
            </Box>
            <Box sx={{ ...flexStyle }}>
              <Typography sx={{ ...righttext }}>{t('services.bookings.Duration')}<span style={{color:'red'}}> *</span></Typography>
              <Box>
              <Box width={"150px"} sx={{ ...flexStyle }}>
                <input
                  id={error === "duration" ? "customerror" : ""}
                  {...register("Duration", {
                    required: true,
                  })}
                  type="number"
                  placeholder="60"
                  style={{ ...borderInput, width: "51px" }}
                />
                <Typography sx={{ ...righttext }}>{t('common.minutes')}</Typography>
              </Box>
              {/* { (error === "duration") && (
                 
                )} */}
              </Box>
            </Box>
            <Box sx={{ ...flexStyle }}>
              <Typography sx={{ ...righttext }}>{t('services.lesson.Lesson Capacity')}<span style={{color:'red'}}> *</span></Typography>
              <Box> 
              <Box width={"150px"} sx={{ ...flexStyle }}>
                <label style={{ ...righttext }}>
                  {t('common.min')}&nbsp;{" "}
                  <input
                    id={error === "minimum capacity" ? "customerror" : ""}
                    {...register("minimum_capacity", { required: true })}
                    type="text"
                    placeholder="2"
                    style={{ ...borderInput, width: "30px" }}
                  />
                </label>
                <label style={{ ...righttext }}>
                  {t('common.max')}&nbsp;{" "}
                  <input
                    id={error === "maximum capacity" ? "customerror" : ""}
                    {...register("maximum_capacity", { required: true })}
                    type="text"
                    placeholder="4"
                    style={{ ...borderInput, width: "40px" }}
                  />
                </label>
              </Box>
              {/* { (error === "minimum capacity" || error === "maximum capacity") && (
                 
                )} */}
                </Box>
             
            </Box>
            { error === "capacity" && <span style={{ fontFamily: "var(--font-regular)",color:'red',fontSize:'13px',textAlign:'right'}}>{t('services.bookings.minimum capacity must be less than maximum capacity')}</span>}
            <Box sx={{ ...flexStyle }}>
              <Typography sx={{ ...righttext }}>{t('services.events.Price per person')}<span style={{color:'red'}}> *</span></Typography>
              <Box>
              <Box width={"150px"} sx={{ ...flexStyle }}>
              <Controller
                    name="price"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Cleave
                      id={error == "price" ? "customerror" : ""}
                      style={{ ...borderInput, width: "86px" }}
                      onChange={(event:any) => onChange(event.target.rawValue)}
                      value={value}
                      className="input-price"
                      placeholder="100"
                      options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'thousand',
                         delimiter:selectedlocation.currency === 'IDR' ?'\u2024':','
                      }}
                  />
                    )}
                  />{" "}
                <Box sx={{fontFamily:"var(--font-regular)", color:"#22356D"}}>{selectedlocation.currency}</Box>
              </Box>
              {/* { error==="price" && (
                 
                )} */}
                </Box>
            </Box>
          </Box>
        </Grid>

        <Grid sx={{width:'100%'}} marginTop={"25px"}>
          <div>
            <span style={underlineCss}>
            {t('services.events.Coaches that can participate in this event')} {selectedCoaches?.length}/
              {coachlist?.length}
            </span>
          </div>
          <CustomDropdownBookings
            getTime={setSelectedCoaches}
            preTime={selectedCoaches}
            listData={coachlist}
          />
              <Box id="table-person" sx={{maxHeight:'260px',overflowY:'auto',marginTop: "10px",
                borderRadius: "7px",
                boxShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.1)",
                width:{xs:'-webkit-fill-available',md:'100%' }}}>
                {selectedCoaches?.map((item: any, index) => (
                  <EventServicesRow
                    id={item?.id}
                    setSelectedCoaches={setSelectedCoaches}
                    key={index}
                    index={index}
                    data={item}
                  />
                ))}
              </Box>
        </Grid>

        <Grid marginTop={"25px"}>
          <Grid container alignItems={"center"} gap={"15px"}>
            <h3
              style={{
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: "16px",
              }}
            >
              {" "}
              {t('services.events.Discount Conditions')}
            </h3>
            <Button
              onClick={handleDiscountCount}
              style={{
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                gap: "10px",
                textTransform: "none",
              }}
            >
              {t('services.bookings.Add Condition')}
              <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
            </Button>
          </Grid>
          <Grid sx={{marginLeft:{xs:"0px", sm:"20px"}}}>
            {conditionData?.map((item: any, index: number) => (
            <EventDiscountCondition
              remove={remove}
              allMembership={AllMemebrship}
              key={index}
              setValue={setCondiiton}
              preData={item}
              index={index}
              selectedlocation={selectedlocation}
              />
            ))}
          </Grid>
        </Grid>
        <Grid>
        <Box
          sx={{
            width: "max-content",
            margin: "10px 0",
            color: "#22356D",
            fontFamily: "var(--font-semibold)",
            fontSize: "16px",
            borderBottom: "1px solid #22356D",
          }}
        >
          {t('courts.App Settings')}
        </Box>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center', gap:'10px'}}>
          <Typography sx={{ color: "#22356D",
            fontFamily: "var(--font-regular)",
            fontSize: "16px",}}>Available in App</Typography>
            <label className="switch">
            <input
              checked={isAppAvail}
              onChange={()=>setIsAppAvail(!isAppAvail)}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </Box>
        <Box sx={{display:'flex',flexDirection:{xs:'column',sm:'row'},justifyContent:'space-between',margin:'5px 0'}}>
          <Typography sx={{  width: "160px",
            color: "rgba(34, 53, 109)",
            fontFamily: "var(--font-regular)",
            fontSize: "14px",
            lineHeight: "17px",
            borderRadius: "7px",
            padding: "5px 8px",
            border: "1px solid #EEEFEE",
          }}>Waiting List</Typography>
          <FormControl>
          <Select
            value={isWaitingOn}
            renderValue={(selected) => {
              if (selected) {
                return selected
              }
              return `Select`;
            }}
            onChange={(e:any)=>setIsWaitingOn(e.target.value)}
              id="customdropdown"
              style={{
                height: "27px",
                border: "1px solid #EEEEFE",
                borderRadius: "7px",
                fontSize: "14px",
                opacity: "1",
                fontFamily: "var(--font-regular)",
                color: "#22356D",
                width:'150px'
              }}
              MenuProps={{ sx: {...dopdowncustomcss, '& .Mui-selected::after': {content:'none'}}}}
              IconComponent={FaAngleDown}
            >
              {['On','Off'].map((day:string) => (
                <MenuItem
                  style={{
                    fontSize: "14px",
                    opacity: "1",
                    fontFamily: "var(--font-regular) !important",
                    color: "#22356D",
                  }}
                  key={day}
                  value={day}
                >
                  {day}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{display:'flex',flexDirection:{xs:'column',sm:'row'},justifyContent:'space-between',margin:'5px 0'}}>
          <Typography sx={{  width: "160px",
            color: "rgba(34, 53, 109)",
            fontFamily: "var(--font-regular)",
            fontSize: "14px",
            lineHeight: "17px",
            borderRadius: "7px",
            padding: "5px 8px",
            border: "1px solid #EEEFEE",
          }}>Level Restriction</Typography>
            <EventLevelRestrictions
              key={1}
              setDiscountConditions={setLevelRestriction}
              preData={levelRestriction}
              index={1}
            />
        </Box>
        </Grid>
        {/* <Grid marginTop={"25px"}>
          <Grid container alignItems={"center"} gap={"15px"}>
            <h3
              style={{
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: "16px",
              }}
            >
              {t('services.events.Level Restrictions')}
            </h3>
            <Button
              onClick={handleLevelRestrictionCount}
              style={{
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                gap: "10px",
                textTransform: "none",
              }}
            >
              {t('services.bookings.Add Condition')}
              <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
            </Button>
          </Grid>
          <Box sx={{marginLeft:{xs:"0px", sm:"20px"}}}>
            {LevelRest?.length !== undefined &&
            LevelRest?.length == levelRestCount
              ? LevelRest?.map((item: any, index: number) => (
                  <EventLevelRestrictions
                    key={index}
                    setDiscountConditions={setLevelRest}
                    preData={item}
                    index={index}
                  />
                ))
              : Array(levelRestCount)
                  ?.fill("-")
                  ?.map((_, index: number) => (
                    <EventLevelRestrictions
                      key={index}
                      setDiscountConditions={setLevelRest}
                      preData={undefined}
                      index={index}
                    />
                ))}
          </Box>
        </Grid> */}
        <Grid marginTop={"25px"}>
          <div>
            <span style={underlineCss}>{t('services.events.Event info')}</span>
          </div>
          <textarea rows={4} cols={5}
            {...register("eventInfo")}
            style={{
              width: "100%",
              opacity: "0.7",
              padding: "10px",
              fontSize: "14px",
              border: "1px solid #EEEFEE",
              borderRadius: "7px",
              color: "#22356D",
              marginTop: "10px",
            }}
            placeholder={t('services.events.write event description')}
          ></textarea>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          marginTop={"20px"}
        >
          <Button
            onClick={EventID !== undefined ? deleteEvent : handleClose}
            style={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              width: "104px",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              padding: "10px 30px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            }}
          >
            {EventID !== undefined ? t("common.Delete") :t("common.Cancel")}
          </Button>
          <Button
            type="submit"
            // onClick={EventID !== undefined ? updateEvent : createEvent}
            style={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              color: "white",
              backgroundColor: "#22356D",
              width: "92px",
              height: "37px",
              fontSize: "16px",
            }}
          >
            {t("common.Save")}
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default EventServices;
