import { useState } from "react";
import { useQuery } from "react-query";
import { apiCaller } from "src/api/ApiCaller";
import { getClubId } from "src/api/CalendarBooking";

export const useGetAllCourts = () => {
    const [sportId, setSportId] = useState<number>();
  
    const club_id = getClubId();
  
    const query = useQuery(
      ["AllCourts",sportId],
      async () => {

        const response = await apiCaller.get(`${club_id}/courts/all/${sportId}`);
        return response.data.data;
      },
      {
        enabled: !!sportId,
        staleTime: 1000 * 60 * 5,
        retry: false,
      }
    );
  
    return {...query, setSportId};
  };