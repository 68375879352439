import { apiCaller } from "./ApiCaller";
import { getClubId } from "./CalendarBooking";

const GetALLBookingDataByLocationAndSport = async (
  location_id: number,
  sport_id: number
) => {
    let club_id = getClubId();
  try {
    const sport =
    sport_id !== 0 ? `?sport_id=${sport_id}` : "";
    const response: any = await apiCaller.get(
      `${club_id}/services/bookings-all/${location_id}${sport}`
    );
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;  }
};

const updateBookingDataSettings = async (booking_id: number, APIdata: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.put(
      `${club_id}/services/bookings/${booking_id}`,
      APIdata
    );
    let data = response.data.message;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;  }
};

const CreateBookingBysportID = async (sport_id: number, APIdata: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/services/bookings/${sport_id}`,
      APIdata
    );
    let data = response.data.message;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;  }
};

const GetBookingByID = async (booking_id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(
      `${club_id}/services/bookings/${booking_id}`
    );
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const DeleteBookingByID = async (booking_id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.delete(
      `${club_id}/services/bookings/${booking_id}`
    );
    let data = response.data.message;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const GetAllLessonByLocationAndSport = async (
  location_id: number,
  sport_id: number
) => {
    let club_id = getClubId();
  try {
    const sport =
    sport_id !== 0 ? `?sport_id=${sport_id}` : "";
    const response: any = await apiCaller.get(
      `${club_id}/services/lessons-all/${location_id}${sport}`
    );
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const GetLessonByID = async (lesson_id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(`${club_id}/services/lessons/${lesson_id}`);
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const CreateLessonBySportID = async (sport_id: number, APiData: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/services/lessons/${sport_id}`,
      APiData
    );
    let data = response.data.message;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const UpdateLessonByID = async (lesson_id: number, APiData: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.put(
      `${club_id}/services/lessons/${lesson_id}`,
      APiData
    );
    let data = response.data.message;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const DeleteLessonByID = async (lesson_id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.delete(
      `${club_id}/services/lessons/${lesson_id}`
    );
    let data = response.data.message;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const GetAllEventByLocationAndSport = async (
  location_id: number,
  sport_id: number
) => {
    let club_id = getClubId();
  try {
    const sport =
    sport_id !== 0 ? `?sport_id=${sport_id}` : "";
    const response: any = await apiCaller.get(
      `${club_id}/services/events-all/${location_id}${sport}`
    );
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const GeEventByID = async (lesson_id: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(`${club_id}/services/events/${lesson_id}`);
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const CreateEventBySportID = async (sport_id: number, APiData: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/services/events/${sport_id}`,
      APiData
    );
    let data = response.data.message;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const UpdateEventByID = async (lesson_id: number, APiData: any) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.put(
      `${club_id}/services/events/${lesson_id}`,
      APiData
    );
    let data = response.data.message;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const DeleteEventByID = async (lesson_id: number) => {
    let club_id = getClubId();


  try {
    const response: any = await apiCaller.delete(
      `${club_id}/services/events/${lesson_id}`
    );
    let data = response.data.message;   
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


export {
  GetALLBookingDataByLocationAndSport,
  GeEventByID,
  CreateEventBySportID,
  UpdateEventByID,
  GetAllEventByLocationAndSport,
  DeleteEventByID,
  DeleteLessonByID,
  UpdateLessonByID,
  CreateLessonBySportID,
  GetLessonByID,
  CreateBookingBysportID,
  updateBookingDataSettings,
  GetBookingByID,
  DeleteBookingByID,
  GetAllLessonByLocationAndSport,
};
