import { Box, Button, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCamera, FaChevronDown } from "react-icons/fa";
import MainContext from "src/context/MainContext";
import { useForm } from "react-hook-form";
import { CreateProduct, DeleteProduct, getCategory, UpdateProduct } from "src/api/POS";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import { EditRegionModel } from "../Customers/EditFieldModel";
import Cleave from "cleave.js/react";
import MyDropdown from "../../components/Payment/LocationSelectCheckBox";
import { useGetLocation } from "src/api/CourtSettings";
import CurrencySelector from "../../components/Admin/CurrencySelector";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

interface category {
  "id": number,
  "club_id": number,
  "category_name": string,
  "sequence": number
}


const commonFlex = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop:'15px'
  };
 const commonText = { fontFamily: "var(--font-regular)" };

const ProductModel = ({handleClose,Data,update,allCategory,updateCat}:{Data:any,handleClose:any,update:any,allCategory:any,updateCat:any}) => {
    const [isServiceOpen,setservicesopen] = useState(false);
    const [Location,setLocation] = useState<any[]>([]);
     const StaffPermission = useGetPermissions();
    const [selectedlocations,setSelectedLocations] = useState<any[]>([]);
    const {data:locationdata,isError:locationerror,isSuccess:locationSucess} = useGetLocation();
    const { register,watch, control, setValue, handleSubmit, formState: { errors }, reset} = useForm();
    const {category,image,product_image,status,product_name,price,currency} = watch();
    const {setNotification,setIsPermissionOn} = useContext(MainContext);
    const [Error,setError] = useState('');
    // const [image,setImage] = useState<string>();
    const {t} = useTranslation();
    
    const [open,setOpen] = useState(false);


    const onSubmit = async()=>{
      if(!StaffPermission.edit_permissions){
        setIsPermissionOn(true);
        handleClose();
        return;
      }
      
      if (!product_name) {
        setError("product_name");
        setTimeout(() => setError(""), 2000);
      } else if (!category) {
        setError("category");
        setTimeout(() => setError(""), 2000);
      } else if (!price) {
        setError("price");
        setTimeout(() => setError(""), 2000);
      } else if (selectedlocations?.length==0) {
        setError("location");
        setTimeout(() => setError(""), 2000);
      }else{

      const selectedLoc :any= selectedlocations?.map((item:any)=>item.id);
      const selectedCurrency = selectedlocations[0]?.currency||currency
       const formdata = new FormData();
       formdata.append('status',status?"Active":"Inactive");
       formdata.append('product_name',product_name);
       formdata.append('category_id',category?.id);
       formdata.append('price',price);
      //  formdata.append('location_ids',selectedLoc);
       formdata.append("location_ids", JSON.stringify(selectedLoc));
       formdata.append('currency',selectedCurrency)
       image && formdata.append('image',image);       

if(Data){
  try {
    const res = await UpdateProduct(Data.id,formdata);
    setNotification({isShow:true,message:'Product updated successfully',subMessage:'',type:'success'})
    handleClose();
    update();
  } catch (error:any) {
    setNotification({isShow:true,message:error,subMessage:'',type:'error'})
    console.log(error)
  }
}else{
  try {
    const res = await CreateProduct(formdata);
    setNotification({isShow:true,message:'Product added successfully',subMessage:'',type:'success'})
    handleClose();
    update();
  } catch (error:any) {
    setNotification({isShow:true,message:error,subMessage:'',type:'error'})
    console.log(error)
  }
}
}
    }


    useEffect(() => {
      if(Data){
        reset({
          category:Data.category,
          price:Data.price,
          product_image:Data.product_img,
          product_name:Data.product_name,
          image:'',
          status:Data.status==="Active"?true:false
        });
        setSelectedLocations(Data?.locations?.map((item:any)=>{
          const {id,location_name,currency} = item;
          return {id,name:location_name,currency}
        }))

      }  else{
        reset({
          category:'',
          price:'',
          product_image:'',
          product_name:'',
          image:'',
          status:''
        })
      }  
    }, [Data])

    const handleDelete = async()=>{
      if(!StaffPermission.edit_permissions){
        setIsPermissionOn(true);
        handleClose();
        return;
      }

    if(Data){
      try {
        const res = await DeleteProduct(Data?.id);
        handleClose();
    update();
      } catch (error) {
        console.log(error)
      }
    }else{
      handleClose();
    }
    }

    useEffect(() => {
      if(locationSucess){
        const filtered = locationdata?.map((item:any)=>{
         const {location_name,id,currency}= item;
         return {name:location_name,currency,id}
        })
          setLocation(filtered)
      }else{
          setLocation([])
      }
    
  }, [locationdata,locationSucess,locationerror])
  
    


    const uploadHandler=(event:any)=> {
        const imageSize:number= Math.round(Number(event.target.files[0].size/1024))
          if (imageSize > 10*1024) {
            setNotification({isShow:true,message:"Image not uploaded",subMessage:'Please upload a file smaller than 10 MB.',type:'success'})
        }else{
          setValue('image',event.target.files[0])
          setValue('product_image',URL.createObjectURL(event.target.files[0]))

          //   setValue("profile_url",URL.createObjectURL(event.target.files[0]))
        }
      }
      
const dropdownstyle = {
  height: "29px",
  borderRadius: "7px",
  fontSize: "14px",
  fontFamily: "var(--font-regular)",
  color: "#22356D",
  width:'160px'
}

  return (
    <Box sx={{ display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",}}>
          <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={commonFlex}>
        <Typography sx={{ fontFamily: "var(--font-medium)", fontSize: "18px",borderBottom:'1px solid #22356D' }}>
         {t('POS.Add new product')}
        </Typography>
        <Box sx={{...commonFlex,alignItems:'end',gap:'10px',opacity:status?1:0.6,position:'relative'}}>
      {  product_image?<img alt="product-img" style={{width:'80px',height:'80px',borderRadius:'7px',boxShadow:"0px 4px 4px 0px rgba(0, 0, 0, 0.07)"}} src={product_image} />:<span style={{width:'80px',height:'80px',borderRadius:'7px',backgroundColor:'#d6d2d6',boxShadow:"0px 4px 4px 0px rgba(0, 0, 0, 0.07)"}}><FaCamera style={{color:'white',width:'60px',height:'60px',marginLeft:'10px',marginTop:'10px'}}/></span>}
        <input
            onChange={(e) => uploadHandler(e)}
            style={{
            zIndex: 4,
            position: "absolute",
            opacity: "0",
            width: "30px",
            height: "30px",
            bottom: "0",
            right: "3px",
            }}
            type="file"
            name="file"
        />
          <FaCamera style={{background:'#22356D',color:'white',padding:'5px',fontSize:'22px',borderRadius:'7px'}}/>
        </Box>
      </Box>
      <Box sx={commonFlex}>
        <Typography sx={commonText}>{t('POS.Product Name')}<span style={{color:'red'}}> *</span></Typography>
        <Box>
        
        <input
        id={Error==="product_name"?'customerror':''}
         {...register("product_name",)}
          style={{
            padding: "5px 8px",
            fontSize: "14px",
            border: "1px solid #EEEFEE",
            borderRadius: "7px",
            color: "#22356D",
          }}
          type="text"
          placeholder={t('common.Enter Name')}
        />
        </Box>
      </Box>
      <Box sx={commonFlex}>
        <Typography sx={commonText}>{t('POS.Category')}<span style={{color:'red'}}> *</span></Typography>
        <Box>
        <FormControl>
    <Select
      // {...register("category",)}
     label="Select Category"
     id={'customerror customdropdown'}
      sx={Error==="category" ?{...dropdownstyle,border: "1px solid red",
      }
        :{...dropdownstyle,border: "1px solid #EEEEFE",
        }}
      renderValue={(value:any) => {
        if (value.category_name) {
          return value.category_name;
        }
        return <em>{t('POS.Select category')}</em>;
      }}
      value={category?category:'Select category'}
      placeholder="Select Category"
      MenuProps={{ sx: {...dopdowncustomcss, '& .Mui-selected::after': {content:'none'}}}}
      IconComponent={FaChevronDown}
    >
       <MenuItem
       onClick={()=>setOpen(true)}
        style={{
          fontSize: "14px",
          opacity: "1",
          fontFamily: "var(--font-regular) !important",
          color: "#22356D",
        }}
      >
        {t('POS.Add new Category')} +
      </MenuItem>
      {allCategory.map((category:any) => (
        <MenuItem
        onClick={()=>setValue('category',category)}
          style={{
            fontSize: "14px",
            opacity: "1",
            fontFamily: "var(--font-regular) !important",
            color: "#22356D",
          }}
          key={category?.id}
          value={category}
        >
          {category?.category_name}
        </MenuItem>
      ))}
    </Select>
 
  </FormControl>
  {/* {Error ==="category" && (
                 
                )} */}
                </Box>
      </Box>
      <Box sx={commonFlex}>
        <Typography sx={commonText}>{t('common.Price')}<span style={{color:'red'}}> *</span></Typography>
        <Box> 
        <Cleave
        id={Error==="price"?'customerror':''}
          placeholder={t('common.Enter Price')}
          value={price}
          onChange={(e:any)=>setValue('price',e.target.rawValue)}
          style={{
            padding: "5px 8px",
            fontSize: "14px",
            border: "1px solid #EEEFEE",
            borderRadius: "7px",
            color: "#22356D",
          }}
          options={{
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
            delimiter:"\u2024" ,
            numeralDecimalMark: ',',
          }}
        /> 
        {/* {Error ==="price" && (
          <Typography
            sx={{
              fontFamily: "var(--font-regular)",
              fontSize: "12px",
              color: "red",
            }}
          >
            {t('This is a required field')}
          </Typography>
        )} */}
        </Box>
        {/* <input
         {...register("price",)}
          style={{
            padding: "5px 8px",
            fontSize: "14px",
            border: "1px solid #EEEFEE",
            borderRadius: "7px",
            color: "#22356D",
          }}
          type="number"
        
        /> */}
      </Box>
      <Box sx={commonFlex}>
        <Typography sx={commonText}>Status</Typography>
        <Box sx={{ ...commonFlex, gap: "10px" }}>
          <Typography sx={commonText}>{status?t('common.Active'):t('common.Inactive')}</Typography>{" "}
          <label className="switch">
            <input
              checked={status}
              onChange={()=>setValue('status',!status)}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </Box>
      </Box>
      <Box sx={commonFlex}>
          <Typography sx={{fontFamily: "var(--font-regular)", color: "#22356D",}}>{t('payments.Locations')}<span style={{color:'red'}}> *</span></Typography>
          <Box width={'184px'}>
              <MyDropdown 
                  isValidation={true}
                  getOpeon={setservicesopen} 
                  border={true}
                  error={Error==="location" ? Error:''}
                  text="locations"
                  arr={Location}
                  selected={selectedlocations}
                  setSelected={setSelectedLocations}
              />
               {/* {Error ==="location" && (
                 
                )} */}
          </Box>
      </Box>
      <Box sx={commonFlex}>
      <Typography sx={{fontFamily: "var(--font-regular)", color: "#22356D",}}>{t('locationmanager.Currency')}<span style={{color:'red'}}> *</span></Typography>
      {  selectedlocations?.length>0 ?<Typography sx={{fontFamily: "var(--font-regular)", color: "#22356D",}}>{selectedlocations[0]?.currency}</Typography> :<CurrencySelector inHeight={'30px'} maxHeight={'150px'} setIsopen={setservicesopen} width='70px' selected={currency} setCurrency={(curr:any)=>setValue('currency',curr)}/>}
      </Box>
      
      <Box
       sx={{display:'flex',flexDirection:'row',marginTop:isServiceOpen?'180px':'20px',justifyContent:'space-between',alignItems:'center'}}
      >
        <Button
          onClick={handleDelete}
          style={{
                  textTransform: "unset",
                  fontFamily: "var(--font-semibold)",
                  width: "104px",
                  height: "37px",
                  color: "#22356D",
                  backgroundColor: "white",
                  fontSize: "16px",
                  padding: "10px 30px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                }}
        >
        {Data?t("common.Delete"):t('common.Cancel')}
        </Button>
        <Button
        type="submit"
        //   onClick={handleSave}
            style={{
            textTransform: "unset",
            fontFamily: "var(--font-semibold)",
            color: "white",
            backgroundColor: "#22356D",
            lineHeight:'14px',
            width: "92px",
            height: "37px",
            fontSize: "16px",
          }}
        >
         {t("common.Save")}
        </Button>
      </Box>
      </form>
      <EditRegionModel type="category"
        updateLocationList={updateCat}
        updateLocationName={()=>console.log('')}
        regionName={()=>console.log('')}
        arr={allCategory}
        text={"Edit Categories"}
        editField={""}
        open={open}
        setOpen={setOpen} />
    </Box>
  );
};

export default ProductModel;
