import React, { useState } from 'react';
import { Box, Button,Stack, Typography } from '@mui/material';
import { RxHamburgerMenu } from 'react-icons/rx';
import MainContext from 'src/context/MainContext';
import SalesDashboard from 'src/features/statistics/SalesDashboard';
import OccupancyDashboard from 'src/features/statistics/OccupancyDashboard';
import PaymentMethod from 'src/features/statistics/PaymentMethod';
import ServiceTypeSat from 'src/features/statistics/ServiceTypeSat';
import LocationStats from 'src/features/statistics/LocationStats';
import CoachStats from 'src/features/statistics/CoachesStats';
import OccupancyStats from 'src/features/statistics/OccupancyStats';
import { BsFillInfoCircleFill } from "react-icons/bs";
import { RiFontSize } from 'react-icons/ri';
import { PieChart } from '@mui/x-charts/PieChart';
import { GrSearch } from 'react-icons/gr';
import { FinalCalednarasdmnf } from '../components/customTimeSeletor';
import moment from 'moment';
import CustomerDashboard from 'src/features/statistics/CustomerDashboard';
import { useTranslation } from 'react-i18next';
import TopUserInfo from "../components/TopUserInfo";

const fontStyle = {display:"flex", alignItems:"center", gap:"10px",fontSize:"1.1rem", fontFamily:"var(--font-regular)", color:"#22356DB2"};
const badge = {display:"block",width:"11.87px", height:"8px", borderRadius:'3px'};

const Statistics = () => {
  const {t} = useTranslation();
  const slides = [1, 2, 3, 4, 5, 6, 7]
  const [selectedDay,setSelectedDays]=useState<any>({date_start:moment(new Date()).format('YYYY-MM-DD'),date_end:moment(new Date()).format('YYYY-MM-DD')});
  const {MobileSideBar, setMobileSideBar} = React.useContext(MainContext);
  const [values, setValues] = useState(1);
  const [showGraph, setShowGraph] = useState(values === 1 ? "Payment Method" : values === 2 ? "Service Type" :"");

  const openMobileSidebar=(event:any)=>{
    event.stopPropagation();
    setMobileSideBar(true)
  }

  return (
    <Box sx={{paddingBottom:"30px", paddingRight:"20px", fontFamily:"var(--font-regular)", color:"#22356D"}}>
      <TopUserInfo/>
      <Stack direction={'row'} alignItems={'center'} gap={1} sx={{marginTop:"57px", textAlign:"center", justifyContent:{xs:"left",sm:"left", md:"center"}, paddingLeft:{xs:"20px", sm:"20px", md:"0px"}}}>
        <Box sx={{display:{xs:'block', sm:"block", md:"none"},cursor:"pointer"}} onClick={openMobileSidebar}>
          <RxHamburgerMenu className='MobileHamburger'/>
        </Box>
        <Typography
          sx={{ fontFamily: "var(--font-medium)", fontSize: "1.4rem" }}
        >
          {t('title.Stats')}
        </Typography>
      </Stack>
      <Box sx={{paddingLeft:'20px'}}> 
        <Stack direction={'row'} alignItems={'center'} gap={1.5} sx={{flexWrap:"wrap",">div":{paddingBottom:"2.5px", fontFamily:"var(--font-medium)", fontSize:"1.2rem"}}}>
          <Box sx={{borderBottom:values==1 ? "2px solid #22356D" : '2px solid transparent', color:values==1 ? "#22356D" : '#22356D99'}} onClick={()=>setValues(1)}>{t('title.Sales Dashboard')}</Box>
          <Box sx={{borderBottom:values==2 ? "2px solid #22356D" : '2px solid transparent', color:values==2 ? "#22356D" : '#22356D99'}} onClick={()=>{setValues(2);setShowGraph("Service Type")}}>{t('title.Occupancy Dashboard')}</Box>
          <Box sx={{borderBottom:values==3 ? "2px solid #22356D" : '2px solid transparent', color:values==3 ? "#22356D" : '#22356D99'}} onClick={()=>setValues(3)}>{t('title.Customers Dashboard')}</Box>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2} paddingTop={3}>
        <FinalCalednarasdmnf type={'range'} containercss={{backgroundColor:"white",padding:'6px',borderRadius:'7px'}} placeholder={"Select days"} selectedDay={selectedDay} setSelectedDays={setSelectedDays}  icon={false} style={{fontSize:"16px", color:'rgba(34, 53, 109, 0.90)',background:"transparent", paddingLeft:"0px",width:"-webkit-fill-available",maxWidth:'170px'}}/>
          {/* <Box sx={{width:"228px", height:"37px", borderRadius:"7px", padding:"9px 20px", background:"white"}}>1 Feb - 2 March 2024</Box> */}
         {values!==3 && <Button sx={{display:{xs:"none", sm:"block"}, width:"160px",height:"37px",border:'1px solid #C6CBDA', borderRadius:"7px", background:"white", padding:"5.5px 20px", fontSize:"0.8rem", color:'#22356D99'}}>{t('common.Export To CSV')}</Button>}
        </Stack>

        <Stack>
          {/* for showing the region and location */}
          { values===1 ? <SalesDashboard/> : values===2 && <OccupancyDashboard/> }

          {/* if values is equal to 1 */}
          {values === 1  && <Box>
            {showGraph==="Payment Method" ? 
            <PaymentMethod showGraph={showGraph} setShowGraph={setShowGraph} fontStyle={fontStyle} badge={badge} /> : showGraph==="Service Type" ? 
            <ServiceTypeSat showGraph={showGraph} setShowGraph={setShowGraph} fontStyle={fontStyle} badge={badge} /> : 
            <LocationStats showGraph={showGraph} setShowGraph={setShowGraph} fontStyle={fontStyle} badge={badge} />}
          </Box>}

          {/* if values is equal to 2 */}
          {values == 2 && <Box>
            {showGraph==="Service Type" && 
            <OccupancyStats showGraph={showGraph} setShowGraph={setShowGraph} fontStyle={fontStyle} badge={badge} /> }
            {showGraph==="Coaches" &&
            <CoachStats showGraph={showGraph} setShowGraph={setShowGraph} fontStyle={fontStyle} badge={badge} />}
          </Box>}

          {/* if values is equal to 3 */}
          {values == 3 && <CustomerDashboard /> }
        </Stack>
      </Box>


    </Box>
  )
}

export default Statistics;