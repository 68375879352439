import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import convertTimeFormat from "src/constants/TimeFormatter";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import { RiExpandUpDownLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

function CustomDropdown({
  listData,
  getTime,
  preTime,
  zIndex,
  isCoach
}: {
  zIndex?: any;
  listData: any;
  getTime?: any;
  isCoach?:any;
  preTime?: any;
}) {
  const {t} = useTranslation();
  const [selectedOption, setSelectedOption] = useState('');

useEffect(() => {
  if(preTime!==undefined && preTime !== ""){
    setSelectedOption(preTime);
  }else{
    setSelectedOption("08:00:00")
  }
}, [preTime])


const handleChange=(data:any)=>{
  getTime(data.target.value)
  setSelectedOption(data.target.value)
}

  return (
    <FormControl>
    <Select
    defaultValue={selectedOption}
    error={selectedOption==="" || selectedOption === undefined}
     value={selectedOption}
     onChange={handleChange}
     label="Select an option"
      id="customdropdown"
      style={{
        height: "27px",
        border: "1px solid #EEEEFE",
        borderRadius: "7px",
        fontSize: "14px",
        opacity: "1",
        fontFamily: "var(--font-regular)",
        color: "#22356D",
      }}
      placeholder="Customer type"
      MenuProps={{ sx: {...dopdowncustomcss, '& .Mui-selected::after': {content:'none'}}}}
      IconComponent={RiExpandUpDownLine}
    >
      {isCoach &&  <MenuItem
        style={{
          fontSize: "14px",
          opacity: "1",
          fontFamily: "var(--font-regular) !important",
          color: "#22356D",
        }}
        key={"Unavailable"}
        value={"Unavailable"}
      >
        {t('services.coach.Unavailable')}
      </MenuItem>}
      {listData.map((location:any) => (
        <MenuItem
          style={{
            fontSize: "14px",
            opacity: "1",
            fontFamily: "var(--font-regular) !important",
            color: "#22356D",
          }}
          key={location}
          value={location}
        >
          {convertTimeFormat(location)==="Select Time"?t("courts.Select Time"):convertTimeFormat(location)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  );
}

export default CustomDropdown;
