import { apiCaller } from "./ApiCaller";
import { getClubId } from "./CalendarBooking";

const DeleteCoachByID = async (coachID: number) => {
  try {
      let club_id = getClubId();
    const response: any = await apiCaller.delete(
      `${club_id}/coaches/${coachID}`
    );
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const UpdateCoachByID = async (
  coachID: number,
  APIData: any
) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.put(
      `${club_id}/coaches/${coachID}`,
      APIData
    );
    let data = response;
    return true;
  } catch (error: any) {
    throw error
    let err = error.response?.data?.message;
    throw err;
  }
};

const CreateCoachByClubId = async (
  sport_id: number,
  APIData: any
) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/coaches/${sport_id}`,
      APIData
    );
    return response.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const GetCoachByID = async (coachID: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(`${club_id}/coaches/${coachID}`);
    let data = response.data.data;
    return data;
  } catch (error: any) {
      let err = error.response?.data?.message;
    throw err;
  }
};

const GetCoachSettings = async (coachID: number) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(
      `${club_id}/coaches/coach-settings/${coachID}`
    );
    let data = response.data.data;
    return data;
  } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
};

const UpdateCoachSettings = async (
  coachID: number,
  APIData: any
) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.put(
      `${club_id}/coaches/coach-settings/${coachID}`,
      APIData
    );
    return true;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const CreateCoachSettings = async (
  coachID: number,
  APIData: any
) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/coaches/coach-settings`,
      APIData
    );
    return true
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const GetCoachAppSettings = async (coachID: number) => {
  try {
      let club_id = getClubId();
    const response: any = await apiCaller.get(
      `${club_id}/coaches/app-settings/${coachID}`
    );
    let data = response.data.data;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const UpdateCoachAppSettings = async (
  coachID: number,
  APIData: any
) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.put(
      `${club_id}/coaches/app-settings/${coachID}`,
      APIData
    );
    return true;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

export {
  DeleteCoachByID,
  UpdateCoachByID,
  CreateCoachSettings,
  CreateCoachByClubId,
  GetCoachSettings,
  GetCoachByID,
  UpdateCoachSettings,
  GetCoachAppSettings,
  UpdateCoachAppSettings,
};
