import moment from "moment";

export const formatMinutes = (minutes:number) => {
  const duration = moment.duration(minutes, 'minutes');
  const hours = duration.hours();
  const remainingMinutes = duration.minutes();

  let formattedDuration = '';
  if (hours > 0) {
    formattedDuration += `${hours}hr `;
  }
  if (remainingMinutes > 0) {
    formattedDuration += `${remainingMinutes}min`;
  }

  return formattedDuration.trim();
};