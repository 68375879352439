import React, { useCallback, useEffect, useState } from "react";
import CourtSettingsLeft2days from "./CourtSettingsLeft2days";

const CourtSettingsLeft2 = ({
  setWorkingHoursDiff,
  preWorkingHours
}: {
  setWorkingHoursDiff: any;
  preWorkingHours:any
}) => {
  const [DayTimeBreak, setDayTimeBreak] = useState<any>(preWorkingHours?.length !==0 ? preWorkingHours:[]);
 
  const handleDataPassing = useCallback(() => {
    setWorkingHoursDiff(DayTimeBreak);
  }, [DayTimeBreak]);

  useEffect(() => {
    handleDataPassing();
  }, [DayTimeBreak]);


  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];



  return (
    <div>
      { DayTimeBreak?.length !==0 ? days.map((day:any,index:any) => {
   const dayData = DayTimeBreak.find((item:any) => item.days === day);
        return (<CourtSettingsLeft2days
          key={index}
          index={day}
          DayTimeBreak={dayData }
          setDayTimeBreak={setDayTimeBreak}
          day={day}
        />)
      }
      ): days.map((item) => (
        <CourtSettingsLeft2days
          key={item}
          index={item}
          DayTimeBreak={DayTimeBreak}
          setDayTimeBreak={setDayTimeBreak}
          day={item}
        />
      ))}
    </div>
  );
};

export default CourtSettingsLeft2;
