import { useQuery } from "react-query";
import { apiCaller } from "./ApiCaller";
import { getClubId } from "./CalendarBooking";
import { useState } from "react";

export const GetAllVouchers = async (locationd_id:number) => {
      let club_id = getClubId();
    const location = locationd_id !==0 ? `?location_id=${locationd_id}`:``
    try {
      const response :any= await apiCaller.get(`${club_id}/vouchers${location}`);
      return response.data.data;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  
export const useGetAllVouchers = (location_id: number) => {
    let club_id = getClubId();
  const location = location_id !==0 ? `?location_id=${location_id}`:``
  return useQuery(
    ["AllVoucher", location_id],
    async () => {
      const response: any = await apiCaller.get(`${club_id}/vouchers${location}`);
      return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: false }
  );
};

 
export const useGetAllVouchersByClub = () => {
    let club_id = getClubId();
  return useQuery(
    ["AllVoucherbyClub",club_id],
    async () => {
      const response: any = await apiCaller.get(`${club_id}/customers/vouchers`);
      return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false}
  );
};
  
export const GetAllServicesList = async () => {
      let club_id = getClubId();
  
    try {
      const response :any= await apiCaller.get(`${club_id}/vouchers/services/all`);
      return response.data.data;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };


  export const useGetAllServicesCourt = () => {
      let club_id = getClubId();
    const [Name,setName] =  useState<string>();
    const [sport_id,setsport_id] = useState<number>();
  
    const query = useQuery(
      ["GetAllServicesCourt",Name,sport_id],
      async () => {
        const response :any= await apiCaller.get(`${club_id}/courts/${Name}/${sport_id}`);
       return response.data.data;
      },
      { staleTime: 1000, retry: false, enabled: !!Name && !!sport_id }
    );
    return {setName,setsport_id,...query}
  };
  

  
export const GetAllServicesCourt = async (name:string,sport_id:number) => {
    let club_id = getClubId();

  try {
    const response :any= await apiCaller.get(`${club_id}/courts/${name}/${sport_id}`);
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

export const GetVoucherSale = async (date: any) => {
  try {
        let club_id = getClubId();
      let res = await apiCaller.get(`${club_id}/vouchers/vouchermanualcredit/records?start_date=${date?.date_start}&end_date=${date?.date_end}`);
      return res?.data?.data;
  } catch (error:any) {
      let err = error.respone.data?.message;
      return err;
  }
}


  
export const GetAllPaymentMethods = async (location_id:any,customer_id:any) => {
    let club_id = getClubId();

  try {
    const response :any= await apiCaller.get(`${club_id}/payments/customer-methods/${location_id}/${customer_id}`);
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};


export const useGetAllPaymentMethodsByCust = () => {
    let club_id = getClubId();
  const [customer_id,setCustomer_id] =  useState<number>();
  const [location_id,setLocation_id] = useState<number>();

  const query = useQuery(
    ["getPaymentMethodsByCustomer",customer_id,location_id],
    async () => {
      const response :any= await apiCaller.get(`${club_id}/payments/customer-methods/${location_id}/${customer_id}`);
     return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: !!customer_id && !!location_id }
  );
  return {setCustomer_id,setLocation_id,...query}
};


  
export const DeleteVoucher = async (voucher_id:number) => {
      let club_id = getClubId();
  
    try {
      const response :any= await apiCaller.delete(`${club_id}/vouchers/${voucher_id}`);
      return response.data.message;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  
export const CreateVoucher = async (ApiData:any) => {
      let club_id = getClubId();
  
    try {
      const response :any= await apiCaller.post(`${club_id}/vouchers`,ApiData);
      return response.data.message;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  
export const UpdateVoucher = async (voucher_id:number,ApiData:any) => {
      let club_id = getClubId();
  
    try {
      const response :any= await apiCaller.patch(`${club_id}/vouchers/${voucher_id}`,ApiData)
      const data = response.data.message;
      return data;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };