import * as React from "react";
import Box from "@mui/material/Box";
import {styled} from "@mui/material";
import { FiPlus } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import Dialog from "@mui/material/Dialog";
import MainContext from "../context/MainContext";
import Typography from "@mui/material/Typography";
import { IoClose, IoMenu } from "react-icons/io5";
import ToggleButton from "@mui/material/ToggleButton";
import DialogContent from "@mui/material/DialogContent";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AddSportByLocationID, useAddSportByLocationID } from "src/api/CourtSettings";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";

const StyledBox = styled(Box)`
  box-shadow: 0px 4px 4px 0px #0000000d inset; /* Adjust the values to customize the shadow */
`;



const inputStyle = { border:'1px solid #EEEEFE', padding:"7px 0px 7px 12px", marginTop:"15px", fontSize: '14px', color:" #22356D" };
const style = { fontFamily: 'var(--font-semibold)', boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px", padding: "7px 30px", borderRadius: '7px' };
const commonStyle = {display:'flex', alignItems:"center", gap:"7px"};


export default function CustomTabComponent({
  tabValue,
  setTabValue,
  type,
  tabTextArray,
  setSelectedSport,
  locationid,
  getSportById,
}: {
  tabValue: any;
  type:any;
  setTabValue: any;
  tabTextArray: any[];
  setSelectedSport?: any;
  locationid: any;
  getSportById: any;
}) {
    const context = React.useContext(MainContext);
    const { t } = useTranslation();
    const { control, setValue, watch } = useForm();
    const { fields, append, remove, swap, move, insert } = useFieldArray(
      {
        control,
        name: "Rows",
      }
    );
    const SportsData = watch("Rows");
    const setActiveTab = context?.setActiveTab;
    const [showaddSport, setShowAddSport] = React.useState<boolean>(false);
    const [array, setArray] = React.useState<string[]>([]);
    const {Notification,setNotification} = React.useContext(MainContext);

    const handleChange = (newAlignment: any) => {
      if (newAlignment !== null) {
        setTabValue(newAlignment);
      }
    };


    React.useEffect(() => {
      if (setActiveTab) setActiveTab(tabValue);

      if (tabValue && setSelectedSport) setSelectedSport(tabValue);
    }, [tabValue, setActiveTab]);

    const handleClose = () => {
      setShowAddSport(false);
      remove();
    };

    const addObj = {
      sport_name:'',
      create_level:false
    }

    const handleAdd = () => {
      append(addObj);
      setArray(prevArray => [...prevArray, ""]);
    };

    React.useEffect(()=>{
     if(type==="service"){
      const final = tabTextArray?.slice(1,tabTextArray?.length);
      const allData = final?.map((item:any)=>{
        append(item);
      });
      // setArray(final)
     }else{
      const allData = tabTextArray?.map((item:any)=>{
        append(item);
      });
      // setArray(final)
     }
    },[tabTextArray])

  
    const handleSubmit= async(e:any)=>{
      e.preventDefault();
      const APIdata:any= {
        sports:SportsData?.map((item:any,index:number)=>{
        const obj ={
          "sport_name":item.sport_name,
          "sequence":index+1,
          "create_level":item.create_level
        }
      return obj
        })
      }
    
      try {
        const response = await AddSportByLocationID(locationid,APIdata);
        setNotification({isShow:true,message:response.data.message,subMessage:'',type:'success'})
        getSportById(locationid)
        handleClose();
      } catch (error:any) {
        setNotification({isShow:true,message:error,subMessage:'',type:'error'})
      }

    }

// remove
  const handleRemove = (index: number) => {
    remove(index);
};



// updation for particuklar sport
  const handleUpdate = (index:number,name:string)=>{
    setValue(`Rows.${index}.sport_name`,name);
  }



  // on drag updatedata
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return; // Dragged outside the list, do nothing
    }
    const { source, destination } = result;
    if (source.index === destination.index) {
      return; // Item dropped in the same position, do nothing
    }
    move(source.index, destination.index); // Use move from useFieldArray
  };

  
  return (
    <StyledBox
      sx={{
        padding: "2px",
        borderRadius: "7px", // Set the Box border radius
        display: "inline-block", // Wrap the ToggleButtonGroup
      }}
    >
      <ToggleButtonGroup
        value={tabValue}
        exclusive
        sx={{
            flexWrap:"wrap",
          "& .MuiToggleButtonGroup-grouped": {
            border: "none", // Remove border from buttons
            borderRadius: "7px", // Rounded corners for all buttons
            padding: {xs:"1px 18px",sm:"1px 18px", md:"1px 20px"},
            fontFamily: "var(--font-medium) !important",
            margin: (theme) => theme.spacing(0.5),
            "&.Mui-selected": {
              color: "#22356D",
              backgroundColor: "#fff", // White background for the selected button
              "&:hover": {
                backgroundColor: "#fff", // Keep the background white when hovered
              },
            },
            "&:not(.Mui-selected)": {
              backgroundColor: "transparent", // Transparent background for unselected
              color: "#889AB6",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)", // Slight shade when hovered
              },
            },
          },
        }}
      >
        {tabTextArray?.map((item: any) => (
          <ToggleButton
            key={item?.sport_name}
            value={item}
            onClick={(e: any) => handleChange(item)}
            sx={item?.sport_name =="All Sports" ?{  
               width:'100px',
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "5px",
              paddingBottom: "5px",
              textTransform: "capitalize",
            }:{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "5px",
              paddingBottom: "5px",
              textTransform: "capitalize",
            }}
          >
            {item?.sport_name}
          </ToggleButton>
        ))}
        <ToggleButton
          value={"add"}
          onClick={() => setShowAddSport(true)}
          sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "5px",
            paddingBottom: "5px",
            textTransform: "capitalize",
          }}
        >
          <FiPlus />
        </ToggleButton>
      </ToggleButtonGroup>
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            width: "650px",
            height: "auto",
          },
        }}
        onClose={handleClose}
        open={showaddSport}
      >
        <DialogContent>
          <Box>
            <form onSubmit={(e:any)=>handleSubmit(e)}>
                <Typography sx={{fontFamily:"var(--font-medium)"}}>{t('courts.Edit Sports')}</Typography>
                <Typography onClick={handleAdd} sx={{...commonStyle, cursor:'pointer',fontFamily:"var(--font-regular)", padding:"10px 0px 10px 10px"}}>{t('courts.Add new label')}<IoMdAdd style={{cursor:'pointer'}}  /></Typography>
                <Box sx={{paddingLeft:"20px"}}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {SportsData.map((item:any, index:any) => (
                        <Draggable key={index} draggableId={index.toString()} index={index}>
                          {(provided) => (
                            <Box  {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef} sx={{ ...commonStyle, marginBottom: "10px" }} key={index}>
                              <IoMenu style={{ color: "#22356D", fontSize: "1.5rem" }} />
                                  <input onChange={(e)=>handleUpdate(index,e.target.value)} value={item.sport_name} type='text' placeholder='Enter a label' style={{ ...inputStyle, marginTop: "0px !important" }} />                              
                              <IoClose style={{ cursor: "pointer",width:'20px' }} onClick={() => handleRemove(index)} />
                                <Typography sx={{display:'flex',flexDirection:'row',gap:'10px',fontFamily:'var(--font-regular) !important',marginLeft:'15px'}}>Level for this sport  
                                  <label style={{marginLeft:'5px',marginBottom:'-10px'}} className="container relative">
                                      <input onClick={()=>setValue(`Rows.${index}.create_level`,!item.create_level)} type="checkbox" checked={item.create_level} />
                                      <span className="mark" style={{ left: "-9px" }}></span>
                                    </label>
                                </Typography>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                    </Droppable>
                </DragDropContext>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} marginTop={2} >
                    <button style={{ ...style, color: 'white', background: "#22356D" }} type='submit' >{t('common.Save')}</button>
                </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </StyledBox>
  );
}
