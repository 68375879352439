import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import Cleave from 'cleave.js/react';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MainContext from 'src/context/MainContext';
import { dopdowncustomcss } from 'src/constants/dropdownCSS';


const SingleCustomerPaymentRow = ({item,setPayments,PaymentMethods}:{item:any,setPayments:any,PaymentMethods:any}) => {

    const [discount,setdiscount] = useState<number>(0);
    const [price,setPrice] = useState<number>(0);
    const {Notification,setNotification} = useContext(MainContext);
    const [SelectedVoucher,setSelectedVoucher] = useState<any>();
     
  useEffect(() => {
    setPrice(item?.serv?.players[0]?.pending_price);
  }, [item]);


  

const PAssData = useCallback(()=>{
      let apidata :any= {
        "payment_id": item?.id,
        "discount": Number(discount),
        "price":Number(price),
    }
     if(SelectedVoucher?.hasOwnProperty('balance')){
      apidata.wallet_id = SelectedVoucher.id;
      return apidata
    }else if(SelectedVoucher?.hasOwnProperty('method_name')){
     apidata.method_id =SelectedVoucher.id;
     return apidata
    }
},[discount,SelectedVoucher,price])


useEffect(()=>{
    setPayments((prev:any)=>{ 
        const newFormattedData = PAssData();
        if (!newFormattedData || newFormattedData === undefined) {
          return prev;
        }
        if(prev?.length>0){
          const indexToUpdate = prev?.findIndex(
              (item: any) => item?.payment_id === newFormattedData?.payment_id
            );
            // If an object with the same id is found, update its data
      
            if (indexToUpdate !== -1) {
              const updatedState = [...prev];
              updatedState[indexToUpdate] = newFormattedData;
              return updatedState;
            } else if (prev.length !== 0) {
              return [...prev, newFormattedData];
            } 
        }
        else {
          return [newFormattedData];
        }
      })
},[PAssData,discount,SelectedVoucher,setPayments]);

    useEffect(()=>{
        if(SelectedVoucher && SelectedVoucher?.balance){
          if(SelectedVoucher?.balance<price){
            setNotification({isShow:true,message:"Customer doesn’t have sufficient wallet credits",subMessage:'',type:'error'});
            setSelectedVoucher({});
          }
        }
      },[SelectedVoucher]);

  return (
    <Box
    display={"flex"}
    justifyContent={"space-between"}
    marginTop={2}
    sx={{alignItems: {xs:"baseline", sm:"center"},gap:{xs:"0px", sm:"2rem"}}}
  >
   

    <Box sx={{display:"flex", flexDirection:{xs:"column", sm:"row"}, alignItems:{xs:"end", sm:"center"}, gap:"5px", flex:1, justifyContent:{xs:"unset", sm:"space-between"}}}>
    <FormControl>
      <Select
        IconComponent={KeyboardArrowDownIcon}
        placeholder="Customer type"
        value={
          !!SelectedVoucher ? SelectedVoucher : "Payment Method"
        }
        id="customdropdown"
        renderValue={(value) => {
          if (value?.method_name) {
            return value?.method_name;
          }
          return <em>{"Payment Method"}</em>;
        }}
        style={{
          width: "180px",
          height: "35px",
          border: "1px solid #EEEEFE",
          borderRadius: "7px",
          fontSize: "14px",
          opacity: "1",
          fontFamily: "var(--font-regular)",
          color: "#22356D",
        }}
        MenuProps={{ sx: dopdowncustomcss }}
      >
        {PaymentMethods?.map((location:any) => (
          <MenuItem onClick={(e:any)=>setSelectedVoucher(location)} key={location.id} value={location}>
            {location?.method_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <div className="flex flex-row items-center gap-1">
      <p
        style={{
          color: "rgba(34, 53, 109)",
          fontFamily: "var(--font-medium)",
          fontSize: "14px",
          lineHeight: "17px",
        }}
      >
      {"Discount: "}
      </p>
      <Cleave
        value={discount}
    
        style={{
          width: "100px",
          color: "rgba(34, 53, 109)",
          fontFamily: "var(--font-regular)",
          fontSize: "14px",
          lineHeight: "17px",
          borderRadius: "7px",
          padding: "5px 8px",
          border: "1px solid #EEEFEE",
        }}
        onChange={(event: any) => setdiscount(Number(event.target.rawValue))}
        placeholder={"Discount"}
        options={{
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          delimiter:'\u2024'
        }}
      />
    </div>

    <div className="flex flex-row items-center gap-1">
      <p
        style={{
          color: "rgba(34, 53, 109)",
          fontFamily: "var(--font-medium)",
          fontSize: "14px",
          lineHeight: "17px",
        }}
      >
       IDR
      </p>

      <Cleave
      onChange={(e:any)=>setPrice(e.target.rawValue)}
      value={price}
        style={{
          width: "100px",
          color: "rgba(34, 53, 109)",
          fontFamily: "var(--font-regular)",
          fontSize: "14px",
          lineHeight: "17px",
          borderRadius: "7px",
          padding: "5px 8px",
          border: "1px solid #EEEFEE",
        }}
        placeholder="100"
        options={{
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          delimiter:'\u2024'
        }}
      />
    </div>
    </Box>
  
  </Box>
  )
}

export default SingleCustomerPaymentRow