import React, { useEffect, useRef, useState } from "react";
import "air-datepicker/air-datepicker.css";
import { SelectorIcon } from "../SelectorIcon";
import { Select, SelectItem} from "@nextui-org/react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { PiCaretUpDownLight } from "react-icons/pi";
import { useForm } from "react-hook-form";
import moment from "moment";
import { SingleDateMu } from "../../components/customTimeSeletor";
import MainContext from "src/context/MainContext";
import { useTranslation } from "react-i18next";

const day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', "Friday", "Saturday", "Sunday"];

const RecurringBooking = ({ data, handleFormSubmit }:{ data?:any, handleFormSubmit?: any }) => {
    const [recurrings, setRecurrings] = React.useState<string>("Daily");
    const {t} = useTranslation();
    const [every, setEvery] = useState<Number>(1);
    const [onMonth, setOnMonth] = useState<string>('1')
    const { register, watch, handleSubmit, reset, setValue } = useForm();
    const {start_date,finishing_date} = watch();
    const formData = watch();
    const recurring = watch("recurring");
    const context = React.useContext(MainContext);
    const selectedDate = context?.selectedDate;
    const formRef = useRef<any>(null);
    const label:any = { inputProps: { 'aria-label': 'Checkbox demo' } };
    

    useEffect(()=>{
        if(Object.keys(data).length > 0){
            reset({
                start_date: moment(data.start_date).format('YYYY-MM-DD'),
                finishing_date: moment(data.finishing_date).format('YYYY-MM-DD'),
                every: Number(data?.every),
                recurring: data?.recurring,
                onDayOfMonth: data?.onDayOfMonth || undefined,
                onDaysOfWeek:data?.onDaysOfWeek || undefined
            })
        }
    },[data]) 
 
    useEffect(() => {
        handleFormSubmit(formData);
        if(formData?.recurring){
            setRecurrings(recurring);
        }
        if(formData?.every){
            setEvery(Number(formData?.every))
        }
        if(formData?.onDayOfMonth){
            setOnMonth(formData?.onDayOfMonth)
        }
    }, [formData?.finishing_date, formData?.every,formData?.recurring, formData?.start_date, formData?.onDayOfMonth, formData?.onDaysOfWeek, recurrings]);

    useEffect(() => {
    if(start_date===undefined){
        setValue("start_date",moment(selectedDate).format("YYYY-MM-DD"))
    }
    }, [start_date])
    
    return (
        <Box marginBottom={'15px'} color={'#22356D99'}>
            <FormControl sx={{ minWidth: '100%' }}>
                <form ref={formRef}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} marginBottom={'7px'}>
                        <p>{t('Calendar.Starting Date')}</p>
                        <SingleDateMu style={{color:'#22356D !important', fontFamily:"var(--font-regular) !important",width:'100px',}} type={'single'} containercss={{color:'#22356D !important',paddingRight:'5px', borderRadius:'7px',border:'1px solid #93939366',height:'32px'}} selectedDay={start_date!== undefined ?start_date:moment(selectedDate).format("YYYY-MM-DD")} placeholder={'select date'} icon={<PiCaretUpDownLight style={{color:'#22356D !important'}} />} format={""} setSelectedDays={(date:any)=> setValue("start_date", date)}
                        />
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} marginBottom={'7px'}>
                        <p>{t('Calendar.Finishing Date')}</p>
                        <SingleDateMu style={{color:'#22356D !important', fontFamily:"var(--font-regular) !important",width:'100px',}} type={'single'} containercss={{color:'#22356D !important',paddingRight:'5px', borderRadius:'7px',border:'1px solid #93939366',height:'32px'}} selectedDay={finishing_date} placeholder={'select date'} icon={<PiCaretUpDownLight style={{color:'#22356D'}} />} format={""} setSelectedDays={(date:any)=> setValue("finishing_date",date)}
                        />
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'7px'}>
                        <Select
                            labelPlacement={'outside-left'}
                            label="Recurring"
                            variant='bordered'
                            placeholder="Select"
                            radius={'sm'}
                            selectorIcon={<SelectorIcon />}
                            selectedKeys={[recurrings]}
                            {...register("recurring")}
                            // onChange={(e) => setRecurrings(e.target.value)}
                            classNames={{
                                label: "starting-date",
                                trigger:"starting-trigger",
                                mainWrapper: "starting-date-wrapper",
                                value: 'starting-date-value'
                            }}
                        >
                            {["Daily", "Weekly", "Monthly"].map((item)=><SelectItem key={item} value={item} style={{color:"#22356D", fontFamily:"var(--font-regular)"}}>{t(`Calendar.${item}`)}</SelectItem>)}
                        </Select>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'7px'} position={'relative'}>
                        <Select
                        style={{width:'60px'}}
                            labelPlacement={'outside-left'}
                            label="Every"
                            variant='bordered'
                            placeholder="1"
                            radius={'sm'}
                            selectorIcon={<SelectorIcon />}
                            selectedKeys={[`${every}`]}
                            {...register("every")}
                            // value={every}
                            // onChange={(event)=>setEvery(Number(event.target.value))}
                            classNames={{
                                label: "starting-date",
                                trigger:"starting-trigger",
                                mainWrapper: "starting-date-wrapper every-wrapper",
                                value: 'starting-date-value'
                            }}
                        >
                            {[1, 2, 3, 4, 5].map((item)=><SelectItem style={{minWidth:'80px', fontFamily:"var(--font-regular)", color:"#22356D"}} key={Number(item)} value={Number(item)} textValue={String(item)}>{item}</SelectItem>)}
                        </Select>
                        <Typography position={'absolute'} top={5} right={0} color={'#22356D99'} sx={{fontSize:"0.85rem", color:"#22356D", fontFamily:"var(--font-regular)"}}>{recurring && (recurring ==="Daily" ? "day[s]" : recurring==="Weekly" ? "week[s]" : recurring==="Monthly" ? "month[s]" :"")}</Typography>
                    </Stack>
                    {/* --- for selecting the week day --- */}
                    {recurring==="Weekly" && <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                        <p>On</p>
                        <Stack direction={'row'} spacing={1} marginBottom={'7px'}>
                            {day.map((item)=>
                                <Box key={item}>
                                    <p style={{color:"#22356D", fontFamily:"var(--font-regular)"}}>{item.slice(0, 3)}</p>
                                    <label style={{ margin: "4px" }} className="container">
                                        <input type="checkbox"
                                        {...register(`onDaysOfWeek[${item}]`)}
                                        />
                                        <span className="mark"></span>
                                    </label>
                                </Box>
                            )}
                        </Stack>
                    </Stack>}
                    {/* ----- for select the month ---- */}
                    {recurring==="Monthly" && 
                    <Stack direction={'row'} spacing={1} marginBottom={'7px'} position={'relative'} >
                        <Select
                        style={{minWidth:'80px'}}
                            labelPlacement={'outside-left'}
                            label="On day"
                            variant='bordered'
                            placeholder="1"
                            radius={'sm'}
                            selectedKeys={[onMonth]}
                            selectorIcon={<SelectorIcon />}
                            classNames={{
                                label: "starting-date",
                                trigger:"starting-trigger",
                                mainWrapper: "starting-date-wrapper month-wrapper",
                                value: 'starting-date-value'
                            }}
                            {...register("onDayOfMonth")}
                        >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,13,14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((item)=>
                            <SelectItem  key={item} value={item} textValue={String(item)} style={{paddingRight:"0px !important",minWidth:'60px', color:"#22356D", fontFamily:"var(--font-regular)"}}>{item}</SelectItem>)}
                        </Select>
                        <p style={{position:"absolute", right:'20px', top:'8px', fontFamily:"var(--font-regular)"}}>th</p>
                    </Stack>}
                </form>
            </FormControl>
        </Box>
  )
}

export default RecurringBooking;