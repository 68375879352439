import { useQuery } from "react-query";
import { apiCaller } from "./ApiCaller";

// adding club admin in created club
const insertClubAdmin = async (id:number, data: any) => {
  try {
    const response:any = await apiCaller.post(`/admins/clubs/club-admin/${id}`, data);
    // getAllClub();
    return response.data;
  } catch (error:any) {
    let err = error.response?.data?.message;
    throw err;  }
}

// creating the club
const createClub = async (club: any) => {
  try {
    const response:any = await apiCaller.post("/admins/clubs", club);
    return response.data;
  } catch (error:any) {
    let err = error.response?.data?.message;
    throw err
  }
};

// deleting the club
const deleteClub = async (club_id: number) => {
  try {
    const response:any = await apiCaller.delete(`/admins/clubs/${club_id}`);
    return response.data;
  } catch (error:any) {
    let err = error.response?.data?.message;
    throw err
  }
};

// get all club list
const getAllClub = async (name?:string) => {
  try {
    let baseURL1 = "/admins/clubs";
    let baseURL2 = `admins/clubs?club_name=${name}`;

    const response:any = await apiCaller.get(name?baseURL2:baseURL1);
    let data = response.data?.data
    if (data && !Array.isArray(data)) {
      data = [data]; // Wrap single object in an array
    } else if (!data) {
      data = []; // Set to empty array if data is null or undefined
    }
    return data;
  } catch (error:any) {
    let err = error.response?.data?.message;
    throw err;
  }
}

// /admins/clubs/names
const getClubsNames = async () => {
  try {
    const response:any = await apiCaller.get("/admins/clubs/names");
    let data = response.data?.data;
    return data;
  } catch (error:any) {
    let err = error.response?.data?.message;
    throw err;
  }
}

export const useGetAllCLubs = () => {
  const query = useQuery(
    ["GetAllClubs"],
    async () => {
      const response: any = await apiCaller.get(`/admins/clubs`);
      return response.data.data;
    },
    {
      enabled:false,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return query
 
};

// update club details
const updateClubData = async (club_id:number, data: any) => {
  try {
    const response:any = await apiCaller.put(`/admins/clubs/${club_id}`, data);
    let res = response.data;
   return res;
  } catch (error:any) {
    let err = error.response?.data?.message;
    throw err
  }
}

// update club admin details
const updateClubAdmin = async (user_id:number, data: any) => {
  try{
    const response:any = await apiCaller.patch(`/admins/clubs/club-admin/${user_id}`, data);
    let res = response.data;
   return res;
  }catch(error:any){
    let err = error.response?.data?.message;
    throw err
  }
}

export { insertClubAdmin, createClub, getAllClub, getClubsNames, updateClubData, updateClubAdmin };