import React, { useEffect, useState } from "react";
import { activeColor } from "src/constants/color";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddPlayer from "./Calendar/AddPlayer";
import "./tableStyling.css";

const scoreStyle={
    borderRadius: '4px',
    border:'1px solid #939393',
    width:'20px',
    height:'20px',
    textAlign:"center",
    fontSize:{xs:"0.8rem", sm:"0.9rem"},
    backgroundColor:'white'
}

  let players = [
    {
      label:"Joaquin Reyes"
    },
    {
      label:"Stella Wilson"
    },
    {
      label:"Murphy Wilson"
    }
  ]

const ScoreList = ({selectAll, handleMasterCheckboxChange, userData, selectedOpenRegular, handleCheckboxChange}:{selectAll: any, handleMasterCheckboxChange: any, userData: any, selectedOpenRegular: string,handleCheckboxChange: any}) => {
    const [addPlayer, setAddPlayer] = useState<string>('');
    const [showList, setShowList] = useState<boolean>(false);
    const [data, setData] = useState(players);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value
    setAddPlayer(val);
    setShowList(true);
    let arr = userData.filter((item:any)=>{
      let name = item.label.toLowerCase();
      return name.includes(val.toLowerCase())
    })
    setData(arr);
  }

  const selectedUser = (item: any)=>{
    setShowList(false);
    setAddPlayer(item.label);
  }

  useEffect(()=>{
    document.addEventListener("click",()=>{
      setShowList(false);
    })
  },[])

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <AddPlayer addPlayer={addPlayer} handleChange={handleChange} showList={showList} setShowList={setShowList} data={data} selectedUser={selectedUser} />
            </Box>
            {/* ----- player list ----- */}
            {userData.length>0 ? 
            <Box height={userData.length>3?'288px':'auto'} id="table-person">
                <Box className="waitingList">
                    {userData.map((user: any, index: number) => (
                        <Box key={user.id} display={'flex'} gap={1} sx={{"&:hover":{background:"#889AB64D !important"}}} alignItems={'center'} padding={'8px 10px'}>
                            <Box>
                              <Box sx={{ borderRadius:"7px", width:"26px", height:"26px", textAlign:"center", padding: '0px 10px', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)', fontSize:{xs:"0.8rem", sm:"1rem"} }}>{index + 1}</Box>
                            </Box>
                            <Box style={{width: "inherit", flex:1}}>
                                <Box display={'flex'} justifyContent={"space-between"} width={"100%"} color={activeColor} alignItems={'center'}>
                                  <Box>
                                    <Box display={"flex"} alignItems={"center"} gap={1} marginBottom={1} sx={{marginBottom:{xs:"0px", sm:"8px"},">img":{display:{xs:"none", sm:"block"},">p":{display:{xs:"none", sm:"block"}}}}}>
                                      <img src="/images/Reserved.svg" alt="user" width='30px' height='30px' />
                                      <Typography sx={{fontFamily:"var(--font-regular)", fontSize:{xs:"0.8rem", sm:"1rem"}}}>{user.name}</Typography>
                                      <Typography sx={{fontFamily:"var(--font-regular)", display:{xs:"none", sm:"block"}}}>+569 81346824</Typography>
                                    </Box>
                                    <Box display={"flex"} alignItems={"center"} gap={1} sx={{">img":{display:{xs:"none", sm:"block"},">p:nth-child(1)":{display:{xs:"none", sm:"block"}}}}}>
                                      <img src="/images/Reserved.svg" alt="user" width='30px' height='30px' />
                                      <Typography sx={{fontFamily:"var(--font-regular)", fontSize:{xs:"0.8rem", sm:"1rem"}}}>{user.name}</Typography>
                                      <Typography sx={{fontFamily:"var(--font-regular)", display:{xs:"none", sm:"block"}}}>+569 81346824</Typography>
                                    </Box>
                                  </Box>
                                  <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} paddingRight={1} sx={{flexDirection:{xs:"column", sm:"row"}, width:{xs:"106px", sm:"196px"}}}>
                                    <Box display={"flex"} alignItems={"center"} gap={2}>
                                      <Box sx={{...scoreStyle}} >4</Box>
                                      <Box sx={{...scoreStyle}} >6</Box>
                                      <Box sx={{...scoreStyle}} >4</Box>
                                    </Box>
                                    {index!==0 && <Typography fontFamily={"var(--font-medium)"} sx={{fontSize:{xs:"0.8rem", sm:"1rem"}}}>Winner</Typography>}
                                  </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
            :
            <p style={{color: activeColor, marginBottom:"40px", marginTop:"7px"}}>There is no Waiting List</p>}
        </>
      )
}

export default ScoreList