import { Box, Button, Dialog, DialogContent, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'
import Cleave from 'cleave.js/react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'
import { dopdowncustomcss } from 'src/constants/dropdownCSS';
import { useGetAllPaymentMethodsByLoc } from 'src/hooks/useGetAllPaymentMethods';
import { useGetClubWalletOfCustomer } from 'src/api/Customer';
import { useGetAllPaymentMethodsByCust } from 'src/api/Voucher';
import MainContext from 'src/context/MainContext';
import { addThousandSeparator } from 'src/constants/SportSEparator';
import { RefundProduct } from 'src/api/POS';
import { Permission, useGetPermissions } from 'src/api/CalendarBooking';

const RefundSale = ({refetch,handleClose,open,SelectedLocation,selectedLocationID,data}:{handleClose:any,open:boolean,SelectedLocation:any,selectedLocationID:number,data:any,refetch:any}) => {
      
    const {t} = useTranslation();
     const StaffPermission = useGetPermissions();
    const { control, register, handleSubmit, setValue,reset,watch ,unregister} = useForm();
    const [isClickable,setIsClickable] = useState(false);
    const {refund_price,price,customer_id} = watch();
    const [PaymentMethods,setPaymentMethods] =useState<any[]>([]);
    const [CustomerWallet,setCustomerWallet] =useState<any[]>([]);
    const [SelectedVoucher, setSelectedVoucher] = useState<any>();
    const {data:PaymentMet,isSuccess:PaymentMethodsSuccess,isError:PaymentMethodsError,setlocation_id:SetLocationID} = useGetAllPaymentMethodsByLoc();
    const {
        data:ClubWalletData,
        isSuccess,
        isError,
        setCustomer_id,
        refetch: RefetchClubWallet,
      } = useGetClubWalletOfCustomer();

    const { Notification, setNotification,setIsPermissionOn } = useContext(MainContext);


    // dynamcially selecting wallet and method
    const handleSelectPayment = (data: any) => {
        if (data?.hasOwnProperty("balance")) {
         
            setValue(
              `wallet_id`,
              data?.id
            );
            unregister(`method_id`)
            setSelectedVoucher(data);
           
        } else if (data?.hasOwnProperty("method_name")) {
          setSelectedVoucher(data);
          unregister(`wallet_id`)
          setValue(
            `method_id`,
            data?.id
          );
        }
      };

    
      // popup close
      const handlePopUpClose =()=>{
        handleClose();
        setSelectedVoucher('');
        reset();
      }

      // form submitting
      const onSubmit = async(formData:any)=>{

        if(!StaffPermission.edit_permissions){
          setIsPermissionOn(true);
          handleClose();
          return;
        }

        const apidata = {
          refund_price:refund_price,
          method_id:formData?.method_id? formData?.method_id:undefined
        }
        try {
        const response  = await RefundProduct(data.id,apidata);
        setNotification({isShow:true,message:'Refund of Product done successfully',subMessage:'',type:'success'});
        refetch();
        handlePopUpClose();
        } catch (error) {
        
        }
      }

      // validating refund price must less than product price
      const handleRefundValue= (refund_value:number)=>{
        if(refund_value>price){
          setNotification({isShow:true,message:'Refund price should be less than or equal to product price',subMessage:'',type:'error'});
          setValue('refund_price',0)
        }else{
          setValue('refund_price',refund_value)
        }
      }

      //calling api on  the basis of data
      useEffect(() => {
        if(customer_id!==0){
          setCustomer_id(customer_id);
          SetLocationID(selectedLocationID);
        }else{
          SetLocationID(selectedLocationID);
        }
        }, [selectedLocationID,customer_id]);

        // setting form value dyncamically
        useEffect(() => {
          const updateddata = {
            customer_id:data?.customers ? data?.customers?.id :0,
            method_id:'',
            price:data?.price,
            refund_price:''
          }
          reset(updateddata)
        }, [data])
        
  
        
        useEffect(() => {
          if(PaymentMethodsSuccess){
            setPaymentMethods(PaymentMet)
          }else{
            setPaymentMethods([]);
          }
          }, [PaymentMet,PaymentMethodsSuccess,PaymentMethodsError]);
  
          useEffect(() => {
            if(isSuccess && customer_id!==0){
              const updated = ClubWalletData?.map((item: any) => {
                const method_name = `Wallet ${addThousandSeparator(
                  item.balance,
                  item.currency
                )} ${item.currency}`;
                return { ...item, method_name };
              });
              setCustomerWallet(updated)
            }else{
              setCustomerWallet([]);
            }
            }, [ ClubWalletData,
              isSuccess,
              isError,]);


  return (
  
          <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{">p:not(:first-child)":{fontFamily:"var(--font-regular) !important", color:"#22356DB2"}}}>
              <Typography sx={{fontFamily:"var(--font-semibold) !important", color:"#22356D"}}>{t("POS.Sale Settings")}</Typography>
              <Typography>{moment(new Date()).format('dddd, DD MMMM, yyyy')} | {moment(new Date()).format('HH:mm')}</Typography>
              {/* <Typography>{selLocation?.location_name}</Typography> */}
          </Box>
          <Box
            marginTop={2}
            sx={{alignItems: {xs:"baseline", sm:"center"},gap:{xs:"0px", sm:"2rem"}}}
          >
          {customer_id!==0 &&  <Box
              sx={{
                display: "flex",
                alignItems: {xs:"baseline", sm:"center"},
                gap: {xs:"5px",sx:"15px"},
                ">img":{display:{xs:"none", sm:"block"}}
              }}
            >
              <img style={{width:'40px',height:'40px',borderRadius:'50%'}} src={data?.customers?.profile_url?data?.customers?.profile_url:"/images/Reserved.svg"}
              />
              <Box
                sx={{
                  display: "flex",
                  gap:{sm:'5px',xs:''},
                  flexDirection: {xs:"column"},
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "var(--font-regular)",
                    color: "#22356D",
                    textAlign: "left",
                  }}
                >
                {data?.customers?.first_name} {data?.customers?.last_name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  {/* {
                  data?.customer?.customerTypes?.map((item:any)=> 
                  <Typography key={item?.id}
                  sx={{
                    fontFamily: "var(--font-regular)",
                    color: "#22356D",
                    padding: "0px 5px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    border: " 0.8px solid #889AB638",
                    background:"#889AB626",
                    textTransform:"capitalize"
                  }}
                >{item?.name}
                </Typography>)
                  } */}
                </Box>
              </Box>
            </Box>}
            <Box sx={{display:"flex", flexDirection:{xs:"column", sm:"row"}, alignItems:{xs:"end", sm:"center"}, gap:"5px", flex:1, justifyContent:{xs:"unset",sm:'space-between'},marginTop:'5px'}}>
         
            <Box sx={{mariongBottom:`${14} px`}}>
            <FormControl sx={{width:{xs:"100%", md:"unset"}}}>
              <Select
                IconComponent={KeyboardArrowDownIcon}
                placeholder="Customer type"
                value={!!SelectedVoucher ? SelectedVoucher : t("customers.Payment Method")}
                id="customdropdown"
                renderValue={(value) => {
                  if (value?.method_name) {
                    return value?.method_name;
                  }
                  return <em>{t("customers.Payment Method")}</em>;
                }}
                sx={{
                  width: {xs:"100%", md:"180px"},
                  height: "35px",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontSize: "14px",
                  opacity: "1",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                }}
                MenuProps={{ sx: dopdowncustomcss }}
              >
                {CustomerWallet?.map((location: any) => (
                  <MenuItem
                    onClick={(e: any) => handleSelectPayment(location)}
                    key={location.id}
                    value={location}
                  >
                    {location?.method_name}
                  </MenuItem>
                ))}
                {PaymentMethods?.map((location: any) => (
                  <MenuItem
                    onClick={(e: any) => handleSelectPayment(location)}
                    key={location.id}
                    value={location}
                  >
                    {location?.method_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>

            <div className="flex flex-row items-center gap-1">
              <p
                style={{
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-medium)",
                  fontSize: "14px",
                  lineHeight: "17px",
                }}
              >
              {"Refund: "}
              </p>
              <Cleave
                value={refund_price}
                style={{
                  width: "100px",
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  lineHeight: "17px",
                  borderRadius: "7px",
                  padding: "5px 8px",
                  border: "1px solid #EEEFEE",
                }}
                onChange={(e:any)=>handleRefundValue(e.target.rawValue)}
                placeholder={"Amount"}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter:'\u2024'
                }}
              />
            </div>

            <div className="flex flex-row items-center gap-1">
              <p
                style={{
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-medium)",
                  fontSize: "14px",
                  lineHeight: "17px",
                }}
              >
               {"Paid"} {SelectedLocation?.currency}
              </p>

              <Cleave
              readOnly={true}
              value={price}
                style={{
                  width: "100px",
                  color: "rgba(34, 53, 109)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  lineHeight: "17px",
                  borderRadius: "7px",
                  padding: "5px 8px",
                  border: "1px solid #EEEFEE",
                }}
                placeholder="100"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  delimiter:SelectedLocation?.currency==="IDR"?'\u2024':','
                }}
              />
            </div>
            </Box>
          </Box>
              <Grid marginTop={'40px'} container justifyContent={"space-between"} alignItems={"center"}>
              <Button
                onClick={handlePopUpClose}
                style={
                {
                        textTransform: "unset",
                        fontFamily: "var(--font-semibold)",
                        width: "104px",
                        height: "37px",
                        color: "#22356D",
                        backgroundColor: "white",
                        fontSize: "16px",
                        padding: "10px 30px",
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                      }
                }
              >
                {t('common.Cancel')}
              </Button>
              <Button
                type='submit'
                style={isClickable?{
                  textTransform: "unset",
                  fontFamily: "var(--font-semibold)",
                  color: "white",
                  backgroundColor: "#22356D",
                  lineHeight:'14px',
                  width: "92px",
                  height: "37px",
                  fontSize: "16px",
                  pointerEvents:'none'
                }:{
                  textTransform: "unset",
                  fontFamily: "var(--font-semibold)",
                  color: "white",
                  backgroundColor: "#22356D",
                  lineHeight:'14px',
                  width: "92px",
                  height: "37px",
                  fontSize: "16px",
                  pointerEvents:'auto'
                }}
              >
              {t('common.Save')}
              </Button>
          </Grid>
          </form>
      
  )
}

export default RefundSale