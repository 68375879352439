import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BsChevronDown } from "react-icons/bs";

export default function CustomEvSelect({
  fontBold,
  placeholder,
  selectedValueIndex = '',
  widthSize,
  callback,
  children
}: {
  fontBold?:any,
  placeholder: string;
  selectedValueIndex?: string;
  widthSize?: string;
  callback: (tab: string) => void;
  children?: any
}) {
  const [value, setValue] = React.useState(selectedValueIndex);

  React.useEffect(() => {
    setValue(selectedValueIndex || ''); 
  }, [selectedValueIndex]);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    callback(event.target.value);
  };

  React.useEffect(()=>{},[value]);

  return (
    <FormControl
      sx={{
        width: widthSize ? widthSize : "100%",
        "& .MuiInputBase-input, & .MuiOutlinedInput-input": {
          height: "40px", // Target the input height
          padding:"4.5px 12px 0px 12px !important",
        },
        "& .MuiTypography-root": {
          fontFamily: fontBold?'var(--font-medium) !important':"var(--font-regular) !important",
          color:`${placeholder.includes("time") ? "#22356DB2":"#22356D"}`
        },
        "& .MuiOutlinedInput-root": {
          height: "40px", // Ensure the outer part of the TextField matches the height
          borderRadius:"8px",
          border:"1px solid #EEEFEE",
          width:fontBold?"300px":"auto"
        },
      }}>
      <Select value={`${value}`}
        MenuProps={{
          sx: {
            '& .MuiMenu-paper': {
              maxHeight:"232px",
              '&::-webkit-scrollbar': {
                width: '14px',
              },
              '&::-webkit-scrollbar-track': {
                borderRadius: "0px 7px 7px 0px",
                border: "0.5px solid #EEEFEE",
                background: "linear-gradient(0deg, rgba(238, 239, 238, 0.05) 0%, rgba(238, 239, 238, 0.05) 100%), #FFF",
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset, -4px 0px 4px 0px rgba(0, 0, 0, 0.05) inset'
              },
              '&::-webkit-scrollbar-thumb': {
                border: "4px solid #EEEFEE",
                background: "rgba(147, 147, 147, 0.40)",
                borderRadius: "7px",
                marginTop: "10px",
              }
            },
            "& .MuiButtonBase-root": {
              background:"#f3f5f8 !important",
              "&:hover":{
                background:"#22356D !important",
                color:"white !important",
                "& .MuiTypography-root":{
                  color:"white !important"
                }
              }
            },
            "& .Mui-selected":{
              background:"#22356D !important",
              color:"white !important",
              "& .MuiTypography-root":{
                color:"white !important"
              }
            }
          }
        }}
        placeholder={placeholder} onChange={handleChange} 
        IconComponent={() => <BsChevronDown style={{fontSize:'20px', paddingRight:"4px"}} />}
      >
        {children}
      </Select>
    </FormControl>
  );
}
