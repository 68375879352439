import React, {useEffect, useState } from "react";
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import localeEn from "air-datepicker/locale/en";
import { IoCloseOutline } from "react-icons/io5";
import TimeRangePicker from "../../components/CustomDropdownTimeRange";
import { allTimeFormats } from "src/constants/variables";

const CustomCalenderTime = ({
  preTime,
  setShowCalendar,
  setGetDateTime,
  index,
  handleClose
}: {
  preTime: any;
  index?: any;
  setShowCalendar: any;
  setGetDateTime?: any;
  handleClose:any;
}) => {
  const [selectedDATES, setselectedDATES] = useState<any>(
    preTime?.date_start === undefined && preTime?.date_end === undefined
      ? ""
      : {
          date_start: preTime?.date_start,
          date_end: preTime?.date_end,
        }
  );
  const [time, setTime] = useState<any>(
    preTime === undefined
      ? ""
      : {
          start_time: preTime.start_time,
          end_time: preTime.end_time,
        }
  );

  const Handle=()=>{
  const format=[{
    date_start: selectedDATES.date_start,
    date_end: selectedDATES.date_end,
    start_time:time.start_time,
    end_time:time.end_time
  },
]
        setGetDateTime(format)
        handleClose();
}


  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];


  let isRange = true;
  useEffect(() => {
    new AirDatepicker("#calendar", {
      locale: localeEn,
      range: isRange,
      multipleDatesSeparator: " - ",
      selectedDates: selectedDATES?.date_start !== null &&
        selectedDATES?.date_end !== null &&
        selectedDATES?.date_start !== undefined &&
        selectedDATES?.date_end !== undefined && [
          selectedDATES?.date_start,
          selectedDATES?.date_end,
        ],
      onSelect: ({ date }) => {
        const newSelectedDates = Array.isArray(date) ? date : [date]; // Ensure date is always an array
        if (
          newSelectedDates[0] == undefined &&
          newSelectedDates[1] === undefined
        ) {
          setselectedDATES([]);
        } else if (
          newSelectedDates[0] !== undefined &&
          newSelectedDates[1] === undefined
        ) {
          isRange = false;
          const datesToStore = {
            date_start: newSelectedDates[0],
            date_end: newSelectedDates[0],
          };
          setselectedDATES(datesToStore);
        }else if (
          newSelectedDates[0] !== undefined &&
          newSelectedDates[1] !== undefined
        ) {
          isRange = true;
          const datesToStore = {
            date_start: newSelectedDates[0],
            date_end: newSelectedDates[1],
          };
          setselectedDATES(datesToStore);
        }
      },
    });
  }, []);

  return (
    <div
      style={{
        width: "261px",
        backgroundColor: "white",
        borderRadius: "7px",
      }}
    >
      <div
        style={{ position: "relative", height: "20px", marginBottom: "8px" }}
      >
        <div
          onClick={() => setShowCalendar(false)}
          style={{ cursor: "pointer", position: "absolute", right: "0" }}
        >
          <IoCloseOutline style={{ fontSize: "18px" }} />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <TimeRangePicker
          TimeRange={time}
          zIndex={"100000"}
          listData={allTimeFormats}
          getTime={setTime}
        />
     <div
          style={{ margin: "20px 0" }}
          className="flex flex-row justify-center relative gap-1"
        >
      
        <div className="relative">
          <span
            style={{ width: "239px !important", justifySelf: "center" }}
            id="calendar"
          />
        </div>
      </div>
      </div>
      <div className='flex flex-row justify-end items-center'>
      <button onClick={Handle} style={{
        marginTop:'10px',
            textTransform: "unset",
            fontFamily: "var(--font-semibold)",
            color: "white",
            backgroundColor: "#22356D",
            width: "92px",
            height: "37px",
            fontSize: "16px",
          }}>Save</button>
      </div>
    </div>
  );
};

export default CustomCalenderTime;
