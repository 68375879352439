import React, { useEffect, useState } from "react";
import { LevelRange } from "src/constants/variables";
import CustomDropdownMinutes from "../../components/CustomDropdownMinutes";
import { Box, Typography } from "@mui/material";

function LevelRangePicker({
  getTime,
  preTime,
  setDropdown,
  setshowLimit
}: {
  getTime: any;
  preTime: any;
  setDropdown: any;
  setshowLimit: any
}) {
  const [minimum_range, setminimum_range] = useState(preTime?.minimum_range !== undefined ? preTime.minimum_range:null);
  const [maximum_range, setmaximum_range] = useState(preTime?.maximum_range !== undefined ? preTime.maximum_range:null);
  const [isChecked,setIschecked] = useState(preTime?.minimum_range===0 && preTime?.maximum_range===0 ?true:false);

  const timeBreak = { minimum_range, maximum_range };
  const returnTime = () => {
    getTime(timeBreak);
  };
  useEffect(() => {
    returnTime();
  }, [minimum_range, maximum_range]);

const handleClick = ()=>{
 if(!isChecked){
  setshowLimit(false); 
  setminimum_range(0);
  setmaximum_range(0);
  setIschecked(!isChecked);
 }else{
  setIschecked(!isChecked);
 }
}


  return (
    <div>
      <Box sx={{display:'flex',flexDirection:'row' ,margin:'10px',gap:'5px',alignItems:'center'}} >
        <Typography sx={{fontSize:'14px',fontFamily:"var(--font-regular)", color:"#22356D"}}>No Level Restriction</Typography>  <label className="switch">
            <input
              checked={isChecked}
              onClick={handleClick}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
      </Box>
      <div style={{opacity:isChecked?0.7:1,pointerEvents:isChecked?"none":'auto',cursor:isChecked?'not-allowed':'auto'}} className="flex flex-row items-center gap-1 p-2">
        <CustomDropdownMinutes
          width={'130px'}
          type={''}
          selectedOption={minimum_range}
          setSelectedOption={setminimum_range}
          placeholder={"start range"}
          listData={LevelRange}
          setDropdown={setDropdown}
        />
         <Typography sx={{fontFamily:"var(--font-regular)", color:"#22356D"}}>to</Typography>
        <CustomDropdownMinutes
          width={'130px'}
          type={''}
          selectedOption={maximum_range}
          setSelectedOption={setmaximum_range}
          placeholder={"end range"}
          listData={LevelRange}
          setDropdown={setDropdown}
        />
      </div>
    </div>
  );
}

export default LevelRangePicker;
