import { useQuery } from "react-query";
import { apiCaller } from "./ApiCaller";
import { useState } from "react";
import { getClubId } from "./CalendarBooking";


//get month status
const getMonthStatus = async (month_year:any, location_id:number) => {
    try {
          let club_id = getClubId();
        let res:any = await apiCaller.get(`${club_id}/calendars/monthly-stats/${location_id}/${month_year}`);
        return res; 
    } catch (error:any) {
        let err = error.response?.data?.message; 
        console.log(err);   
        return err;
    }
}

//get coach list 
const getCoachlist = async (locationId:number) => {
    try {
          let club_id = getClubId();
        let res:any = await apiCaller.get(`${club_id}/coaches/all/${locationId}`);
        return res;
    } catch (error:any) {
        const err = error.response?.data?.message;
        console.log(err);
        throw err;
    }
}



//get daily service list
// export const useGetCoachesDailyService = () => {
//       let club_id = getClubId();
//     const [location_id,setLocation_id] = useState<number>();
//     const [selectedDate,setSelectedDate] =useState<string>();

//     const query = useQuery(
//       ["Coaches-daily-view",selectedDate,location_id],
//       async () => {
//         const response: any = await apiCaller.get(`${club_id}/calendars/coaches-daily-view/${location_id}/${selectedDate}`);
//         return response.data.data;
//       },
//       {
//         enabled:!!location_id && !!selectedDate,
//         staleTime: 1000 * 60 * 5,
//         retry: false,
//       }
//     );
//     return {setLocation_id,setSelectedDate,...query}
//   };

export const useGetCoachesTimely = () => {
      let club_id = getClubId();
    const [location_id,setLocation_id] = useState<number>();
    const [selectedDT,setSelectedDT] =useState<any>();

    const query = useQuery(
      ["Coaches-time-by-court",selectedDT,location_id],
      async () => {
        const response: any = await apiCaller.get(`${club_id}/coaches/available-courts/${location_id}/?start_time=${
          selectedDT?.startTime + ":00"
        }&end_time=${selectedDT?.endTime + ":00"}&date=${selectedDT?.sDate}`);
        return response.data.data;
      },
      {
        enabled:!!location_id && !!selectedDT,
        staleTime: 1000 * 60 * 5,
        retry: false,
      }
    );
    return {setLocation_id,setSelectedDT,...query}
  };
  
  
  //get weekly coach list
export const useGetWeeklyServices = () => {
      let club_id = getClubId();
    const [coachId,setCoachID] = useState<number>();
    const [month_year,setMonthYear] =useState<string>();
    const[weekNumber,setWeekNumber] = useState<number>();

    const query = useQuery(
      ["coach-weekly-view-date",coachId,weekNumber,month_year],
      async () => {
        const response: any = await apiCaller.get(`${club_id}/calendars/coaches-weekly-view/${coachId}/${month_year}/${weekNumber}`);
        return response.data?.data;
      },
      {
        enabled:!!coachId && !!month_year && !!weekNumber,
        staleTime: 1000 * 60 * 5,
        retry: false,
      }
    );
    return {setWeekNumber,setMonthYear,setCoachID,...query}
  };
  
  
// get weekly service list // used react query
// const getWeeklyService = async (coachId:number,month_year:string, weekNumber:number) => {
//     try {
//           let club_id = getClubId();
//         let res:any = await apiCaller.get(`${club_id}/calendars/coaches-weekly-view/${coachId}/${month_year}/${weekNumber}`);
//         return res;
//     } catch (error:any) {
//         const err = error?.response?.data?.message;
//         console.log(err);
//         return err;
//     }
// }


// get weekly service list // used react query
// export const getCourtWeeklyService = async (court_id:number,month_year:string, weekNumber:number) => {
//   try {
//         let club_id = getClubId();
//       let res:any = await apiCaller.get(`${club_id}/calendars/courts-weekly-view/${court_id}/${month_year}/${weekNumber}`);
//       return res;
//   } catch (error:any) {
//       const err = error?.response?.data?.message;
//       console.log(err);
//       return err;
//   }
// }

export const useGetCourtsWeekly = () => {
    let club_id = getClubId();
  const [court_id,setCourt_id] = useState<number>();
  const [weekNumber,setWeekNumber] =useState<number>();
  const [month_year,setMonthYear]= useState<String>();

  const query = useQuery(
    ["Courts-wekly-view",month_year,court_id,weekNumber],
    async () => {
      const response: any = await apiCaller.get(`${club_id}/calendars/courts-weekly-view/${court_id}/${month_year}/${weekNumber}`);
      return response.data.data;
    },
    {
      enabled:!!weekNumber && !!month_year && !!court_id,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return {setWeekNumber,setCourt_id,setMonthYear,...query}
};

export const useGetCoachesWeekly = () => {
    let club_id = getClubId();
  const [coach_id,setcoach_id] = useState<number>();
  const [weekNumber,setWeekNumber] =useState<number>();
  const [month_year,setMonthYear]= useState<String>();

  const query = useQuery(
    ["Coachess-wekly-view",month_year,coach_id,weekNumber],
    async () => {
      const response: any = await apiCaller.get(`${club_id}/calendars/coaches-weekly-view/${coach_id}/${month_year}/${weekNumber}`);
      return response.data.data;
    },
    {
      enabled:!!weekNumber && !!month_year && !!coach_id,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return {setWeekNumber,setcoach_id,setMonthYear,...query}
};


//get daily service list
export const useGetCoachesDailyStats = () => {
      let club_id = getClubId();
    const [locationId,setLocation_id] = useState<number>();
    const [sports, setSports] = useState([]);
    const [selectedDate,setSelectedDate] =useState<string>();


    const queryString = sports.map(id => `sportId=${id}`).join('&');

    const query = useQuery(
      ["Coaches-daily-view-stats",selectedDate,locationId,sports],
      async () => {
        const response: any = await apiCaller.get(`${club_id}/calendars/coaches-stats/${locationId}?start_date=${selectedDate}&end_date=${selectedDate}&${queryString}`);
        return response.data.data;
      },
      {
        enabled:!!locationId && !!selectedDate,
        staleTime: 1000 * 60 * 5,
        retry: false,
      }
    );
    return {setLocation_id,setSelectedDate,setSports,...query}
  };
  
  



export { getMonthStatus, getCoachlist};
