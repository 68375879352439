import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MainContext from 'src/context/MainContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { RxHamburgerMenu } from "react-icons/rx";
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';


const iconsList: any[] = [
  {
    "Calendar": '/images/calendars.png'
  },
  {
    "Payments": '/images/file.png'
  },
  // {
  //   "Bookings": '/images/book.png'
  // },
  {
    "Customers": '/images/user.svg'
  },
  {
    "Services": '/images/tennisBall.png'
  },
  {
    "Courts": '/images/court 1.svg'
  },
   {
    "Settings":"/images/settings.png"
  },
  {
    POS: "/images/shoppingCart.svg",
  },
  {
    "Stats":"/images/statistics.png"
  },
  {
    "Logout":'/images/logout.svg'
  }
]

export default function TemporaryDrawer() {
  const {t} = useTranslation();
  const {MobileSideBar, setMobileSideBar} = React.useContext(MainContext);

  const toggleDrawer = (newOpen: boolean) => () => {
    setMobileSideBar(newOpen);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const context = React.useContext(MainContext);
  const setAuthenticated = context?.setAuthenticated;


  const theme = useTheme();
  
  const isActive = (path: string) => {
    if (path === "calender" && location.pathname === "/") {
      return true;
    }
    return location.pathname.includes(path);
  };

  const getImageUrl = (key: string): string | undefined => {
    const icon = iconsList.find(item => Object.keys(item)[0] === key);
    return icon ? icon[key] : undefined;
  };
 
  const handleNavigation = (path: string) => {
    navigate(path);
    setMobileSideBar(false);
  };

  React.useEffect(()=>{
    document.addEventListener("click", () => {
      setMobileSideBar(false);
    });
  },[])


  const DrawerList = (
    <Box sx={{ 
      width: 80,
      background:'linear-gradient(0deg, rgba(136, 154, 182, 0.15), rgba(136, 154, 182, 0.15)), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
      height:{xs:'100%',sm:"100%", md:"100%"},
      paddingTop:'40px'
      }} 
      role="presentation" 
      >
        <List>
          <ListItem  disablePadding sx={{ display: "block" }} >
            <ListItemButton
            onClick={toggleDrawer(false)}
              sx={{
                minHeight: 28,
                justifyContent: "center",
                px: 2.5
              }}>
              <ListItemIcon 
                sx={{
                  minWidth: 0,
                  display:'flex',
                  alignItems:"center",
                  justifyContent: "center",
                  borderRadius: '7px',
                  padding: '7px',
                }}>
                  <RxHamburgerMenu color='#22356D' fontSize={"20px"} fontWeight={"500"}/>
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        {["Calendar","Payments","Customers","Services", "Courts", "Settings","POS","Stats","Logout"].map((text, index) => (
          <ListItem key={text} disablePadding sx={{ display: "block" }} >
            <ListItemButton
              onClick={() => handleNavigation(text.toLowerCase())}
              sx={{
                minHeight: 28,
                display:'flex',
                alignItems:"center",
                flexDirection:"column",
                justifyContent: "center",
                px: 0,
              }}>
              <ListItemIcon 
                sx={{
                  minWidth: 0,
                  display:'flex',
                  alignItems:"center",
                  justifyContent: "center",
                  backgroundColor: isActive(text.toLowerCase()) ? "#22356D" : "#9ca5be",
                  borderRadius: '7px',
                  padding: '7px',
                }}>
                <img src={getImageUrl(text)} alt={text} style={{width:"22px", height:"22px"}} />
              </ListItemIcon>
              <Typography sx={{fontSize:"0.87rem", fontFamily:"var(--font-regular)", color:"#707da1"}}>{t(`title.${text}`)}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
         </Box>
  );

  return (
    <div>
      
      <Drawer hideBackdrop onClose={toggleDrawer(false)} open={MobileSideBar}>
        {DrawerList}
      </Drawer>
    </div>
  );
}